import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule }  from "@angular/forms";
import { StripeModule } from "stripe-angular";
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { SearchComponent } from './search/search.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProfileComponent } from './profile/profile.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { TermsComponent } from './terms/terms.component';
import { LicenseComponent } from './license/license.component';
import { MemberComponent } from './member/member.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LoginComponent } from './login/login.component';
import { BlogsComponent } from './blogs/blogs.component';
import { BlogDetailsComponent } from './blogs/blog-details/blog-details.component';
import { IconsComponent } from './icons/icons.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { FigmaComponent } from './figma/figma.component';
import { SketchComponent } from './sketch/sketch.component';
import { ThreeDimensionComponent } from './three-dimension/three-dimension.component';
import { FaqsComponent } from './faqs/faqs.component';
import { IllusComponent } from './illus/illus.component';
import { PaymentComponent } from './payment/payment.component';
import { SalesComponent } from './sales/sales.component';
import {GoogleAnalyticsService} from './google-analytics.service';
import { MarketingComponent } from './marketing/marketing.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { PricingComponent } from './pricing/pricing.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { IllustratorComponent } from './illustrator/illustrator.component';
import { HometwoComponent } from './hometwo/hometwo.component';
import { CagesComponent } from './cages/cages.component';
import { CagesiconComponent } from './cagesicon/cagesicon.component';
import { PluginComponent } from './plugin/plugin.component';
import { UploadDetailsComponent } from './upload-details/upload-details.component';
import { KaleidoscopeComponent } from './kaleidoscope/kaleidoscope.component';
import { IsoAllComponent } from './iso-all/iso-all.component';
import { TranslateAiComponent } from './translate-ai/translate-ai.component';
import { ImgFigmaComponent } from './img-figma/img-figma.component';

let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("555466977776-4f0b92s51tkr1pme5cvt74c7srnqt537.apps.googleusercontent.com")
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("248686746478971")
  }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    SearchComponent,
    ProfileComponent,
    EditProfileComponent,
    TermsComponent,
    LicenseComponent,
    MemberComponent,
    ContactUsComponent,
    LoginComponent,
    BlogsComponent,
    BlogDetailsComponent,
    IconsComponent,
    AboutUsComponent,
    FigmaComponent,
    SketchComponent,
    ThreeDimensionComponent,
    FaqsComponent,
    IllusComponent,
    PaymentComponent,
    SalesComponent,
    MarketingComponent,
    CheckoutComponent,
    PricingComponent,
    MaintenanceComponent,
    IllustratorComponent,
    HometwoComponent,
    CagesComponent,
    CagesiconComponent,
    PluginComponent,
    UploadDetailsComponent,
    KaleidoscopeComponent,
    IsoAllComponent,
    TranslateAiComponent,
    ImgFigmaComponent,
  ],
  imports: [
    BrowserModule,
    NgxCaptchaModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    SocialLoginModule,
    ReactiveFormsModule,
    NgHttpLoaderModule.forRoot(),
    InfiniteScrollModule,
    StripeModule.forRoot(environment.STRIPE_TEST_KEY)
  ],
  providers: [
    GoogleAnalyticsService,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
