import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private httpClient: HttpClient) { }

  private preLoaddedCategoriesData = new BehaviorSubject<any>(null);
  public preLoaddedCategoriesData$ = this.preLoaddedCategoriesData.asObservable();
  api_root: string = environment.REST_API_SERVER;;
  categories_api: string = 'categories/';

  getCategories() {
    return this.httpClient.get(this.api_root + this.categories_api);
  }

  setCategoriesData(data: any): void {
    this.preLoaddedCategoriesData.next(data)
  }
}
