import { Component, OnInit, ViewChild } from '@angular/core';
import { IconsService } from './service/icons.service';
import { SharedService } from '../shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { AlertService } from '../components/alert/alert.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'angularx-social-login';
import { AuthenticationService } from '../authentication.service';
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { Title, Meta } from '@angular/platform-browser';
import { PaginationService } from "../pagination/pagination.service";


@Component({
  selector: 'app-search',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.css']
})

export class IconsComponent implements OnInit {

  title = 'Free Open Source SVG Icons for Design Projects';
  limit: number = 20;
  offset: number = 0;
  next: string;
  constructor(
    private iconsService: IconsService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private alert: AlertService,
    private modalService: NgbModal,
    public router: Router,
    private authService: AuthService,
    private authenticationService: AuthenticationService,
    private activateRoute: ActivatedRoute,
    private titleService: Title,
    private metaTagService: Meta,
    private paginationService: PaginationService
  ) {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }
  loginModal: any;
  returnUrl: any;
  icons = [];
  iconsCategory: [];
  searchText: string = '';
  submitted: boolean;
  categories: [];
  categoriesCheckbox: [];
  selectedCategories: string;
  checkedIDs = [];
  headerCategory: string;
  categorySelected: boolean = false;
  paginationLoading: boolean = false;
  searchTextEntered: string;
  showLimit: boolean = true;
  currentIllus: any;
  currentUser: any;
  iconsSimilar: [];
  @ViewChild('limitModal') limitModal;
  @ViewChild('downloadModal') downloadModal;
  @ViewChild('loginModal') loginModalAttr;

  public lat;
  public lng;

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'description', content: 'Get open source SVG icons absolutely free for mobile, web and design projects. Get free royalty free SVG icons with Crafttor.'},
      { name: 'keywords', content: 'free icons download, free brand icons, free brand logos, website icons, app icons, svg icons, vector icons, high quality svg icons, download free svg icons, free vector icons' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Crafttor' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);

    this.route.queryParams.subscribe(params => {
      this.searchTextEntered = '';
      this.iconsSimilar = [];
      this.headerCategory = params['category'];
      this.searchText = params['search'];
      this.searchIcons(this.searchText);
      this.makeCategoriesChecklist(this.headerCategory);
      if (this.headerCategory) {
        this.categorySelected = true;
      }
      this.currentUser = this.authenticationService.currentUserValue;
    });

    this.authService.authState.subscribe((user) => {
      if (user && !this.authenticationService.currentUserValue) {
        this.loginToServer(user, user.photoUrl);
      }
    });

    this.activateRoute.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'];
    });

    this.getLocation();
    this.getDataValue();
  }

  getDataValue(){
    var details = JSON.parse(localStorage.getItem('searchIconsDetails'));
    this.searchText = details.searchKey;
    this.searchIcons(this.searchText);
    this.getIconCatgories();
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: Position) => {
        if (position) {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
        }
      },
        (error: PositionError) => console.log(error));
    } else {
      // alert("Geolocation is not supported by this browser.");
    }
  }


  open(content: any) {
    this.loginModal = this.modalService.open(content, { centered: true, windowClass: 'login-modal' })
  }

  openClose() {
    this.loginModal.close();
  }


  loginToServer(user: any = null, photoUrl: string) {
    this.authenticationService.login(user.provider.toLowerCase(), user.id,
    user.authToken, user.email, user.firstName, user.lastName,
    photoUrl, this.lat, this.lng)
      .subscribe(
        (_data) => {
          if (this.returnUrl) {
            this.router.navigate([this.returnUrl]);
          }
          this.loginModal.close();

        }
      );
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    // this.loginToServer();
  }

  fbSignOut(): void {
    this.authService.signOut();
  }

  logOut(): void {
    this.authenticationService.logout();
    this.authService.signOut();
  }

  private makeCategoriesChecklist(headerCategory: string) {
    this.sharedService.preLoaddedCategoriesData$.subscribe((data) => {
      if (data) {

        this.categories = data['results'];
        this.categoriesCheckbox = data['results'].map(x => {

          if (headerCategory) {
            if (headerCategory == x.id) {
              this.selectedCategories = x.id;
              return {
                id: x.id,
                isChecked: true,
                label: x.name
              }
            }
            else {
              return {
                id: x.id,
                isChecked: false,
                label: x.name
              }
            }


          } else {
            return {
              id: x.id,
              isChecked: true,
              label: x.name
            }
          }

        }
        );
      }
    });
  }
  openDownloadModal(modal) {
    this.downloadModal = this.modalService.open(modal, { centered: true, windowClass: 'download-assests ' });
  }
  openLimitModal(modal) {
    this.modalService.open(modal, { centered: true, windowClass: 'premium-pop ' });
  }
  closeLimitModal() {
    this.limitModal.close();
  }
  closeDownloadModal() {
    this.downloadModal.close();
  }

  categoryChanged() {
    this.checkedIDs = [];
    this.checkedIDs = this.categoriesCheckbox.filter((value) => {
      return value['isChecked'];
    });
    this.selectedCategories = '';
    this.selectedCategories = this.selectedCategories + this.checkedIDs.map(category =>
      category.id
    );
    if (this.selectedCategories) {
      this.searchIcons(this.searchText);
    }

  }

  onSubmit() {
    this.searchIcons(this.searchText);
  }

  private searchCompleted(searchText: string, data: any, appendData: boolean = false) {
    if (appendData) {
      this.icons.push.apply(this.icons, data['results'])
    }
    else {
      this.icons = data['results'];
    }
    this.next = data['next'];
    this.iconsSimilar = [];
    if (searchText) {

      this.searchTextEntered = searchText;
    }
    if (this.icons.length <= 10) {
      this.iconsService.similarIcons().subscribe((data: any) => {
        this.iconsSimilar = data;
      });
    }
  }

  searchIcons(searchText: string = "", limit: number = this.limit): void {
    this.iconsService.searchIcons(searchText, limit).subscribe((data: any) => {
      this.searchCompleted(searchText, data);
    });
  }

  onScrollDown() {
    if(this.next){
      this.paginationLoading = true;
      this.paginationService.getFromUrl(this.next).subscribe(data => {
        this.searchCompleted(this.searchText, data, true);
        this.paginationLoading = false;
      }, (error: any) => {
        this.paginationLoading = false;
      });
    }
  }

  goToLink(link: string) {
    if (this.limitModal) {
      this.limitModal.close()
    }
  }

  downloadPngImage(): void {
    this.iconsService.downloadPng(this.currentIllus.id).subscribe((data: any) => {
      // console.log("data received===>", data)
      saveAs(data, this.currentIllus.title+'.png')
    },
      (error: any) => {
        if (error.status === 403) {
          this.openLimitModal(this.limitModal);

        }
        else {
          // this.alert.alert("Alert", "Limit reached! Please login to download", () => {
          //   console.log('Ok')
          // })
          this.downloadModal.close();
          this.loginModal = this.modalService.open(this.loginModalAttr, { centered: true, windowClass:
             'dark-modal exceed-limit login-modal' })

        }
      }
    );

  }
  downloadSvgImage(): void {
    this.iconsService.downloadSvg(this.currentIllus.id).subscribe((data: any) => {
      saveAs(data, this.currentIllus.title+'.svg')
    },
      (error: any) => {
        if (error.status === 403) {
          this.openLimitModal(this.limitModal);
        }
        else {
          // this.alert.alert("Alert", "Please login to download", () => {
          //   console.log('Ok')
          // })
          // this.limitModal.close();
          this.downloadModal.close();
          this.loginModal = this.modalService.open(this.loginModalAttr,
             { centered: true, windowClass: 'dark-modal exceed-limit login-modal' })

        }
      }
    );
  }

  public selectIconsValue(category: any) {
     localStorage.setItem('iconCategoryDetails', JSON.stringify({ id: category.id, name: category.name,
       image: category.image, description: category.description }));
  }

  getIconCatgories() {
    this.iconsService.iconsCategory().subscribe((data: any) => {
      this.iconsCategory = data['results'];
    });
  }
}
