import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IllusService {

  constructor(private httpClient: HttpClient) { }
  api_root: string;
  search_illus_api: string = 'illus/'
  similar_illus_api: string = 'illus/similar/'
  public searchIllus(search: string = "", categories: string = "", limit: number=10, offset: number=0) {
    this.api_root = environment.REST_API_SERVER;
    let query_params: string = '';

    let search_url = this.api_root + this.search_illus_api;
    if (search) { query_params += "search=" + search };
    if (categories) { query_params += "&categories=" + categories };
    if (limit) { query_params += "&limit=" + limit };
    if (offset) { query_params += "&offset=" + offset };
    if (query_params) {
      search_url += '?' + query_params;
    }
    return this.httpClient.get(search_url);
  }
  public similarIllus() {
    this.api_root = environment.REST_API_SERVER;

    let similar_url = this.api_root + this.similar_illus_api;

    return this.httpClient.get(similar_url);
  }

  public downloadSvg(id: number) {

    const url = `${environment.REST_API_SERVER}illus/${id}/download_svg_image/`
    return this.httpClient.get(url, { responseType: 'blob' })

  }

  public downloadPng(id: number) {

    const url = `${environment.REST_API_SERVER}illus/${id}/download_png_image/`
    return this.httpClient.get(url, { responseType: 'blob' })

  }

  }
