<header class="masthead fixed-top">
  <!-- <div class="" style="background: #FFE5BE;">
    <p style="text-align: center;"><img style="width: 30px;" src="assets/img/christ-tree.png" alt="">  Seasonal Special: Enjoy a <b>50%</b> Discount on Any Plan!  <img src="assets/img/skate.png" alt=""></p>
  </div> -->
  <!-- Navigation -->
  <nav class="navbar navbar-expand-lg" id="mainNav">
    <div class="container">
      <a class="navbar-brand js-scroll-trigger" (click)="blogClicked=false;" href="#" routerLink="/"><img src="assets/img/logo.svg" alt="crafttor"></a>
      <div class="blog-area" *ngIf="blogClicked">
        <p><b>Blog</b></p>
      </div>

      <li class="nav-item login-btn" *ngIf="!currentUser && mobileView">
        <a class="nav-link btn" (click)="open(mymodal)">Login</a>
      </li>
      <li class="nav-item user-id dropdown" *ngIf="currentUser && mobileView">
        <a class="nav-link" href="javascript:;" data-toggle="dropdown">
          <img *ngIf="!userPic" src="assets/img/user-profile.svg">
					<img *ngIf="userPic" src="{{userPic}}">
          {{currentUser.firstName+" "+currentUser.lastName}} </a>
        <ul class="dropdown-menu">
          <li><a class="nav-link" href="javascript:;" (click)="blogClicked=false;" routerLink="profile/">Profile</a></li>
          <li><a class="nav-link" href="javascript:;" (click)="logOut()">Log Out</a></li>
        </ul>
      </li>
      <!-- <li class="nav-item dropdown search-nav" *ngIf="mobileView">
        <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="javascript:;"><img src="assets/img/search.svg" alt="search free illustrations"></a>
        <div class="dropdown-menu">
          <form class="search" (submit)="goToSearch()">
            <input type="search" [(ngModel)]="searchText" name="searchText" placeholder="Search illustration"><button type="submit" >Explore</button>
          </form>
        </div>
      </li> -->

      <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
      </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="javascript:;">Products</a>
            <ul class="dropdown-menu">
              <li><a class="nav-link" href="javascript:;" (click)="blogClicked=false; openWorkspace()"> Workspace </a></li>
              <li><a class="nav-link" href="javascript:;" (click)="blogClicked=false;" routerLink="/marketplace"> Marketplace </a>
              </li>
            </ul>
          </li>

            <!-- <li class="nav-item">
              <a class="nav-link" href="javascript:;" (click)="loadDataOnly('3d'); blogClicked=false;" routerLink="search/">3D Assets<button class="new-3d">New</button></a>
            </li> -->

            <li class="nav-item">
                <a class="nav-link" href="javascript:;" (click)="loadDataOnly('2d'); blogClicked=false;" routerLink="search/">Illustrations</a>
            </li>

            <li class="nav-item">
              <a class="nav-link" href="javascript:;" (click)="blogClicked=false;" routerLink="/icons">Icons</a>
            </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#">Plugins</a>
            <ul class="dropdown-menu">
              <li><a class="nav-link" (click)="blogClicked=false;" routerLink="/figma">
                <img src="assets/img/ic-figma.png" alt=""> Figma </a></li>
              <li><a class="nav-link" href="javascript:;" (click)="blogClicked=false;" routerLink="/sketch">
                <img src="assets/img/ic-sketch.png" alt=""> Sketch </a></li>
              <li><a class="nav-link" href="javascript:;" (click)="blogClicked=false;" routerLink="/illustrator">
                <img src="assets/img/aii.svg" alt="" style="width: 32px; height: 32px;"> Illustrator </a></li>
            </ul>
          </li>

          <li class="nav-item">
            <a class="nav-link" href="javascript:;" (click)="blogClicked=true;" routerLink="blogs">Blog</a>
          </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="javascript:;">Pricing</a>
            <ul class="dropdown-menu">
              <li><a class="nav-link" href="javascript:;" (click)="blogClicked=false;" (click)="openWorkspacePricing()"> Workspace </a></li>
              <li><a class="nav-link" href="javascript:;" (click)="blogClicked=false;" routerLink="/member"> Marketplace </a>
              </li>
            </ul>
          </li>

          <li class="nav-item search-nav">
            <a class="nav-link" href="javascript:;" routerLink="/search"><img src="assets/img/search.svg" alt="search free illustrations"></a>
          </li>
          <!-- <li class="nav-item dropdown search-nav" *ngIf="!mobileView">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="javascript:;"><img src="assets/img/search.svg" alt="search free illustrations"></a>
            <div class="dropdown-menu">
              <form class="search" (submit)="goToSearch()">
                <input type="search" [(ngModel)]="searchText" name="searchText" placeholder="Search Illustrations"><button type="submit" >Explore</button>
              </form>
            </div>
          </li> -->
          <!-- <li class="nav-item login-btn" *ngIf="!currentUser && !mobileView">
            <a class="nav-link-login btn" (click)="open(mymodal); socialLogin()">Login</a>
          </li> -->
          <li class="nav-item login-btn dropdown" *ngIf="!currentUser && !mobileView">
            <a class="nav-link btn" data-toggle="dropdown">Sign in for Free</a>
            <ul class="dropdown-menu">
              <li><a class="nav-link" href="javascript:;" (click)="openWorkspaceLogin()"> Workspace </a></li>
              <li><a class="nav-link" href="javascript:;" (click)="open(mymodal); socialLogin(); blogClicked=false;"> Marketplace </a>
              </li>
            </ul>
          </li>

          <li class="nav-item user-id dropdown" *ngIf="currentUser  && !mobileView">
            <a class="nav-link" href="javascript:;" data-toggle="dropdown">
              <img *ngIf="!userPic" src="assets/img/user-profile.svg">
              <img *ngIf="userPic" src="{{userPic}}">
              {{currentUser.firstName+" "+currentUser.lastName}} </a>
            <ul class="dropdown-menu">
              <li><a class="nav-link" href="javascript:;" routerLink="profile/">Profile</a></li>
              <li><a class="nav-link" href="javascript:;" (click)="logOut()">Log Out</a></li>
            </ul>
          </li>

        </ul>
      </div>
    </div>
  </nav>
</header>

<ng-template #mymodal let-modal>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="login-content">
      <button type="button" class="close" (click)="openClose()" data-dismiss="modal" aria-label="Close">
        <img src="assets/img/cross.svg">
      </button>
      <div class="modal-body login-dialog">
        <h3>{{loginSignUpText}}</h3>
        <div class="button-wrapper">
          <a href="javascript:;" class="button google-btn" (click)="signInWithGoogle()" *ngIf="socialLoginSection"><i class="fa fa-google-plus"></i> Continue with Google</a>
          <!-- <a href="javascript:;" class="button fb-btn" (click)="signInWithFB()"><i class="fa fa-facebook-f"></i>Continue with Facebook</a> -->
          <a href="javascript:;" class="button manual-btn" (click)="manualLogin()" *ngIf="socialLoginManualSection"><i class="fa fa-envelope" style="padding-right: 8px;"></i>Continue with Email</a>

          <div class="or-section">
            <hr class="hr-area">
            <span class="or-text">Or</span>
            <hr class="hr-area">
          </div>


          <div class="container" *ngIf="login">
            <form action="" [formGroup]="loginForm" (ngSubmit)="loginFunction()">
              <input id="textinput" formControlName="email" type="email" placeholder="Enter email id*" required>
              <span class="text-danger error-text"
              *ngIf="(loginForm.controls.email.touched || submitted) && loginForm.controls.email.errors?.required">
              Email is required
            </span>
            <span class="text-danger error-text"
              *ngIf="(loginForm.controls.email.touched || submitted) && loginForm.controls.email.errors?.pattern">
              Email is not valid
            </span>
            <input id="password" formControlName="password" type="password" placeholder="Enter password*" required>
              <span class="text-danger error-text"
              *ngIf="(loginForm.controls.password.touched || submitted) && loginForm.controls.password.errors?.required">
              Password is required
            </span>

              <label style="margin-bottom: 10px;color: #2c85ed; display: block; text-align: left;
              clear: both;" class="control-label" for="textinput"><a href="javascript:;" (click)="forgotScreen()">Forgot Password?</a></label>

              <button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

              <button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="!showLeaveLoader">Submit</button>

              <p class="below-txt">New at Crafttor? <a href="javascript:;" (click)="socialSignUp()" style="color: #2c85ed"><b>Sign Up</b></a></p>
              <!-- <a href="javascript:;" (click)="socialLogin()" style="color: #2c85ed">Back</a> -->

              <!-- <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt=""> -->
              </form>
            </div>

            <div class="container" *ngIf="emailVerify">
              <form action="" [formGroup]="emailVerifyForm" (ngSubmit)="getOTP()">
              <input id="textinput" formControlName="email" type="email" placeholder="Enter email id*" required>
              <span class="text-danger error-text"
              *ngIf="(emailVerifyForm.controls.email.touched || emailVerifySubmit) && emailVerifyForm.controls.email.errors?.required">
              Email is required
            </span>
            <span class="text-danger error-text"
            *ngIf="(emailVerifyForm.controls.email.touched || emailVerifySubmit) && emailVerifyForm.controls.email.errors?.pattern">
            Email is not valid
          </span>

          <button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

          <button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="!showLeaveLoader">Get OTP</button>

          <p class="below-txt">Already have an account? <a href="javascript:;" (click)="socialLogin()" style="color: #2c85ed"><b>Login</b></a></p>
          <!-- <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt=""> -->
          </form>
          </div>

          <div class="container" *ngIf="signup">
            <form action="" [formGroup]="signUpForm" (ngSubmit)="signUpFormFunction()">
              <input id="textinput" formControlName="fname" type="text" placeholder="Enter first name*" required style="float: left; width: 48%">
              <input id="textinput" formControlName="lname" type="text" placeholder="Enter last name*" required style="float: right; width: 48%">
              <span class="text-danger error-text"
              *ngIf="(signUpForm.controls.fname.touched || submitt) && signUpForm.controls.fname.errors?.required">
              First Name is required
            </span>
              <span class="text-danger error-text" style="float: right;"
              *ngIf="(signUpForm.controls.lname.touched || submitt) && signUpForm.controls.lname.errors?.required">
              Last Name is required
            </span>
            <input id="password" formControlName="password" type="password" placeholder="Enter password*" required>
            <span class="text-danger error-text"
              *ngIf="(signUpForm.controls.password.touched || submitt) && signUpForm.controls.password.errors?.required">
              Password is required
            </span>

            <input id="textinput" formControlName="otp" type="text" placeholder="Enter OTP*" required>
            <span class="text-danger error-text"
              *ngIf="(signUpForm.controls.otp.touched || submitt) && signUpForm.controls.otp.errors?.required">
              OTP is required
            </span>

            <button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

            <button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="!showLeaveLoader">Submit</button>

            <p class="below-txt">Already have an account? <a href="javascript:;" (click)="showLoginScreen()" style="color: #2c85ed"><b>Login</b></a></p>
              <!-- <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt=""> -->
          </form>
        </div>

        <div class="container" *ngIf="forgotpassword">
          <form action="" [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPasswordFunction()">
            <input id="textinput" formControlName="email" type="email" placeholder="Enter email id*" required>
            <span class="text-danger error-text"
            *ngIf="(forgotPasswordForm.controls.email.touched || submittedForm) && forgotPasswordForm.controls.email.errors?.required">
              Email is required
            </span>
            <span class="text-danger error-text"
            *ngIf="(forgotPasswordForm.controls.email.touched || submittedForm) && forgotPasswordForm.controls.email.errors?.pattern">
              Email is not valid
            </span>

            <button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

            <button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="!showLeaveLoader">Get OTP</button>
            <p class="below-txt"><a href="javascript:;" (click)="showLoginScreen()" style="color: #2c85ed">Return to Login</a></p>
            <!-- <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt=""> -->
            </form>
          </div>

          <div class="container" *ngIf="enterOtp">
            <form action="" [formGroup]="enterOtpForm" (ngSubmit)="resetPasswordFunction()">
              <input id="otp" formControlName="otp" type="text" placeholder="Enter OTP*" required>
              <span class="text-danger error-text"
              *ngIf="(enterOtpForm.controls.otp.touched || passwordSubmit) && enterOtpForm.controls.otp.errors?.required">
              Enter OTP
            </span>

            <input id="password" formControlName="password" type="password" placeholder="Enter password*" required>
            <span class="text-danger error-text"
            *ngIf="(enterOtpForm.controls.password.touched || passwordSubmit) && enterOtpForm.controls.password.errors?.required">
            Enter new password
          </span>
          <input id="repassword" formControlName="repassword" type="password" placeholder="Re-enter password*" required>
          <span class="text-danger error-text"
            *ngIf="(enterOtpForm.controls.repassword.touched || passwordSubmit) && enterOtpForm.controls.repassword.errors?.required">
            Re-enter password
          </span>

            <button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

            <button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="!showLeaveLoader">Submit</button>
              <p class="below-txt"><a href="javascript:;" (click)="showLoginScreen()" style="color: #2c85ed">Return to Login</a></p>
              <!-- <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt=""> -->
              </form>
            </div>

          <p>By Login, You agree to our <a href="javascript:;" (click)="openClose()" [routerLink]="['privacy-terms', {active: 'terms'}]">Terms of Use</a> & <a href="javascript:;" (click)="openClose()"
            [routerLink]="['privacy-terms', {active: 'privacy'}]">Privacy Policy</a>.</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
