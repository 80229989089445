import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IconsService {

  constructor(private httpClient: HttpClient) { }
  api_root: string;
  search_cages_api: string = 'cages/'
  similar_cages_api: string = 'cages/similar/'
  public searchIcons(search: string = "", limit: number=10, offset: number=0) {
    this.api_root = environment.REST_API_SERVER;
    let query_params: string = '';

    let icon_url = this.api_root + this.search_cages_api;
    if (search) { query_params += "search=" + search };
    // if (categories) { query_params += "&categories=" + categories };
    if (limit) { query_params += "&limit=" + limit };
    if (offset) { query_params += "&offset=" + offset };
    if (query_params) {
      icon_url += '?' + query_params;
    }
    console.log("oiuhioqjoiqjq", icon_url)
    return this.httpClient.get(icon_url);
  }

  public iconsCategory() {
    this.api_root = environment.REST_API_SERVER;
    let similar_url = this.api_root + 'icon_categories/';
    return this.httpClient.get(similar_url);
  }

  public iconsList(iconId: AttestationConveyancePreference) {
    this.api_root = environment.REST_API_SERVER;
    let similar_url = this.api_root + `icon_categories/${iconId}/icons/`;
    return this.httpClient.get(similar_url);
  }

  public similarIcons() {
    this.api_root = environment.REST_API_SERVER;
    let similar_url = this.api_root + this.similar_cages_api;
    return this.httpClient.get(similar_url);
  }

  public downloadSvg(id: number) {
    const url = `${environment.REST_API_SERVER}cages/${id}/download_svg_image/`
    return this.httpClient.get(url, { responseType: 'blob' })
  }

  public downloadPng(id: number) {
    const url = `${environment.REST_API_SERVER}cages/${id}/download_png_image/`
    return this.httpClient.get(url, { responseType: 'blob' })
  }
}
