import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
// import { Blog } from '../_models/blog';
import { BlogsService } from './blogs.service';
import { PaginationService } from '../pagination/pagination.service';


@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent implements OnInit {

  title = 'Blog | Crafttor';
  recentBlogs: any;
  popularBlogs: any;
  latestBlog: any;
  blogDetails: boolean = false;
  blogs: boolean = true;
  limit: number = 10;
  paginationLoading: boolean = false;
  offset: number = 0;
  next: string;

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private paginationService: PaginationService,
    private blogService: BlogsService
  ) { }

  ngOnInit(): void {
      this.titleService.setTitle(this.title);
      this.metaTagService.addTags([
        { name: 'description', content: 'Make your work more appealing with the mix & match combinations of unlimited illustration styles for commercial and non commercial projects.'},
        { name: 'keywords', content: 'Free illustrations download, royality free illustrations, illustrations for free, free illustrations online' },
        { name: 'robots', content: 'index, follow' },
        { name: 'author', content: 'Crafttor' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { charset: 'UTF-8' }
      ]);
      this.getRecentBlogs();
      this.getPopularBlogs();
  }

  private getRecentBlogs(){
    this.blogService.getBlogs('-created_at',).subscribe((data)=>{
      this.recentBlogs = data['results'];
      this.latestBlog = data['results'][0];
      this.next = data['next'];
    });
  }

  private getPopularBlogs(){
    this.blogService.getBlogs('-popular', 3).subscribe((data)=>{
      this.popularBlogs = data['results'];
    });
  }

  onScrollDown() {
    this.paginationLoading = true;
    this.paginationService.getFromUrl(this.next).subscribe(data => {
      this.paginationLoading = false;
      this.recentBlogs.push.apply(this.recentBlogs, data['results'])
      this.next = data['next'];
    }, (error: any) => {
      this.paginationLoading = false;
    });
  }
}
