<!-- Search Section -->
<section class="search-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-box">
					<h1>Discover High Quality <br>Illustration Resources</h1>
					<!-- <form class="custom-form">
					   <input type="text" placeholder="Type somthing" class="text-field">
					   <input type="Submit" value="Search" class="button">
					</form> -->
					<div class="switcher">
						<input type="radio" name="subscription" value="yearly" id="yearly" [checked]="radioYearly" (click)="twoDimensionFunc(); radioMontly=false; radioYearly=true;">
						<label for="yearly">2D</label>
						<input type="radio" name="subscription" value="monthly" id="monthly" [checked]="radioMontly"  (click)="threeDimensionFunc(); radioYearly=false; radioMontly=true;">
						<label for="monthly">3D</label>
					</div>
					<form *ngIf="twoDimension" class="custom-form" (ngSubmit)="onSubmit()" #heroForm="ngForm">
						<input type="text" placeholder="Search 2D Illustrations" class="text-field" name="searchText" [(ngModel)]="searchText">
						<input type="Submit" value="Search" class="button">
					</form>
					<form *ngIf="!twoDimension" class="custom-form" (ngSubmit)="onSubmit3D()" #heroForm="ngForm">
						<input type="text"  placeholder="Search 3D Illustrations" class="text-field" name="searchText3D" [(ngModel)]="searchText3D">
						<input type="Submit" value="Search" class="button">
					</form>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- Global Section -->
<section class="global-section" *ngIf="twoDimension">
	<div class=" left">
		<div class="row">
			<div class="col-sm-12">
				<div class="top-head" *ngIf="illustrations && illustrations?.length > 0">
					<h5>Vector Styles</h5>
					<ul class="checklist" *ngFor="let category of categoriesCheckbox">
						<div class="" *ngIf="category.type == '3d'; else elseBlock">
						</div>

						<ng-template #elseBlock>
							<div class="" #elseBlock>
								<li class="custom-control custom-checkbox">
								<input (change)="categoryChanged()" [(ngModel)]="category.isChecked" type="checkbox" class="custom-control-input" [id]="'vector-'+category.id">
								<input (change)="categoryChanged()" [(ngModel)]="category.isChecked" type="checkbox" class="custom-control-input" [id]="'vector-'+category.id">
								<label class="custom-control-label" [for]="'vector-'+category.id">{{ category.label }} </label>
								</li>
							</div>
						</ng-template>
					</ul>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- Vectors Section -->
<section class="vectors-section">
	<div class="container">
		<ul class="load-vector" infiniteScroll
		[infiniteScrollDistance]="1"
		[infiniteScrollUpDistance]="2"
		[infiniteScrollThrottle]="50"
		(scrolled)="onScrollDown()">
			<li *ngFor="let illus of illustrations">
				<div class="inner-card" (click)="currentIllus= illus;openDownloadModal(downloadModal)">
					<img *ngIf="twoDimension" [src]="illus.image_resize" alt="high quality 2D illustrations">
					<img *ngIf="!twoDimension" [src]="illus.image_resize" alt="high quality 3D illustrations">
					<div class="hover-style">
						<div class="title">{{ illus.title }}</div>
					</div>
				</div>
			</li>
		</ul>
		<div *ngIf="illustrations && illustrations?.length == 0" style="text-align: center;">
			<h3>No results found</h3>
			<br>
			<br>
		</div>
		<h3  *ngIf="illusSimilar?.length > 0" style="font-weight: bold; margin-top: 60px;">Other Illustrations</h3>
		<ul *ngIf="illusSimilar?.length > 0"  class="load-vector" style="margin-top: 0px;">
			<li *ngFor="let illus of illusSimilar">
				<div class="inner-card" (click)="currentIllus= illus;openDownloadModal(downloadModal)">
					<img [src]="illus.image_resize" alt="best svg illustrations">
					<div class="hover-style">
						<div class="title">{{ illus.title }}</div>
					</div>
				</div>
			</li>
		</ul>
		<img src="assets/img/loader.gif" *ngIf="paginationLoading" id="loadMorevector" href="javascript:;" style="display: block;margin-left: auto;margin-right: auto; margin-top:40px; width: 70px; height:60px;">
	</div>
</section>

<ng-template #downloadModal let-downloadModal>
	<div class="download-assests">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<button type="button" class="close" (click)="downloadModal.close()">
					<img src="assets/img/cross.svg" alt="download free illustrations">
				</button>
				<div class="modal-body">
					<div class="thumb">
						<img *ngIf="twoDimension" [src]="currentIllus.image_resize" alt="download svg illustrations">
						<img *ngIf="!twoDimension" [src]="currentIllus.image_resize" alt="download svg illustrations">
					</div>
					<div class="content-right">
						<h3>{{currentIllus.title || ''}}</h3>
						<div *ngIf="twoDimension" class="style-txt"><strong>Style: </strong>{{currentIllus.category || ''}}</div>
						<div *ngIf="!twoDimension" class="style-txt"></div>
						<div class="options">
							<div class="custom-checkbox">
								<a *ngIf="twoDimension" href="javascript:;" class="button" (click)="downloadPngImage()">Download PNG</a>
								<a *ngIf="!twoDimension" href="javascript:;" class="button" (click)="downloadPngImageSmall()">Small PNG</a>
							</div>
							<div class="custom-checkbox">
								<a *ngIf="twoDimension" href="javascript:;" class="button" (click)="downloadSvgImage()">Download SVG</a>
								<a *ngIf="!twoDimension" href="javascript:;" class="button" (click)="downloadPngImageBig()">Large PNG</a>
							</div>
						</div>
						<div class="bottom-txt">
							<!--<a href="javascript:;" class="button" (click)="downloadPngImage()">Download PNG</a>
							<a href="javascript:;" class="button" (click)="downloadSvgImage()">Download SVG</a>-->
							<span>Attribution is required.</span>
							<strong>Crafttor License</strong>
							<p>Free for personal and commercial purpose with attribution. <a href="javascript:;" (click)="downloadModal.close(); router.navigate(['/license'])" class="link">More info</a></p>
							<strong>For Exclusive Content & Unlimited Downloads</strong>
							<div class="btn-wrapper"><a class="button yellow-btn" data-dismiss="modal" (click)="downloadModal.close(); router.navigate(['/member'])" href="javascript:;">Premium Membership</a></div>
							<span>No attribution required.</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<!-- Modal -->
<ng-template #limitModal let-limitModal>
	<div class="modal-dialog modal-dialog-centered  premium-pop" role="document">
		<div class="modal-content">
			<button type="button" class="close" (click)="limitModal.close()">
				<img src="assets/img/cross.svg" alt="download funny illustrations">
			</button>
			<div class="modal-body">
				<h3>Sorry!</h3>
				<h4>You exceed your daily limit</h4>
				<a href="javascript:;" (click)="limitModal.close(); closeDownloadModal();" [routerLink]="['/license', {active: 'readrules'}]" class="simple-link">Read the Rules</a>
				<p>For Exclusive Content & Unlimited Downloads</p>
				<div class="btn-wrapper"><a class="button yellow-btn" (click)="limitModal.close(); closeDownloadModal(); router.navigate(['/member'])" href="javascript:;">Premium Membership</a></div>
				<span>No attribution required.</span>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #premiumModal let-premiumModal>
	<div class="modal-dialog modal-dialog-centered  premium-pop-dialog" role="document">
		<div class="modal-content">
			<button type="button" class="close" (click)="premiumModal.close()">
				<img src="assets/img/cross.svg" alt="download funny illustrations">
			</button>
			<div class="modal-body">
				<h3>Unlock Large 3D</h3>
				<h4>& get access of all the products.</h4>
				<p>For Exclusive Content & Unlimited Downloads</p>
				<div class="btn-wrapper"><a class="button yellow-btn" (click)="premiumModal.close(); closeDownloadModal(); router.navigate(['/member'])" href="javascript:;">Premium Membership</a></div>
				<span>No attribution required.</span>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #loginModal let-modal>
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<button type="button" class="close" (click)="openClose()" data-dismiss="modal" aria-label="Close">
				<img src="assets/img/cross.svg" alt="download free illustrations">
			</button>
			<div class="modal-body">
				<h3>{{loginSignUpText}}</h3>
				<div class="button-wrapper">
					<a href="javascript:;" class="button google-btn" (click)="signInWithGoogle()" *ngIf="socialLoginSection"><i class="fa fa-google-plus"></i> Continue with Google</a>
					<a href="javascript:;" class="button manual-btn" (click)="manualLogin()" *ngIf="socialLoginManualSection"><i class="fa fa-envelope" style="padding-right: 8px;"></i>Continue with Email</a>
					<div class="or-section">
            <hr class="hr-area">
            <span class="or-text">Or</span>
            <hr class="hr-area">
          </div>

					<div class="container" *ngIf="login">
						<form action="" [formGroup]="loginForm" (ngSubmit)="loginFunction()">
							<input id="textinput" formControlName="email" type="email" placeholder="Enter email id*" required>
							<span class="text-danger error-text"
							*ngIf="(loginForm.controls.email.touched || submitted) && loginForm.controls.email.errors?.required">
							Email is required
							</span>
							<span class="text-danger error-text"
								*ngIf="(loginForm.controls.email.touched || submitted) && loginForm.controls.email.errors?.pattern">
								Email is not valid
							</span>
							<input id="password" formControlName="password" type="password" placeholder="Enter password*" required>
							<span class="text-danger error-text"
								*ngIf="(loginForm.controls.password.touched || submitted) && loginForm.controls.password.errors?.required">
								Password is required
							</span>

							<label style="margin-bottom: 10px;color: #2c85ed; display: block; text-align: left;
							clear: both;" class="control-label" for="textinput"><a href="javascript:;" (click)="forgotScreen()">Forgot Password?</a></label>

							<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

							<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="!showLeaveLoader">Submit</button>

							<p class="below-txt">New at Crafttor? <a href="javascript:;" (click)="socialSignUp()" style="color: #2c85ed"><b>Sign Up</b></a></p>
							</form>
						</div>

						<div class="container" *ngIf="emailVerify">
							<form action="" [formGroup]="emailVerifyForm" (ngSubmit)="getOTP()">
							<input id="textinput" formControlName="email" type="email" placeholder="Enter email id*" required>
							<span class="text-danger error-text"
							*ngIf="(emailVerifyForm.controls.email.touched || emailVerifySubmit) && emailVerifyForm.controls.email.errors?.required">
							Email is required
						</span>
						<span class="text-danger error-text"
						*ngIf="(emailVerifyForm.controls.email.touched || emailVerifySubmit) && emailVerifyForm.controls.email.errors?.pattern">
						Email is not valid
						</span>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="!showLeaveLoader">Get OTP</button>

						<p class="below-txt">Already have an account? <a href="javascript:;" (click)="socialLogin()" style="color: #2c85ed"><b>Login</b></a></p>
						</form>
					</div>

					<div class="container" *ngIf="signup">
						<form action="" [formGroup]="signUpForm" (ngSubmit)="signUpFormFunction()">
							<input id="textinput" formControlName="fname" type="text" placeholder="Enter first name*" required style="float: left; width: 48%">
							<input id="textinput" formControlName="lname" type="text" placeholder="Enter last name*" required style="float: right; width: 48%">
							<span class="text-danger error-text"
							*ngIf="(signUpForm.controls.fname.touched || submitt) && signUpForm.controls.fname.errors?.required">
							First Name is required
						</span>
							<span class="text-danger error-text" style="float: right;"
							*ngIf="(signUpForm.controls.lname.touched || submitt) && signUpForm.controls.lname.errors?.required">
							Last Name is required
						</span>
						<input id="password" formControlName="password" type="password" placeholder="Enter password*" required>
						<span class="text-danger error-text"
							*ngIf="(signUpForm.controls.password.touched || submitt) && signUpForm.controls.password.errors?.required">
							Password is required
						</span>

						<input id="textinput" formControlName="otp" type="text" placeholder="Enter OTP*" required>
						<span class="text-danger error-text"
							*ngIf="(signUpForm.controls.otp.touched || submitt) && signUpForm.controls.otp.errors?.required">
							OTP is required
						</span>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="!showLeaveLoader">Submit</button>

						<p class="below-txt">Already have an account? <a href="javascript:;" (click)="socialLogin()" style="color: #2c85ed"><b>Login</b></a></p>
							<!-- <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt=""> -->
					</form>
				</div>

				<div class="container" *ngIf="forgotpassword">
					<form action="" [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPasswordFunction()">
						<input id="textinput" formControlName="email" type="email" placeholder="Enter email id*" required>
						<span class="text-danger error-text"
						*ngIf="(forgotPasswordForm.controls.email.touched || submittedForm) && forgotPasswordForm.controls.email.errors?.required">
							Email is required
						</span>
						<span class="text-danger error-text"
						*ngIf="(forgotPasswordForm.controls.email.touched || submittedForm) && forgotPasswordForm.controls.email.errors?.pattern">
							Email is not valid
						</span>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="!showLeaveLoader">Get OTP</button>
						<p class="below-txt"><a href="javascript:;" (click)="socialLogin()" style="color: #2c85ed">Return to Login</a></p>
						<!-- <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt=""> -->
						</form>
					</div>

					<div class="container" *ngIf="enterOtp">
						<form action="" [formGroup]="enterOtpForm" (ngSubmit)="resetPasswordFunction()">
							<input id="otp" formControlName="otp" type="text" placeholder="Enter OTP*" required>
							<span class="text-danger error-text"
							*ngIf="(enterOtpForm.controls.otp.touched || passwordSubmit) && enterOtpForm.controls.otp.errors?.required">
							Enter OTP
						</span>

						<input id="password" formControlName="password" type="password" placeholder="Enter password*" required>
						<span class="text-danger error-text"
						*ngIf="(enterOtpForm.controls.password.touched || passwordSubmit) && enterOtpForm.controls.password.errors?.required">
						Enter new password
					</span>
					<input id="repassword" formControlName="repassword" type="password" placeholder="Re-enter password*" required>
					<span class="text-danger error-text"
						*ngIf="(enterOtpForm.controls.repassword.touched || passwordSubmit) && enterOtpForm.controls.repassword.errors?.required">
						Re-enter password
					</span>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="!showLeaveLoader">Submit</button>
							<p class="below-txt"><a href="javascript:;" (click)="showLoginScreen()" style="color: #2c85ed">Return to Login</a></p>
							<!-- <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt=""> -->
							</form>
						</div>

					<p>By Login, I agree the <a href="javascript:;" (click)="openClose()" [routerLink]="['privacy-terms', {active: 'terms'}]">Terms of Use</a> & <a href="javascript:;" (click)="openClose()"
							[routerLink]="['privacy-terms', {active: 'privacy'}]">Privacy Policy</a>.</p>
				</div>
			</div>

		</div>
	</div>
</ng-template>
