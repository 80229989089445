import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MemberService } from "../member/member.service";
import { PaymentService } from "../payment/payment.service";
import { LocationService } from "../location/location.service";
import { HeaderService } from '../components/header/header.service';
import { AuthenticationService } from '../authentication.service';
import { Payment } from '../_models/payment';
import { environment } from '../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})

export class CheckoutComponent implements OnInit {


    title = 'Checkout | Crafttor';

    memberCode: string = '';
    address: string = '124 santa street';
    city: string = 'San Francisco';
    state: string = 'CA';
    country: string = 'US';
    postcode: string = '94016';

    strikeCheckout:any = null;
    productName: any;
    productPrice: any;
    productImage: any;
    productOrder: any;
    memberships = [];
    paymentSelected: boolean = false;
    payNow: boolean = false;
    mainSection: boolean = true;
    public radioYearly: boolean = true;
    public radioMontly: boolean = false;
    currentUser: any = null;
    selectedMembership: any = null;
    payment: Payment;
    public isIndia: boolean = false;
    private month_membership: any = [];
    private year_membership: any = [];
    @ViewChild('paymentSuccess') paymentSuccess;
    paymentSuccessModal: any;
    constructor(
      private memberService: MemberService,
      private headerService: HeaderService,
      private authenticationService: AuthenticationService,
      private locationService: LocationService,
      private modalService: NgbModal,
      public router: Router,
      private titleService: Title,
      private metaTagService: Meta,
    ) { }

    ngOnInit(): void {
      this.getDataValue()
      this.stripePaymentGateway();
      this.titleService.setTitle("Crafttor | "+this.productName +" "+"Checkout");
      this.metaTagService.addTags([
        { name: 'description', content: 'Free access to unique vector illustrations to create anything without any blocker to your creative thoughts.'},
        { name: 'keywords', content: 'Free illustrations, clean pixel illustrations, premium illustration, ' },
        { name: 'robots', content: 'index, follow' },
        { name: 'author', content: 'Crafttor' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { charset: 'UTF-8' }
      ]);

      this.memberService.getWebSettings('membership').subscribe(
        (data) => {
          // console.log('*****', data)

          for (let membership of data) {
            if (!membership.valid) {
              this.year_membership.push(membership);
              this.month_membership.push(membership);
            }
            else if (membership.valid.years == 0 && membership.valid.days == 0) {
              this.month_membership.push(membership);
            }
            else {
              this.year_membership.push(membership);
            }
            // else if(membership.valid)
          }
          this.memberships =  this.year_membership;
        }
      );
      this.authenticationService.currentUser.subscribe(x => { this.currentUser = x });
      this.selectedMembership = null;
      let country = this.locationService.getCountry();
      if (!country) {
        this.locationService.getPosition().then(pos => {
          // this.locationService.setLocation(pos);
          this.locationService.getGeoLocationGoogle(pos.lat, pos.lng).subscribe(data => {
            this.locationService.setLocation(data);
            country = this.locationService.getCountry();
            if (country == 'India') {
              this.isIndia = true;
            }
          })
        }).catch(err => {
          this.isIndia = false;
        })
      }
      else {
        if (country == 'India') {
          this.isIndia = true;
        }
      }

    }

    showPayment(membership: any) {
      window.scrollTo(0, 0);
      if (this.currentUser) {
        this.paymentSelected = true;
        this.mainSection = false;
        this.selectedMembership = membership;
        // console.log(membership)
      } else {

        this.headerService.setLoginModalValue(true);
      }
    }

    showSuccess() {
      this.payNow = false;
      this.paymentSelected = false;
      this.mainSection = true;
      this.paymentSuccessModal = this.modalService.open(this.paymentSuccess, { windowClass: 'product_view' })
    }

    closeSuccessModal() {
      this.paymentSuccessModal.close();
    }

    checkout(productPrice: any) {
      // this.showSuccess();
      // return;
      this.payNow = true;
      this.paymentSelected = false;
      this.mainSection = false;
    }

    yearly(){
      this.memberships = this.year_membership;
    }
    monthly(){
      this.memberships = this.month_membership;
    }

  getDataValue(){
    var details = JSON.parse(localStorage.getItem('priceDetails'));
    this.productName = details.name;
    this.productPrice = details.price;
    this.productImage = details.image;
    this.productOrder = details.order;
    // console.log("tyqwrqtyw "+details.image +" - "+details.price)
   }

   checkoutStripe(amount, currency, membership) {
     const strikeCheckout = (<any>window).StripeCheckout.configure({
       key: environment.STRIPE_LIVE_KEY,
       locale: 'auto',
       token: (stripeToken: any) => {
         // console.log(stripeToken)
         this.memberService.postStripeOrder(stripeToken, amount, currency, this.productOrder, this.productName, 'category', this.currentUser.firstName+" "+ this.currentUser.lastName, this.address, this.city, this.state, this.country, this.postcode).subscribe((data) => {
           this.showSuccess();
         });
       }
     });

     strikeCheckout.open({
       name: 'Crafttor',
       description: 'Stripe Payment Gateway',
       image: 'https://crafttor.com/crafttor.jpg',
       currency: currency,
       amount: amount * 100
     });
   }

   stripePaymentGateway() {
   if(!window.document.getElementById('stripe-script')) {
     const scr = window.document.createElement("script");
     scr.id = "stripe-script";
     scr.type = "text/javascript";
     scr.src = "https://checkout.stripe.com/checkout.js";

     scr.onload = () => {
       this.strikeCheckout = (<any>window).StripeCheckout.configure({
         key: environment.STRIPE_LIVE_KEY,
         locale: 'auto',
         token: function (token: any) {
         console.log(token)
         alert('Payment via stripe successfull!');
         }
       });
     }
     window.document.body.appendChild(scr);
     }
   }
}
