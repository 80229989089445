import { Component, OnInit } from '@angular/core';
import { User } from '../_models/user';
import { Title, Meta } from '@angular/platform-browser';
import { AuthService } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { AuthenticationService } from '../authentication.service';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService } from "../components/alert/alert.service";
import { HomeService } from "../home/home.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  title = 'Login | Free SVG Illustrations | Graphics | Icons';

  returnUrl: any;
  user_from: string;
  currentUser: User;
  mobileView: boolean = false;

  socialLoginSection: boolean = true;
  manualLoginSection: boolean = false;
  login: boolean = true;
  emailVerify: boolean = false;
  signup: boolean = false;
  forgotpassword: boolean = false;
  forgotpasswordFields: boolean = false;
  enterOtp: boolean = false;
  resetPassword: boolean = false;

  submitted: Boolean = false;
  submitt: Boolean = false;
  submittedForm: Boolean = false;
  submittion: Boolean = false;
  emailVerifySubmit: Boolean = false;
  passwordSubmit: Boolean = false;
  reload: Boolean = true;
  aFormGroup: any;
  workspace_token: string;
  emailId: string;
  emailIds: string;
  showSending: boolean = false;
  showSendingSmall: boolean = false;

  loginForm = this.fb.group({
    email: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
    password: ['', Validators.required],
  });

  forgotPasswordForm = this.fb.group({
    email: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
  });

  enterOtpForm = this.fb.group({
    otp: ['', Validators.required],
    password: ['', Validators.required],
    repassword: ['', Validators.required],
  });

  emailVerifyForm = this.fb.group({
    email: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
  });

  signUpForm = this.fb.group({
    fname: ['', Validators.required],
    lname: ['', Validators.required],
    password: ['', Validators.required],
    otp: ['', Validators.required],
  });

  resetPasswordForm = this.fb.group({
    otp: ['', Validators.required],
    password: ['', Validators.required],
    repassword: ['', Validators.required],
  });

  public lat;
  public lng;

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private authService: AuthService,
    private authenticationService: AuthenticationService,
    private fb: FormBuilder,
    private alert: AlertService,
    private homeService: HomeService,
    private router: Router
  ) { }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID)
    .then((user) => this.loginToServer(user, user.photoUrl));
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'description', content: 'Make your work more appealing with the mix & match combinations of unlimited illustration styles for commercial and non commercial projects.'},
      { name: 'keywords', content: 'free svg, royality free svg, free illustrations download, free vector images online' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Crafttor' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);

    this.authenticationService.currentUser.subscribe(x => { this.currentUser = x });

    this.checkLoggedIn();
    if (window.screen.width <= 991) { // 768px portrait
      this.mobileView = true;
    }else{
      this.mobileView = false;
    }

    this.getLocation();
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: Position) => {
        if (position) {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
        }
      },
        (error: PositionError) => console.log(error));
    } else {
      // alert("Geolocation is not supported by this browser.");
    }
  }

  checkLoggedIn() {
    if(this.currentUser) {
      this.router.navigate(['/home']);
    } else {

    }
  }

  socialLogin(){
    this.showSendingSmall = false;
    this.socialLoginSection = true;
    this.manualLoginSection = false;
  }

  manualLogin(){
    this.showSending = false;
    this.showSendingSmall = false;
    this.socialLoginSection = false;
    this.manualLoginSection = true;
  }

  logOut(): void {
    this.authenticationService.logout();
    this.authService.signOut();
  }

  loginToServer(user: any = null, photoUrl: string) {
    this.authenticationService.login(user.provider.toLowerCase(), user.id,
    user.authToken, user.email, user.firstName, user.lastName,
    photoUrl, this.lat, this.lng)
      .subscribe(
        (_data) => {
          this.router.navigate(['/home']);
        }
      );
  }

  loginFunction(){
    this.submitted = true;
    if (this.loginForm.valid) {
      this.showSendingSmall = true;
        this.homeService.loginWithEmail(this.loginForm.value.email, this.loginForm.value.password, this.workspace_token).subscribe((data) => {
          this.alert.success("Login Successful!");
          this.showSendingSmall = false;
          this.submitted = false;
          this.loginForm.reset()
        },
        (error) => {
          const errors = error.error || error.statusText;
          Object.keys(errors).map((key) => {
            errors[key].map((message) => {
              this.alert.error(message);
              this.submitted = false;
              this.showSendingSmall = false;
            })
          })
       })
    }
  }

  signUpFormFunction(){
    this.submitt = true;
    if (this.signUpForm.valid) {
      this.showSendingSmall = true;
      this.homeService.registerWithEmail(this.signUpForm.value.fname, this.signUpForm.value.lname, this.emailIds, this.signUpForm.value.password, this.signUpForm.value.otp)
        .subscribe(
          (_data) => {
            this.submitt = false;
            this.showSendingSmall = false;
            this.signUpForm.reset()
          },
          (error) => {
            const errors = error.error || error.statusText;
            Object.keys(errors).map((key) => {
              errors[key].map((message) => {
                this.alert.error(message);
                this.submitt = false;
                this.showSendingSmall = false;
              })
            })
         });
    }
  }

  getOTP(){
    this.emailVerifySubmit = true;
    this.emailIds = this.emailVerifyForm.value.email
    if (this.emailVerifyForm.valid) {
      this.showSendingSmall = true;
      this.homeService.getOTP(this.emailVerifyForm.value.email).subscribe((data: any) => {
            this.alert.success("OTP sent Successfully!");
            this.showSendingSmall = false;
            this.emailVerifyForm.reset()
            this.login = false;
            this.emailVerify = false;
            this.forgotpassword = false;
            this.forgotpasswordFields = false;
            this.enterOtp = false;
            this.resetPassword = false;
            this.signup = true;
            this.emailVerifySubmit = false;
          },
          (error) => {
            const errors = error.error || error.statusText;
            Object.keys(errors).map((key) => {
              errors[key].map((message) => {
                  this.alert.error(message);
                  this.emailVerifySubmit = false;
                  this.showSendingSmall = false;
              })
            })
         });

    }
  }

  forgotPasswordFunction(){
    this.submittedForm = true;
    if (this.forgotPasswordForm.valid) {
      this.showSendingSmall = true;
      this.homeService.getResetPasswordOTP(this.forgotPasswordForm.value.email).subscribe((data: any) => {
            this.alert.success("OTP sent Successfully!");
            this.showSendingSmall = false;
            this.login = false;
            this.signup = false;
            this.forgotpassword = false;
            this.enterOtp = true;
            this.resetPassword = true;
            this.submittedForm = false;
          },
          (error) => {
            const errors = error.error || error.statusText;
            Object.keys(errors).map((key) => {
              // console.log("finally revealed" + errors[key]);
              errors[key].map((message) => {
                this.alert.error(message);
                this.submittedForm = false;
                this.showSendingSmall = false;
              })
            })
         });
    }
  }

  resetPasswordFunction(){
    this.passwordSubmit = true;
      if (this.enterOtpForm.valid) {
        this.showSendingSmall = true;
        this.homeService.resetPassword(this.forgotPasswordForm.value.email, this.enterOtpForm.value.otp,
          this.enterOtpForm.value.password, this.enterOtpForm.value.repassword).subscribe((data: any) => {
            this.alert.success("Password reset Successfully!");
            this.showSendingSmall = false;
            this.passwordSubmit = false;
            this.enterOtpForm.reset()
            this.forgotPasswordForm.reset()
            this.login = true;
            this.signup = false;
            this.forgotpassword = false;
            this.enterOtp = false;
            this.resetPassword = false;
          },
          (error) => {
            const errors = error.error || error.statusText;
            Object.keys(errors).map((key) => {
              // console.log("finally revealed" + errors[key]);
              errors[key].map((message) => {
                this.alert.error(message);
                this.passwordSubmit = false;
                this.showSendingSmall = false;
              })
            })
         });
        }
      }

      showLoginScreen() {
        this.forgotpassword = false;
        this.forgotpasswordFields = false;
        this.enterOtp = false;
        this.resetPassword = false;
        this.signup = false;
        this.emailVerify = false;
        this.passwordSubmit = false;
        this.submittedForm = false;
        this.emailVerifySubmit = false;
        this.submitt = false;
        this.submitted = false;
        this.login = true;
      }

      showEmailVerifyScreen() {
        this.login = false;
        this.forgotpassword = false;
        this.forgotpasswordFields = false;
        this.enterOtp = false;
        this.resetPassword = false;
        this.signup = false;
        this.passwordSubmit = false;
        this.submittedForm = false;
        this.emailVerifySubmit = false;
        this.submitt = false;
        this.submitted = false;
        this.emailVerify = true;
      }

      showSignUpScreen() {
        this.login = false;
        this.forgotpassword = false;
        this.forgotpasswordFields = false;
        this.enterOtp = false;
        this.resetPassword = false;
        this.emailVerify = false;
        this.passwordSubmit = false;
        this.submittedForm = false;
        this.emailVerifySubmit = false;
        this.submitt = false;
        this.submitted = false;
        this.signup = true;
      }

      forgotScreen(){
        this.loginForm.reset();
        this.login = false;
        this.enterOtp = false;
        this.resetPassword = false;
        this.signup = false;
        this.emailVerify = false;
        this.forgotpasswordFields = false;
        this.passwordSubmit = false;
        this.submittedForm = false;
        this.emailVerifySubmit = false;
        this.submitt = false;
        this.submitted = false;
        this.forgotpassword = true;
      }

      showResetPasswordScreen() {
        this.login = false;
        this.forgotpassword = false;
        this.forgotpasswordFields = false;
        this.enterOtp = false;
        this.emailVerify = false;
        this.signup = false;
        this.passwordSubmit = false;
        this.submittedForm = false;
        this.emailVerifySubmit = false;
        this.submitt = false;
        this.submitted = false;
        this.resetPassword = true;
      }
}
