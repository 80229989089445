import { Component, OnInit, ViewChild } from '@angular/core';
import { IllusService } from './services/illus.service';
import { ThreeDService } from '../three-dimension/services/three-d.service';
import { SharedService } from '../shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { AlertService } from '../components/alert/alert.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'angularx-social-login';
import { AuthenticationService } from '../authentication.service';
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { Title, Meta } from '@angular/platform-browser';
import { PaginationService } from "../pagination/pagination.service";
import { HeaderService } from '../components/header/header.service';
import { FormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  title = 'Free Open Source SVG Illustrations, 3D Graphics, Vector Icons';
  limit: number = 20;
  offset: number = 0;
  next: string;
  constructor(
    private illusService: IllusService,
    private threeDService: ThreeDService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private alert: AlertService,
    private modalService: NgbModal,
    public router: Router,
    private authService: AuthService,
    private authenticationService: AuthenticationService,
    private activateRoute: ActivatedRoute,
    private titleService: Title,
    private metaTagService: Meta,
    private paginationService: PaginationService,
    private headerService: HeaderService,
    private fb: FormBuilder,
  ) {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  data2dValue: any;
  twoDimension: boolean;
  public radioYearly: boolean = true;
  public radioMontly: boolean = false;
  loginModal: any;
  returnUrl: any;
  illustrations = [];
  searchText: string = '';
  searchText3D: string = '';
  show3D: boolean = false;
  categories: [];
  categoriesCheckbox: [];
  selectedCategories: string;
  checkedIDs = [];
  headerCategory: string;
  categorySelected: boolean = false;
  paginationLoading: boolean = false;
  searchTextEntered: string;
  showLimit: boolean = true;
  currentIllus: any;
  currentUser: any;
  illusSimilar: [];
  @ViewChild('limitModal') limitModal;
  @ViewChild('downloadModal') downloadModal;
  @ViewChild('downloadModalNew') downloadModalNew;
  @ViewChild('loginModal') loginModalAttr;
  @ViewChild('premiumModal') premiumModal;

  socialLoginSection: boolean = true;
  manualLoginSection: boolean = false;
  submitted: Boolean = false;
  submitt: Boolean = false;
  submittedForm: Boolean = false;
  submittion: Boolean = false;
  emailVerifySubmit: Boolean = false;
  passwordSubmit: Boolean = false;
  showSendingSmall: boolean = false;
  login: boolean = false;
  emailVerify: boolean = false;
  signup: boolean = false;
  forgotpassword: boolean = false;
  forgotpasswordFields: boolean = false;
  enterOtp: boolean = false;
  resetPassword: boolean = false;
  showSending: boolean = false;
  emailIds: string;
  workspace_token: any;
  showLeaveLoader: boolean = false;
  loginSignUpText: string;
  socialLoginManualSection: boolean = false;

  loginForm = this.fb.group({
    email: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
    password: ['', Validators.required],
  });

  forgotPasswordForm = this.fb.group({
    email: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
  });

  enterOtpForm = this.fb.group({
    otp: ['', Validators.required],
    password: ['', Validators.required],
    repassword: ['', Validators.required],
  });

  emailVerifyForm = this.fb.group({
    email: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
  });

  signUpForm = this.fb.group({
    fname: ['', Validators.required],
    lname: ['', Validators.required],
    password: ['', Validators.required],
    otp: ['', Validators.required],
  });

  resetPasswordForm = this.fb.group({
    otp: ['', Validators.required],
    password: ['', Validators.required],
    repassword: ['', Validators.required],
  });

  public lat;
  public lng;

  ngOnInit(): void {
    this.getMenuResponse();
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'description', content: 'Download high quality clean pixel free svg illustrations includes couple, love, corona, error, space, login & many more illustrations for your websites, apps and products.'},
      { name: 'keywords', content: 'Couple Illustrations, Love Illustrations, Error Illustrations, beautiful Illustrations, Login Illustrations, Corona Illustrations, Coronavirus Illustrations, Doctor Illustrations, high quality svg illustrations' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Crafttor' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);

    // this.handleScroll();
    this.getLocation();
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: Position) => {
        if (position) {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
        }
      },
        (error: PositionError) => console.log(error));
    } else {
      // alert("Geolocation is not supported by this browser.");
    }
  }

  getMenuResponse(){
    var details = JSON.parse(localStorage.getItem('2dDetails'));
    this.data2dValue = details.nameValue
    if(details.nameValue == '3d'){
      this.threeDimensionFunc()
    }else if(details.nameValue == '2d'){
      this.twoDimensionFunc();
      this.route.queryParams.subscribe(params => {
        this.searchTextEntered = '';
        this.illusSimilar = [];
        this.headerCategory = params['category'];
        this.searchText = params['search'];
        this.searchIllus(this.searchText, this.headerCategory);
        this.makeCategoriesChecklist(this.headerCategory);
        if (this.headerCategory) {
          this.categorySelected = true;
        }
        this.currentUser = this.authenticationService.currentUserValue;
      });

      this.authService.authState.subscribe((user) => {
        if (user && !this.authenticationService.currentUserValue) {
          this.loginToServer(user, user.photoUrl);
        }
      });

      this.activateRoute.queryParams.subscribe(params => {
        this.returnUrl = params['returnUrl'];
      });
    }
  }

  open(content: any) {
    this.loginModal = this.modalService.open(content, { centered: true, windowClass: 'login-modal' })
  }

  openClose() {
    this.loginModal.close();
  }

  loginToServer(user: any = null, photoUrl: string) {
    this.authenticationService.login(user.provider.toLowerCase(), user.id,
    user.authToken, user.email, user.firstName, user.lastName,
    photoUrl, this.lat, this.lng)
      .subscribe(
        (_data) => {
          if (this.returnUrl) {
            this.router.navigate([this.returnUrl]);
          }
          this.loginModal.close();

        }
      );
  }
  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    // this.loginToServer();
  }

  openPremiumModal(modal) {
    this.modalService.open(modal, { centered: true, windowClass: 'premium-pop-dialog ' });
  }

  closePremiumModal() {
    this.premiumModal.close();
  }

  fbSignOut(): void {
    this.authService.signOut();
  }

  logOut(): void {
    this.authenticationService.logout();
    this.authService.signOut();
  }

  private makeCategoriesChecklist(headerCategory: string) {
    this.sharedService.preLoaddedCategoriesData$.subscribe((data) => {
      if (data) {
        this.categories = data['results'];
        this.categoriesCheckbox = data['results'].map(x => {
          if (headerCategory) {
            if (headerCategory == x.id) {
              this.selectedCategories = x.id;
              return {
                id: x.id,
                isChecked: true,
                label: x.name,
                type: x.type
              }
            }
            else {
              return {
                id: x.id,
                isChecked: false,
                label: x.name,
                type: x.type
              }
            }


          } else {
            return {
              id: x.id,
              isChecked: true,
              label: x.name,
              type: x.type
            }
          }
        });
      }
    });
  }

  openDownloadModal(modal) {
    this.downloadModal = this.modalService.open(modal, { centered: true, windowClass: 'download-assests ' });
  }

  open3DDownloadModal(modal) {
    this.downloadModal = this.modalService.open(modal, { centered: true, windowClass: 'download-assests ' });
  }

  openLimitModal(modal) {
    this.modalService.open(modal, { centered: true, windowClass: 'premium-pop ' });
  }
  closeLimitModal() {
    this.limitModal.close();
  }
  closeDownloadModal() {
    this.downloadModal.close();
  }

  categoryChanged() {
    this.checkedIDs = [];
    this.checkedIDs = this.categoriesCheckbox.filter((value) => {
      return value['isChecked'];
    });
    this.selectedCategories = '';
    this.selectedCategories = this.selectedCategories + this.checkedIDs.map(category =>
      category.id
    );
    if (this.selectedCategories) {
      this.searchIllus(this.searchText, this.selectedCategories);
    }
  }

  onSubmit() {
    this.searchIllus(this.searchText, this.selectedCategories);
  }

  onSubmit3D() {
    this.search3DIllus(this.searchText3D, this.selectedCategories);
  }

  private searchCompleted(searchText: string, data: any, appendData: boolean = false) {
    if (appendData) {
      this.illustrations.push.apply(this.illustrations, data['results'])
    }
    else {
      this.illustrations = data['results'];
    }
    this.next = data['next'];
    this.illusSimilar = [];
    if (searchText) {

      this.searchTextEntered = searchText;
    }
    if (this.illustrations.length <= 10) {
      this.illusService.similarIllus().subscribe((data: any) => {
        this.illusSimilar = data;
      });
    }
  }

  private searchCompleted3D(searchText3D: string, data: any, appendData: boolean = false) {
    if (appendData) {
      this.illustrations.push.apply(this.illustrations, data['results'])
    }
    else {
      this.illustrations = data['results'];
    }
    this.next = data['next'];
    this.illusSimilar = [];
    if (searchText3D) {

      this.searchTextEntered = searchText3D;
    }
  }

  searchIllus(searchText: string = "", categories: string = "", limit: number = this.limit): void {
    this.illusService.searchIllus(searchText, categories, limit).subscribe((data: any) => {
      this.searchCompleted(searchText, data);
    });

  }

  search3DIllus(searchText3D: string = "", categories: string = "", limit: number = this.limit): void {
    this.threeDService.searchIllus(searchText3D, categories, limit).subscribe((data: any) => {
      this.searchCompleted3D(searchText3D, data);
    });

  }

  onScrollDown() {
    this.paginationLoading = true;
    this.paginationService.getFromUrl(this.next).subscribe(data => {
      this.searchCompleted(this.searchText, data, true);
      this.paginationLoading = false;
    }, (error: any) => {
      this.paginationLoading = false;
    });
  }

  goToLink(link: string) {
    if (this.limitModal) {
      this.limitModal.close()
    }
  }

  downloadPngImage(): void {
    this.illusService.downloadPng(this.currentIllus.id).subscribe((data: any) => {
      saveAs(data, this.currentIllus.title+'.png')
    },
      (error: any) => {
        if (error.status === 403) {
          this.openLimitModal(this.limitModal);

        }
        else {
          this.downloadModal.close();
          this.socialLogin();
          this.loginModal = this.modalService.open(this.loginModalAttr, { centered: true, windowClass: 'dark-modal exceed-limit login-modal' })
        }
      }
    );

  }
  downloadSvgImage(): void {
    this.illusService.downloadSvg(this.currentIllus.id).subscribe((data: any) => {
      saveAs(data, this.currentIllus.title+'.svg');
    },
      (error: any) => {
        if (error.status === 403) {
          this.openLimitModal(this.limitModal);
        }
        else {
          this.downloadModal.close();
          this.socialLogin();
          this.loginModal = this.modalService.open(this.loginModalAttr, { centered: true, windowClass: 'dark-modal exceed-limit login-modal' })

        }
      }
    );

  }

  downloadPngImageSmall(): void {
    this.threeDService.downloadPngSmall(this.currentIllus.id).subscribe((data: any) => {
      saveAs(data, this.currentIllus.title+'.png')
    },
      (error: any) => {
        if (error.status === 403) {
          this.openLimitModal(this.limitModal);
        }
        else {
          this.downloadModal.close();
          this.socialLogin();
          this.loginModal = this.modalService.open(this.loginModalAttr, { centered: true, windowClass: 'dark-modal exceed-limit login-modal' })

        }
      }
    );
  }

  downloadPngImageBig(): void {
    this.threeDService.downloadPngBig(this.currentIllus.id).subscribe((data: any) => {
      saveAs(data, this.currentIllus.title+'.png')
    },
      (error: any) => {
        if (error.status === 403 || error.status === 400) {
          this.openLimitModal(this.premiumModal);
        }
        else {
          this.downloadModal.close();
          this.socialLogin();
          this.loginModal = this.modalService.open(this.loginModalAttr, { centered: true, windowClass: 'dark-modal exceed-limit login-modal' })
        }
      }
    );
  }

  twoDimensionFunc(){
    this.radioYearly = true;
    this.radioMontly = false;
    this.twoDimension = true;
    this.route.queryParams.subscribe(params => {
      this.searchTextEntered = '';
      this.illusSimilar = [];
      this.headerCategory = params['category'];
      this.searchText = params['search'];
      this.searchIllus(this.searchText, this.headerCategory);
      this.makeCategoriesChecklist(this.headerCategory);
      if (this.headerCategory) {
        this.categorySelected = true;
      }
      this.currentUser = this.authenticationService.currentUserValue;
    });

    this.authService.authState.subscribe((user) => {
      if (user && !this.authenticationService.currentUserValue) {
        this.loginToServer(user, user.photoUrl);
      }
    });

    this.activateRoute.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'];
    });
  }

  threeDimensionFunc(){
    this.radioYearly = false;
    this.radioMontly = true;
    this.twoDimension = false;
    this.route.queryParams.subscribe(params => {
      this.searchTextEntered = '';
      this.illusSimilar = [];
      this.headerCategory = params['category'];
      this.searchText3D = params['search'];
      this.search3DIllus(this.searchText3D, this.headerCategory);
      this.makeCategoriesChecklist(this.headerCategory);
      if (this.headerCategory) {
        this.categorySelected = true;
      }
      this.currentUser = this.authenticationService.currentUserValue;
    });
    this.authService.authState.subscribe((user) => {
      if (user && !this.authenticationService.currentUserValue) {
        this.loginToServer(user, user.photoUrl);
      }
    });

    this.activateRoute.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'];
    });
  }

  loginFunction(){
    this.submitted = true;
    if (this.loginForm.valid) {
      this.showLeaveLoader = true;
        this.authenticationService.loginWithEmail(this.loginForm.value.email, this.loginForm.value.password, this.workspace_token).subscribe((data) => {
          this.showLeaveLoader = false;
          this.submitted = false;
          this.loginForm.reset()
          this.loginModal.close();
        },
        (error) => {
          const errors = error.error || error.statusText;
          Object.keys(errors).map((key) => {
            errors[key].map((message) => {
              this.alert.error(message);
              this.submitted = false;
              this.showLeaveLoader = false;
            })
          })
       })
    }
  }

  getOTP(){
    this.emailVerifySubmit = true;
    this.emailIds = this.emailVerifyForm.value.email
    if (this.emailVerifyForm.valid) {
      this.showLeaveLoader = true;
      this.authenticationService.getOTP(this.emailVerifyForm.value.email).subscribe((data: any) => {
        this.alert.success("OTP sent Successfully!");
        this.showLeaveLoader = false;
        this.emailVerifyForm.reset()
        this.login = false;
        this.emailVerify = false;
        this.forgotpassword = false;
        this.forgotpasswordFields = false;
        this.enterOtp = false;
        this.resetPassword = false;
        this.signup = true;
        this.emailVerifySubmit = false;
      },
      (error) => {
        const errors = error.error || error.statusText;
        Object.keys(errors).map((key) => {
          errors[key].map((message) => {
              this.alert.error(message);
              this.emailVerifySubmit = false;
              this.showLeaveLoader = false;
          })
        })
      });
    }
  }

  forgotPasswordFunction(){
    this.submittedForm = true;
    if (this.forgotPasswordForm.valid) {
      this.showLeaveLoader = true;
      this.authenticationService.getResetPasswordOTP(this.forgotPasswordForm.value.email).subscribe((data: any) => {
            this.alert.success("OTP sent Successfully!");
            this.showLeaveLoader = false;
            this.login = false;
            this.signup = false;
            this.forgotpassword = false;
            this.enterOtp = true;
            this.resetPassword = true;
            this.submittedForm = false;
          },
          (error) => {
            const errors = error.error || error.statusText;
            Object.keys(errors).map((key) => {
              // console.log("finally revealed" + errors[key]);
              errors[key].map((message) => {
                this.alert.error(message);
                this.submittedForm = false;
                this.showLeaveLoader = false;
              })
            })
         });
    }
  }

  signUpFormFunction(){
    this.submitt = true;
    if (this.signUpForm.valid) {
      this.showLeaveLoader = true;
      this.authenticationService.registerWithEmail(this.signUpForm.value.fname, this.signUpForm.value.lname, this.emailIds, this.signUpForm.value.password, this.signUpForm.value.otp, this.workspace_token)
        .subscribe(
          (_data) => {
            this.submitt = false;
            this.showLeaveLoader = false;
            this.signUpForm.reset()
            this.loginModal.close();
          },
          (error) => {
            const errors = error.error || error.statusText;
            Object.keys(errors).map((key) => {
              errors[key].map((message) => {
                this.alert.error(message);
                this.submitt = false;
                this.showLeaveLoader = false;
              })
            })
         });
    }
  }

  resetPasswordFunction(){
    this.passwordSubmit = true;
      if (this.enterOtpForm.valid) {
        this.showLeaveLoader = true;
        this.authenticationService.resetPassword(this.forgotPasswordForm.value.email, this.enterOtpForm.value.otp,
          this.enterOtpForm.value.password, this.enterOtpForm.value.repassword).subscribe((data: any) => {
            this.alert.success("Password reset Successfully!");
            this.showLeaveLoader = false;
            this.passwordSubmit = false;
            this.enterOtpForm.reset()
            this.forgotPasswordForm.reset()
            this.login = true;
            this.signup = false;
            this.forgotpassword = false;
            this.enterOtp = false;
            this.resetPassword = false;
          },
          (error) => {
            const errors = error.error || error.statusText;
            Object.keys(errors).map((key) => {
              // console.log("finally revealed" + errors[key]);
              errors[key].map((message) => {
                this.alert.error(message);
                this.passwordSubmit = false;
                this.showLeaveLoader = false;
              })
            })
         });
        }
      }

      socialLogin(){
        this.loginSignUpText = "Welcome Back!"
        this.showLeaveLoader = false;
        this.socialLoginSection = true;
        this.manualLoginSection = false;
        this.login = true;
        this.emailVerify = false;
        this.forgotpassword = false;
        this.forgotpasswordFields = false;
        this.enterOtp = false;
        this.resetPassword = false;
        this.signup = false;
        this.emailVerifySubmit = false;
        this.showSending = false;
      }

      socialSignUp(){
        this.loginSignUpText = "Let's get started"
        this.showLeaveLoader = false;
        this.socialLoginSection = true;
        this.manualLoginSection = false;
        this.login = false;
        this.emailVerify = true;
        this.forgotpassword = false;
        this.forgotpasswordFields = false;
        this.enterOtp = false;
        this.resetPassword = false;
        this.signup = false;
        this.emailVerifySubmit = false;
        this.showSending = false;
      }

      manualLogin(){
        this.showSending = false;
        this.showLeaveLoader = false;
        this.socialLoginSection = true;
        this.manualLoginSection = false;
        this.login = false;
        this.emailVerify = true;
        this.forgotpassword = false;
        this.forgotpasswordFields = false;
        this.enterOtp = false;
        this.resetPassword = false;
        this.signup = false;
        this.emailVerifySubmit = false;
      }

      showLoginScreen() {
        this.socialLoginSection = true;
        this.manualLoginSection = false;
        this.forgotpassword = false;
        this.forgotpasswordFields = false;
        this.enterOtp = false;
        this.resetPassword = false;
        this.signup = false;
        this.emailVerify = false;
        this.passwordSubmit = false;
        this.submittedForm = false;
        this.emailVerifySubmit = false;
        this.submitt = false;
        this.submitted = false;
        this.login = true;
      }

  showEmailVerifyScreen() {
    this.login = false;
    this.forgotpassword = false;
    this.forgotpasswordFields = false;
    this.enterOtp = false;
    this.resetPassword = false;
    this.signup = false;
    this.passwordSubmit = false;
    this.submittedForm = false;
    this.emailVerifySubmit = false;
    this.submitt = false;
    this.submitted = false;
    this.emailVerify = true;
  }

  showSignUpScreen() {
    this.login = false;
    this.forgotpassword = false;
    this.forgotpasswordFields = false;
    this.enterOtp = false;
    this.resetPassword = false;
    this.emailVerify = false;
    this.passwordSubmit = false;
    this.submittedForm = false;
    this.emailVerifySubmit = false;
    this.submitt = false;
    this.submitted = false;
    this.signup = true;
  }

  forgotScreen(){
    this.loginForm.reset();
    this.login = false;
    this.enterOtp = false;
    this.resetPassword = false;
    this.signup = false;
    this.emailVerify = false;
    this.forgotpasswordFields = false;
    this.passwordSubmit = false;
    this.submittedForm = false;
    this.emailVerifySubmit = false;
    this.submitt = false;
    this.submitted = false;
    this.forgotpassword = true;
  }

  showResetPasswordScreen() {
    this.login = false;
    this.forgotpassword = false;
    this.forgotpasswordFields = false;
    this.enterOtp = false;
    this.emailVerify = false;
    this.signup = false;
    this.passwordSubmit = false;
    this.submittedForm = false;
    this.emailVerifySubmit = false;
    this.submitt = false;
    this.submitted = false;
    this.resetPassword = true;
  }
}
