<!-- Search Section -->
<section class="term-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="tabs-wrapper">
          <div class="vertical-tabs">
            <!-- Tabs -->
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item">
                <a class="nav-link" [ngClass]="{'active': terms}" data-toggle="tab" href="#terms" role="tab" aria-controls="terms">Terms of use</a>
              </li>
              <li class="nav-item">
                <a class="nav-link"  [ngClass]="{'active': privacy}" data-toggle="tab" href="#privacy" role="tab" aria-controls="privacy">Privacy Policy</a>
              </li>
            </ul>

            <!-- Tabs Content -->
            <div class="tab-content">
              <div class="tab-pane" [ngClass]="{'active': terms}" id="terms" role="tabpanel">
                <div class="sv-tab-panel">
                 <h2>Terms of use</h2>
								<h6>Last Updated: <b class="month-terms">MAY' 2024</b></h6>
								<hr>
								<p>Please read the Terms of Use carefully before taking any action on the website.
                By using or accessing the assets of Crafttor Technologies, including products, services, websites, applications, images, illustrations, etc., or collectively the entire platform, you agree to accept and be bound by the terms of Crafttor Technologies described herein or described by all the terms and conditions, policies, and guidelines incorporated by any reference. This Terms of Use describes the rights and responsibilities that apply to your use of the Crafttor website, mobile apps, or any services or products owned by Crafttor Technologies.
                </p>

								<p>If you do not agree to our Terms of use, then don’t use any of the Crafttor’s Products/Services, Websites/Mobile Apps.</p>
								<p>You are only allowed to use Crafttor’s Website/mobile apps, products and services if you agree to abide by and be bound by the terms.</p>

								<p><b>1. Your Crafttor Account - </b>If you create an account (your “Account”), you are responsible for maintaining the security of your Account and everything related to it, including content. You are fully responsible for all activities that occur under your Account and for any other actions taken on the Crafttor platform. You must not describe or assign content to your Account in a misleading or unlawful manner, including in a manner intended to trade on the name or reputation of others. Crafttor may change or remove any description or keyword that it considers inappropriate or unlawful. You must immediately notify Crafttor of any unauthorized access to your Account or any other breaches of security. Crafttor will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. Crafttor is liable to take legal action if it finds anything wrong or against the company’s law or illegal.</p>

                <p><b>Payment, Refunds, and Upgrading Plans:</b> Payments: A user who is the owner of the account can access and change/upgrade a plan. Crafttor considers the person whose name is provided or appears on the credit or charge card, or other payment method used to pay the charges for the Service, as the owner of the Paid Account. If any other name is used for paying the membership payment for a Crafttor account, the company considers that name/person to be the owner of the account. If any disputes arise regarding the ownership or control of a Paid Account, the company (Crafttor) is liable to access the true information of the paid member via the Name and Card Information provided at the time of purchasing the membership. Currently, we don’t have auto-renewals of accounts, so the Name or Card details provided at the time of purchasing the membership should be considered as the owner of the Paid Account.<br>
                  <b>Refunds:</b> If a user wishes to discontinue using Crafttor plans or any other services before their term expires, no refunds will be initiated for the accounts. Additionally, if multiple payments have occurred, it will take a maximum of 7-10 days to process the refund.
                </p>

								<p>For <b>Paid Accounts</b>, Crafttor considers the person whose name is provided or appears on the credit or charge card, or other payment method used for paying the charges for the Service, to be the owner of the Paid Account. If any other name is used to pay the membership fee for a Crafttor account, the company considers that name/person to be the owner of the account. In the event of a dispute regarding the ownership or control of a Paid Account, Crafttor is liable to access the true information of the paid member via the name and card information provided at the time of membership purchase. Currently, we do not have auto-renewals for accounts, so the name or card details provided at the time of membership purchase should be considered as the owner of the Paid Account.</p>

                <p><b>2. Rules - </b>Use of any product/services offered by Crafttor Technologies is subject to the following rules of the company. By using our products/services, you agree to our terms of use and guidelines, as well as the rules and regulations set forth by Crafttor Technologies. You agree not to engage in any of the following activities.</p>
								<ul>
									<li>Reverse engineer the platform, decompile the code or product, disassemble it, decipher it, or otherwise attempt to derive the source code for the Services or any related technology that we have not made open source.</li>
									<li>Rent or lease our services based on your account, trade, sell, resell, or otherwise monetize the Services or related data or access to them, without the company’s express written consent or any legal notice from the company.</li>
									<li>Monitor the availability, performance, or functionality of the Services for any competitive purpose.</li>
									<li>Do not post anything that contains software viruses, worms, or any other harmful code, assets/images, or videos that affect Crafttor’s platform</li>
									<li>Do not violate Crafttor’s intellectual property rights or the intellectual property rights of others.</li>
									<li>Do not override any security feature, or bypass or circumvent any access controls or limits of the Services provided by Crafttor IT Technologies.</li>
									<li>Do not create a false identity or misrepresent your identity, create a profile for anyone else other than yourself, or attempt to use another’s account through hacking or any other means.</li>
								</ul>
								<p>Crafttor IT Technologies Pvt Ltd reserves the right to take legal action if a user engages in any of the above-mentioned activities that violate the company’s rules.</p>
								<p><b>3. Termination of Account - </b>Crafttor may terminate this agreement or any type of account (Simple/Paid), full or partial services, with or without cause, with or without any notice effective immediately if the company finds anything misleading or unlawful. If a user wishes to terminate all services before the end of the term, no refunds for paid accounts or any payments made will be initiated. Crafttor Technologies is not liable to refund any amount or initiate any transfer in such cases.</p>
								<h3>CONTACT US</h3>
								<p>If you have any queries about our Terms of Use or wish to make a complaint, you can email us at <b><a href="mailto:info@crafttor.com">info@crafttor.com</a></b>.</p>
								<p>Become a part of Crafttor’s family and enjoy unlimited access to the illustrations provided under the company’s Terms of Use and Privacy Policy.</p>
                </div>
              </div>
              <!-- Tabs 1-->

              <div class="tab-pane" [ngClass]="{'active': privacy}" id="privacy" role="tabpanel">
                <div class="sv-tab-panel">
                <h2>Privacy Policy</h2>
                <h6>Last Updated: <b class="month-terms">MAY' 2024</b></h6>
								<hr>
								<p>Crafttor is dedicatedly committed to protecting its users' privacy and personal information against any breaches. Crafttor’s users can refer to our privacy policy for further information on how we collect and use our users' information, as well as how confidentially it is disclosed in any legal case where Crafttor is required to disclose its users' privacy.</p>


                <h3>INFORMATION COLLECTION AND USE ON CRAFTTOR’S PLATFORM</h3>
                <p>When a user registers on our website, we strictly follow security rules to ensure the protection of our users' personal information. We require minimal information from the user, such as their image, name, email, and location, and all the gathered information is securely stored in our systems. We have tight control and security features in place to protect all confidential information from any breaches. We gather this personal information to create a user's account (simple account). For Paid Accounts, we do not retain any information related to credit/debit cards or net banking.</p>
                <p>All images and illustrations provided are for user usage only, and Crafttor does not permit its users to use the company's website or any content/images for purposes other than their own business. Users are strictly prohibited from copying or reproducing the content or data provided on Crafttor’s platform.</p>
								<p>We take steps to ensure that only employees who require access to your personal information to fulfill their job duties have access to it. We may use and disclose your personal or account information for the following purposes:</p>
								<ul>
									<li>To provide the Site and Services to you and to other users of the Site and Services.</li>
									<li>To improve the quality of the Site and Services through polls, surveys and other similar feedback gathering activities conducted by Dribbble and/or third parties.</li>
									<li>To create, manage and control your account information, and to verify access rights to the Site and Services.</li>
									<li>To bill your account for the services provided.</li>
									<li>To communicate with you (subject to your opt-out rights set forth in this Privacy Policy). This includes providing you with information about the Services, informing you of changes or additions to the Services, or notifying you of the availability of any other services or features we provide.</li>
									<li>To assess service levels, monitor traffic patterns and gauge popularity of different features and service options of the Site and/or Services.</li>
									<li>To enforce this Privacy Policy or our Terms of Service.</li>
									<li>To protect against fraud or error, and to respond to claims of any violation of our rights or those of any third parties.</li>
									<li>To respond to your requests for customer service.</li>
									<li>To protect the rights, property or personal safety of you, us, our users and the public.</li>
									<li>As required to comply with applicable laws or as authorized by applicable laws.</li>
								</ul>

								<p>Crafttor utilizes third-party payment gateways, such as Razorpay or Paypal, for the purpose of billing your account (if you have a paid account) and to process payments. These gateways will use your credit card and other payment information in accordance with their respective privacy policies. Crafttor shall not be liable to you or any other person for any damages that might result from unauthorized use, publication, disclosure, or any other misuse of such payment information, including credit card information.</p>
								<p>Crafttor may share your personal information with its registered API application providers, which deliver relevant design stories and inspiration. We invite you to review their applicable data protection policies.</p>
								<p>When we disclose your personal information to third parties, we take reasonable measures to ensure that the rules set forth in this Privacy Policy are complied with and that these third parties provide sufficient guarantees to implement appropriate technical and organisational measures.</p>
								<p>We may occasionally communicate with you regarding our products, services, news, and events. You have the option to not receive this information. We provide an opt-out function within all email communications of this nature, or we will cease to communicate with you for this purpose if you contact us and inform us not to communicate this information to you. The only kind of these communications that you may not 'opt-out' of are those required to communicate announcements related to the Services, including information specific to your Account, planned Services suspensions, and outages. We will attempt to minimize this type of communication to you</p>
								<h3>COOKIES & LOG FILES</h3>
								<p>We use cookies and log files to track user information. Cookies are small amounts of data that are transferred to your web browser by a web server and stored on your computer’s hard drive. We use cookies to track which page variant a visitor has seen, to monitor if a visitor has clicked on a page variant, to monitor traffic patterns, and to gauge the popularity of service options. We will use this information to deliver relevant content and services to you.</p>

								<h3>RIGHTS TO YOUR INFORMATION</h3>
								<p>You have the right to access and edit your information at any time through the web interface provided as part of the Services. Upon written request and subject to proof of identity, you may access the personal information that we hold, use, or communicate, and ask for any necessary corrections to be made, where applicable, as authorized or required by law. However, to ensure that the personal information we maintain about you is accurate and up to date, please inform us immediately of any changes to your personal information via email.</p>

                <h3>POLICY CHANGES</h3>
                <p>We may update our Privacy Policy from time to time. If we do so, we will send an email to all specified users notifying them of the changes to our privacy policies. Please read the updated policy to stay informed. Users can also subscribe to receive up-to-date information. To do so, users should ensure that their email address is updated with us. (Users are responsible for ensuring that Crafttor has their up-to-date email address for this purpose).</p>
                <h3>CONTACT US</h3>
                <p>If you have any queries about our Privacy Policy, your personal information, accessing your privacy policy or personal information, correction requests, or wish to make a complaint, you can email us at <b><a href="mailto:info@crafttor.com">info@crafttor.com</a></b>.</p>
								<p>Become a part of Crafttor’s family and enjoy unlimited access to the illustrations provided under the company’s Terms of Use and Privacy Policy.</p>
                </div>
              </div>
              <!-- Tabs 2 -->

              <!-- Tabs 3 -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
