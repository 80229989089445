import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogsService {

  constructor(
    private httpClient: HttpClient,
  ) { }
  api_root: string;
  blogs_api: string = 'blogs/'

  public getBlogs(ordering: string = "", limit: number = 10, offset: number = 0) {
    this.api_root = environment.REST_API_SERVER;
    let query_params: string = '';

    let blog_url = this.api_root + this.blogs_api;
    if (ordering) { query_params += "ordering=" + ordering };
    if (limit) { query_params += "&limit=" + limit };
    if (offset) { query_params += "&offset=" + offset };
    if (query_params) {
      blog_url += '?' + query_params;
    }
    return this.httpClient.get(blog_url);
  }
  public getBlog(id: string) {
    this.api_root = environment.REST_API_SERVER;
    let blog_url = this.api_root + this.blogs_api+ id;
    return this.httpClient.get(blog_url);
  }
}
