import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { FormBuilder, Validators } from '@angular/forms';
import { ContactUsService } from "./contact-us.service";
import { AlertService } from "../components/alert/alert.service";
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  title = 'Contact Us | Crafttor';

  contactUsForm = this.fb.group({
    name: ['', Validators.required],
    message: ['', Validators.required],
    // email: ['', Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")],
    email: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
  });
  submitted: Boolean = false;
  reload: Boolean = true;
  showContactSubmitLoader: boolean = false;
  showContactErrorMsg: string;

  aFormGroup: any;
  constructor(
    private reCaptchaV3Service: ReCaptchaV3Service,
    private fb: FormBuilder,
    private contacUsService: ContactUsService,
    private alert: AlertService,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    private metaTagService: Meta,

  ) { }

  public siteKey?: string = '6Lf59_gUAAAAAEJNHrHp1Vdd1LaG1epuOF62yj80';
  public token?: string;
  public siteKeyV2: string = "6Lf4BvkUAAAAAKreyepy8vMm4ttzZtXIY_0xVJ0O";
  type: string = "checkbox";
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'description', content: 'We are working 24*7 to design a difference in our products. Feel free to contact us for your queries.'},
      { name: 'keywords', content: '' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Crafttor' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);
  }



  public submitContactUs() {
    this.submitted = true;
    this.showContactErrorMsg = "";
    if (this.contactUsForm.valid) {
      this.showContactSubmitLoader = true;
      this.reCaptchaV3Service.execute(this.siteKey, 'contact_us', (token) => {
        this.contactUsForm.value['recaptcha'] = token;
        this.cdr.detectChanges();
        this.contacUsService.contactUs(this.contactUsForm.value).subscribe((data) => {
          this.alert.success("We will get back to you soon! Thanks");
          this.showContactSubmitLoader = false;
          this.submitted = false;
          this.contactUsForm.reset()
        },
        (error) => {
          if(error.status == 400){
            this.showContactSubmitLoader = false;
            this.showContactErrorMsg = error.error.message;
          } else {
            this.showContactSubmitLoader = false;
            this.showContactErrorMsg = error.error.message;
          }
        });
      });
    }
  }
}
