
<body>
<!-- <ng-http-loader [filteredUrlPatterns]="['offset']"></ng-http-loader> -->
<app-header></app-header>


<router-outlet></router-outlet>

<app-footer></app-footer>

</body>

<!-- </html> -->
