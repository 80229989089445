<section class="new-landing-page-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-content">
					<h1>500+ 2D/3D Illustrations</h1>
					<p>Trusted by over 5000 customers worldwide.</p>
					<div class="btn-wrapper">
						<a href="javascript:;" routerLink="/search" class="button black-border-btn" (click)="exploreMore()">Explore</a>
						<a href="#" class="button yellow-btn" routerLink="/member" (click)="goPremium()">Go Premium</a>
					</div>
          <p>Go Premium & get 30% instant discount.</p>
				</div>
			</div>
		</div>
	</div>
  </section>

<section class="new-landing-page-img-section">
    <div class="container">
  		<div class="row">
        <div class="col-sm-12">
          <div class="inner-content">
          <div class="img-box" >
						<img class="figma-img" data-aos="slide-left" data-aos-duration="1000" *ngIf="showFigmaImage" src="assets/img/chair.png" alt="">
						<img class="sketch-img" data-aos="slide-left" data-aos-duration="1000" *ngIf="showSketchImage" src="assets/img/sketch-plu.png" alt="">
						<h2 *ngIf="showText">COMING SOON</h2>
          </div>
        </div>
        </div>
      </div>
    </div>
</section>

<section class="plugin-img-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-content">
			<div class="btn-wrapper">
				<a href="javascript:;" class="plugin-icon"><img (mouseenter) ="figmaHover()" style="width: 45px;" src="assets/img/figma-plugin.png" alt="figma illustrations"></a>

				<a href="javascript:;" class="plugin-icon"><img (mouseenter) ="sketchHover()" src="assets/img/sketch.svg" alt="sketch illustrations"></a>

				<a href="#" class="plugin-icon" routerLink="/search"><img (mouseenter) ="xdHover()" (mouseleave) ="falseHover()" class="xd-img" src="assets/img/xd.png" alt="xd illustrations"></a>
			</div>
			<div class="btn-wrapper">
					<a *ngIf="sketch" href="{{sketchData.download}}" class="button option-btn">Install Sketch Plugin</a>
					<a *ngIf="figma" href="https://www.figma.com/community/plugin/902086023682633830" class="button option-btn">Install Figma Plugin</a>
			</div>
			</div>
			</div>
		</div>
	</div>
</section>

<!-- Carousel Section -->
<section class="carousel-section">
  <div class="container">
    <div class="col-sm-12">
      <h2>Our Customers</h2>
    </div>
    <div class="row" style="margin-top: -20px;">
      <div class="col-sm-12">
        <div class="regular slider">
          <ngb-carousel (slide)="slideActivate($event)">

          <ng-template ngbSlide>

            <div class="picsum-img-wrapper">
          <div class="slide-desc">
            <div class="inner-desc">
              <div class="thumb">
                <img src="assets/img/pnew.jpeg" alt="nickelfox likes our beautiful illustrations">
                <!-- <img src="https://s3.ap-south-1.amazonaws.com/crafttor.com/assets/img/pnew.jpeg" alt=""> -->
              </div>
              <div class="content">
                <h3>Falling In love with these illustrations.</h3>
                <p>We at Nickelfox found ourself spoiled by the amazing illustrations by Crafttor. What an amazing resource. If you're ever looking
                  for beautiful SVG illustrations for your app or website Crafttor is your go-to place. Saves a lot of time.</p>
                <div class="name">Prashish</div>
                <div class="designation"><span>Founder:</span> Nickelfox</div>
              </div>
            </div>
          </div>
        </div>

      </ng-template>
      <ng-template ngbSlide>

        <div class="picsum-img-wrapper">
          <div class="slide-desc">
            <div class="inner-desc">
              <div class="thumb">
                <img src="assets/img/snew.jpeg" alt="anthillcreations use svg illustrations">
                <!-- <img src="https://s3.ap-south-1.amazonaws.com/crafttor.com/assets/img/snew.jpeg" alt=""> -->
              </div>
              <div class="content">
                <h3>Best Illustrations I Ever have.</h3>
                <p>We have been using Crafttor since the last few months and it has fulfilled all our core graphic design requirements. Not only do they have variety, the graphics are easily editable and reconfigurable.
                  The designers in my team are delivering high quality outcomes in half the time.</p>
                <div class="name">Souradeep Paul</div>
                <div class="designation"><span>Founder:</span> anthillcreations.org</div>
              </div>
            </div>
          </div>
        </div>

      </ng-template>
      <ng-template ngbSlide>

        <div class="picsum-img-wrapper">
          <div class="slide-desc">
            <div class="inner-desc">
              <div class="thumb">
                <img src="assets/img/prnew.jpeg" alt="ketoindia use vector graphics">
                <!-- <img src="https://s3.ap-south-1.amazonaws.com/crafttor.com/assets/img/prnew.jpeg" alt=""> -->
              </div>
              <div class="content">
                <h3>High quality illustrations for every business.</h3>
                <p>Crafttor and the team helped us in the early days at Keto India, in the website design and illustrations. The team was able to help us with high quality illustrations
                  that helped us, not just build a beautiful, engaging website - but a highly converting one!</p>
                <div class="name">Sahil Pruthi</div>
                <div class="designation"><span>Founder:</span> Livofy</div>
              </div>
            </div>
          </div>
        </div>

      </ng-template>
      <ng-template ngbSlide>

        <div class="picsum-img-wrapper">
          <div class="slide-desc">
            <div class="inner-desc">
              <div class="thumb">
                <img src="assets/img/sanew.jpeg" alt="innerfit likes our impressive illustrations">
                <!-- <img src="https://s3.ap-south-1.amazonaws.com/crafttor.com/assets/img/sanew.jpeg" alt=""> -->
              </div>
              <div class="content">
                <h3>Extremely Impressive Illustrations.</h3>
                <p>Crafttor is the best place for the illustrations for applications & websites. Crafttor's illustrations helped us in designing our platform more interesting & engaging
                for our business levels. I highly recommend its illustrations to everyone.</p>
                <div class="name">Sanchit Garg</div>
                <div class="designation"><span>Founder:</span> innerfit.me</div>
              </div>
            </div>
          </div>
        </div>

      </ng-template>
    </ngb-carousel>
          </div>
      </div>
    </div>
  </div>
</section>

<!-- Create Anything Section -->
<section class="createuwant-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<h2>Why Crafttor?</h2>
				<p>Trendy and artistic illustrations to make your interfaces<br> look stylish.</p>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-4">
				<div class="inner-box">
					<img src="assets/img/quality.svg" alt="high quality illustrations">
					<h6>High-quality illustrations</h6>
					<p>We will provide you the high-quality illustrations <br>in the market.</p>
				</div>
			</div>
			<div class="col-sm-4">
				<div class="inner-box">
					<img src="assets/img/free.svg" alt="free illustrations">
					<h6>Free to use</h6>
					<p>We give free PNG & SVG files for your <br>every project.</p>
				</div>
			</div>
			<div class="col-sm-4">
				<div class="inner-box">
					<img src="assets/img/updates.svg" alt="vector illustrations">
					<h6>Regular Updates</h6>
					<p>Every month we create a new style for the <br>competitive market.</p>
				</div>
			</div>
		</div>
	</div>
  </section>

  <!-- Category Section -->
  <section class="new-landing-page-vectorimg-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<h2>Royalty Free Illustration Styles</h2>
				<p>High-quality digital resources for a faster and smoother design workflow.</p>
			</div>
		</div>
		<div class="row">
			<ul class="load-list">
				<li *ngFor="let category of categories">
					<div *ngIf="category.type == '2d'; else elseBlock" class="vector-box" routerLink="/illustration" [queryParams]="{category: category.id}" (click)="selectValueSales(category)">
						<div class="thumb">
							<img [src]="category.image" alt="fit professional funny illustrations">
						</div>
						<div class="category-title">
							<!-- <div class="category-price-section">
								<h5 *ngIf="isIndia" id="priceIndia">₹{{ category.price_inr }}</h5>
								<h5 *ngIf="!isIndia" id="priceNotIndia">${{ category.price_usd }}</h5>
							</div> -->
							<h3>{{ category.name }}</h3>
						</div>

						<p>{{ category.description }}</p>

						<!-- <div class="btn-wrapper-category">
							<a href="javascript:;" *ngIf="isIndia" routerLink="payment/" (click)="sendPrice(category.price_inr, category.name)" class="button">Buy now</a>
							<a href="javascript:;" *ngIf="!isIndia" routerLink="payment/" (click)="sendPrice(category.price_usd, category.name)" class="button">Buy now</a>
						</div> -->
					</div>

					<ng-template #elseBlock>

						<div #elseBlock class="vector-box" routerLink="/3d-illustrations" [queryParams]="{category: category.id}">
							<div class="thumb">
								<img [src]="category.image" alt="fit professional funny illustrations">
							</div>
							<div class="category-title">
								<!-- <div class="category-price-section">
									<h5 *ngIf="isIndia" id="priceIndia">₹{{ category.price_inr }}</h5>
									<h5 *ngIf="!isIndia" id="priceNotIndia">${{ category.price_usd }}</h5>
								</div> -->
								<h3>{{ category.name }}</h3>
							</div>

							<p>{{ category.description }}</p>

							<!-- <div class="btn-wrapper-category">
								<a href="javascript:;" *ngIf="isIndia" routerLink="payment/" (click)="sendPrice(category.price_inr, category.name)" class="button">Buy now</a>
								<a href="javascript:;" *ngIf="!isIndia" routerLink="payment/" (click)="sendPrice(category.price_usd, category.name)" class="button">Buy now</a>
							</div> -->
						</div>
					</ng-template>
				</li>
			</ul>
		</div>
	</div>
</section>

<section class="new-landing-page-sale-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-7">
				<img src="assets/img/sale.png" alt="">
			</div>

			<div class="col-sm-5">
				<div class="sale-pop-new" role="document">
						<div class="modal-body">
							<h4>Limited Period Offer</h4>
							<br>
							<h2>30% Off</h2>
							<h6>Till 30th September</h6>
							<div class="btn-wrapper">
                <a href="#" class="button yellow-btn" routerLink="/member">Go Premium</a>
              </div>
						</div>
				</div>
			</div>
		</div>
	</div>
</section>
