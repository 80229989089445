<section class="cart_sec" *ngIf="mainSection">
	<div class="container">
		<div class="row">
			<div class="col-sm-12" style="margin-left: -16px;">
				<div class="inner-wrapper">
					<div class="user-info">
						<div class="member-name"></div>
						<h1>Checkout</h1>
						<div class="back-area" routerLink="/marketplace">
							<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
							    <title>Back</title>
							    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
							        <g id="DAM-Pricing-Copy-2" transform="translate(-40.000000, -28.000000)">
							            <g id="Back" transform="translate(40.000000, 28.000000)">
							                <rect id="Rectangle-8" x="0" y="0" width="24" height="24"></rect>
							                <g id="Group-7" stroke-linecap="round" stroke-linejoin="round" transform="translate(3.000000, 6.000000)" stroke="#000000" stroke-width="1.5">
							                    <line x1="0" y1="6" x2="17.1428571" y2="6" id="Stroke-1"></line>
							                    <polyline id="Path-2" points="5.4 0 0 5.9256575 5.27460736 12"></polyline>
							                </g>
							            </g>
							        </g>
							    </g>
							</svg>
							<p style="margin-left: 8px;">Back</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="cart_sec-details" *ngIf="mainSection">
	<div class="container">
		<div class="row">
			<div class="col-sm-7 cart-illus-img" style="padding: 0px;">
				<div class="single-product-design">
					<h2 weight="500" class="illus-main-txt">{{productName}}</h2>
					<span size="8" class=""></span>
					<p weight="400" class="basic-txt">
						<span>{{productDesc}}</span>
					</p>
				</div>
				<img src="{{productImage}}" alt="" class="item-img">

				<ul class="checkout-ul">
					<li class="">
						<div class="checkout-main-details" style="position: relative;">
							<i class="fa fa-check" aria-hidden="true"></i>
							<h6 class="illus-detail-txt">All illustrations of <b>{{productName}}</b>.</h6>
						</div>
					</li>
					<li class="">
						<div class="checkout-main-details" style="position: relative;">
							<i class="fa fa-check" aria-hidden="true"></i>
							<h6 class="illus-detail-txt">All updates & features of <b>{{productName}}</b>.</h6>
						</div>
					</li>
					<li class="note-area">
						<div class="note-details">
							<span><b>*Note: </b> All taxes are included in the total amount.</span>
						</div>
					</li>
				</ul>
			</div>
			<div class="col-sm-5">
				<div class="payment-section">
					<h5>Your order summary</h5>
					<hr>
					<table class="shop_table" cellspacing="0">
						<tbody>
							<tr class="month-year-area">
								<td class="yearly-monthly">
									<input type="radio" name="subscription" value="yearly" id="yearly" [checked]="detailsYearly">
									<label for="yearly">Annual subscription<div class="badge"></div></label>
								</td>
								<td>
									<div class="price" style="margin-top: -6px;">${{productPrice}}</div>
								</td>
							</tr>
							<tr>
								<td colspan="2" class="coupon-box">
								<a class="link-btn" data-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1" (click)="hideErroMsg()">Have a coupon code?</a>
								<div class="collapse multi-collapse" id="multiCollapseExample1">
									<div class="input-group">
									<input type="text" class="form-control coupon" maxlength="20" placeholder="Coupon code" type="text" value="" [(ngModel)]="couponcode" required>
										<span class="input-group-append">
											<button class="btn btn-primary btn-apply coupon" [disabled]="couponcode === ''" (click)="applyCouponCode()">{{couponBtnText}}</button>
										</span>
									</div>
									<p *ngIf="showCouponCodeError" class="coupon-error-txt">{{couponCodeErrorMsg}}</p>
								</div>
								</td>
							</tr>
							<hr style="width: 130%;">
							<tr class="order-total">
								<th>Total</th>
								<td data-title="Subtotal">
									<span class="woocommerce-Price-amount amount">${{productPrice}}</span>
								</td>
							</tr>
						</tbody>
					</table>
					<a href="javascript:;" class="button payment-btn" (click)="categoryCheckoutRazorpay(productPrice, productName)">Continue Payment</a>
				</div>

				<div class="">
					<p class="small" style="font-size: 12px; margin-top: 6px;">By confirming your subscription, You allow Crafttor to charge your card for this payment in accordance with their terms.</p>
				</div>
			</div>
		</div>

		<div class="loader-scenarios" *ngIf="showTopRightLoader">
			<img class="img-up-loader" style="width: 30px;" src="assets/img/img-up.svg" alt="">
		</div>

		<div class="loader-scenarios-success-msgs" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="200" *ngIf="sendSuccessful">
			<span class="ant-avatar-selected">
				<svg width="18" height="14" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1 5L4.58824 9L13 1" stroke="#0fa636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>
			</span>
			<p>{{successfullMessage}}</p>
		</div>

		<div class="loader-scenarios-error-msgs" data-aos="fade-left" data-aos-easing="linear" data-aos-duration="200" *ngIf="sendSuccessfulErrorMsg">
			<p>{{sendSuccessfulErrorMessage}}</p>
		</div>

	</div>
</section>

<section class="cart_sec" [hidden]="!payNow">
	<div class="container" style="max-width: 500px;">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-wrapper" style="max-width: 500px; padding-bottom: 60px;">
					<a href="javascript:" class="back-link" (click)="mainSection=true;payNow=false;"><img src="assets/img/back-arrow.svg"> Back</a>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- {%popup content} -->
<ng-template #paymentSuccess let-paymentSuccess>
	<div class="modal-dialog modal-dialog-centered  premium-pop" role="document">
		<div class="modal-content">
			<!-- <button type="button" class="close" (click)="paymentSuccess.close()">
				<img src="assets/img/cross.svg" alt="download funny icons">
			</button> -->
			<div class="modal-body">
				<div class="row">
					<div class="container">
						<div class="col-md-12 product_img">
							<div class="popup_wrapper">
								<img src="assets/img/gift.png" style="width: 200px; height: 180px; display: block; margin-left: auto; margin-right: auto;width: 50%;">
								<div class="title">
									<h3 style="text-align:center; margin-top:30px; font-weight: 600">Your Payment is successfull</h3>
								</div>
								<p style="text-align:center; margin-top:16px;">Happy Downloading!!</p>
								<a href="javascript:;" class="button" style="width: 100%" (click)="closeSuccessModal(); router.navigate(['/search'])">Search Illustrations</a>
								<p style="text-align:center; margin-top:6px;"><small>facing any issue email us: <a href="mailto:info@crafttor.com">info@crafttor.com</a></small></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<div
	style="width: 100%;
	background-color: rgba(0,0,0, .9);
  padding-top:200px;"
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngStyle]="{'display':showloaderStyle}">
	<div class="modal-dialog-style modal-dialog-centered-style" role="document" style="height: 100px;
    width: 25%; margin: 0 auto; text-align: center;">
		<div class="modal-content" style="background-color: rgb(255,255,255); border-radius: 10px;">
			<div class="modal-body">
				<img class="main-loader-img" style="width: 140px;" src="assets/img/loader.svg" alt="">
			  <h6>Please wait</h6>
			  <p>payment processing...</p>
			</div>
		</div>
	</div>
</div>

<ng-template #loginmodal let-modal>
	<div class="modal-dialog modal-dialog-centered login-modal home-login-modal" role="document">
		<div class="modal-content">
			<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				<img src="assets/img/cross.svg" alt="download free illustrations" (click)="openClose()">
			</button>
			<div class="modal-body">
				<h3>{{loginSignUpText}}</h3>
				<!-- <p style="margin-bottom:30px; margin-top:8px;">Please login to download more illustrations</p> -->
				<div class="button-wrapper">
					<a href="javascript:;" class="button google-btn" (click)="signInWithGoogle()" *ngIf="socialLoginSection"><i class="fa fa-google-plus"></i> Continue with Google</a>
					<!-- <a href="javascript:;" class="button fb-btn" (click)="signInWithFB()"><i class="fa fa-facebook-f"></i>Continue with Facebook</a> -->
					<!-- <a href="javascript:;" class="button twitter-btn"><i class="fa fa-twitter"></i> Continue with Twitter</a> -->
					<a href="javascript:;" class="button manual-btn" (click)="manualLogin()" *ngIf="socialLoginManualSection"><i class="fa fa-envelope" style="padding-right: 8px;"></i>Continue with Email</a>

					<div class="or-section">
            <hr class="hr-area">
            <span class="or-text">Or</span>
            <hr class="hr-area">
          </div>

					<div class="container" *ngIf="login">
						<form action="" [formGroup]="loginForm" (ngSubmit)="loginFunction()">
							<input id="textinput" formControlName="email" type="email" placeholder="Enter email id*" required>
							<span class="text-danger error-text"
							*ngIf="(loginForm.controls.email.touched || submitted) && loginForm.controls.email.errors?.required">
							Email is required
						</span>
						<span class="text-danger error-text"
							*ngIf="(loginForm.controls.email.touched || submitted) && loginForm.controls.email.errors?.pattern">
							Email is not valid
						</span>
						<input id="password" formControlName="password" type="password" placeholder="Enter password*" required>
							<span class="text-danger error-text"
							*ngIf="(loginForm.controls.password.touched || submitted) && loginForm.controls.password.errors?.required">
							Password is required
						</span>

							<label style="margin-bottom: 10px;color: #2c85ed; display: block; text-align: left;
							clear: both;" class="control-label" for="textinput"><a href="javascript:;" (click)="forgotScreen()">Forgot Password?</a></label>

							<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

							<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="!showLeaveLoader">Submit</button>

							<p class="below-txt">New at Crafttor? <a href="javascript:;" (click)="socialSignUp()" style="color: #2c85ed"><b>Sign Up</b></a></p>
							<!-- <a href="javascript:;" (click)="socialLogin()" style="color: #2c85ed">Back</a> -->

							<!-- <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt=""> -->
							</form>
						</div>

						<div class="container" *ngIf="emailVerify">
							<form action="" [formGroup]="emailVerifyForm" (ngSubmit)="getOTP()">
							<input id="textinput" formControlName="email" type="email" placeholder="Enter email id*" required>
							<span class="text-danger error-text"
							*ngIf="(emailVerifyForm.controls.email.touched || emailVerifySubmit) && emailVerifyForm.controls.email.errors?.required">
							Email is required
						</span>
						<span class="text-danger error-text"
						*ngIf="(emailVerifyForm.controls.email.touched || emailVerifySubmit) && emailVerifyForm.controls.email.errors?.pattern">
						Email is not valid
					</span>

					<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

					<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="!showLeaveLoader">Get OTP</button>

					<p class="below-txt">Already have an account? <a href="javascript:;" (click)="socialLogin()" style="color: #2c85ed"><b>Login</b></a></p>
					<!-- <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt=""> -->
					</form>
					</div>

					<div class="container" *ngIf="signup">
						<form action="" [formGroup]="signUpForm" (ngSubmit)="signUpFormFunction()">
							<input id="textinput" formControlName="fname" type="text" placeholder="Enter first name*" required style="float: left; width: 48%">
							<input id="textinput" formControlName="lname" type="text" placeholder="Enter last name*" required style="float: right; width: 48%">
							<span class="text-danger error-text"
							*ngIf="(signUpForm.controls.fname.touched || submitt) && signUpForm.controls.fname.errors?.required">
							First Name is required
						</span>
							<span class="text-danger error-text" style="float: right;"
							*ngIf="(signUpForm.controls.lname.touched || submitt) && signUpForm.controls.lname.errors?.required">
							Last Name is required
						</span>
						<input id="password" formControlName="password" type="password" placeholder="Enter password*" required>
						<span class="text-danger error-text"
							*ngIf="(signUpForm.controls.password.touched || submitt) && signUpForm.controls.password.errors?.required">
							Password is required
						</span>

						<input id="textinput" formControlName="otp" type="text" placeholder="Enter OTP*" required>
						<span class="text-danger error-text"
							*ngIf="(signUpForm.controls.otp.touched || submitt) && signUpForm.controls.otp.errors?.required">
							OTP is required
						</span>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="!showLeaveLoader">Submit</button>

						<p class="below-txt">Already have an account? <a href="javascript:;" (click)="socialLogin()" style="color: #2c85ed"><b>Login</b></a></p>
							<!-- <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt=""> -->
					</form>
				</div>

				<div class="container" *ngIf="forgotpassword">
					<form action="" [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPasswordFunction()">
						<input id="textinput" formControlName="email" type="email" placeholder="Enter email id*" required>
						<span class="text-danger error-text"
						*ngIf="(forgotPasswordForm.controls.email.touched || submittedForm) && forgotPasswordForm.controls.email.errors?.required">
							Email is required
						</span>
						<span class="text-danger error-text"
						*ngIf="(forgotPasswordForm.controls.email.touched || submittedForm) && forgotPasswordForm.controls.email.errors?.pattern">
							Email is not valid
						</span>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="!showLeaveLoader">Get OTP</button>
						<p class="below-txt"><a href="javascript:;" (click)="socialLogin()" style="color: #2c85ed">Return to Login</a></p>
						<!-- <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt=""> -->
						</form>
					</div>

					<div class="container" *ngIf="enterOtp">
						<form action="" [formGroup]="enterOtpForm" (ngSubmit)="resetPasswordFunction()">
							<input id="otp" formControlName="otp" type="text" placeholder="Enter OTP*" required>
							<span class="text-danger error-text"
							*ngIf="(enterOtpForm.controls.otp.touched || passwordSubmit) && enterOtpForm.controls.otp.errors?.required">
							Enter OTP
						</span>

						<input id="password" formControlName="password" type="password" placeholder="Enter password*" required>
						<span class="text-danger error-text"
						*ngIf="(enterOtpForm.controls.password.touched || passwordSubmit) && enterOtpForm.controls.password.errors?.required">
						Enter new password
					</span>
					<input id="repassword" formControlName="repassword" type="password" placeholder="Re-enter password*" required>
					<span class="text-danger error-text"
						*ngIf="(enterOtpForm.controls.repassword.touched || passwordSubmit) && enterOtpForm.controls.repassword.errors?.required">
						Re-enter password
					</span>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="!showLeaveLoader">Submit</button>
							<p class="below-txt"><a href="javascript:;" (click)="showLoginScreen()" style="color: #2c85ed">Return to Login</a></p>
							<!-- <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt=""> -->
							</form>
						</div>

					<p>By Login, I agree the <a href="javascript:;" (click)="openClose()" [routerLink]="['privacy-terms', {active: 'terms'}]">Terms of Use</a> & <a href="javascript:;" (click)="openClose()"
							[routerLink]="['privacy-terms', {active: 'privacy'}]">Privacy Policy</a>.</p>
				</div>
			</div>

		</div>
	</div>
</ng-template>
