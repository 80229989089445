import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';


import { AuthenticationService } from '../authentication.service';
import { AlertService } from '../components/alert/alert.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  [x: string]: any;
  constructor(private authenticationService: AuthenticationService, private alertService: AlertService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      const currentUser = this.authenticationService.currentUserValue;
      const isLoggedIn = currentUser && currentUser.token;
      if (err.status === 401 && isLoggedIn) {
        // auto logout if 401 response returned from refresh api
        if (request.url.indexOf('/token/refresh') > -1) {
          this.authenticationService.logout();
          location.reload(true);
        }
        return this.handle401Error(request, next);
      }

      try {
        const error = err.error || err.statusText;
        Object.keys(error).map((key) => {
          console.log(error[key]);
          error[key].map((message) => {
            // this.alertService.error(key + ": " + message);
          })
        })
      } catch (error) {
        // this.alertService.error(err.statusText);
      }

      return throwError(err);
    }))
  }
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);


  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.authenticationService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.access);
          return next.handle(this.addToken(request, token.access));
        },

      ),


      );

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }
}
