import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { IconsService } from '../icons/service/icons.service';
import { BlogsService } from '../blogs/blogs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { ContactUsService } from "../contact-us/contact-us.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReCaptchaV3Service } from 'ngx-captcha';
import AOS from 'aos';

@Component({
  selector: 'app-cages',
  templateUrl: './cages.component.html',
  styleUrls: ['./cages.component.css']
})
export class CagesComponent implements OnInit {

  title = 'Free Open Source SVG Icons for Design Projects';

  iconsCategory: [];
  searchTerm: any;
  recentBlogs: any;
  showLeaveLoader: boolean = false;
  showContactErrorMsg: string;
  submitted: Boolean = false;
  public siteKey?: string = '6Lf59_gUAAAAAEJNHrHp1Vdd1LaG1epuOF62yj80';

  @ViewChild('requesticons') requesticons;

  public lat;
  public lng;

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private iconsService: IconsService,
    public router: Router,
    private blogService: BlogsService,
    private fb: FormBuilder,
    private contacUsService: ContactUsService,
    private modalService: NgbModal,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private cdr: ChangeDetectorRef,
  ) { }

  requestIconsForm = this.fb.group({
    name: ['', Validators.required],
    message: ['', Validators.required],
    email: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
  });

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'description', content: 'Get open source SVG icons absolutely free for mobile, web and design projects. Get free royalty free SVG icons with Crafttor.'},
      { name: 'keywords', content: 'free icons download, free brand icons, free brand logos, website icons, app icons, svg icons, vector icons, high quality svg icons, download free svg icons, free vector icons' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Crafttor' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);

    this.getIconCatgories();
    this.getLocation();
    AOS.init();
    this.getRecentBlogs();
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: Position) => {
        if (position) {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
        }
      },
        (error: PositionError) => console.log(error));
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  getIconCatgories() {
    this.iconsService.iconsCategory().subscribe((data: any) => {
      this.iconsCategory = data['results'];
    });
  }

  public selectIconsValue(category: any) {
     localStorage.setItem('iconCategoryDetails', JSON.stringify({ id: category.id, name: category.name,
       image: category.image, description: category.description }));
  }

  public searchIcons() {
    localStorage.setItem('searchIconsDetails', JSON.stringify({ searchKey: this.searchTerm }));
    this.router.navigate(['icons-search']);
  }

  public contactUs() {
    this.router.navigate(['contact']);
  }

  public forum() {
    // this.router.navigate(['contact']);
  }

  private getRecentBlogs(){
    this.blogService.getBlogs('-created_at', 3).subscribe((data)=>{
      this.recentBlogs = data['results'];
    });
  }

  openRequestIconsModal(modal) {
    this.requesticons = this.modalService.open(modal, { centered: true, windowClass: 'request-icons-popup ' });
  }

  submitIconsRequest() {
    this.submitted = true;
    this.showContactErrorMsg = "";
    if (this.requestIconsForm.valid) {
      this.showLeaveLoader = true;
      this.reCaptchaV3Service.execute(this.siteKey, 'contact_us', (token) => {
        this.requestIconsForm.value['recaptcha'] = token;
        this.cdr.detectChanges();
        this.contacUsService.contactUsIcons(this.requestIconsForm.value['name'],
        this.requestIconsForm.value['email'], this.requestIconsForm.value['message'],
        this.requestIconsForm.value['recaptcha']).subscribe((data) => {
          this.showLeaveLoader = false;
          this.submitted = false;
          this.requestIconsForm.reset();
          this.requesticons.close();
        },
        (error) => {
          if(error.status == 400){
            this.showLeaveLoader = false;
            this.showContactErrorMsg = error.error.message;
          } else {
            this.showLeaveLoader = false;
            this.showContactErrorMsg = error.error.message;
          }
        });
      });
    }
  }

  openFigma() {
    window.open("https://www.figma.com/community/plugin/902086023682633830/Crafttor", "_blank");
  }

  openSketch() {
    window.open("https://crafttor.com/sketch", "_blank");
  }

  openIllustrator() {
    window.open("https://exchange.adobe.com/apps/cc/109306/crafttor-for-illustrator", "_blank");
  }
}
