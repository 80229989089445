<header class="masthead fixed-top">
  <!-- Navigation -->
  <nav class="navbar navbar-expand-lg" id="mainNav">
    <div class="container">
      <a class="navbar-brand js-scroll-trigger" href="#" routerLink="/"><img src="assets/img/logo.svg" alt="crafttor free illustrations"></a>

      <li class="nav-item login-btn" *ngIf="!currentUser && mobileView">
        <a class="nav-link btn" (click)="openLoginModal()">Login</a>
      </li>
      <li class="nav-item user-id dropdown" *ngIf="currentUser && mobileView">
        <a class="nav-link" href="javascript:;" data-toggle="dropdown">
          <img *ngIf="!userPic" src="assets/img/user-profile.svg">
					<img *ngIf="userPic" src="{{userPic}}">
          {{currentUser.firstName+" "+currentUser.lastName}} </a>
        <ul class="dropdown-menu">
          <li><a class="nav-link" href="javascript:;" routerLink="/profile">Profile</a></li>
          <li><a class="nav-link" href="javascript:;" (click)="logOut()">Logout</a></li>
        </ul>
      </li>
      <!-- <li class="nav-item dropdown search-nav" *ngIf="mobileView">
        <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="javascript:;"><img src="assets/img/search.svg" alt="search free illustrations"></a>
        <div class="dropdown-menu">
          <form class="search" (submit)="goToSearch()">
            <input type="search" [(ngModel)]="searchText" name="searchText" placeholder="Search illustration"><button type="submit" >Explore</button>
          </form>
        </div>
      </li> -->

      <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
      </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ml-auto">

          <!-- <li class="nav-item">
              <a class="nav-link" href="javascript:;" (click)="blogClicked=false;" routerLink="3d-illustrations/">3D</a>
            </li> -->

          <!-- <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" (click)="blogClicked=false;" data-toggle="dropdown" href="#">Illustrations</a>
            <ul class="dropdown-menu">
              <li *ngFor="let category of categories"><a (click)="blogClicked=false;" class="nav-link" href="javascript:;" routerLink="search/{{category.name}}"> {{category.name}} </a></li>
            </ul>
          </li> -->

        <!-- <li class="nav-item">
            <a class="nav-link" href="javascript:;" (click)="blogClicked=false;" routerLink="/icons">Icons</a>
          </li> -->

          <!-- https://static.figma.com/app/icon/1/favicon.svg -->

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#">Plugins</a>
            <ul class="dropdown-menu">
              <li><a class="nav-link" routerLink="/figma">
                <img src="assets/img/figma.svg" alt=""> Figma </a></li>
              <li><a class="nav-link" href="javascript:;" routerLink="/sketch">
                <img src="assets/img/ic-sketch.png" alt=""> Sketch </a></li>
            </ul>
          </li>

          <!-- <li class="nav-item">
            <a class="nav-link" href="javascript:;" (click)="blogClicked=true;" routerLink="blogs">Blog</a>
          </li> -->

          <li class="nav-item">
            <a class="nav-link btn-price" href="javascript:;" routerLink="/pricing">Pricing</a>
          </li>
          <!-- <li class="nav-item dropdown search-nav" *ngIf="!mobileView">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="javascript:;"><img src="assets/img/search.svg" alt="search free illustrations"></a>
            <div class="dropdown-menu">
              <form class="search" (submit)="goToSearch()">
                <input type="search" [(ngModel)]="searchText" name="searchText" placeholder="Search Illustrations"><button type="submit" >Explore</button>
              </form>
            </div>
          </li> -->
          <li class="nav-item login-btn" *ngIf="!currentUser && !mobileView">
            <a class="nav-link btn" (click)="openLoginModal()">Login</a>
          </li>
          <li class="nav-item user-id dropdown" *ngIf="currentUser  && !mobileView">
            <a class="nav-link" href="javascript:;" data-toggle="dropdown">
              <img *ngIf="!userPic" src="assets/img/user-profile.svg">
    					<img *ngIf="userPic" src="{{userPic}}">
              {{currentUser.firstName+" "+currentUser.lastName}} </a>
            <ul class="dropdown-menu">
              <!-- <li><a class="nav-link" href="javascript:;" routerLink="/profile">Profile</a></li> -->
              <li><a class="nav-link" href="javascript:;" (click)="logOut()">Logout</a></li>
            </ul>
          </li>

        </ul>
      </div>
    </div>
  </nav>
</header>


<!-- Search Section -->
<section class="search-section profile-heading" *ngIf="mainSection">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-box">
					<h1>Premium Membership</h1>
					<p>Choose a plan that fits you and your needs.</p>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="letsmake-section-pricing pricing-pre" *ngIf="mainSection">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="letsmake-box">

					<div class="switcher">
						<input type="radio" name="subscription" value="yearly" id="yearly" [checked]="radioYearly" (click)="yearly();radioMontly=false; radioYearly=true;">
						<label for="yearly">Yearly</label>
						<input type="radio" name="subscription" value="monthly" id="monthly" [checked]="radioMontly"  (click)="monthly(); radioYearly=false; radioMontly=true;">
						<label for="monthly">Monthly</label>
					</div>
				<img src="assets/img/saving.svg" alt="download high quality vector illustrations">
					<div class="price-card">
						<div class="inner-card" *ngFor="let membership of memberships | slice:1:3">
							<div class="card-box">
								<img src="assets/img/{{membership.image}}" alt="best quality svg illustrations">
								<h3>{{membership.text}}</h3>
								<!-- <span class="price">{{membership.amount_text.now}}</span> -->

								<!-- <span class="price" *ngIf="isIndia">{{membership.amount_text.INR.now}}</span> -->

								<span class="price">{{membership.amount_text.USD.now}}</span>
								<span class="main-price" *ngIf="membership.text == 'Free'">Free Access</span>

								<!-- <span class="main-price" *ngIf="membership.text != 'Free' && isIndia">Earlier <span><s>{{membership.amount_text.INR.earlier}}</s></span></span> -->
								<span class="main-price" *ngIf="membership.text != 'Free'">Earlier <span><s>{{membership.amount_text.USD.earlier}}</s></span></span>
								<ul>
									<li *ngFor="let li of membership.li">{{li}}</li>
								</ul>
								<a href="javascript:;" class="button gray-btn" routerLink="/search" *ngIf="membership.text == 'Free'" (click)="downloadIllus()">Download</a>
								<a href="javascript:;" class="button buy-now-membership" *ngIf="membership.text != 'Free'" (click)="showPayment(membership)">Buy now</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- {popup-content - end } -->
</section>


<section class="cart_sec" *ngIf="paymentSelected">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-wrapper">
					<div class="user-info">
						<div class="member-name"></div>
						<h1>Checkout</h1>
					</div>
				</div>
			</div>
		</div>

		<div class="row table_row">
			<div class="col-sm-7">
				<div class="payments_details_sec">
					<div class="payments_details"></div>
					<table cellspacing="0">
						<thead>
							<tr>
								<th class="product">Name</th>
								<th class="price"></th>
								<th class="subtotal">Price</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<img src="assets/img/{{selectedMembership.image}}" alt="" class="item-img">
								<td class="individual product-name" data-title="Product">{{selectedMembership.text}}</td>
								<!-- <td class="price" data-title="price" *ngIf="isIndia">{{selectedMembership.amount_text.INR.now}}</td> -->
								<td class="price" data-title="price">{{selectedMembership.amount_text.USD.now}}</td>
								<a href="javascript:" class="back-link" (click)="paymentSelected=false;mainSection=true;"><img src="assets/img/cross.svg" alt=""></a>
							</tr>
						</tbody>
					</table>
					<hr>
					<table cellspacing="0">
					<tfoot>
						<tr class="order-total">
							<th>Total</th>
							<th><strong></strong></th>
							<!-- <td class="subtotal_price" *ngIf="isIndia">{{selectedMembership.amount_text.INR.now}}</td> -->
							<td class="subtotal_price">{{selectedMembership.amount_text.USD.now}}</td>
						</tr>
					</tfoot>
					</table>
				</div>
			</div>
			<div class="col-sm-5 payment-section">
				<table class="shop_table" cellspacing="0">
					<tbody>
						<p *ngIf="!noShowSavings">Total savings: <b>30%</b></p>
						<tr class="order-total">
							<th>Total</th>
							<td data-title="Subtotal">
								<!-- <span class="woocommerce-Price-amount amount" *ngIf="isIndia">₹{{selectedMembership.amount.INR}}/-</span> -->
								<span class="woocommerce-Price-amount amount">${{selectedMembership.amount.USD}}/-</span>
							</td>
						</tr>
					</tbody>
				</table>
				<!-- checkout(selectedMembership) -->
				<!-- createRzpayOrder(selectedMembership.amount.INR, selectedMembership) -->
				<!-- checkoutStripe(selectedMembership.amount.INR, 'inr', selectedMembership) -->
				<!-- checkoutStripe(selectedMembership.amount.USD, 'usd', selectedMembership) -->

				<!-- <a href="javascript:;" class="button payment-btn" *ngIf="isIndia" (click)="checkoutStripe(selectedMembership.amount.INR, 'inr', selectedMembership)">Continue Payment</a> -->

				<!-- <a href="javascript:;" class="button payment-btn" *ngIf="isIndia" (click)="checkoutStripe(selectedMembership.amount.INR, 'inr', selectedMembership)">Continue Payment</a> -->
				<a href="javascript:;" class="button payment-btn" (click)="checkoutStripe(selectedMembership.amount.USD, 'usd', selectedMembership)">Continue Payment</a>
			</div>
			<div class="col-sm-12 notes-section">
				<!-- <div class="col-sm-7" style="background-color: #ff9900; float: right">
					<small><i>*Note: All taxes are included in the total amount</i></small>
				</div> -->
				<div class="col-sm-5">
						<small><b>Included in your purchase:</b></small><br><br>
						<small><i>All illustrations & icons.</i></small><br>
						<small><i>All updates & features.</i></small><br><br>
						<small><i><b>*Note:</b> All taxes are included in the total amount.</i></small>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="cart_sec" [hidden]="!payNow">
	<div class="container" style="max-width: 500px;">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-wrapper" style="max-width: 500px; padding-bottom: 60px;">
					<a href="javascript:" class="back-link" (click)="paymentSelected=true;mainSection=false;payNow=false;"><img src="assets/img/back-arrow.svg"> Back</a>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- FAQs Section -->
<section class="faq-section-pricing-pages">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="inner-content">
          <h6>FAQs</h6>
          <hr>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="faq-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>Is there any offer or a free trial?</h2>
          <p>Yes, we do have a free plan & offers going on. In fact, we are a step ahead. You may use our graphic assets free of cost for personal or commercial purposes but there is a limit in downloading the assets. Also we do have a 20% off on yearly plans for both Individual and Premimun.</p>
          <hr>
        </div>
      </div>
      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>Which plan should I choose?</h2>
          <p>We have one free and two paid plans going on. Free plan has a per day download limit and paid plans like individual & premium also have download limits but there is no daily download limit. You can check out https://crafttor.com/member for more details about all the plans.</p>
          <hr>
        </div>
      </div>

      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>Which membership is more beneficial?</h2>
          <p>Benefits of every membership is based on customer's requirements. If anyone wants to download less PNG & SVG with 3D illustrations then Individual is the plan and if requirement of download is more then Premium plan is a good option with one custom illustration by us.</p>
          <hr>
        </div>
      </div>
      <div class="col-sm-12 right-question faq-bottom">
        <div class="inner-content">
          <h2>What is custom illustration in Premium plan?</h2>
          <p>For our premium members this is a special add-on in the plan. For premium members we will design a custom illustration as per their requirements. A custom illustration can be based on any topic with different sizes as required. We will provide the custom illustration in PNG & SVG format only.</p>
          <hr>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- {%popup content} -->
<ng-template #paymentSuccess>
	<!-- <div class="modal fade product_view" id="product_view_second"> -->
	<div class="modal-dialog modal-dialog-centered" role="document" style="margin-top:auto; margin-bottom: auto;">
		<!-- <div class="modal-dialog"> -->
		<div class="modal-content">
			<div class="modal-header" style="display: block; border-bottom: none;">
				<a (click)="closeSuccessModal()" data-dismiss="modal" class="class pull-right">
					<img  style="display: block; float: right" src="assets/img/cross.svg">
				</a>
			</div>
			<div class="modal-body">
				<div class="row">
					<div class="container">
						<div class="col-md-12 product_img">
							<div class="popup_wrapper">
								<img src="assets/img/gift.png" style="width: 200px; height: 180px; display: block; margin-left: auto; margin-right: auto;width: 50%;">
								<div class="title">
									<h3 style="text-align:center; margin-top:30px;">Your Payment is successfull</h3>
								</div>
								<!-- <p style="text-align:center;">Your membership isn't activated(Technical issue).</p> -->
								<p style="text-align:center;">Happy Downloading!!</p>
								<a href="javascript:;" class="button" style="width: 100%" (click)="closeSuccessModal(); router.navigate(['/search'])">Search Illustrations</a>
								<p style="text-align:center;"><small>For any further assistance email us: <a href="mailto:info@crafttor.com"><b>info@crafttor.com</b></a></small></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- </div> -->
</ng-template>
