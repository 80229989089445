<!-- Footer -->
<footer class="site-footer">
  <div class="container">
    <div class="row">
      <div class="col-sm-4">
        <div class="coulmn-1">
          <img src="assets/img/logo.svg" alt="crafttor free svg illustrations">
          <!-- <p>B-1315, Green fields, Faridabad, India</p> -->
          <p>Free assests for your next project</p>
          <a href="mailto:info@crafttor.com"><i class="fa fa-envelope" aria-hidden="true"></i> info@crafttor.com</a>
        </div>
      </div>
      <!-- 3 -->
      <div class="col-sm-2">
        <h5>Products</h5>
        <ul class="menu">
          <li><a href="javascript:;" (click)="openWorkspace()">Workspace</a></li>
          <li><a href="javascript:;" routerLink="/marketplace">Marketplace</a></li>
          <li><a href="javascript:;" routerLink="/smart-plugins">Plugins</a></li>
        </ul>
      </div>

      <div class="col-sm-2">
        <h5>Plugins</h5>
        <ul class="menu">
          <li><a href="javascript:;" routerLink="/figma">Figma</a></li>
          <li><a href="javascript:;" routerLink="/kaleidoscope">Kaleidoscope</a></li>
          <li><a href="javascript:;" routerLink="/iso-all">Iso All</a></li>
          <li><a href="javascript:;" routerLink="/translate-ai">Translate AI</a></li>
          <li><a href="javascript:;" routerLink="/image-to-figma">Image to Figma</a></li>
          <li><a href="javascript:;" routerLink="/sketch">Sketch</a></li>
          <li><a href="javascript:;" routerLink="/illustrator">Illustrator</a></li>
        </ul>
      </div>

  <!-- 3 -->
      <div class="col-sm-2">
        <h5 class="license-txt">License</h5>
        <ul class="menu">
          <li><a href="javascript:;" routerLink="/license">Free License</a></li>
          <li><a href="javascript:;" routerLink="/license">Paid License</a></li>
        </ul>
      </div>

      <div class="col-sm-2">
        <h5>Company</h5>
        <ul class="menu">
          <li><a href="javascript:;" routerLink="/about-us">About Us</a></li>
          <li><a href="javascript:;" routerLink="/privacy-terms">Terms & Conditions</a></li>
          <li><a href="javascript:;" routerLink="/contact">Contact Us</a></li>
          <li><a href="javascript:;" routerLink="/faq">FAQ</a></li>
          <li><a href="javascript:;" routerLink="/blogs">Blog</a></li>
        </ul>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 all-social-icons">
        <h5>Socials</h5>
          <ul class="social-icons">
            <li><a rel="nofollow" href="https://www.facebook.com/Crafttor-Studio-102023868185724/?modal=admin_todo_tour" target=”_blank”
              alt="facebook"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
            <li><a rel="nofollow" href="https://twitter.com/CrafttorS" target=”_blank” alt="twitter"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
            <li><a rel="nofollow" href="https://www.linkedin.com/company/crafttor/" target=”_blank” alt="linkedin"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
            <li><a rel="nofollow" href="https://www.instagram.com/crafttorstudio/" target=”_blank” alt="instagram"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
            <li><a rel="nofollow" href="https://www.youtube.com/channel/UC6SM1D8MU7uwLVEC8uFDW-w/?guided_help_flow=5" target=”_blank” alt="youtube"><i class="fa fa-youtube-play" aria-hidden="true"></i></a></li>
            <li><a rel="nofollow" href="https://dribbble.com/crafttorstudio" target=”_blank” alt="dribbble"><i class="fa fa-dribbble" aria-hidden="true"></i></a></li>
            <li><a rel="nofollow" href="https://www.behance.net/crafttorstudio" target=”_blank” alt="behance"><i class="fa fa-behance" aria-hidden="true"></i></a></li>
          </ul>
      </div>

      <div class="col-sm-12 text-center">
          <small>&copy; 2024 Crafttor IT Technologies Pvt Ltd. All rights reserved</small>
        </div>
    </div>
  </div>
</footer>
