import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { BlogsService } from '../blogs/blogs.service';
import { LocationService } from '../location/location.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HomeService } from "./home.service";
import { AlertService } from "../components/alert/alert.service";
import { Router } from '@angular/router';
import AOS from 'aos';
import { NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from "angularx-social-login";
import { AuthenticationService } from '../authentication.service';
import { User } from '../_models/user';
import { GoogleLoginProvider } from "angularx-social-login";
import { HeaderService } from '../components/header/header.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

title = 'Trendy & High Quality 2D/3D Illustrations, Graphics, Images | Crafttor';

slideActivate(ngbSlideEvent: NgbSlideEvent) {}

  categories = [];
  illus = [];
  memberships = [];
  isIndia:boolean = false;
  popularBlogs: any;
  recentBlogs: any;
  submitted: Boolean = false;
  userName: string;
  userEmail: string;
  companyName: string;

  availableTimeSlots = [];
  availableSlots = [];
  selectedTime: any;
  selectedDate: any;
  isDateSelected: boolean = false;
  isTimeSelected: boolean = false;
  showSelectionMessage: boolean = false;
  showSelectedMsg: string;

  currentUser: User;
  socialLoginSection: boolean = true;
  manualLoginSection: boolean = false;
  submitt: Boolean = false;
  submittedForm: Boolean = false;
  submittion: Boolean = false;
  emailVerifySubmit: Boolean = false;
  passwordSubmit: Boolean = false;
  showSendingSmall: boolean = false;
  login: boolean = false;
  emailVerify: boolean = false;
  signup: boolean = false;
  forgotpassword: boolean = false;
  forgotpasswordFields: boolean = false;
  enterOtp: boolean = false;
  resetPassword: boolean = false;
  showSending: boolean = false;
  emailIds: string;
  workspace_token: any;
  mobileView: boolean=false;
  loginModal: any;
  loggedIn: any;
  returnUrl: any;
  showLeaveLoader: boolean = false;
  socialLoginManualSection: boolean = false;

  showTime: boolean = false;
  showFinalTime: boolean = false;
  showSubmitBtn: boolean = false;
  timeSelected: boolean = false;
  dateSelected: boolean = false;
  shownTime: string;
  showDate: boolean = true;
  shownDate: string;
  shownMonth: string;
  shownDay: string;
  companySize: any = "0-5";
  heardAbout: any = "Google Search";
  thankYouText: string = "Thank you, select date for a quick call.";
  loginSignUpText: string;

  showloaderStyle = "none";

  letsTalkFormNew = this.fb.group({
    fullName: ['', Validators.required],
    fullEmail: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
    message: ['0-5', Validators.required],
    company: ['Google Search', Validators.required],
  });

  letsTalkForm = this.fbb.group({
    name: ['', Validators.required],
    lname: ['', Validators.required],
    message: ['Plan query Landing page(inside)', Validators.required],
    email: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
    company: ['', Validators.required],
  });

  public siteKey?: string = '6Lf59_gUAAAAAEJNHrHp1Vdd1LaG1epuOF62yj80';
  public token?: string;
  public siteKeyV2: string = "6Lf4BvkUAAAAAKreyepy8vMm4ttzZtXIY_0xVJ0O";

  constructor(
    private blogService: BlogsService,
    private locationService: LocationService,
    private homeService: HomeService,
    private titleService: Title,
    private metaTagService: Meta,
    private googleAnalyticsService: GoogleAnalyticsService,
    private fb: FormBuilder,
    private fbb: FormBuilder,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private alert: AlertService,
    private router: Router,
    private authService: AuthService,
    private authenticationService: AuthenticationService,
    private headerService: HeaderService,
  ) {}

  loginForm = this.fb.group({
    email: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
    password: ['', Validators.required],
  });

  forgotPasswordForm = this.fb.group({
    email: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
  });

  enterOtpForm = this.fb.group({
    otp: ['', Validators.required],
    password: ['', Validators.required],
    repassword: ['', Validators.required],
  });

  emailVerifyForm = this.fb.group({
    email: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
  });

  signUpForm = this.fb.group({
    fname: ['', Validators.required],
    lname: ['', Validators.required],
    password: ['', Validators.required],
    otp: ['', Validators.required],
  });

  resetPasswordForm = this.fb.group({
    otp: ['', Validators.required],
    password: ['', Validators.required],
    repassword: ['', Validators.required],
  });

  @ViewChild('letsTalk') letsTalk;
  @ViewChild('selectDate') selectDate;
  @ViewChild('showloader') showloader;
  @ViewChild('thankyou') thankyou;
  @ViewChild('loginmodal') loginmodal;

  public lat;
  public lng;

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'description', content: 'Unlimited royalty free trendy, high quality illustrations artworks, graphics, character and images for commercial and non commercial websites.'},
      { name: 'keywords', content: 'free illustrations download, free illustrations online, royality free illustrations, free illustrations, freebie, illustration, illustrations, illustrations for free, Graphics, vector illustration' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Crafttor' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);

    AOS.init();
    this.getRecentBlogs();
    let country = this.locationService.getCountry();
    if(!country){
      this.locationService.getPosition().then(pos=>
      {
        // this.locationService.setLocation(pos);
        this.locationService.getGeoLocationGoogle(pos.lat, pos.lng).subscribe(data=>{
          this.locationService.setLocation(data);
          country = this.locationService.getCountry();
          if(country == 'India'){
            this.isIndia = true;
          }
        })
      }).catch(err => {
        this.isIndia = false;
      })
    }
    else{
      if(country == 'India'){
        this.isIndia = true;
      }
    }

    this.authService.authState.subscribe((user) => {
      if (user && !this.authenticationService.currentUserValue) {
        this.loginToServer(user, user.photoUrl);
      }
    });
    this.authenticationService.currentUser.subscribe(x => { this.currentUser = x });
    if (window.screen.width <= 991) { // 768px portrait
      this.mobileView = true;
    }else{
      this.mobileView = false;
    }

    this.getLocation();
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: Position) => {
        if (position) {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
        }
      },
        (error: PositionError) => console.log(error));
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  selectCompanySize (event: any) {
    this.companySize = event.target.value;
  }

  selectHeardAbout (event: any) {
    this.heardAbout = event.target.value;
  }

  redirectToLogin() {
    this.router.navigate(['/login']);
  }

  private getRecentBlogs(){
    this.blogService.getBlogs('-created_at', 3).subscribe((data)=>{
      this.recentBlogs = data['results'];
    });
  }

  private getPopularBlogs(){
    this.blogService.getBlogs('-popular', 3).subscribe((data)=>{
      this.popularBlogs = data['results'];
    });
  }

  openLetsTalkDialog(modal){
    this.showDate = true;
    this.isDateSelected = false;
    this.dateSelected = false;
    this.showSubmitBtn = false;
    this.showSelectionMessage = true;
    this.showTime = false;
    this.timeSelected = true;
    this.showFinalTime = true;
    this.letsTalk = this.modalService.open(modal, { centered: true, windowClass: 'letstalk-assests ' });
  }

  openThankskDialog(modal){
      this.thankyou = this.modalService.open(modal, { centered: true, windowClass: 'thanks-popup ' });
  }

  showLoaderDialog(modal){
      this.showloader = this.modalService.open(modal, { centered: true, windowClass: 'show-loader ' });
  }

  showLoaderDialogClose(modal){
      this.showloader.close();
  }

  openSelectTimeDialog(modal){
      this.selectDate = this.modalService.open(modal, { centered: true, windowClass: 'select-time-popup ' });
  }

  public submitContactUs(modal) {
    this.submitted = true;
    if (this.letsTalkForm.valid) {
      this.showLeaveLoader = true;
      this.reCaptchaV3Service.execute(this.siteKey, 'contact_us', (token) => {
        this.showLeaveLoader = false;
        this.letsTalkForm.value['recaptcha'] = token;
        this.cdr.detectChanges();
        this.userName = this.letsTalkForm.value['name'] + " " + this.letsTalkForm.value['lname'];
        this.userEmail = this.letsTalkForm.value['email'];
        this.companyName = this.letsTalkForm.value['company'];
        this.letsTalkForm.reset();
        this.letsTalk.close();
        this.getDateListApi();
        this.selectDate = this.modalService.open(modal, { centered: true, windowClass: 'select-time-popup ' });
      });
    }
  }

  bookDemo(modal) {
    this.showDate = true;
    this.isDateSelected = false;
    this.dateSelected = false;
    this.showSubmitBtn = false;
    this.showSelectionMessage = true;
    this.showTime = false;
    this.timeSelected = true;
    this.showFinalTime = true;
    this.submitted = true;
    if (this.letsTalkFormNew.valid) {
      this.reCaptchaV3Service.execute(this.siteKey, 'contact_us', (token) => {
        this.letsTalkFormNew.value['recaptcha'] = token;
        this.cdr.detectChanges();
        this.userName = this.letsTalkFormNew.value['fullName'];
        this.userEmail = this.letsTalkFormNew.value['fullEmail'];
        this.companyName = this.heardAbout +" : " +this.companySize;
        this.getDateListApi();
        this.selectDate = this.modalService.open(modal, { centered: true, windowClass: 'select-time-popup ' });
      });
    }
  }

  getDateListApi() {
    this.homeService.getDateCall().subscribe((data: any) => {
      this.availableSlots = data;
      // this.getTimeListApi(data[0].date);
    });
  }

  getTimeListApi(date: any) {
    this.homeService.getTimeCall(date).subscribe((data: any) => {
      this.availableTimeSlots = data;
    });
  }

  contactSalesCall(modal) {
    if(this.isDateSelected == true && this.isTimeSelected == true) {
      this.showloaderStyle = "block";
       this.homeService.contactSalesCallApi(this.userName, this.userEmail, this.companyName,
         this.selectedDate, this.selectedTime).subscribe((data) => {
         this.submitted = false;
         this.letsTalkFormNew.reset();
         this.showloaderStyle = "none";
         this.openThankskDialog(modal);
       },(error: any) => {
         if(error.status == 400){
           this.submitted = false;
           this.showloaderStyle = "none";
           this.alert.error(error.error.message);
         } else {
           this.submitted = false;
           this.showloaderStyle = "none";
           this.alert.error(error.error.message);
         }
      });
    } else if(this.isDateSelected == false) {
      this.showSelectionMessage = true;
      this.showSelectedMsg = "Select Date";
    } else if(this.isTimeSelected == false) {
      this.showSelectionMessage = true;
      this.showSelectedMsg = "Select Time";
    }
  }

  selectDateSales(date: string, day: string, month: string) {
    this.selectedDate = date;
    this.showDate = false;
    this.isDateSelected = true;
    this.dateSelected = true;
    this.showSubmitBtn = true;
    this.showSelectionMessage = false;
    this.thankYouText = "Thank You!! select time."

    this.getTimeListApi(date);
    this.showTime = true;
    this.timeSelected = false;
    this.showFinalTime = false;

    this.shownMonth = month;
    if(day == 'Mon') {
      this.shownDay = "Monday";
    } else if(day == 'Tue') {
      this.shownDay = "Tuesday";
    } else if(day == 'Wed') {
      this.shownDay = "Wednesday";
    } else if(day == 'Thu') {
      this.shownDay = "Thursday";
    } else if(day == 'Fri') {
      this.shownDay = "Friday";
    } else if(day == 'Sat') {
      this.shownDay = "Saturday";
    } else if(day == 'Sun') {
      this.shownDay = "Sunday";
    }

    let stringToSplit = this.selectedDate;
    let splitDate = stringToSplit.split("-");

    if(splitDate[1] == '01') {
      this.shownDate = "Jan" +" "+ splitDate[2] +", "+splitDate[0];
    } else if(splitDate[1] == '02') {
      this.shownDate = "Feb" +" "+ splitDate[2] +", "+splitDate[0];
    } else if(splitDate[1] == '03') {
      this.shownDate = "March" +" "+ splitDate[2] +", "+splitDate[0];
    } else if(splitDate[1] == '04') {
      this.shownDate = "April" +" "+ splitDate[2] +", "+splitDate[0];
    } else if(splitDate[1] == '05') {
      this.shownDate = "May" +" "+ splitDate[2] +", "+splitDate[0];
    } else if(splitDate[1] == '06') {
      this.shownDate = "June" +" "+ splitDate[2] +", "+splitDate[0];
    } else if(splitDate[1] == '07') {
      this.shownDate = "Julu" +" "+ splitDate[2] +", "+splitDate[0];
    } else if(splitDate[1] == '08') {
      this.shownDate = "Aug" +" "+ splitDate[2] +", "+splitDate[0];
    } else if(splitDate[1] == '09') {
      this.shownDate = "Sept" +" "+ splitDate[2] +", "+splitDate[0];
    } else if(splitDate[1] == '10') {
      this.shownDate = "Oct" +" "+ splitDate[2] +", "+splitDate[0];
    } else if(splitDate[1] == '11') {
      this.shownDate = "Nov" +" "+ splitDate[2] +", "+splitDate[0];
    } else if(splitDate[1] == '12') {
      this.shownDate = "Dec" +" "+ splitDate[2] +", "+splitDate[0];
    }
  }

  changeDate() {
    this.showDate = true;
    this.isDateSelected = false;
    this.dateSelected = false;
    this.showSubmitBtn = false;
    this.showSelectionMessage = false;
    this.showTime = false;
    this.isTimeSelected = false;
    this.timeSelected = false;
    this.showFinalTime = false;
    // this.getTimeListApi(this.availableSlots[0].date);
  }

  selectTimeSales(time) {
    this.selectedTime = time;
    this.showTime = false;
    this.showSubmitBtn = true;
    this.shownTime = time;
    this.showFinalTime = true;
    this.isTimeSelected = true;
    this.showSelectionMessage = false;
    this.timeSelected = true;
    this.thankYouText = "Thank You!! you meeting will be scheduled."
  }

  changeTime() {
    this.showTime = true;
    this.isTimeSelected = false;
    this.timeSelected = false;
    this.showSelectionMessage = false;
  }

  openLoginModal(modal) {
    this.loginmodal = this.modalService.open(modal, { centered: true, windowClass: 'login-modal ' });
  }

  closeLoginModal() {
    this.loginmodal.close();
  }

  loginToServer(user: any = null, photoUrl: string) {
    this.authenticationService.login(user.provider.toLowerCase(), user.id,
    user.authToken, user.email, user.firstName,
    user.lastName, photoUrl, this.lat, this.lng)
      .subscribe(
        (_data) => {
          if (this.returnUrl) {
            this.router.navigate([this.returnUrl]);
          }
          this.loginmodal.close();
        }
      );
  }

  loginFunction(){
    this.submitted = true;
    if (this.loginForm.valid) {
      this.showLeaveLoader = true;
        this.authenticationService.loginWithEmail(this.loginForm.value.email,
           this.loginForm.value.password, this.workspace_token).subscribe((data) => {
             this.submitted = false;
             this.showLeaveLoader = false;
             this.loginForm.reset();
             this.loginmodal.close();
        },
        (error) => {
          const errors = error.error || error.statusText;
          Object.keys(errors).map((key) => {
            errors[key].map((message) => {
              this.alert.error(message);
              this.submitted = false;
              this.showLeaveLoader = false;
            })
          });
       });
    }
  }

  getOTP(){
    this.emailVerifySubmit = true;
    this.emailIds = this.emailVerifyForm.value.email
    if (this.emailVerifyForm.valid) {
      this.showLeaveLoader = true;
      this.authenticationService.getOTP(this.emailVerifyForm.value.email).subscribe((data: any) => {
            this.alert.success("OTP sent Successfully!");
            this.showLeaveLoader = false;
            this.emailVerifyForm.reset();
            this.login = false;
            this.emailVerify = false;
            this.forgotpassword = false;
            this.forgotpasswordFields = false;
            this.enterOtp = false;
            this.resetPassword = false;
            this.signup = true;
            this.emailVerifySubmit = false;
          },
          (error) => {
            const errors = error.error || error.statusText;
            Object.keys(errors).map((key) => {
              errors[key].map((message) => {
                  this.alert.error(message);
                  this.emailVerifySubmit = false;
                  this.showLeaveLoader = false;
              })
            })
         });
    }
  }

  forgotPasswordFunction(){
    this.submittedForm = true;
    if (this.forgotPasswordForm.valid) {
      this.showLeaveLoader = true;
      this.authenticationService.getResetPasswordOTP(this.forgotPasswordForm.value.email).subscribe((data: any) => {
            this.alert.success("OTP sent Successfully!");
            this.showLeaveLoader = false;
            this.login = false;
            this.signup = false;
            this.forgotpassword = false;
            this.enterOtp = true;
            this.resetPassword = true;
            this.submittedForm = false;
          },
          (error) => {
            const errors = error.error || error.statusText;
            Object.keys(errors).map((key) => {
              // console.log("finally revealed" + errors[key]);
              errors[key].map((message) => {
                this.alert.error(message);
                this.submittedForm = false;
                this.showLeaveLoader = false;
              })
            })
         });
    }
  }

  signUpFormFunction(){
    this.submitt = true;
    if (this.signUpForm.valid) {
      this.showLeaveLoader = true;
      this.authenticationService.registerWithEmail(this.signUpForm.value.fname, this.signUpForm.value.lname, this.emailIds, this.signUpForm.value.password, this.signUpForm.value.otp, this.workspace_token)
        .subscribe(
          (_data) => {
            this.submitt = false;
            this.showLeaveLoader = false;
            this.signUpForm.reset();
            this.loginmodal.close();
          },
          (error) => {
            const errors = error.error || error.statusText;
            Object.keys(errors).map((key) => {
              errors[key].map((message) => {
                this.alert.error(message);
                this.submitt = false;
                this.showLeaveLoader = false;
              })
            })
         });
    }
  }

  resetPasswordFunction(){
    this.passwordSubmit = true;
      if (this.enterOtpForm.valid) {
        this.showLeaveLoader = true;
        this.authenticationService.resetPassword(this.forgotPasswordForm.value.email, this.enterOtpForm.value.otp,
          this.enterOtpForm.value.password, this.enterOtpForm.value.repassword).subscribe((data: any) => {
            this.alert.success("Password reset Successfully!");
            this.showLeaveLoader = false;
            this.passwordSubmit = false;
            this.enterOtpForm.reset();
            this.forgotPasswordForm.reset();
            this.login = true;
            this.signup = false;
            this.forgotpassword = false;
            this.enterOtp = false;
            this.resetPassword = false;
          },
          (error) => {
            const errors = error.error || error.statusText;
            Object.keys(errors).map((key) => {
              // console.log("finally revealed" + errors[key]);
              errors[key].map((message) => {
                this.alert.error(message);
                this.passwordSubmit = false;
                this.showLeaveLoader = false;
              })
            })
         });
        }
      }

  socialLogin(){
    this.loginSignUpText = "Welcome Back!"
    this.showSendingSmall = false;
    this.socialLoginSection = true;
    this.manualLoginSection = false;
    this.login = true;
    this.emailVerify = false;
    this.forgotpassword = false;
    this.forgotpasswordFields = false;
    this.enterOtp = false;
    this.resetPassword = false;
    this.signup = false;
    this.emailVerifySubmit = false;
    this.showSending = false;
    this.showSendingSmall = false;
  }

  socialSignUp(){
    this.loginSignUpText = "Let's get started"
    this.showLeaveLoader = false;
    this.socialLoginSection = true;
    this.manualLoginSection = false;
    this.login = false;
    this.emailVerify = true;
    this.forgotpassword = false;
    this.forgotpasswordFields = false;
    this.enterOtp = false;
    this.resetPassword = false;
    this.signup = false;
    this.emailVerifySubmit = false;
    this.showSending = false;
  }

  manualLogin(){
    this.loginForm.reset();
    this.emailVerifyForm.reset();
    this.signUpForm.reset();
    this.enterOtpForm.reset();
    this.forgotPasswordForm.reset();
    this.showSending = false;
    this.showSendingSmall = false;
    this.socialLoginSection = false;
    this.manualLoginSection = false;
    this.login = true;
    this.emailVerify = false;
    this.forgotpassword = false;
    this.forgotpasswordFields = false;
    this.enterOtp = false;
    this.resetPassword = false;
    this.signup = false;
    this.emailVerifySubmit = false;
  }

  showLoginScreen() {
    this.socialLoginSection = true;
    this.manualLoginSection = false;
    this.forgotpassword = false;
    this.forgotpasswordFields = false;
    this.enterOtp = false;
    this.resetPassword = false;
    this.signup = false;
    this.emailVerify = false;
    this.passwordSubmit = false;
    this.submittedForm = false;
    this.emailVerifySubmit = false;
    this.submitt = false;
    this.submitted = false;
    this.login = true;
  }

  showEmailVerifyScreen() {
    this.login = false;
    this.forgotpassword = false;
    this.forgotpasswordFields = false;
    this.enterOtp = false;
    this.resetPassword = false;
    this.signup = false;
    this.passwordSubmit = false;
    this.submittedForm = false;
    this.emailVerifySubmit = false;
    this.submitt = false;
    this.submitted = false;
    this.emailVerify = true;
  }

  showSignUpScreen() {
    this.login = false;
    this.forgotpassword = false;
    this.forgotpasswordFields = false;
    this.enterOtp = false;
    this.resetPassword = false;
    this.emailVerify = false;
    this.passwordSubmit = false;
    this.submittedForm = false;
    this.emailVerifySubmit = false;
    this.submitt = false;
    this.submitted = false;
    this.signup = true;
  }

  forgotScreen(){
    this.loginForm.reset();
    this.login = false;
    this.enterOtp = false;
    this.resetPassword = false;
    this.signup = false;
    this.emailVerify = false;
    this.forgotpasswordFields = false;
    this.passwordSubmit = false;
    this.submittedForm = false;
    this.emailVerifySubmit = false;
    this.submitt = false;
    this.submitted = false;
    this.forgotpassword = true;
  }

  showResetPasswordScreen() {
    this.login = false;
    this.forgotpassword = false;
    this.forgotpasswordFields = false;
    this.enterOtp = false;
    this.emailVerify = false;
    this.signup = false;
    this.passwordSubmit = false;
    this.submittedForm = false;
    this.emailVerifySubmit = false;
    this.submitt = false;
    this.submitted = false;
    this.resetPassword = true;
  }

  openWorkspaceLogin() {
    window.open("https://workspace.crafttor.com/login", "_blank");
  }

  items = [
    { image: 'assets/img/pnew.jpeg', icon: 'assets/img/icon-yellow.svg' , name: 'Prashish', designation: 'Nickelfox', desc: 'We at Nickelfox found ourself spoiled by the amazing illustrations by Crafttor. What an amazing resource. If you are ever looking for beautiful SVG illustrations for your app or website Crafttor is your go-to place. Saves a lot of time.' },
    { image: 'assets/img/snew.jpeg', icon: 'assets/img/icon-red.svg', name: 'Souradeep Paul', designation: 'anthillcreations.org', desc: 'We have been using Crafttor since the last few months and it has fulfilled all our core graphic design requirements. Not only do they have variety, the graphics are easily editable and reconfigurable. The designers in my team are delivering high quality outcomes in half the time.' },
    { image: 'assets/img/prnew.jpeg',icon: 'assets/img/icon-blue.svg', name: 'Sahil Pruthi', designation: 'Livofy', desc: 'Crafttor and the team helped us in the early days at Keto India, in the website design and illustrations. The team was able to help us with high quality illustrations that helped us, not just build a beautiful, engaging website - but a highly converting one!' },
    { image: 'assets/img/sanew.jpeg',icon: 'assets/img/icon-mustard.svg', name: 'Sanchit Garg', designation: ' innerfit.me', desc: 'Crafttor is the best place for the illustrations for applications & websites. Crafttor illustrations helped us in designing our platform more interesting & engaging for our business levels. I highly recommend its illustrations to everyone.' },
  ];

  currentIndex = 0;
  visibleItems = 3.5; // Number of visible items (2.5)

  // Go to the previous slide
  prevSlide() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }
  }

  // Go to the next slide
  nextSlide() {
    if (this.currentIndex < this.items.length - this.visibleItems) {
      this.currentIndex++;
    }
  }
}
