import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  title = 'Terms & conditions | Crafttor';

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private metaTagService: Meta,) { }
  activeTab: String = this.route.snapshot.paramMap.get('active');
  privacy:Boolean=false;
  terms:Boolean=true;

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'description', content: 'We ensure privacy and maintain our users account at priority. Download trendy illustrations securely on Crafttor.'},
      { name: 'keywords', content: '' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Crafttor' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);

    if(this.activeTab){
      if(this.activeTab == 'privacy'){ this.privacy=true; this.terms=false}
      if(this.activeTab == 'terms'){this.terms=true; this.privacy=false;}
    }
  }
  currentOrientation = 'horizontal';

}
