import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LandingService {

  constructor(private httpClient: HttpClient) { }
  api_root: string = environment.REST_API_SERVER;

  getIllus(limit: number){
    return this.httpClient.get(this.api_root+"illus/?limit="+limit)
  }
}
