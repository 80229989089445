import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlogsService } from '../blogs.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.css']
})
export class BlogDetailsComponent implements OnInit {

  blog:any;
  private blogId: string;

  constructor(
    private route: ActivatedRoute,
    private blogService: BlogsService,
    private titleService: Title,
    private metaTagService: Meta,

  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
    this.blogId = params.get('blogId');
    this.blogService.getBlog(this.blogId).subscribe((data)=>{
      this.blog = data;
      this.getSeoData(this.blog.title, this.blog.seo_description)
    })
  });
}

  private getSeoData(title: string, desc: string){
    this.titleService.setTitle(title);
    this.metaTagService.addTags([
      { name: 'description', content: desc},
      { name: 'keywords', content: 'Free illustrations download, royalty free illustrations, illustrations for free, free illustrations online' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Crafttor' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);
  }
}
