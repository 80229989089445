import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Payment } from '../_models/payment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  webSetting = 'settings/'
  orderUrl = 'create-order/'
  stripeUrl = 'stripe-payments/'
  public getWebSettings(key: string) {
    const api_root = environment.REST_API_SERVER;
    let url = api_root + this.webSetting + key + "/";

    return this.httpClient.get(url).pipe(map(data => {
      return data['value'];
    }));
  }

  public postOrder(payment: Payment) {
    const api_root = environment.REST_API_SERVER;
    let url = api_root + this.orderUrl;
    const data = {
      "paypal_order_id": payment.paypalOrderId,
      "payment": payment.orderAmount,
      "plan_id": payment.planId,
      "status": payment.status
    }
    return this.httpClient.post<any>(url, data);
  }

  public postStripeOrder(stripeJson: any, amount: string, currency: string, plan_id: string, plan_text: string, plan_type: string, userName: string, address: string, city: string, state: string, country: string, postcode: string, productId: any) {
    const api_root = environment.REST_API_SERVER;
    // console.log(amount +" - "+currency +" - "+plan_id +" - "+plan_text)
    let url = api_root + this.stripeUrl;
    const data = {
      "stripe_obj": stripeJson,
      "amount": amount,
      "currency": currency,
      "plan_id": plan_id,
      "plan_text": plan_text,
      "plan_type": plan_type,
      "name": userName,
      "line1": address,
      "city": city,
      "state": state,
      "country": country,
      "postal_code": postcode,
      "category_id": productId,
      "order_from": 'stripe'
    }
    return this.httpClient.post<any>(url, data);
  }

  public singleCategoryStripe(categoryId: any, stripeJson: any, amount: string, currency: string,
    plan_text: string, plan_type: string, userName: string, address: string, city: string,
    state: string, country: string, postcode: string) {
    const api_root = environment.REST_API_SERVER;
    let url = api_root + `categories/${categoryId}/buy_category_stripe/`;
    const data = {
      "stripe_obj": stripeJson,
      "amount": amount,
      "currency": currency,
      "plan_text": plan_text,
      "plan_type": plan_type,
      "name": userName,
      "line1": address,
      "city": city,
      "state": state,
      "country": country,
      "postal_code": postcode,
      "order_from": 'stripe'
    }
    return this.httpClient.post<any>(url, data);
  }

  public singleCategoryRazor(categoryId: any, amount: string, currency: string,
    plan_text: string, plan_type: string, userName: string, address: string, city: string,
    state: string, country: string, postcode: string, razorpayPaymentId: string, razorpayOrderId: string, razorpaySignature: string) {
    const api_root = environment.REST_API_SERVER;
    let url = api_root + `categories/${categoryId}/buy_category_razor/`;
    const data = {
      "amount": amount,
      "currency": currency,
      "plan_text": plan_text,
      "plan_type": plan_type,
      "name": userName,
      "line1": address,
      "city": city,
      "state": state,
      "country": country,
      "postal_code": postcode,
      "razorpay_payment_id": razorpayPaymentId,
      "razorpay_order_id": razorpayOrderId,
      "razorpay_signature": razorpaySignature,
    }
    return this.httpClient.post<any>(url, data);
  }

  public getAmountRazor(categoryId: any) {
    const api_root = environment.REST_API_SERVER;
    let url = api_root + `categories/${categoryId}/get_category_amount_razor/`;
    return this.httpClient.get<any>(url);
  }
}
