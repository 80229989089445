import { Component, OnInit, ViewChild } from '@angular/core';
import { IconsService } from '../icons/service/icons.service';
import { SharedService } from '../shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { AlertService } from '../components/alert/alert.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'angularx-social-login';
import { AuthenticationService } from '../authentication.service';
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { Title, Meta } from '@angular/platform-browser';
import { PaginationService } from "../pagination/pagination.service";

@Component({
  selector: 'app-cagesicon',
  templateUrl: './cagesicon.component.html',
  styleUrls: ['./cagesicon.component.css']
})
export class CagesiconComponent implements OnInit {

  iconTitle: string;
  iconImage: string;
  iconDesc: any;
  iconId: any;
  iconsList: [];
  iconsCategory: [];

  limit: number = 20;
  offset: number = 0;
  next: string;

  constructor(
    private iconsService: IconsService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private alert: AlertService,
    private modalService: NgbModal,
    public router: Router,
    private authService: AuthService,
    private authenticationService: AuthenticationService,
    private activateRoute: ActivatedRoute,
    private titleService: Title,
    private metaTagService: Meta,
    private paginationService: PaginationService
  ) { }

  loginModal: any;
  returnUrl: any;
  searchText: string = '';
  submitted: boolean;
  categories: [];
  categoriesCheckbox: [];
  selectedCategories: string;
  checkedIDs = [];
  headerCategory: string;
  categorySelected: boolean = false;
  paginationLoading: boolean = false;
  searchTextEntered: string;
  showLimit: boolean = true;
  currentIllus: any;
  currentUser: any;
  iconsSimilar: [];
  @ViewChild('limitModal') limitModal;
  @ViewChild('downloadModal') downloadModal;
  @ViewChild('loginModal') loginModalAttr;

  public lat;
  public lng;

  ngOnInit(): void {
    this.getDataValue();

    this.titleService.setTitle(this.iconTitle +" |  Open Source Trendy SVG Illustrations");
    this.metaTagService.addTags([
      { name: 'description', content: 'Download high quality clean pixel free svg illustrations includes couple, love, corona, error, space, login & many more illustrations for your websites, apps and products.'},
      { name: 'keywords', content: 'Couple Illustrations, Love Illustrations, Error Illustrations, beautiful Illustrations, Login Illustrations, Corona Illustrations, Coronavirus Illustrations, Doctor Illustrations, high quality svg illustrations' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Crafttor' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);

    this.route.queryParams.subscribe(params => {
      this.searchTextEntered = '';
      this.headerCategory = params['category'];
      this.searchText = params['search'];
      this.makeCategoriesChecklist(this.headerCategory);
      if (this.headerCategory) {
        this.categorySelected = true;
      }
      this.currentUser = this.authenticationService.currentUserValue;
    });

    this.authService.authState.subscribe((user) => {
      if (user && !this.authenticationService.currentUserValue) {
        this.loginToServer(user, user.photoUrl);
      }
    });

    this.activateRoute.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'];
    });
  }

  getDataValue(){
    var details = JSON.parse(localStorage.getItem('iconCategoryDetails'));
    this.iconTitle = details.name;
    this.iconDesc = details.description;
    this.iconImage = details.image;
    this.iconId = details.id;
    this.titleService.setTitle(this.iconTitle +" |  SVG Icons");
    this.getIconList(this.iconId);
    this.getIconCatgories();
   }

   getIconList(iconId: any) {
     this.iconsService.iconsList(iconId).subscribe((data: any) => {
       this.iconsList = data['results'];
     });
   }

   open(content: any) {
     this.loginModal = this.modalService.open(content, { centered: true, windowClass: 'login-modal' })
   }

   openClose() {
     this.loginModal.close();
   }


   loginToServer(user: any = null, photoUrl: string) {
     this.authenticationService.login(user.provider.toLowerCase(), user.id,
     user.authToken, user.email, user.firstName, user.lastName,
     photoUrl, this.lat, this.lng)
       .subscribe(
         (_data) => {
           if (this.returnUrl) {
             this.router.navigate([this.returnUrl]);
           }
           this.loginModal.close();

         }
       );
   }

   signInWithGoogle(): void {
     this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
   }

   signInWithFB(): void {
     this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
     // this.loginToServer();
   }

   fbSignOut(): void {
     this.authService.signOut();
   }

   logOut(): void {
     this.authenticationService.logout();
     this.authService.signOut();
   }

   private makeCategoriesChecklist(headerCategory: string) {
     this.sharedService.preLoaddedCategoriesData$.subscribe((data) => {
       if (data) {

         this.categories = data['results'];
         this.categoriesCheckbox = data['results'].map(x => {

           if (headerCategory) {
             if (headerCategory == x.id) {
               this.selectedCategories = x.id;
               return {
                 id: x.id,
                 isChecked: true,
                 label: x.name
               }
             }
             else {
               return {
                 id: x.id,
                 isChecked: false,
                 label: x.name
               }
             }


           } else {
             return {
               id: x.id,
               isChecked: true,
               label: x.name
             }
           }

         }
         );
       }
     });
   }
   openDownloadModal(modal) {
     this.downloadModal = this.modalService.open(modal, { centered: true, windowClass: 'download-assests ' });
   }
   openLimitModal(modal) {
     this.modalService.open(modal, { centered: true, windowClass: 'premium-pop ' });
   }
   closeLimitModal() {
     this.limitModal.close();
   }
   closeDownloadModal() {
     this.downloadModal.close();
   }

   categoryChanged() {
     this.checkedIDs = [];
     this.checkedIDs = this.categoriesCheckbox.filter((value) => {
       return value['isChecked'];
     });
     this.selectedCategories = '';
     this.selectedCategories = this.selectedCategories + this.checkedIDs.map(category =>
       category.id
     );
     if (this.selectedCategories) {
       this.searchIcons(this.searchText);
     }

   }

   onSubmit() {
     // if (this.searchText) {
     this.searchIcons(this.searchText);

     // }
   }

   private searchCompleted(searchText: string, data: any, appendData: boolean = false) {
     if (appendData) {
       this.iconsList.push.apply(this.iconsList, data['results'])
     }
     else {
       this.iconsList = data['results'];
     }
     this.next = data['next'];
     this.iconsSimilar = [];
     if (searchText) {

       this.searchTextEntered = searchText;
     }
     if (this.iconsList.length <= 10) {
       this.iconsService.similarIcons().subscribe((data: any) => {
         this.iconsSimilar = data;
       });
     }
   }

   searchIcons(searchText: string = "", limit: number = this.limit): void {
     this.iconsService.searchIcons(searchText, limit).subscribe((data: any) => {
       this.searchCompleted(searchText, data);
     });

   }

   onScrollDown() {
     if(this.next){
       this.paginationLoading = true;
       this.paginationService.getFromUrl(this.next).subscribe(data => {
         this.searchCompleted(this.searchText, data, true);
         this.paginationLoading = false;
       }, (error: any) => {
         this.paginationLoading = false;
       });
     }
   }

   goToLink(link: string) {
     if (this.limitModal) {
       this.limitModal.close()
     }
   }

   downloadPngImage(): void {
     this.iconsService.downloadPng(this.currentIllus.id).subscribe((data: any) => {
       // console.log("data received===>", data)
       saveAs(data, this.currentIllus.title+'.png')
     },
       (error: any) => {
         if (error.status === 403) {
           this.openLimitModal(this.limitModal);

         }
         else {
           // this.alert.alert("Alert", "Limit reached! Please login to download", () => {
           //   console.log('Ok')
           // })
           this.downloadModal.close();
           this.loginModal = this.modalService.open(this.loginModalAttr, { centered: true, windowClass:
              'dark-modal exceed-limit login-modal' })

         }
       }
     );

   }
   downloadSvgImage(): void {
     this.iconsService.downloadSvg(this.currentIllus.id).subscribe((data: any) => {
       saveAs(data, this.currentIllus.title+'.svg')
     },
       (error: any) => {
         if (error.status === 403) {
           this.openLimitModal(this.limitModal);
         }
         else {
           // this.alert.alert("Alert", "Please login to download", () => {
           //   console.log('Ok')
           // })
           // this.limitModal.close();
           this.downloadModal.close();
           this.loginModal = this.modalService.open(this.loginModalAttr,
              { centered: true, windowClass: 'dark-modal exceed-limit login-modal' })

         }
       }
     );
   }

   public searchIconsRedirect() {
     this.router.navigate(['icons-search']);
   }

   public selectIconsValue(category: any) {
      localStorage.setItem('iconCategoryDetails', JSON.stringify({ id: category.id, name: category.name,
        image: category.image, description: category.description }));
        this.getDataValue();
        this.getIconList(category.id);
   }

   getIconCatgories() {
     this.iconsService.iconsCategory().subscribe((data: any) => {
       this.iconsCategory = data['results'];
     });
   }
}
