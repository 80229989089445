
<section class="single-post-section" >
	<div class="container">
		<a href="javascript:" class="back-link" [routerLink]="['/blogs']" ><img src="assets/img/back-arrow.svg"> Back</a>
		<div class="row">
			<div class="col-sm-12">
				<h1>{{blog.title}}</h1>
				<!-- <div class="date">{{blog.date}}</div> -->
				<div class="tags">
					<a href="javascript:;">{{blog.author}}</a>
					<!-- <a href="javascript:;">Tools</a> -->
				</div>
				<div class="featured-img">
					<img [src]="blog.image_rec">
				</div>
				<div [innerHTML]='blog.content' class="desc">
					<!-- <p>When you are creating a product, Think about the use-cases from the user & business point of view. It’s like to fulfill user requirements to achieve the business goal. So, to achieve this, icons & labels play a very important role in
						product design.</p>
					<p>The well-known case scenario for every product design are: Icons with Labels, Labels only & Icons only.</p>
					<p>A combination of all three is very important to win the user. Getting the right combination for your product is very important. Few things to remember when you are creating a product</p>
					<h2>Understand Your Business</h2>
					<img src="assets/img/img-blog1.png">
					<p>When you are creating a product, Think about the use-cases from the user & business point of view. It’s like to fulfill user requirements to achieve the business goal. So, to achieve this, icons & labels play a very important role in
						product design.</p>
					<h2>Product Frequency & Usage</h2>
					<p>On a social media platform, where a user use the product regularly, then it’s a good place for you to play with more new ideas & do some quick experiments with new features & icons but on the other hand in a real estate product, In which
						a user use your product occasionally then you don’t want to introduce a new icons, which will be a new learning curve for the user.</p>
					<h2>Product Frequency & Usage</h2>
					<p>On a social media platform, where a user use the product regularly, then it’s a good place for you to play with more new ideas & do some quick experiments with new features & icons but on the other hand in a real estate product, In which
						a user use your product occasionally then you don’t want to introduce a new icons, which will be a new learning curve for the user.</p> -->
				</div>
			</div>

		</div>
	</div>
</section>
