<section class="icons-search-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-box">
					<h1>Free Open Source SVG Icons</h1>
					<form class="custom-form" (ngSubmit)="onSubmit()" #heroForm="ngForm">
						<input type="text" placeholder="Search Icons" class="text-field" name="searchText" [(ngModel)]="searchText">
						<input type="Submit" value="Search" class="button">
					</form>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="icons-section">
	<div class="container">
		<ul class="load-vector" infiniteScroll
		[infiniteScrollDistance]="1"
		[infiniteScrollUpDistance]="2"
		[infiniteScrollThrottle]="50"
		(scrolled)="onScrollDown()">
			<li *ngFor="let illus of icons">
				<div class="inner-card" (click)="currentIllus= illus;openDownloadModal(downloadModal)">
					<img [src]="illus.image_svg" alt="high quality free svg icons">
					<div class="hover-style">
						<div class="title">{{ illus.title }}</div>
					</div>
				</div>
			</li>
		</ul>
		<div *ngIf="icons && icons?.length == 0" style="text-align: center;">
			<h3>No results found</h3>
			<br />
			<br />
		</div>
		<h3  *ngIf="iconsSimilar?.length > 0" style="font-weight: bold; margin-top: 60px;">Other Icons</h3>
		<ul *ngIf="iconsSimilar?.length > 0"  class="load-vector" style="margin-top: 0px;">
			<li *ngFor="let illus of iconsSimilar">
				<div class="inner-card" (click)="currentIllus= illus;openDownloadModal(downloadModal)">
					<img [src]="illus.image_svg" alt="best svg icons">
					<div class="hover-style">
						<div class="title">{{ illus.title }}</div>
					</div>
				</div>
			</li>
		</ul>
		<img src="assets/img/loader.gif" *ngIf="paginationLoading" id="loadMorevector" href="javascript:;" style="display: block;margin-left: auto;margin-right: auto; margin-top:40px; width: 70px; height:60px;">
	</div>
</section>

<!-- Cages category section -->
<section class="icon-vectorimg-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<h2>Popular Categories</h2>
			</div>
		</div>
		<div class="row" style="display: block;">
			<ul class="load-list">
				<li *ngFor="let icons of iconsCategory">
					<div class="icon-vector-box" routerLink="/icon" [queryParams]="{category: icons.id}" (click)="selectIconsValue(icons)">
						<img [src]="icons.image" alt="line icons">
					</div>

					<div class="category-title">
						<h3>{{ icons.name }}</h3>
					</div>
				</li>
			</ul>
		</div>
	</div>
</section>


<!-- FAQs Section -->
<section class="faq-section-other-pages">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-content">
					<h6>FAQs</h6>
					<hr>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="faq-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>Are these icons free?</h2>
					<p>Yes, We have beautiful and clean pixel SVG icons for free. Anyone can download these free icons and use anywhere they want like mobile apps, websites, etc. We are providing SVG icons for websites, mobile apps, and for your other products too.</p>
					<hr>
				</div>
			</div>
			<div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>Is there any icons download limit?</h2>
					<p>No, Please note that free assets like icons do not come with any download limitations. Anyone can download any number of icons in a day. There might be some download limits to our icons in the future and we will update our license and other data to let people know about any change happen.</p>
					<hr>
				</div>
			</div>

			<div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>Do you have PNG format icons?</h2>
					<p>No, we stick to SVG format icons. As per today's trend, people use SVG format icons for their platforms like websites or mobile apps because SVG format does not pixelate either if you increase/decrease the size of an icon. The other use case is you can easily change the color of the SVG icon because it is an XML code base.</p>
					<hr>
				</div>
			</div>
			<div class="col-sm-12 right-question faq-bottom">
				<div class="inner-content">
					<h2>Do you offer different categories for icons?</h2>
					<p>Yes, we offer open-source icons within different categories, and you can also search which icon is required via Search icons option. Right now we are offering 3 different categories to our beautiful icons. We do provide high-quality SVG icons based on the latest topics like airpods, browsers, etc.</p>
					<hr>
				</div>
			</div>
		</div>
	</div>
</section>

<ng-template #downloadModal let-downloadModal>
	<div class="download-assests">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<button type="button" class="close" (click)="downloadModal.close()">
					<img src="assets/img/cross.svg" alt="download free icons">
				</button>
				<div class="modal-body">
					<div class="thumb">
						<img style="width:100px; height:100px;" [src]="currentIllus.image_svg" alt="download svg icons">
					</div>
					<div class="content-right">
						<h3>{{currentIllus.title || ''}}</h3>
						<!-- <div class="style-txt"><strong>Style: </strong>{{currentIllus.category || ''}}</div> -->
						<div class="options">
							<!-- <div class="custom-checkbox">
								<a href="javascript:;" class="button" (click)="downloadPngImage()">Download PNG</a>
							</div> -->
							<div class="custom-checkbox">
								<a href="javascript:;" class="button" (click)="downloadSvgImage()">Download SVG</a>
							</div>
						</div>
						<div class="bottom-txt">
							<span>Attribution is required.</span>
							<strong>Crafttor License</strong>
							<p>Free for personal and commercial purpose with attribution. <a href="javascript:;" (click)="downloadModal.close(); router.navigate(['/license'])" class="link">More info</a></p>
							<strong>For Exclusive Content & Unlimited Downloads</strong>
							<div class="btn-wrapper"><a class="button yellow-btn" data-dismiss="modal" (click)="downloadModal.close(); router.navigate(['/member'])" href="javascript:;">Premium Membership</a></div>
							<span>No attribution required.</span>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</ng-template>

<ng-template #limitModal let-limitModal>
	<div class="modal-dialog modal-dialog-centered  premium-pop" role="document">
		<div class="modal-content">
			<button type="button" class="close" (click)="limitModal.close()">
				<img src="assets/img/cross.svg" alt="download funny icons">
			</button>
			<div class="modal-body">
				<h3>Sorry!</h3>
				<h4>You exceed your daily limit</h4>
			<a href="javascript:;" (click)="limitModal.close(); closeDownloadModal();" [routerLink]="['/license', {active: 'readrules'}]" class="simple-link">Read the Rules</a>
				<p>For Exclusive Content & Unlimited Downloads</p>
				<div class="btn-wrapper"><a class="button yellow-btn" (click)="limitModal.close(); closeDownloadModal(); router.navigate(['/member'])" href="javascript:;">Premium Membership</a></div>
				<span>No attribution required.</span>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #loginModal let-modal>
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<button type="button" class="close" (click)="openClose()" data-dismiss="modal" aria-label="Close">
				<img src="assets/img/cross.svg" alt="download free icons">
			</button>
			<div class="modal-body">
				<h3>Login</h3>
				<div class="button-wrapper">
					<a href="javascript:;" class="button google-btn" (click)="signInWithGoogle()"><i class="fa fa-google-plus"></i> Continue with Google</a>
					<a href="javascript:;" class="button fb-btn" (click)="signInWithFB()"><i class="fa fa-facebook-f"></i>Continue with Facebook</a>
					<p>By Login, I agree the <a href="javascript:;" (click)="openClose()" [routerLink]="['privacy-terms', {active: 'terms'}]">Terms of Use</a> & <a href="javascript:;" (click)="openClose()"
							[routerLink]="['privacy-terms', {active: 'privacy'}]">Privacy Policy</a>.</p>
				</div>
			</div>

		</div>
	</div>
</ng-template>


<!-- <section>
	<div class="download-icons">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<button type="button" class="close" (click)="downloadModal.close()">
					<img src="assets/img/cross.svg" alt="download free icons">
				</button>
				<div class="modal-body">
					<div class="thumb">
						<img [src]="currentIllus.image_resize" alt="download svg icons">
					</div>
					<div class="content-right">
						<h3>{{currentIllus.title || ''}}</h3>
						<div class="style-txt"><strong>Style: </strong>{{currentIllus.category || ''}}</div>
						<div class="options">
							<div class="custom-checkbox">
								<a href="javascript:;" class="button" (click)="downloadPngImage()">Download PNG</a>
							</div>
							<div class="custom-checkbox">
								<a href="javascript:;" class="button" (click)="downloadSvgImage()">Download SVG</a>
							</div>
						</div>
						<div class="bottom-txt">
							<span>Attribution is required.</span>
							<strong>Crafttor License</strong>
							<p>Free for personal and commercial purpose with attribution. <a href="javascript:;" (click)="downloadModal.close(); router.navigate(['/license'])" class="link">More info</a></p>
							<strong>For Exclusive Content & Unlimited Downloads</strong>
							<div class="btn-wrapper"><a class="button yellow-btn" data-dismiss="modal" (click)="downloadModal.close(); router.navigate(['/member'])" href="javascript:;">Premium Membership</a></div>
							<span>No attribution required.</span>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</section> -->
