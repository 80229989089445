<!-- Search Section -->
<section class="term-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="tabs-wrapper">
					<div class="vertical-tabs">

						<!-- Tabs -->
						<ul class="nav nav-tabs" role="tablist">
							<li class="nav-item">
								<a class="nav-link" [ngClass]="{'active': licence}" data-toggle="tab" href="#licence" role="tab" aria-controls="licence">License</a>
							</li>
							<li class="nav-item">
								<a class="nav-link"  [ngClass]="{'active': readrules}" data-toggle="tab" href="#readrules" role="tab" aria-controls="readrules">Read the rules</a>
							</li>
						</ul>

						<div class="tab-content">
              <div class="tab-pane" [ngClass]="{'active': licence}" id="licence" role="tabpanel">
							  <div class="sv-tab-panel">
								<h2>License</h2>
								<p>Crafttor has all the aspects and different licenses for our users at Crafttor’s website. In addition, we provide all the necessary resources a user needs to enhance the value of the product.</p>


								<h3>FREE License</h3>
								<p>Following are the guidelines under Free License:</p>
								<ul>
									<li>With our free license users are allowed to access & download illustrations from Crafttor’s website.</li>
									<li>Users can get unlimited access to all the resources available with daily & monthly limits.</li>
									<li>Download restrictions under free license.</li>
									<li>No fee structure for any plans.</li>
									<li>There is no need for permission.</li>
								</ul>

								<h3>PAID License</h3>
								<p>Following are the guidelines under Paid License:</p>
								<ul>
									<li>With our paid license, users are allowed to access & download 2D/3D illustrations from Crafttor’s website.</li>
									<li>Users can get unlimited access to all the resources available.</li>
									<li>Download restrictions are based on plans.</li>
									<li>Individual plan: Download only 100 PNG(includes 2D & large 3D) & 50 SVG in a month.</li>
									<li>Premium Member plan: Download 200 PNG(includes 2D & large 3D) & 120 SVG in a month.</li>
									<li>Flat fee structure for different plans.</li>
									<li>No daily download limit.</li>
								</ul>
								<h3>CONTACT US</h3>
								<p>If you have any query about our Licenses, feel free to email us at <b><a href="mailto:info@crafttor.com">info@crafttor.com</a></b>.</p>
								<p>Be a Crafttor’s family and enjoy unlimited access to the illustrations provided under the company’s license.</p>
							  </div>
							</div>

							<div class="tab-pane" [ngClass]="{'active': readrules}" id="readrules" role="tabpanel">
								<div class="sv-tab-panel">
									<h2>Rules & Regulations</h2>
									<p>Following are the rules &amp; regulations under Crafttor:</p>
									<ul>
										<li>Every plan has a daily & monthly download limit for PNG(2D/3D) & SVG. You can upgrade a plan as per your requirement.</li>
										<li>Login is mandatory, if you want to download SVG for free.</li>
										<li>Large 3D are available in paid plans only.</li>
										<li>Free 2D & 3D Illustrations also have download limits.</li>
										<li>No need for permission.</li>
										<li>All PNG’s(2D & 3D) and SVG’s on Crafttor are free to use.</li>
										<li>Attribution is required. Giving credits to Crafttor is always appreciated.</li>
	                  <li>You can modify the illustrations from Crafttor as you want. Be creative and edit them as you like.</li>
	                  <li>Don't redistribute or sell the assets on other platforms.</li>
									</ul>

									<h3>CONTACT US</h3>
									<p>If you have any query about our rules & and regulations, feel free to email us at <b><a href="mailto:info@crafttor.com">info@crafttor.com</a></b>.</p>
									<p>Be a Crafttor’s family and enjoy unlimited access to the illustrations provided under the company’s rules.</p>

									</div>
									</div>
							</div>
							<!-- Tabs 3 -->
						</div>
				</div>
			</div>
		</div>
	</div>
  </section>
