<section class="community-section community-background-design">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
				<h1>Translate AI is now available on <span class="freelaner-txt">Figma</span></h1>
				<p>Translate AI empowers you to effortlessly translate content within your designs using the power of AI.</p>
				<div class="btn-wrapper">
					<a href="https://www.figma.com/community/plugin/1409844094149025473/translate-ai" target="_blank" class="button gray-btn">
						<img style="width:16px; height:20px; margin-right: 15px; " class="btnimg" src="assets/img/figma.svg" alt="" />
						Install Plugin</a>
				</div>
      </div>
    </div>
  </div>
</section>

<section class="figma-hero-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<img class="kaleidoscope-img" src="assets/img/translate-ai.png" alt="">

        <h2>Translate AI Plugin</h2>
        <p>Translate your design content effortlessly with the Translate AI plugin and enhance global accessibility.</p>
			</div>
		</div>
	</div>
</section>

<section class="figma-hero-section" style="background: #ffffff;">
	<div class="container">
    <div class="row before-after-section">
      <div class="col-sm-1">

      </div>
      <div class="col-sm-5 white-back-temp">
        <h5>Before Translate AI</h5>
        <ul>
          <li class="red-before">
            <img src="assets/img/cross-red.svg" alt="">
            Increased Risk of Errors.
          </li>

          <li class="red-before">
            <img src="assets/img/cross-red.svg" alt="">
            No multiple outcome options.
          </li>

          <li class="red-before">
            <img src="assets/img/cross-red.svg" alt="">
            Complex translation results.
          </li>
        </ul>
      </div>

      <div class="col-sm-5 white-back-temp">
        <h5>After Translate AI</h5>
        <ul>
          <li class="green-after">
            <img src="assets/img/tick-green.svg" alt="">
            Accurate Multilingual Content.
          </li>

          <li class="green-after">
            <img src="assets/img/tick-green.svg" alt="">
            Three outcomes of a line is possible.
          </li>

          <li class="green-after">
            <img src="assets/img/tick-green.svg" alt="">
            AI powered translation results.
          </li>
        </ul>
      </div>
      <div class="col-sm-1">

      </div>
    </div>
	</div>
</section>

<section class="community-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
				<h3>Break language barriers effortlessly with the Translate AI plugin and expand your reach</h3>
				<p>Seamlessly bridge language gaps and reach a worldwide audience with Translate AI and unlock global communication.</p>
      </div>
    </div>
  </div>
</section>

<section class="kaleidoscope-video-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-7 video-area">
        <video autoplay loop [muted]="'muted'" class="kaleidoscope-img" controls>
          <source src="https://marketplace-crafttor-assets.s3.ap-south-1.amazonaws.com/translate.mp4" type="video/mp4">
        </video>
			</div>

      <div class="col-sm-1">
			</div>

      <div class="col-sm-4 video-text-area">
        <h2>Boost Your Visuals: Here's the Features Walkthrough</h2>
        <p>Check the plugin demo and give it a try to translate UI text into different languages.</p>
			</div>
		</div>
	</div>
</section>

<section class="figma-content-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-content">
					<h1>A new way to learn and design</h1>
					<p>Loved by designers from</p>
				</div>
			</div>
      <ul>
        <li></li>
        <li>
          <img class="air-lic" src="assets/img/airtel.svg" alt="">
        </li>

        <li>
          <img class="air-lic" src="assets/img/licious.svg" alt="">
        </li>

        <li>
          <img class="del-whe" src="assets/img/deloitte.svg" alt="">
        </li>

        <li>
          <img class="del-whe" src="assets/img/wheels.svg" alt="">
        </li>
        <li></li>
      </ul>
		</div>
	</div>
</section>

<!-- Big CTA Section -->
<section class="cta-section">
	<div class="container">
		<div class="inner-cta">
			<div class="row">
				<div class="col-md-7">
					<h2>Effortlessly translate your designs with the Translate AI plugin to connect and become a creator of the new era.</h2>
				</div>
				<div class="col-md-5">
					<div class="btn-wrap">
						<a href="https://www.figma.com/community/plugin/1408354139656199596/kaleidoscope" target="_blank" class="button gray-btn">
							<img style="width:16px; height:20px; margin-right: 15px; " class="btnimg" src="assets/img/figma.svg" alt="" />
							Install Plugin</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="kaleidoscope-description-section">
	<div class="container">
		<div class="row">
      <div class="col-sm-3">
			</div>

			<div class="col-sm-6 graphic-section">
        <img src="assets/img/sheild.svg" alt="">
				<h2>Secure and Reliable</h2>
        <p>All our Figma plugins are built with security and reliability in mind — your data remains protected and stays securely within your Figma file.</p>
			</div>

      <div class="col-sm-3">
			</div>
		</div>
	</div>
</section>

<section class="plugin-design-area">
  <div class="container">
    <div class="row">
      <ul>
        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/crafttor.png" alt="" style="border-radius: 4px;">
              <h2>Crafttor</h2>
            </div>
            <p>Workspace & Marketplace offers access to number of illustrations and icons, upload and download options.<br></p>

            <a routerLink="/figma"><button type="button" name="button">Try Crafttor <br><span>Get started today</span></button></a>
          </div>
        </li>

        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/iso.png" alt="">
              <h2>Iso All</h2>
            </div>
            <p>Iso All seems to be a tool or feature that simplifies and enhance the creation of isometric designs.</p>
            <a routerLink="/iso-all"><button type="button" name="button">Try Iso All <br><span>Get started today</span></button></a>
          </div>
        </li>

        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/kalei.png" alt="">
              <h2>Kaleidoscope</h2>
            </div>
            <p>Kaleidoscope plugin adds style from your photos and materials, enhancing your design appearance.</p>
            <a routerLink="/kaleidoscope"><button type="button" name="button">Try Kaleidoscope <br><span>Get started today</span></button></a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</section>

<!-- FAQs Section start-->
<section class="faq-section-other-pages">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-content">
					<h6>FAQs</h6>
					<hr>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="faq-section design-faq">
	<div class="container">
		<div class="row">
      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>What is Translate AI Plugin?</h2>
					<p>The Translate AI Plugin uses artificial intelligence to effortlessly translate text within your designs into multiple languages.</p>
					<hr>
				</div>
			</div>
      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>How many outcomes are there in Translate AI Plugin?</h2>
					<p>There are 3 possible outcomes, and you can select any one or pick all three, one by one.</p>
					<hr>
				</div>
			</div>

      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>Which design tools are compatible with the Translate AI Plugin?</h2>
					<p>The plugin is compatible with only one design tool - Figma</p>
					<hr>
				</div>
			</div>
      <div class="col-sm-12 right-question faq-bottom">
				<div class="inner-content">
					<h2>How do I use the Translate AI Plugin?</h2>
					<p>Install the plugin, select the text you want to translate, choose the target language, and let the plugin handle the translation automatically.</p>
					<hr>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- FAQs Section END -->
