<section class="community-section community-background-design">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
				<h1>Plug into possibilities of <span class="freelaner-txt">Imaginations</span></h1>
				<p>Enhance your product performance and interface by choosing the right plugin. Customize the appearance of websites, mobile apps and other products with Crafttor plugins.</p>
				<div class="btn-wrapper">
					<a href="javascript:;" routerLink="/member" class="button">Become Member</a>
				</div>
      </div>
    </div>
  </div>
</section>

<section class="plugin-design-area">
  <div class="container">
    <div class="row">
      <ul>
        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/crafttor.png" alt="" style="border-radius: 4px;">
              <h2>Marketplace</h2>
            </div>
            <p>Marketplace offers access to number of illustrations and icons, simplifying your workflow and boosting efficiency.<br></p>

            <a routerLink="/figma"><button type="button" name="button">Try Marketplace <br><span>Get started today</span></button></a>
          </div>
        </li>

        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/crafttor.png" alt="" style="border-radius: 4px;">
              <h2>Workspace</h2>
            </div>
            <p>Workspace is a cloud storage, where you can upload your assets to numerous boards and access them through plugin.</p>
            <a routerLink="/figma"><button type="button" name="button">Try Workspace <br><span>Get started today</span></button></a>
          </div>
        </li>

        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/kalei.png" alt="">
              <h2>Kaleidoscope</h2>
            </div>
            <p>Kaleidoscope plugin adds style from your photos and materials, enhancing your design appearance.</p>
            <a routerLink="/kaleidoscope"><button type="button" name="button">Try Kaleidoscope <br><span>Get started today</span></button></a>
          </div>
        </li>

        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/iso.png" alt="">
              <h2>Iso All</h2>
            </div>
            <p>Iso All seems to be a tool or feature that simplifies and enhance the creation of isometric designs.</p>
            <a routerLink="/iso-all"><button type="button" name="button">Try Iso All <br><span>Get started today</span></button></a>
          </div>
        </li>

        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/trans.png" alt="">
              <h2>Translate AI</h2>
            </div>
            <p>Translate AI empowers you to effortlessly translate content within your designs using the power of AI.<br></p>
            <a routerLink="/translate-ai"><button type="button" name="button">Try Translate AI <br><span>Get started today</span></button></a>
          </div>
        </li>

        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/img-fig.png" alt="">
              <h2>Image To Figma</h2>
            </div>
            <p>Image To Figma Design convert any image or screenshot into fully editable Figma designs.<br></p>
            <a routerLink="/image-to-figma"><button type="button" name="button">Try Image To Figma <br><span>Get started today</span></button></a>
          </div>
        </li>

        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/sketch.svg" alt="">
              <h2>Sketch</h2>
            </div>
            <p>Sketch plugin is designed with a new idea, not preconceived, to keep designers engaged with their products.</p>
            <a routerLink="/sketch"><button type="button" name="button">Try Sketch <br><span>Get started today</span></button></a>
          </div>
        </li>

        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/aii.svg" alt="">
              <h2>Illustrator</h2>
            </div>
            <p>Be a mind blowing illustrator with our plugin. It's easy to handle and manage while designing your thoughts.</p>
            <a routerLink="/illustrator"><button type="button" name="button">Try Illustrator <br><span>Get started today</span></button></a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</section>

<section class="community-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
				<h3>Key strategies that drive top-performing teams to success.</h3>
				<p>Elite teams of creatives use our Figma plugins to maximize productivity and streamline their processes.</p>
      </div>

      <ul>
        <li>
          <div class="plugins-data">
            <h4>Figma</h4>
            <h1 class="figma-count-txt">28K</h1>
            <h4>Installs</h4>
          </div>
        </li>

        <li>
          <div class="plugins-data">
            <h4>Sketch</h4>
  					<h1 class="sketch-count-txt">{{sketchData.total_download}}</h1>
  					<h4>Downloads</h4>
          </div>
        </li>

        <li>
          <div class="plugins-data">
            <h4>Marketplace</h4>
  					<h1 class="illustrator-count-txt">500+</h1>
  					<h4>Illustrations</h4>
          </div>
        </li>
      </ul>
    </div>
  </div>
</section>

<section class="community-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
				<h3>Join the creators! Over 40k+ users are enhancing their workflow with our plugins.</h3>
				<p>Let machines handle the routine — join thousands of top designers and developers, automating their workflows with our Figma plugins.</p>
      </div>

      <div class="col-sm-12 companies-logos">
        <div class="col-sm-2">
  			</div>
  			<div class="col-sm-2">
  				<img src="assets/img/airtel.svg" alt="" style="width: 90px;">
  			</div>
  			<div class="col-sm-2">
  				<img src="assets/img/licious.svg" alt="" style="width: 90px;">
  			</div>
  			<div class="col-sm-2">
  				<img src="assets/img/byju.svg" alt="" style="width: 90px;">
  			</div>
  			<div class="col-sm-2">
  				<img src="assets/img/wheels.svg" alt="">
  			</div>
  			<div class="col-sm-2">
  			</div>
      </div>

      <div class="col-sm-12 companies-logos">
        <div class="col-sm-2">
  			</div>
  			<div class="col-sm-2">
  				<img src="assets/img/flip.svg" alt="">
  			</div>
  			<div class="col-sm-2">
  				<img src="assets/img/thoughtworks.svg" alt="">
  			</div>
  			<div class="col-sm-2">
  				<img src="assets/img/yellow.ai.svg" alt="">
  			</div>
  			<div class="col-sm-2">
  				<img src="assets/img/innovaccer.svg" alt="">
  			</div>
  			<div class="col-sm-2">
  			</div>
      </div>
    </div>
  </div>
</section>

<section class="figma-hero-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
				<h4>Premium Customer Experience.</h4>
				<p>Committed to <i>excellence</i>, we make sure you and your team experience the best service, every single day.</p>
      </div>
    </div>
    <div class="row" >
      <div class="comments-list">
        <ul>
          <li>
            <div class="user-rating-data">
              <h4><img src="assets/img/icon-yellow.svg" alt="">This is soo quick and easy with fresh illustrations. Loved it</h4>
            </div>
          </li>

          <li>
            <div class="user-rating-data">
              <h4><img src="assets/img/icon-dark-blue.svg" alt="">i just love crafttor's illustrations and plugin .. Great Job and keep it up !!</h4>
            </div>
          </li>

          <li>
            <div class="user-rating-data">
              <h4><img src="assets/img/icon-red.svg" alt="">Crafttor is a time saver for our time & it's simple to use.</h4>
            </div>
          </li>

          <li>
            <div class="user-rating-data">
              <h4><img src="assets/img/icon-mustard.svg" alt="">amazing plugin</h4>
            </div>
          </li>

          <li>
            <div class="user-rating-data">
              <h4><img src="assets/img/icon-sea-green.svg" alt="">Greeeeeeeeeeeeeeeeat......</h4>
            </div>
          </li>

          <li>
            <div class="user-rating-data">
              <h4><img src="assets/img/icon-pink.svg" alt="">Awesome work!  Keep it up</h4>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="kaleidoscope-description-section">
	<div class="container">
		<div class="row">
      <div class="col-sm-3">
			</div>

			<div class="col-sm-6 graphic-section">
        <img src="assets/img/sheild.svg" alt="">
				<h2>Secure and Reliable</h2>
        <p>All our Figma plugins are built with security and reliability in mind — your data remains protected and stays securely within your Figma file.</p>
			</div>

      <div class="col-sm-3">
			</div>
		</div>
	</div>
</section>

<!-- FAQs Section start-->
<section class="faq-section-other-pages">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-content">
					<h6>FAQs</h6>
					<hr>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="faq-section design-faq">
	<div class="container">
		<div class="row">
			<div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>What is Figma Plugin?</h2>
					<p>Figma Plugin is a web-based prototyping tool and a vector graphics editor for teams to collaborate and bring ideas to life. You can integrate designs, illustrations, and other web elements offered by other brands to create your websites, UI/UX design, and a lot more.</p>
					<hr>
				</div>
			</div>
      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>What is Kaleidoscope Plugin?</h2>
					<p>Kaleidoscope is a simple plugin that adds kaleidoscopic style from your own photos and materials to your projects, enhancing your design appearance. You can extract a new design pattern from a current design pattern.</p>
					<hr>
				</div>
			</div>
			<div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>How to use Figma plugins?</h2>
					<p>Figma is available as a cloud-based, in-browser plugin and is universally available across all platforms. Register for free to start your creative journey. You can install plugins for illustrations, icons, mobile designs, web designs, and a lot more. </p>
					<hr>
				</div>
			</div>
      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>What is Iso All Plugin?</h2>
					<p>Iso All seems to be a tool or feature that simplifies the creation of isometric designs. You can change the look and feel of an image by changing its symmetry or angles.</p>
					<hr>
				</div>
			</div>
      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>What is Sketch Plugin?</h2>
          <p>The sketch is a Mac-only, vector-based digital design tool that is primarily useful for UI/UX design of web, desktop, mobile, and wearables. Plugins improve functionality and workflow by providing creative elements that teams can directly integrate into their designs. You can add features created by developers across the globe.</p>
          <hr>
        </div>
      </div>
      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>What is Translate AI Plugin?</h2>
					<p>Translate AI empowers you to effortlessly translate content within your designs using the power of AI. Whether you need to translate entire frames, groups, or components, this plugin streamlines the process, enhancing your design workflow.</p>
					<hr>
				</div>
			</div>
      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>How to use Sketch plugins?</h2>
          <p>Find your desired plugin on the Sketch website and click on the installation button. You will be redirected to a GitHub repository. Click on the ‘clone or download button’ and click on ‘download zip’ from the drop-down menu. Double-click on the Sketch plugin, and it’s installed. </p>
          <hr>
        </div>
      </div>
      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>How many outcomes are there in Translate AI Plugin?</h2>
					<p>There are 3 possible outcomes, and you can select any one or pick all three, one by one.</p>
					<hr>
				</div>
			</div>
			<div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>Who should use Figma Plugins?</h2>
					<p>Figma is a collaborative tool with a free plan for anyone working in the digital space. Although it’s suitable for both individual projects and team endeavors, the browser-based application saves your work in real-time. Other prototyping tools either don’t have the same ability or entirely lack this essential feature.</p>
					<hr>
				</div>
			</div>
      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>What is Illustrator Plugin?</h2>
          <p>Illustrator Plugin is a vector graphic tool and can be use worldwide by designers of all types who wants to create illustrations, vector graphics and other use like create illustrations, graphics for web, video etc.</p>
          <hr>
        </div>
      </div>
      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>How to use Illustrator plugins?</h2>
          <p>Illustrator is available on Crafttor section of Adobe. Your can simply install the plugin and use it. It's a simple tool and you have to just follow whatever is showing on the plugin. Product(marketplace, workspace) you have to select and good to go. </p>
          <hr>
        </div>
      </div>
			<div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>How can I access Crafftor on Figma?</h2>
					<p>To access our illustrations on Figma, create a free account and choose the starter pack with 30-day version history and unlimited cloud storage. Go to Menu > Plugins > Browse Plugins in Community and search for Crafftor. Install the plugin and start using our illustrations for your projects.</p>
					<hr>
				</div>
			</div>
      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>Who should use Sketch Plugins?</h2>
          <p>Individuals or teams dedicated to designing icons, UI/UX, prototypes, user flow, UI libraries, vector illustrations, social media images, and presentations. However, you must have a MAC to use Sketch.</p>
          <hr>
        </div>
      </div>
      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>How can I access Crafftor on Sketch?</h2>
          <p>We have made the installation easy for you. Click on the download sketch above, and a download will start. Once you’ve installed the Crafttor plugin, you can access its features from the Plugins menu.</p>
          <hr>
        </div>
      </div>
      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>How can I learn Illustrator plugin features?</h2>
          <p>Illustrator plugin is a great tool for designers. One can simply install the plugin and check for the options provided for different products dropdown(marketplace & workspace) and based on products plugin will show illustrations uploaded by Crafttor(marketplace) and other images uploaded by users(workspace) and use as per the need. More you learn when use the plugin.</p>
          <hr>
        </div>
      </div>
      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>What does the Image to Figma Design plugin do?</h2>
					<p>The plugin converts any image or screenshot into fully editable Figma designs, allowing you to modify the elements directly within Figma.</p>
					<hr>
				</div>
			</div>

      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>Which types of images work with this plugin?</h2>
					<p>It supports standard image formats like JPEG, PNG, and screenshots, making them editable within Figma.</p>
					<hr>
				</div>
			</div>

      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>Can I edit every element in the converted image?</h2>
					<p>Yes, the plugin extracts individual elements from the image or screenshot, converting them into editable layers in your Figma design.</p>
					<hr>
				</div>
			</div>

      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>Does the plugin impact the original image quality?</h2>
					<p>The plugin preserves the quality of the image while converting it into editable layers, but the accuracy of the conversion depends on the complexity of the image.</p>
					<hr>
				</div>
			</div>

      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>Is there any limit to how many images I can convert?</h2>
					<p>Currently, the plugin does not limit the number of images you can convert, but processing time may increase with larger or more complex images.</p>
					<hr>
				</div>
			</div>

      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>How secure is my data when using the plugin?</h2>
					<p>All data remains within your Figma file, ensuring security and privacy.</p>
					<hr>
				</div>
			</div>

      <div class="col-sm-12 right-question faq-bottom">
				<div class="inner-content">
					<h2>Is there a file size limit for uploading images?</h2>
					<p>While there's no strict limit, larger files may take longer to process depending on the image complexity.</p>
					<hr>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- FAQs Section END -->
