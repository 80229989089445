import { Component, OnInit } from '@angular/core';
import {SketchService} from '../sketch/service/sketch.service'
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-plugin',
  templateUrl: './plugin.component.html',
  styleUrls: ['./plugin.component.css']
})
export class PluginComponent implements OnInit {

  constructor(
    private sketchService : SketchService,
    private titleService: Title,
    private metaTagService: Meta,
  ) { }

  public sketchData: any;
  public message: string;

  title = 'Plugins | Crafttor';


  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'description', content: 'Plugins are designed with an idea of keeping designers engage with their products'},
      { name: 'keywords', content: '' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Crafttor' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);
    this.sketchService.getSketchVersion().subscribe((data: any) => {
      this.sketchData = data
    });
  }

  saveCountDownload() {
    this.sketchService.downloadCount().subscribe((data: any) => {
      this.sketchService.getSketchVersion().subscribe((data: any) => {
        this.sketchData = data
      });
    });
  }
}
