import { Component, OnInit } from '@angular/core';
import { SharedService } from "../shared.service";
import { LandingService } from "./landing.service";
import { MemberService } from '../member/member.service';
import { LocationService } from '../location/location.service';
import { NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { Title, Meta } from '@angular/platform-browser';
import { DataService } from '../data.service';
import {SketchService} from './sketch.service'
import AOS from 'aos';
import{GoogleAnalyticsService} from '../google-analytics.service';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css'],
})
export class SalesComponent implements OnInit {

    title = 'Sales | High Quality Illustrations, Graphics, Icons';

    public sketchData: any;
    public message: string;

    slideActivate(ngbSlideEvent: NgbSlideEvent) {

     }

      constructor(
        private sharedService: SharedService,
        private homeService: LandingService,
        private memberService: MemberService,
        private locationService: LocationService,
        private titleService: Title,
        private metaTagService: Meta,
        private dataService: DataService,
        private sketchService : SketchService,
        private googleAnalyticsService: GoogleAnalyticsService
      ) {}
      showFigmaImage: boolean = false;
      showSketchImage: boolean = false;
      showText: boolean = false;
      sketch: boolean = false;
      figma: boolean = true;
      categories = [];
      illus = [];
      memberships = [];
      isIndia:boolean = false;
      categoryName: any;
      ngOnInit(): void {
        AOS.init();
        this.sketchService.getSketchVersion().subscribe((data: any) => {
          this.sketchData = data
        });
        this.titleService.setTitle(this.title);
        this.metaTagService.addTags([
          { name: 'description', content: 'Unlimited royalty free trendy, high quality illustrations artworks, graphics, character and images for commercial and non commercial websites.'},
          { name: 'keywords', content: 'free illustrations download, free illustrations online, royality free illustrations, free illustrations, freebie, illustration, illustrations, illustrations for free, Graphics, vector illustration' },
          { name: 'robots', content: 'index, follow' },
          { name: 'author', content: 'Crafttor' },
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
          { charset: 'UTF-8' }
        ]);

        this.sharedService.preLoaddedCategoriesData$.subscribe((data) => {
          this.categories = [];
          if (data) {
            this.categories = data['results'];
          }
        });
        this.getIllus();
        this.getMemberships();

        let country = this.locationService.getCountry();
        if(!country){
          this.locationService.getPosition().then(pos=>
          {
            // this.locationService.setLocation(pos);
            this.locationService.getGeoLocationGoogle(pos.lat, pos.lng).subscribe(data=>{
              this.locationService.setLocation(data);
              country = this.locationService.getCountry();
              if(country == 'India'){
                this.isIndia = true;
              }
            })
          }).catch(err => {
            this.isIndia = false;
          })
        }
        else{
          if(country == 'India'){
            this.isIndia = true;
          }
        }
      }

      private getMemberships() {
        this.memberService.getWebSettings('membership').subscribe(
          (data) => {
            this.memberships = data;
          }
        );
      }
      private getIllus() {

        this.homeService.getIllus(4).subscribe((data: any) => {
          this.illus = data['results'];
        })
      }

      public selectValue(category: any) {
        console.log("click");
        this.dataService.push(category.id);
        console.log("click1");
        this.categoryName =  category.name;
     }

     public selectValueSales(category: any) {
        localStorage.setItem('categoryDetails', JSON.stringify({ name: category.name,
          image: category.image, description: category.description }));
     }


     figmaHover(){
      this.showFigmaImage = true;
      this.showSketchImage = false;
      this.figma = true;
      this.sketch = false;
     }

     sketchHover(){
       this.showFigmaImage = false;
       this.showSketchImage = true;
       this.sketch = true;
       this.figma = false;
     }

     falseHover(){
      this.showFigmaImage = false;
      this.showSketchImage = false;
      this.showText = false;
     }

     xdHover(){
       this.showText = true;
       this.showFigmaImage = false;
       this.showSketchImage = false;
     }

     goPremium(){
       this.googleAnalyticsService.eventEmitter("Go-Premium-Marketing", "Premium", "button", "click", 11);
     }

     exploreMore(){
       this.googleAnalyticsService.eventEmitter("Explore-Marketing", "Premium", "button", "click", 12);
     }
   }
