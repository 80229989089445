// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // REST_API_SERVER: "http://localhost:8000/api/",
  REST_API_SERVER: "https://admin.crafttor.com/api/",
  STRIPE_TEST_KEY: "pk_test_51GsjFpKf944rUC5Tmr8EgKiFGBEJvQunJN5h8UH7IfywavLgtyDSL95z0PEkDqUBoEgydj4hcFbl51tnSIHpX1ag00AQvwC3Md",
  STRIPE_LIVE_KEY: "pk_live_4jJnknwioHz8yiaZhF3BI1SS00zIxLJbG8",
  RAZORPAY_TEST_KEY: "rzp_test_nZYB269JLEnb0S",
  RAZORPAY_LIVE_KEY: "",
  PAYPAL_CLIENT_ID: "AWTxtU2QXDrvnhac_5Gm9oLueVZfXIoaxhVgpmrJ0I3Qf0KdPrhw8Wb30f0kzZMr6dLtFOzGUfrL1IAR",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
