import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  constructor(
    private httpClient: HttpClient,

  ) { }

  public contactUs(data:any) {
    let url = `${environment.REST_API_SERVER}contact-us/`;

    return this.httpClient.post(url, data)
  }

  public contactUsIcons(name:any, email: any, icons: any, reCaptcha: any) {
    let url = `${environment.REST_API_SERVER}contact-us/`;
    const data = {
      "name": name,
      "email": email,
      "message": "Icons Requested: " + icons,
      "recaptcha": reCaptcha
    }
    return this.httpClient.post(url, data)
  }
}
