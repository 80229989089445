<section class="login-screen" *ngIf="socialLoginSection">
  <div class="background container-fluid">
    <div class="container main-heading">
      <h1>Welcome to Crafttor! <span aria-label="tada" role="img">🎉</span></h1>
      <p>Log in with Google to access your Crafttor Workspace</p>
      <div class="google-login-btn">
        <a href="javascript:;">
          <a href="javascript:;" class="button google-btn" (click)="signInWithGoogle()"><i class="fa fa-google-plus" style="padding-right: 8px;"></i> Continue with Google</a>
        <div class="mt-2">
        <a href="javascript:;" class="button fb-btn" (click)="manualLogin()"><i class="fa fa-envelope" style="padding-right: 8px;"></i>Continue with Email</a>
        </div>
        </a>
      </div>
    </div>
  </div>
</section>


<section class="email-section" *ngIf="manualLoginSection">
  <div class="background container-fluid">
    <div class="container main-heading">
      <h1>Welcome to Crafttor! <span aria-label="tada" role="img">🎉</span></h1>
      <p *ngIf="login">Log in with email to access your Crafttor account</p>
      <p *ngIf="signup">Register with email to access your Crafttor account</p>
      <p *ngIf="emailVerify">Register with email to access your Crafttor account</p>
      <p *ngIf="forgotpassword">Reset your password to access your Crafttor account</p>
      <p *ngIf="enterOtp">Reset your password to access your Crafttor account</p>
      <div class="modal-body login-dialog" *ngIf="login">
        <h3>Login</h3>
        <div class="modal-content">
          <div class="button-wrapper">
            <div class="container" *ngIf="login">
              <form action="" [formGroup]="loginForm" (ngSubmit)="loginFunction()">
                <input id="textinput" formControlName="email" type="email" placeholder="Enter email id*" required>
                <span class="text-danger error-text"
                *ngIf="(loginForm.controls.email.touched || submitted) && loginForm.controls.email.errors?.required">
                Email is required
              </span>
              <span class="text-danger error-text"
              *ngIf="(loginForm.controls.email.touched || submitted) && loginForm.controls.email.errors?.pattern">
              Email is not valid
            </span>
                <input id="password" formControlName="password" type="password" placeholder="Enter password*" required>
                <span class="text-danger error-text"
                *ngIf="(loginForm.controls.password.touched || submitted) && loginForm.controls.password.errors?.required">
                Password is required
              </span>
                <label style="float: left; margin-top: 10px;" class="control-label" for="textinput"><a href="javascript:;" (click)="forgotScreen()">Forgot Password?</a></label>

                <button id="singlebutton" name="singlebutton button" class="btn btn-primary" style="width: 100%; background-color: #2c85ed; border-color: #2c85ed; color: #fff;">Submit</button>

                <p>New at Crafttor? <a href="javascript:;" (click)="showEmailVerifyScreen()" style="color: #2c85ed"><b>Sign Up</b></a></p>
                <a href="javascript:;" (click)="socialLogin()" style="color: #2c85ed">Back</a>

                <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt="">
                </form>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-body signup-dialog" *ngIf="emailVerify">
          <h3>Sign Up</h3>
          <div class="button-wrapper">
            <div class="container">
              <form action="" [formGroup]="emailVerifyForm" (ngSubmit)="getOTP()">
              <input id="textinput" formControlName="email" type="email" placeholder="Enter email id*" required>
              <span class="text-danger error-text"
              *ngIf="(emailVerifyForm.controls.email.touched || emailVerifySubmit) && emailVerifyForm.controls.email.errors?.required">
              Email is required
            </span>
            <span class="text-danger error-text"
            *ngIf="(emailVerifyForm.controls.email.touched || emailVerifySubmit) && emailVerifyForm.controls.email.errors?.pattern">
            Email is not valid
          </span>
          <button id="singlebutton" name="singlebutton button" class="btn btn-primary" style="margin-top:4px; background-color: #2c85ed; border-color: #2c85ed; color: #fff; width: 100%;">Get OTP</button>
          <p>Already have an account? <a href="javascript:;" (click)="showLoginScreen()" style="color: #2c85ed"><b>Login</b></a></p>
          <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt="">
          </form>
            </div>
          </div>
        </div>

        <div class="modal-body signup-dialog" *ngIf="signup">
          <h3>Sign Up</h3>
          <div class="button-wrapper">
            <div class="container">
              <form action="" [formGroup]="signUpForm" (ngSubmit)="signUpFormFunction()">
                <input id="textinput" formControlName="fname" type="text" placeholder="Enter first name*" required style="float: left; width: 48%">
                <input id="textinput" formControlName="lname" type="text" placeholder="Enter last name*" required style="float: right; width: 48%">
                <span class="text-danger error-text"
                *ngIf="(signUpForm.controls.fname.touched || submitt) && signUpForm.controls.fname.errors?.required">
                First Name is required
              </span>
                <span class="text-danger error-text" style="float: right;"
                *ngIf="(signUpForm.controls.lname.touched || submitt) && signUpForm.controls.lname.errors?.required">
                Last Name is required
              </span>
            <input id="password" formControlName="password" type="password" placeholder="Enter password*" required>
            <span class="text-danger error-text"
            *ngIf="(signUpForm.controls.password.touched || submitt) && signUpForm.controls.password.errors?.required">
            Password is required
          </span>

          <input id="otp" formControlName="otp" type="otp" placeholder="Enter OTP*" required>
          <span class="text-danger error-text"
          *ngIf="(signUpForm.controls.otp.touched || submitt) && signUpForm.controls.otp.errors?.required">
          OTP is required
          </span>
            <button id="singlebutton" name="singlebutton button" class="btn btn-primary" style="width: 100%; background-color: #2c85ed; border-color: #2c85ed; color: #fff;">Submit</button>
            <p>Already have an account? <a href="javascript:;" (click)="showLoginScreen()" style="color: #2c85ed"><b>Login</b></a></p>
            <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt="">
            </form>
          </div>
        </div>
      </div>


      <div class="modal-body forgot-dialog" *ngIf="forgotpassword">
        <h3>Forgot Password</h3>
        <div class="button-wrapper">
          <div class="container" >
            <form action="" [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPasswordFunction()">
              <input id="textinput" formControlName="email" type="email" placeholder="Enter email id*" required>
              <span class="text-danger error-text"
              *ngIf="(forgotPasswordForm.controls.email.touched || submittedForm) && forgotPasswordForm.controls.email.errors?.required">
                Email is required
              </span>
              <span class="text-danger error-text"
              *ngIf="(forgotPasswordForm.controls.email.touched || submittedForm) && forgotPasswordForm.controls.email.errors?.pattern">
                Email is not valid
              </span>
              <button id="singlebutton" name="singlebutton button" class="btn btn-primary" style="width: 100%; background-color: #2c85ed; border-color: #2c85ed; color: #fff;">Get OTP</button>
              <p><a href="javascript:;" (click)="showLoginScreen()" style="color: #2c85ed">Return to Login</a></p>
              <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt="">
              </form>
            </div>
          </div>
        </div>

        <div class="modal-body forgot-dialog" *ngIf="enterOtp">
          <h3>Enter OTP</h3>
          <div class="button-wrapper">
            <div class="container" >
              <form action="" [formGroup]="enterOtpForm" (ngSubmit)="resetPasswordFunction()">
                <input id="otp" formControlName="otp" type="text" placeholder="Enter OTP*" required>
                <span class="text-danger error-text"
                *ngIf="(enterOtpForm.controls.otp.touched || passwordSubmit) && enterOtpForm.controls.otp.errors?.required">
                Enter OTP
              </span>

              <input id="password" formControlName="password" type="password" placeholder="Enter password*" required>
              <span class="text-danger error-text"
              *ngIf="(enterOtpForm.controls.password.touched || passwordSubmit) && enterOtpForm.controls.password.errors?.required">
              Enter new password
            </span>
            <input id="repassword" formControlName="repassword" type="password" placeholder="Re-enter password*" required>
            <span class="text-danger error-text"
            *ngIf="(enterOtpForm.controls.repassword.touched || passwordSubmit) && enterOtpForm.controls.repassword.errors?.required">
            Re-enter password
          </span>

                  <button id="singlebutton" name="singlebutton button" class="btn btn-primary" style="width: 100%; background-color: #2c85ed; border-color: #2c85ed; color: #fff;">Submit</button>
                <p><a href="javascript:;" (click)="showLoginScreen()" style="color: #2c85ed">Return to Login</a></p>
                <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt="">
                </form>
              </div>
            </div>
          </div>
      </div>
    </div>
</section>
