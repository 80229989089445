import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import AOS from 'aos';

@Component({
  selector: 'app-illustrator',
  templateUrl: './illustrator.component.html',
  styleUrls: ['./illustrator.component.css']
})
export class IllustratorComponent implements OnInit {

  title = 'Illustrator Plugin | Crafttor';

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'description', content: 'illustrator plugin is designed to create better looking landing pages for your products. Its a way to fill empty states that matches your app palette'},
      { name: 'keywords', content: '' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Crafttor' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);
    AOS.init();
  }
}
