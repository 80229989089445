<section class="community-section community-background-design">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
				<h1>Iso All is now available on <span class="freelaner-txt">Figma</span></h1>
				<p>Iso All is an advanced figma tool which simplifies the creation of isometric designs.</p>
				<div class="btn-wrapper">
					<a href="https://www.figma.com/community/plugin/1406167507218530657/iso-all" target="_blank" class="button gray-btn">
						<img style="width:16px; height:20px; margin-right: 15px; " class="btnimg" src="assets/img/figma.svg" alt="" />
						Install Plugin</a>
				</div>
      </div>
    </div>
  </div>
</section>

<section class="figma-hero-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<img class="kaleidoscope-img" src="assets/img/iso-all.png" alt="">

        <h2>Iso All Plugin</h2>
        <p>Create precise isometric designs with the Iso All plugin and elevate the look of your projects.</p>
			</div>
		</div>
	</div>
</section>

<section class="figma-hero-section" style="background: #ffffff;">
	<div class="container">
    <div class="row before-after-section">
      <div class="col-sm-1">

      </div>
      <div class="col-sm-5 white-back-temp">
        <h5>Before Iso All</h5>
        <ul>
          <li class="red-before">
            <img src="assets/img/cross-red.svg" alt="">
            Manual Isometric Design Creation.
          </li>

          <li class="red-before">
            <img src="assets/img/cross-red.svg" alt="">
            Limited Design Flexibility.
          </li>

          <li class="red-before">
            <img src="assets/img/cross-red.svg" alt="">
            Inefficient Workflow.
          </li>
        </ul>
      </div>

      <div class="col-sm-5 white-back-temp">
        <h5>After Iso All</h5>
        <ul>
          <li class="green-after">
            <img src="assets/img/tick-green.svg" alt="">
            Instant Isometric Transformations.
          </li>

          <li class="green-after">
            <img src="assets/img/tick-green.svg" alt="">
            Customisable Angles and Depth.
          </li>

          <li class="green-after">
            <img src="assets/img/tick-green.svg" alt="">
            Streamlined Workflow.
          </li>
        </ul>
      </div>
      <div class="col-sm-1">

      </div>
    </div>
	</div>
</section>

<section class="community-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
				<h3>Transform your designs with isometric precision using Iso All Plugin</h3>
				<p>Iso All plugin enables you to easily create precise isometric designs from standard visuals. Transform flat elements into different dynamics and enhance the depth and impact of your projects.</p>
      </div>
    </div>
  </div>
</section>

<section class="kaleidoscope-video-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-7 video-area">
        <video autoplay loop [muted]="'muted'" class="kaleidoscope-img" controls>
          <source src="https://marketplace-crafttor-assets.s3.ap-south-1.amazonaws.com/iso-all.mp4" type="video/mp4">
        </video>
			</div>

      <div class="col-sm-1">
			</div>

      <div class="col-sm-4 video-text-area">
        <h2>Boost Your Visuals: Here's the Features Walkthrough</h2>
        <p>Check the plugin demo and give it a try to change the symmetry of your UI.</p>
			</div>
		</div>
	</div>
</section>

<section class="figma-content-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-content">
					<h1>A new way to learn and design</h1>
					<p>Loved by designers from</p>
				</div>
			</div>
      <ul>
        <li></li>
        <li>
          <img class="air-lic" src="assets/img/airtel.svg" alt="">
        </li>

        <li>
          <img class="air-lic" src="assets/img/licious.svg" alt="">
        </li>

        <li>
          <img class="del-whe" src="assets/img/deloitte.svg" alt="">
        </li>

        <li>
          <img class="del-whe" src="assets/img/wheels.svg" alt="">
        </li>
        <li></li>
      </ul>
		</div>
	</div>
</section>

<!-- Big CTA Section -->
<section class="cta-section">
	<div class="container">
		<div class="inner-cta">
			<div class="row">
				<div class="col-md-7">
					<h2>Generate new isometric designs from your current work with the Iso All plugin to bring your ideas to life.</h2>
				</div>
				<div class="col-md-5">
					<div class="btn-wrap">
						<a href="https://www.figma.com/community/plugin/1408354139656199596/kaleidoscope" target="_blank" class="button gray-btn">
							<img style="width:16px; height:20px; margin-right: 15px; " class="btnimg" src="assets/img/figma.svg" alt="" />
							Install Plugin</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="kaleidoscope-description-section">
	<div class="container">
		<div class="row">
      <div class="col-sm-3">
			</div>

			<div class="col-sm-6 graphic-section">
        <img src="assets/img/sheild.svg" alt="">
				<h2>Secure and Reliable</h2>
        <p>All our Figma plugins are built with security and reliability in mind — your data remains protected and stays securely within your Figma file.</p>
			</div>

      <div class="col-sm-3">
			</div>
		</div>
	</div>
</section>

<section class="plugin-design-area">
  <div class="container">
    <div class="row">
      <ul>
        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/crafttor.png" alt="" style="border-radius: 4px;">
              <h2>Crafttor</h2>
            </div>
            <p>Workspace & Marketplace offers access to number of illustrations and icons, upload and download options.<br></p>

            <a routerLink="/figma"><button type="button" name="button">Try Crafttor <br><span>Get started today</span></button></a>
          </div>
        </li>

        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/kalei.png" alt="">
              <h2>Kaleidoscope</h2>
            </div>
            <p>Kaleidoscope plugin adds style from your photos and materials, enhancing your design appearance.</p>
            <a routerLink="/kaleidoscope"><button type="button" name="button">Try Kaleidoscope <br><span>Get started today</span></button></a>
          </div>
        </li>

        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/trans.png" alt="">
              <h2>Translate AI</h2>
            </div>
            <p>Translate AI empowers you to effortlessly translate content within your designs using the power of AI.<br></p>
            <a routerLink="/translate-ai"><button type="button" name="button">Try Translate AI <br><span>Get started today</span></button></a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</section>

<!-- FAQs Section start-->
<section class="faq-section-other-pages">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-content">
					<h6>FAQs</h6>
					<hr>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="faq-section design-faq">
	<div class="container">
		<div class="row">
      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>What is Iso All Plugin?</h2>
					<p>The Iso All Plugin is designed to simplify the creation of isometric designs by converting standard designs into isometric perspectives.</p>
					<hr>
				</div>
			</div>

      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>How do I get started with the Iso All Plugin?</h2>
					<p><a href="https://www.figma.com/community/plugin/1406167507218530657/iso-all" target="_blank"><b>Install</b></a> the plugin, select your design elements, and use the plugin’s tools to transform them into isometric views with just a few clicks.</p>
					<hr>
				</div>
			</div>

      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>Can I adjust the angle of the isometric projection?</h2>
					<p>Yes, the plugin allows you to customize the angle and depth of the isometric projection to fit your design needs.</p>
					<hr>
				</div>
			</div>

      <div class="col-sm-12 right-question faq-bottom">
				<div class="inner-content">
					<h2>What design tools is the Iso All Plugin compatible with?</h2>
					<p>Currently, Iso All Plugin is compatible with only design tool Figma.</p>
					<hr>
				</div>
			</div>
		</div>
	</div>
</section>
<!-- FAQs Section END -->
