<section class="search-section profile-bg">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="profile-box">
					<div class="thumbnail">
						<img *ngIf="!userPic" src="assets/img/user-profile.svg">
						<img *ngIf="userPic" src="{{userPic}}">
					</div>
					<div class="user-info">
						<!-- <div class="member-type"><img src="assets/img/crown.svg"> Premium Member</div> -->
						<div class="member-name">{{currentUser.firstName}} {{currentUser.lastName}}</div>
						<!-- <div class="member-location"><img src="assets/img/location.svg"> {{ currentUser.country || '-' }} </div> -->
						<div class="member-email"><img src="assets/img/icon-mail.svg"> {{currentUser.email || '-' }}</div>
						<h5 class="manage-text" style="margin-top: 24px;">Plan <svg viewBox="64 64 896 896" focusable="false" class="" data-icon="arrow-right" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 000-48.4z"></path></svg><span class="pro manage-plan-section">
							{{currentPlan}}
						</span></h5>
					</div>
					<div class="btn-wrapper">
						<a href="account-setting.html" class="edit-btn" routerLink="/profile-edit"><img src="assets/img/edit.svg"> Edit</a>
					</div>
				</div>

				<div class="category-container">
					<ul class="">
						<li *ngFor="let category of planBought">
							<div *ngIf="category.category_type == '2d';" class="vector-box" routerLink="/illustration" [queryParams]="{category: category.category_id}" (click)="selectCategory(category)">
								<div>
									<div>
										<img src={{category.category_icon}} alt="" style="height: 100px;">
										<p>{{category.category_name}}</p>
									</div>
								</div>
							</div>

							<div *ngIf="category.category_type == '3d';" class="vector-box" routerLink="/3d-illustrations" [queryParams]="{category: category.category_id}" (click)="selectCategory(category)">
								<div>
									<div>
										<img src={{category.category_icon}} alt="" style="height: 100px;">
										<p>{{category.category_name}}</p>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>

<!-- Download Count Section -->
		<div class="download-container">
			<h6>Download count</h6>
		<div class="download-img-profile">
			<div class="downloads">
				<div class="svg-download">
					<h6 style="font-size:15px;">SVG</h6>
						<h2 style="margin-top:20px;">{{totalSvg}}</h2>
						<!-- <p style="font-size:15px; color: #B2B2B2;">out of 30 monthly quota</p> -->
				</div>
				<div class="divide"></div>
				<div class="png-download">
					<h6 style="font-size:15px;">PNG</h6>
					<h2 style="margin-top:20px;">{{totalPng}}</h2>
					<!-- <p style="font-size:15px; color: #B2B2B2;">out of 50 monthly quota</p> -->
				</div>
			</div>
		</div>
		<p style="font-size: 14px; color: #333333; margin-top: 6px;"> For doubt read our <a href="#" style="color:#2C85ED;" [routerLink]="['/privacy-terms', {active: 'terms'}]">Terms & Conditions</a> or email us at <a href = "mailto: info@crafttor.com"><b>info@crafttor.com</b></a></p>
		</div>
		</div>
	</div>
</section>
