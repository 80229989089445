import { Injectable } from '@angular/core';
import { EventEmitter } from 'events';
import { BehaviorSubject,  } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private dataTransferSubject:BehaviorSubject<string> = new BehaviorSubject<string>('')
  public dataTransferObservable = this.dataTransferSubject.asObservable();

  push(str: string) {
    this.dataTransferSubject.next(str);
  }
}
