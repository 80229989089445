import { Component, OnInit, ViewChild } from '@angular/core';
import { ThreeDService } from './services/three-d.service';
import { SharedService } from '../shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { AlertService } from '../components/alert/alert.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'angularx-social-login';
import { AuthenticationService } from '../authentication.service';
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { Title, Meta } from '@angular/platform-browser';
import { PaginationService } from "../pagination/pagination.service";

@Component({
  selector: 'app-three-dimension',
  templateUrl: './three-dimension.component.html',
  styleUrls: ['./three-dimension.component.css']
})
export class ThreeDimensionComponent implements OnInit {

  title = 'Free Open Source 3D Illustrations, Grahpics';
  limit: number = 20;
  offset: number = 0;
  next: string;
  constructor(
    private illusService: ThreeDService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private alert: AlertService,
    private modalService: NgbModal,
    public router: Router,
    private authService: AuthService,
    private authenticationService: AuthenticationService,
    private activateRoute: ActivatedRoute,
    private titleService: Title,
    private metaTagService: Meta,
    private paginationService: PaginationService
  ) {
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }
  loginModal: any;
  returnUrl: any;
  illustrations = [];
  searchText: string = '';
  submitted: boolean;
  categories: [];
  categoriesCheckbox: [];
  selectedCategories: string;
  checkedIDs = [];
  headerCategory: string;
  categorySelected: boolean = false;
  paginationLoading: boolean = false;
  searchTextEntered: string;
  showLimit: boolean = true;
  currentIllus: any;
  currentUser: any;
  illusSimilar: [];
  @ViewChild('limitModal') limitModal;
  @ViewChild('downloadModal') downloadModal;
  @ViewChild('premiumModal') premiumModal;
  @ViewChild('loginModal') loginModalAttr;

  public lat;
  public lng;

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'description', content: 'Download high quality clean pixel free svg illustrations includes couple, love, corona, error, space, login & many more illustrations for your websites, apps and products.'},
      { name: 'keywords', content: 'Couple Illustrations, Love Illustrations, Error Illustrations, beautiful Illustrations, Login Illustrations, Corona Illustrations, Coronavirus Illustrations, Doctor Illustrations, high quality svg illustrations' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Crafttor' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);

    this.route.queryParams.subscribe(params => {
      this.searchTextEntered = '';
      this.illusSimilar = [];
      this.headerCategory = params['category'];
      this.searchText = params['search'];
      this.searchIllus(this.searchText, this.headerCategory);
      this.makeCategoriesChecklist(this.headerCategory);
      if (this.headerCategory) {
        this.categorySelected = true;
      }
      this.currentUser = this.authenticationService.currentUserValue;
    });
    this.authService.authState.subscribe((user) => {
      if (user && !this.authenticationService.currentUserValue) {
        this.loginToServer(user, user.photoUrl);
      }
    });

    this.activateRoute.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'];
    });

    this.getLocation();
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: Position) => {
        if (position) {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
        }
      },
        (error: PositionError) => console.log(error));
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }


  open(content: any) {
    this.loginModal = this.modalService.open(content, { centered: true, windowClass: 'login-modal' })
  }

  openClose() {
    this.loginModal.close();
  }


  loginToServer(user: any = null, photoUrl: string) {
    this.authenticationService.login(user.provider.toLowerCase(),
    user.id, user.authToken, user.email, user.firstName,
    user.lastName, photoUrl, this.lat, this.lng)
      .subscribe(
        (_data) => {
          if (this.returnUrl) {
            this.router.navigate([this.returnUrl]);
          }
          this.loginModal.close();

        }
      );
  }
  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    // this.loginToServer();
  }



  fbSignOut(): void {
    this.authService.signOut();
  }

  logOut(): void {
    this.authenticationService.logout();
    this.authService.signOut();
  }

  private makeCategoriesChecklist(headerCategory: string) {
    this.sharedService.preLoaddedCategoriesData$.subscribe((data) => {
      if (data) {

        this.categories = data['results'];
        this.categoriesCheckbox = data['results'].map(x => {

          if (headerCategory) {
            if (headerCategory == x.id) {
              this.selectedCategories = x.id;
              return {
                id: x.id,
                isChecked: true,
                label: x.name
              }
            }
            else {
              return {
                id: x.id,
                isChecked: false,
                label: x.name
              }
            }


          } else {
            return {
              id: x.id,
              isChecked: true,
              label: x.name
            }
          }

        }
        );
      }
    });
  }

  openDownloadModal(modal) {
    this.downloadModal = this.modalService.open(modal, { centered: true, windowClass: 'download-assests ' });
  }

  openPremiumModal(modal) {
    this.modalService.open(modal, { centered: true, windowClass: 'premium-pop-dialog ' });
  }

  openLimitModal(modal) {
    this.modalService.open(modal, { centered: true, windowClass: 'premium-pop ' });
  }

  closeLimitModal() {
    this.limitModal.close();
  }

  closePremiumModal() {
    this.premiumModal.close();
  }

  closeDownloadModal() {
    this.downloadModal.close();
  }

  categoryChanged() {
    this.checkedIDs = [];
    this.checkedIDs = this.categoriesCheckbox.filter((value) => {
      return value['isChecked'];
    });
    this.selectedCategories = '';
    this.selectedCategories = this.selectedCategories + this.checkedIDs.map(category =>
      category.id
    );
    if (this.selectedCategories) {
      this.searchIllus(this.searchText, this.selectedCategories);
    }

  }

  onSubmit() {
    this.searchIllus(this.searchText, this.selectedCategories);
  }

  private searchCompleted(searchText: string, data: any, appendData: boolean = false) {
    if (appendData) {

      this.illustrations.push.apply(this.illustrations, data['results'])
    }
    else {
      this.illustrations = data['results'];

    }

    this.next = data['next'];
    this.illusSimilar = [];
    if (searchText) {

      this.searchTextEntered = searchText;
    }
    if (this.illustrations.length <= 10) {
      this.illusService.similarIllus().subscribe((data: any) => {
        this.illusSimilar = data;
      });
    }
  }

  searchIllus(searchText: string = "", categories: string = "", limit: number = this.limit): void {
    this.illusService.searchIllus(searchText, categories, limit).subscribe((data: any) => {
      this.searchCompleted(searchText, data);
    });

  }

  onScrollDown() {
    this.paginationLoading = true;
    this.paginationService.getFromUrl(this.next).subscribe(data => {
      this.searchCompleted(this.searchText, data, true);
      this.paginationLoading = false;
    }, (error: any) => {
      this.paginationLoading = false;
    });
  }

  goToLink(link: string) {
    if (this.limitModal) {
      this.limitModal.close()
    }
  }

  downloadPngImage(): void {
    this.illusService.downloadPngSmall(this.currentIllus.id).subscribe((data: any) => {
      saveAs(data, this.currentIllus.title+'.png')
    },
      (error: any) => {
        if (error.status === 403) {
          this.openLimitModal(this.limitModal);
        }
        else {
          this.downloadModal.close();
          this.loginModal = this.modalService.open(this.loginModalAttr, { centered: true, windowClass: 'dark-modal exceed-limit login-modal' })

        }
      }
    );
  }

  downloadPngImageBig(): void {
    this.illusService.downloadPngBig(this.currentIllus.id).subscribe((data: any) => {
      saveAs(data, this.currentIllus.title+'.png')
    },
      (error: any) => {
        if (error.status === 403 || error.status === 400) {
          this.openLimitModal(this.premiumModal);
        }
        else {
          this.downloadModal.close();
          this.loginModal = this.modalService.open(this.loginModalAttr, { centered: true, windowClass: 'dark-modal exceed-limit login-modal' })
        }
      }
    );
  }
}
