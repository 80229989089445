import { Component, OnInit, ViewChild } from '@angular/core';
import { SharedService } from "../shared.service";
import { HomeService } from "../home/home.service";
import { MemberService } from '../member/member.service';
import { LocationService } from '../location/location.service';
import { NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import {GoogleAnalyticsService} from '../google-analytics.service';
import { IllusService } from './services/illus.service';
import { ThreeDService } from '../three-dimension/services/three-d.service';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { AlertService } from '../components/alert/alert.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'angularx-social-login';
import { AuthenticationService } from '../authentication.service';
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { Title, Meta } from '@angular/platform-browser';
import { PaginationService } from "../pagination/pagination.service";
import { HeaderService } from '../components/header/header.service';


@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.css']
})

export class MarketingComponent implements OnInit {

  title = 'Trendy & High Quality 2D/3D Illustrations, Graphics | Crafttor';

  limit: number = 20;
  offset: number = 0;
  next: string;

  slideActivate(ngbSlideEvent: NgbSlideEvent) {

   }

    constructor(
      private sharedService: SharedService,
      private homeService: HomeService,
      private memberService: MemberService,
      private locationService: LocationService,
      private titleService: Title,
      private metaTagService: Meta,
      private googleAnalyticsService: GoogleAnalyticsService,
      private illusService: IllusService,
      private threeDService: ThreeDService,
      private route: ActivatedRoute,
      private alert: AlertService,
      private modalService: NgbModal,
      public router: Router,
      private authService: AuthService,
      private authenticationService: AuthenticationService,
      private activateRoute: ActivatedRoute,
      private paginationService: PaginationService,
      private headerService: HeaderService,
    ) {}

    categories = [];
    illus = [];
    memberships = [];
    isIndia:boolean = false;

    data2dValue: any;
    twoDimension: boolean;
    public radioYearly: boolean = true;
    public radioMontly: boolean = false;
    loginModal: any;
    returnUrl: any;
    illustrations = [];
    searchText: string = '';
    searchText3D: string = '';
    submitted: boolean;
    show3D: boolean = false;
    categoriesData: [];
    categoriesCheckbox: [];
    selectedCategories: string;
    checkedIDs = [];
    headerCategory: string;
    categorySelected: boolean = false;
    paginationLoading: boolean = false;
    searchTextEntered: string;
    showLimit: boolean = true;
    currentIllus: any;
    currentUser: any;
    illusSimilar: [];
    mobileView: boolean=false;
    userPic: any;

    @ViewChild('limitModal') limitModal;
    @ViewChild('downloadModal') downloadModal;
    @ViewChild('downloadModalNew') downloadModalNew;
    @ViewChild('loginModal') loginModalAttr;
    @ViewChild('premiumModal') premiumModal;

    public lat;
    public lng;

    ngOnInit(): void {
      // this.getMenuResponse();
      this.threeDimensionFunc();
      this.selectHeader();
      this.titleService.setTitle(this.title);
      this.metaTagService.addTags([
        { name: 'description', content: 'Unlimited royalty free trendy, high quality illustrations artworks, graphics, character and images for commercial and non commercial websites.'},
        { name: 'keywords', content: 'free illustrations download, free illustrations online, royality free illustrations, free illustrations, freebie, illustration, illustrations, illustrations for free, Graphics, vector illustration' },
        { name: 'robots', content: 'index, follow' },
        { name: 'author', content: 'Crafttor' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { charset: 'UTF-8' }
      ]);

      this.authService.authState.subscribe((user) => {
        if (user && !this.authenticationService.currentUserValue) {
          this.loginToServer(user, user.photoUrl);
           // this.router.navigate(['checkout']);
        }
      });

      this.authenticationService.currentUser.subscribe(x => { this.currentUser = x });
      if (window.screen.width <= 991) { // 768px portrait
        this.mobileView = true;
      }else{
        this.mobileView = false;

      }
      if(this.currentUser) {
        this.getUserPic();
      }
      this.sharedService.preLoaddedCategoriesData$.subscribe((data) => {
        this.categories = [];
        if (data) {
          this.categories = data['results'];
        }
      });
      this.getIllus();
      this.getMemberships();

      let country = this.locationService.getCountry();
      if(!country){
        this.locationService.getPosition().then(pos=>
        {
          // this.locationService.setLocation(pos);
          this.locationService.getGeoLocationGoogle(pos.lat, pos.lng).subscribe(data=>{
            this.locationService.setLocation(data);
            country = this.locationService.getCountry();
            if(country == 'India'){
              this.isIndia = true;
            }
          })
        }).catch(err => {
          this.isIndia = false;
        })
      }
      else{
        if(country == 'India'){
          this.isIndia = true;
        }
      }

      this.getLocation();
    }

    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position: Position) => {
          if (position) {
            this.lat = position.coords.latitude;
            this.lng = position.coords.longitude;
          }
        },
          (error: PositionError) => console.log(error));
      } else {
        alert("Geolocation is not supported by this browser.");
      }
    }

    openLoginModal(){
      this.headerService.setLoginModalValue(true);
    }

    homeSearchIllus(){
      this.googleAnalyticsService.eventEmitter("Home-Search-Illustrations", "Premium", "button", "click", 10);
    }

    homeMemberShip(){
      this.googleAnalyticsService.eventEmitter("Home-Membership", "Premium", "button", "click", 10);
    }

    public sendPrice(price: string, name: string, image: string, order: string){
      localStorage.setItem('priceDetails', JSON.stringify({ name: name, price: price, image: image, order: order }));
      window.scrollTo(0, 0);
      if (this.currentUser) {
         this.router.navigate(['checkout']);
        // console.log(membership)
      } else {
        this.headerService.setLoginModalValue(true);
      }
      this.googleAnalyticsService.eventEmitter(name+"-marketing-page", "Premium", "button", "click", parseInt(price));
    }

    public selectValue(category: any) {
       localStorage.setItem('categoryDetails', JSON.stringify({ name: category.name,
         image: category.image, description: category.description }));
    }

    public selectHeader() {
       localStorage.setItem('headerDetails', JSON.stringify({ headerValue: true }));
    }

    private getMemberships() {
      this.memberService.getWebSettings('membership').subscribe(
        (data) => {
          this.memberships = data;
        }
      );
    }
    private getIllus() {
      this.homeService.getIllus(4).subscribe((data: any) => {
        this.illus = data['results'];
      })
    }

    getMenuResponse(){
      var details = JSON.parse(localStorage.getItem('2dDetails'));
      this.data2dValue = details.nameValue
      if(details.nameValue == '3d'){
        this.threeDimensionFunc()
      }else if(details.nameValue == '2d'){
        this.route.queryParams.subscribe(params => {
          this.searchTextEntered = '';
          this.illusSimilar = [];
          this.headerCategory = params['category'];
          this.searchText = params['search'];
          this.searchIllus(this.searchText, this.headerCategory);
          this.makeCategoriesChecklist(this.headerCategory);
          if (this.headerCategory) {
            this.categorySelected = true;
          }
          this.currentUser = this.authenticationService.currentUserValue;
        });

        this.authService.authState.subscribe((user) => {
          if (user && !this.authenticationService.currentUserValue) {
            this.loginToServer(user, user.photoUrl);
          }
        });

        this.activateRoute.queryParams.subscribe(params => {
          this.returnUrl = params['returnUrl'];
        });
        this.twoDimensionFunc()
      }
    }

    open(content: any) {
      this.loginModal = this.modalService.open(content, { centered: true, windowClass: 'login-modal' })
    }

    openClose() {
      this.loginModal.close();
    }


    loginToServer(user: any = null, photoUrl: string) {
      this.authenticationService.login(user.provider.toLowerCase(), user.id,
      user.authToken, user.email, user.firstName, user.lastName,
      photoUrl, this.lat, this.lng)
        .subscribe(
          (_data) => {
            if (this.returnUrl) {
              this.router.navigate([this.returnUrl]);
            }
            this.loginModal.close();

          }
        );
    }
    signInWithGoogle(): void {
      this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    }

    signInWithFB(): void {
      this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
      // this.loginToServer();
    }

    openPremiumModal(modal) {
      this.modalService.open(modal, { centered: true, windowClass: 'premium-pop-dialog ' });
    }

    closePremiumModal() {
      this.premiumModal.close();
    }

    fbSignOut(): void {
      this.authService.signOut();
    }

    logOut(): void {
      this.authenticationService.logout();
      this.authService.signOut();
    }

    // private this.route.queryParams.subscribe(params => {
    //       this.selectedCategories = params['category'];
    //   });

    private makeCategoriesChecklist(headerCategory: string) {
      this.sharedService.preLoaddedCategoriesData$.subscribe((data) => {
        if (data) {

          this.categoriesData = data['results'];
          // console.log("Data Category "+data['results'].type)
          this.categoriesCheckbox = data['results'].map(x => {

            if (headerCategory) {
              if (headerCategory == x.id) {
                this.selectedCategories = x.id;
                return {
                  id: x.id,
                  isChecked: true,
                  label: x.name,
                  type: x.type
                }
              }
              else {
                return {
                  id: x.id,
                  isChecked: false,
                  label: x.name,
                  type: x.type
                }
              }


            } else {
              return {
                id: x.id,
                isChecked: true,
                label: x.name,
                type: x.type
              }
            }

          }
          );
        }
      });
    }
    openDownloadModal(modal) {
      this.downloadModal = this.modalService.open(modal, { centered: true, windowClass: 'download-assests ' });
    }

    open3DDownloadModal(modal) {
      this.downloadModal = this.modalService.open(modal, { centered: true, windowClass: 'download-assests ' });
    }

    openLimitModal(modal) {
      this.modalService.open(modal, { centered: true, windowClass: 'premium-pop ' });
    }
    closeLimitModal() {
      this.limitModal.close();
    }
    closeDownloadModal() {
      this.downloadModal.close();
    }

    categoryChanged() {
      this.checkedIDs = [];
      this.checkedIDs = this.categoriesCheckbox.filter((value) => {
        return value['isChecked'];
      });
      this.selectedCategories = '';
      this.selectedCategories = this.selectedCategories + this.checkedIDs.map(category =>
        category.id
      );
      if (this.selectedCategories) {
        this.searchIllus(this.searchText, this.selectedCategories);
      }

    }

    onSubmit() {
      // if (this.searchText) {
      this.searchIllus(this.searchText, this.selectedCategories);

      // }
    }

    onSubmit3D() {
      this.search3DIllus(this.searchText3D, this.selectedCategories);
    }

    private searchCompleted(searchText: string, data: any, appendData: boolean = false) {
      if (appendData) {

        this.illustrations.push.apply(this.illustrations, data['results'])
      }
      else {
        this.illustrations = data['results'];

      }
      // this.illustrations = data['results'];
      this.next = data['next'];
      this.illusSimilar = [];
      if (searchText) {

        this.searchTextEntered = searchText;
      }
      if (this.illustrations.length <= 10) {
        this.illusService.similarIllus().subscribe((data: any) => {
          this.illusSimilar = data;
        });
      }
    }

    private searchCompleted3D(searchText3D: string, data: any, appendData: boolean = false) {
      if (appendData) {

        this.illustrations.push.apply(this.illustrations, data['results'])
      }
      else {
        this.illustrations = data['results'];

      }
      // this.illustrations = data['results'];
      this.next = data['next'];
      this.illusSimilar = [];
      if (searchText3D) {

        this.searchTextEntered = searchText3D;
      }
      // if (this.illustrations.length <= 10) {
      //   this.threeDService.similarIllus().subscribe((data: any) => {
      //     this.illusSimilar = data;
      //   });
      // }
    }

    searchIllus(searchText: string = "", categories: string = "", limit: number = this.limit): void {
      this.illusService.searchIllus(searchText, categories, limit).subscribe((data: any) => {
        this.searchCompleted(searchText, data);
      });

    }

    search3DIllus(searchText3D: string = "", categories: string = "", limit: number = this.limit): void {
      this.threeDService.searchIllus(searchText3D, categories, limit).subscribe((data: any) => {
        this.searchCompleted3D(searchText3D, data);
      });

    }

    onScrollDown() {
      this.paginationLoading = true;
      this.paginationService.getFromUrl(this.next).subscribe(data => {
        this.searchCompleted(this.searchText, data, true);
        this.paginationLoading = false;
      }, (error: any) => {
        this.paginationLoading = false;
      });
    }

    goToLink(link: string) {
      if (this.limitModal) {
        this.limitModal.close()
      }
    }

    downloadPngImage(): void {
      this.illusService.downloadPng(this.currentIllus.id).subscribe((data: any) => {
        saveAs(data, this.currentIllus.title+'.png')
      },
        (error: any) => {
          if (error.status === 403) {
            this.openLimitModal(this.limitModal);

          }
          else {
            // this.alert.alert("Alert", "Limit reached! Please login to download", () => {
            //   console.log('Ok')
            // })
            this.downloadModal.close();
            this.loginModal = this.modalService.open(this.loginModalAttr, { centered: true, windowClass: 'dark-modal exceed-limit login-modal' })

          }
        }
      );

    }
    downloadSvgImage(): void {
      this.illusService.downloadSvg(this.currentIllus.id).subscribe((data: any) => {
        saveAs(data, this.currentIllus.title+'.svg');
      },
        (error: any) => {
          if (error.status === 403) {
            this.openLimitModal(this.limitModal);
          }
          else {
            // this.alert.alert("Alert", "Please login to download", () => {
            //   console.log('Ok')
            // })
            // this.limitModal.close();
            this.downloadModal.close();
            this.loginModal = this.modalService.open(this.loginModalAttr, { centered: true, windowClass: 'dark-modal exceed-limit login-modal' })

          }
        }
      );

    }

    downloadPngImageSmall(): void {
      this.threeDService.downloadPngSmall(this.currentIllus.id).subscribe((data: any) => {
        saveAs(data, this.currentIllus.title+'.png')
      },
        (error: any) => {
          if (error.status === 403) {
            this.openLimitModal(this.limitModal);
          }
          else {
            this.downloadModal.close();
            this.loginModal = this.modalService.open(this.loginModalAttr, { centered: true, windowClass: 'dark-modal exceed-limit login-modal' })

          }
        }
      );
    }

    downloadPngImageBig(): void {
      this.threeDService.downloadPngBig(this.currentIllus.id).subscribe((data: any) => {
        saveAs(data, this.currentIllus.title+'.png')
      },
        (error: any) => {
          if (error.status === 403 || error.status === 400) {
            this.openLimitModal(this.premiumModal);
          }
          else {
            this.downloadModal.close();
            this.loginModal = this.modalService.open(this.loginModalAttr, { centered: true, windowClass: 'dark-modal exceed-limit login-modal' })
          }
        }
      );
    }

    twoDimensionFunc(){
      this.radioYearly = true;
      this.radioMontly = false;
      this.twoDimension = true;
      this.route.queryParams.subscribe(params => {
        this.searchTextEntered = '';
        this.illusSimilar = [];
        this.headerCategory = params['category'];
        this.searchText = params['search'];
        this.searchIllus(this.searchText, this.headerCategory);
        this.makeCategoriesChecklist(this.headerCategory);
        if (this.headerCategory) {
          this.categorySelected = true;
        }
        this.currentUser = this.authenticationService.currentUserValue;
      });

      this.authService.authState.subscribe((user) => {
        if (user && !this.authenticationService.currentUserValue) {
          this.loginToServer(user, user.photoUrl);
        }
      });

      this.activateRoute.queryParams.subscribe(params => {
        this.returnUrl = params['returnUrl'];
      });
    }

    threeDimensionFunc(){
      this.radioYearly = false;
      this.radioMontly = true;
      this.twoDimension = false;
      this.route.queryParams.subscribe(params => {
        this.searchTextEntered = '';
        this.illusSimilar = [];
        this.headerCategory = params['category'];
        this.searchText3D = params['search'];
        this.search3DIllus(this.searchText3D, this.headerCategory);
        this.makeCategoriesChecklist(this.headerCategory);
        if (this.headerCategory) {
          this.categorySelected = true;
        }
        this.currentUser = this.authenticationService.currentUserValue;
      });
      this.authService.authState.subscribe((user) => {
        if (user && !this.authenticationService.currentUserValue) {
          this.loginToServer(user, user.photoUrl);
        }
      });

      this.activateRoute.queryParams.subscribe(params => {
        this.returnUrl = params['returnUrl'];
      });
    }

    googleAnalytics(){
      this.googleAnalyticsService.eventEmitter("Pricing-mkt-header", "Premium", "button", "click", 0);

    }

    getUserPic() {
      this.headerService.getUserPic().subscribe((data: any) => {
        this.userPic = data.user_pic;
      });
    }
}
