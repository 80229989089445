<header class="masthead fixed-top">
  <!-- Navigation -->
  <nav class="navbar navbar-expand-lg" id="mainNav">
    <div class="container">
      <a class="navbar-brand js-scroll-trigger" href="#" routerLink="/"><img src="assets/img/logo.svg" alt="crafttor free illustrations"></a>

      <li class="nav-item login-btn" *ngIf="!currentUser && mobileView">
        <a class="nav-link btn" (click)="openLoginModal()">Login</a>
      </li>
      <li class="nav-item user-id dropdown" *ngIf="currentUser && mobileView">
        <a class="nav-link" href="javascript:;" data-toggle="dropdown">
          <img *ngIf="!userPic" src="assets/img/user-profile.svg">
					<img *ngIf="userPic" src="{{userPic}}">
          {{currentUser.firstName+" "+currentUser.lastName}} </a>
        <ul class="dropdown-menu">
          <li><a class="nav-link" href="javascript:;" routerLink="/profile">Profile</a></li>
          <li><a class="nav-link" href="javascript:;" (click)="logOut()">Logout</a></li>
        </ul>
      </li>
      <!-- <li class="nav-item dropdown search-nav" *ngIf="mobileView">
        <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="javascript:;"><img src="assets/img/search.svg" alt="search free illustrations"></a>
        <div class="dropdown-menu">
          <form class="search" (submit)="goToSearch()">
            <input type="search" [(ngModel)]="searchText" name="searchText" placeholder="Search illustration"><button type="submit" >Explore</button>
          </form>
        </div>
      </li> -->

      <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
      </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ml-auto">

          <!-- <li class="nav-item">
              <a class="nav-link" href="javascript:;" (click)="blogClicked=false;" routerLink="3d-illustrations/">3D</a>
            </li> -->

          <!-- <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" (click)="blogClicked=false;" data-toggle="dropdown" href="#">Illustrations</a>
            <ul class="dropdown-menu">
              <li *ngFor="let category of categories"><a (click)="blogClicked=false;" class="nav-link" href="javascript:;" routerLink="search/{{category.name}}"> {{category.name}} </a></li>
            </ul>
          </li> -->

        <!-- <li class="nav-item">
            <a class="nav-link" href="javascript:;" (click)="blogClicked=false;" routerLink="/icons">Icons</a>
          </li> -->

          <!-- https://static.figma.com/app/icon/1/favicon.svg -->

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#">Plugins</a>
            <ul class="dropdown-menu">
              <li><a class="nav-link" routerLink="/figma">
                <img src="assets/img/figma.svg" alt=""> Figma </a></li>
              <li><a class="nav-link" href="javascript:;" routerLink="/sketch">
                <img src="assets/img/ic-sketch.png" alt=""> Sketch </a></li>
            </ul>
          </li>

          <!-- <li class="nav-item">
            <a class="nav-link" href="javascript:;" (click)="blogClicked=true;" routerLink="blogs">Blog</a>
          </li> -->

          <li class="nav-item">
            <a class="nav-link btn-price" href="javascript:;" (click)="googleAnalytics()" routerLink="/pricing">Pricing</a>
          </li>
          <!-- <li class="nav-item dropdown search-nav" *ngIf="!mobileView">
            <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="javascript:;"><img src="assets/img/search.svg" alt="search free illustrations"></a>
            <div class="dropdown-menu">
              <form class="search" (submit)="goToSearch()">
                <input type="search" [(ngModel)]="searchText" name="searchText" placeholder="Search Illustrations"><button type="submit" >Explore</button>
              </form>
            </div>
          </li> -->
          <li class="nav-item login-btn" *ngIf="!currentUser && !mobileView">
            <a class="nav-link btn" (click)="openLoginModal()">Login</a>
          </li>
          <li class="nav-item user-id dropdown" *ngIf="currentUser  && !mobileView">
            <a class="nav-link" href="javascript:;" data-toggle="dropdown">
              <img *ngIf="!userPic" src="assets/img/user-profile.svg">
    					<img *ngIf="userPic" src="{{userPic}}">
              {{currentUser.firstName+" "+currentUser.lastName}} </a>
            <ul class="dropdown-menu">
              <!-- <li><a class="nav-link" href="javascript:;" routerLink="/profile">Profile</a></li> -->
              <li><a class="nav-link" href="javascript:;" (click)="logOut()">Logout</a></li>
            </ul>
          </li>

        </ul>
      </div>
    </div>
  </nav>
</header>

<section class="hero-section-mkt">
	<div class="container">
		<div class="row">
			<div class="col-sm-6">
				<div class="inner-content">
					<h1>Best 2D & 3D Illustrations for web & app</h1>
					<p>Handpicked illustrations, No unnecessary & crowded illustrations, Custom illustration option.</p>
					<div class="btn-wrapper">
						<a href="javascript:;" routerLink="/pricing" class="button">Get Started</a>
						<a href="#" class="button gray-btn" routerLink="/mkt"><img style="margin-right:10px; float:left;" src="assets/img/figma.svg" alt="">  <img style="margin-right:10px; float:left;" src="assets/img/ic-sketch.png" alt="">
							Plugins
							<div class="dropup-mkt">
			          <p class="dropbtn" style="visibility: hidden">Plugins</p>
			          <div class="dropup-content">
			            <a class="nav-link" routerLink="/figma">
			              <img style="margin-right:10px;" src="assets/img/figma.svg" alt=""> Figma </a>
			              <a class="nav-link" href="javascript:;" routerLink="/sketch">
			                <img style="margin-right:10px;" src="assets/img/ic-sketch.png" alt=""> Sketch </a>
			          </div>
			        </div>
						<img style="margin-right:10px; margin-top: 4px; float:right;" src="assets/img/drop.png" alt=""></a>
					</div>
				</div>
			</div>
			<div class="col-sm-6">
				<div class="img-box">
					<img src="assets/img/hero-img.gif" alt="download gif illustration">
				</div>
			</div>
		</div>
	</div>
  </section>

	<section class="search-section">
		<div class="container">
			<div class="row">
				<div class="col-sm-12">
					<div class="inner-box">
						<!-- <h1>Discover High Quality <br>Illustration Resources</h1> -->
						<!-- <form class="custom-form">
						   <input type="text" placeholder="Type somthing" class="text-field">
						   <input type="Submit" value="Search" class="button">
						</form> -->
						<div class="switcher">
							<input type="radio" name="subscription" value="yearly" id="yearly" [checked]="radioYearly" (click)="twoDimensionFunc(); radioMontly=false; radioYearly=true;">
							<label for="yearly">2D</label>
							<input type="radio" name="subscription" value="monthly" id="monthly" [checked]="radioMontly"  (click)="threeDimensionFunc(); radioYearly=false; radioMontly=true;">
							<label for="monthly">3D</label>
						</div>
						<form *ngIf="twoDimension" class="custom-form" (ngSubmit)="onSubmit()" #heroForm="ngForm">
							<input type="text" placeholder="Search 2D Illustrations" class="text-field" name="searchText" [(ngModel)]="searchText">
							<input type="Submit" value="Search" class="button">
						</form>
						<form *ngIf="!twoDimension" class="custom-form" (ngSubmit)="onSubmit3D()" #heroForm="ngForm">
							<input type="text"  placeholder="Search 3D Illustrations" class="text-field" name="searchText3D" [(ngModel)]="searchText3D">
							<input type="Submit" value="Search" class="button">
						</form>
					</div>
				</div>
			</div>
		</div>
	</section>

	<!-- Global Section -->
	<section class="global-section" *ngIf="twoDimension">
		<div class=" left">
			<div class="row">
				<div class="col-sm-12">
					<div class="top-head">
						<!-- <h2 >{{searchTextEntered}}</h2> -->
						<!-- <h2 *ngIf="categorySelected && !searchTextEntered">Vector Style</h2> -->
						<h5>Vector Styles</h5>
						<ul class="checklist" *ngFor="let category of categoriesCheckbox">
								<div class="" *ngIf="category.type == '3d'; else elseBlock">
									<!-- <li class="custom-control custom-checkbox">
									<input (change)="categoryChanged()" [(ngModel)]="category.isChecked" type="checkbox" class="custom-control-input" [id]="'vector-'+category.id">
									<input (change)="categoryChanged()" [(ngModel)]="category.isChecked" type="checkbox" class="custom-control-input" [id]="'vector-'+category.id">
									<label class="custom-control-label" [for]="'vector-'+category.id"></label>
									</li> -->
								</div>

								<ng-template #elseBlock>
									<div class="" #elseBlock>
										<li class="custom-control custom-checkbox">
										<input (change)="categoryChanged()" [(ngModel)]="category.isChecked" type="checkbox" class="custom-control-input" [id]="'vector-'+category.id">
										<input (change)="categoryChanged()" [(ngModel)]="category.isChecked" type="checkbox" class="custom-control-input" [id]="'vector-'+category.id">
										<label class="custom-control-label" [for]="'vector-'+category.id">{{ category.label }} </label>
										</li>
									</div>
								</ng-template>

						</ul>
					</div>
				</div>
			</div>
		</div>
	</section>

	<!-- Vectors Section -->
	<section class="vectors-section">
		<div class="container">
			<ul class="load-vector" infiniteScroll
			[infiniteScrollDistance]="1"
			[infiniteScrollUpDistance]="2"
			[infiniteScrollThrottle]="50"
			(scrolled)="onScrollDown()">
				<li *ngFor="let illus of illustrations">
					<div class="inner-card" (click)="currentIllus= illus;openDownloadModal(downloadModal)">
						<img *ngIf="twoDimension" [src]="illus.image_resize" alt="high quality 2D illustrations">
						<img *ngIf="!twoDimension" [src]="illus.image_resize" alt="high quality 3D illustrations">
						<div class="hover-style">
							<div class="title">{{ illus.title }}</div>
							<!-- <div class="btn-box">
								<a href="javascript:;" (click)="currentIllus= illus;openDownloadModal(downloadModal)">SVG</a>
								<a href="javascript:;" (click)="currentIllus= illus;openDownloadModal(downloadModal)">PNG</a>
							</div> -->
						</div>
					</div>
				</li>
				<!-- Col End -->
			</ul>
			<div *ngIf="illustrations && illustrations?.length == 0" style="text-align: center;">
				<h3>No results found</h3>
				<br />
				<br />
			</div>
			<h3  *ngIf="illusSimilar?.length > 0" style="font-weight: bold; margin-top: 60px;">Other Illustrations</h3>
			<ul *ngIf="illusSimilar?.length > 0"  class="load-vector" style="margin-top: 0px;">
				<li *ngFor="let illus of illusSimilar">
					<div class="inner-card" (click)="currentIllus= illus;openDownloadModal(downloadModal)">
						<img [src]="illus.image_resize" alt="best svg illustrations">
						<div class="hover-style">
							<div class="title">{{ illus.title }}</div>
						</div>
					</div>
				</li>
				<!-- Col End -->
			</ul>
			<img src="assets/img/loader.gif" *ngIf="paginationLoading" id="loadMorevector" href="javascript:;" style="display: block;margin-left: auto;margin-right: auto; margin-top:40px; width: 70px; height:60px;">
		</div>
	</section>


  <section class="vectorimg-section-mkt">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h2>Royalty Free Illustration Styles</h2>
        <p>High-quality digital resources for a faster and smoother design workflow.</p>
      </div>
    </div>
    <div class="row">
      <ul class="load-list">
        <li *ngFor="let category of categories">
          <!-- <div class="vector-box" routerLink="search/" [queryParams]="{category: category.id}">
            <div class="thumb">
              <img [src]="category.image" alt="fit professional funny illustrations">
            </div>
            <div class="category-title">
              <div class="category-price-section">
                <h5 *ngIf="isIndia">₹{{ category.price_inr }}</h5>
                <h5 *ngIf="!isIndia">${{ category.price_usd }}</h5>
              </div>
              <h3>{{ category.name }}</h3>

            </div>

            <p>{{ category.description }}</p>

            <div class="btn-wrapper-category">
              <a href="javascript:;" routerLink="payment/" class="button">Buy now</a>
            </div>
          </div> -->

          <div *ngIf="category.type == '2d'; else elseBlock" class="vector-box" routerLink="/illustration" [queryParams]="{category: category.id}" (click)="selectValue(category)">
            <div class="thumb">
              <img [src]="category.image" alt="fit professional funny illustrations">
            </div>
            <div class="category-title">
              <!-- <div class="category-price-section">
                <h5 *ngIf="isIndia" id="priceIndia">₹{{ category.price_inr }}</h5>
                <h5 *ngIf="!isIndia" id="priceNotIndia">${{ category.price_usd }}</h5>
              </div> -->
              <h3>{{ category.name }}</h3>
            </div>

            <p>{{ category.description }}</p>

            <!-- <div class="btn-wrapper-category">
              <a href="javascript:;" *ngIf="isIndia" routerLink="payment/" (click)="sendPrice(category.price_inr, category.name, category.image)" class="button">Buy now</a>
              <a href="javascript:;" *ngIf="!isIndia" routerLink="payment/" (click)="sendPrice(category.price_usd, category.name, category.image)" class="button">Buy now</a>
            </div> -->
           </div>

          <ng-template #elseBlock>

            <div #elseBlock class="vector-box" routerLink="/3d-illustrations" (click)="selectValue(category)">
              <div class="thumb">
                <img [src]="category.image" alt="3D professional illustrations">
              </div>
              <div class="category-title">
                <!-- <div class="category-price-section">
                  <h5 *ngIf="isIndia" id="priceIndia">₹{{ category.price_inr }}</h5>
                  <h5 *ngIf="!isIndia" id="priceNotIndia">${{ category.price_usd }}</h5>
                </div> -->
                <h3>{{ category.name }}</h3>
              </div>

              <p>{{ category.description }}</p>

              <!-- <div class="btn-wrapper-category">
                <a href="javascript:;" *ngIf="isIndia" routerLink="payment/" (click)="sendPrice(category.price_inr, category.name, category.image)" class="button">Buy now</a>
                <a href="javascript:;" *ngIf="!isIndia" routerLink="payment/" (click)="sendPrice(category.price_usd, category.name, category.image)" class="button">Buy now</a>
              </div> -->
            </div>
          </ng-template>
        </li>
      </ul>
    </div>
  </div>
</section>

<section class="carousel-section">
  <div class="container">
    <div class="col-sm-12">
      <h2>Our Customers</h2>
    </div>
    <div class="row" style="margin-top: -20px;">
      <div class="col-sm-12">
        <div class="regular slider">
          <ngb-carousel (slide)="slideActivate($event)">

          <ng-template ngbSlide>

            <div class="picsum-img-wrapper">
          <div class="slide-desc">
            <div class="inner-desc">
              <div class="thumb">
                <img src="assets/img/pnew.jpeg" alt="nickelfox likes our beautiful illustrations">
                <!-- <img src="https://s3.ap-south-1.amazonaws.com/crafttor.com/assets/img/pnew.jpeg" alt=""> -->
              </div>
              <div class="content">
                <h3>Falling In love with these illustrations.</h3>
                <p>We at Nickelfox found ourself spoiled by the amazing illustrations by Crafttor. What an amazing resource. If you're ever looking
                  for beautiful SVG illustrations for your app or website Crafttor is your go-to place. Saves a lot of time.</p>
                <div class="name">Prashish</div>
                <div class="designation"><span>Founder:</span> Nickelfox</div>
              </div>
            </div>
          </div>
        </div>

      </ng-template>
      <ng-template ngbSlide>

        <div class="picsum-img-wrapper">
          <div class="slide-desc">
            <div class="inner-desc">
              <div class="thumb">
                <img src="assets/img/snew.jpeg" alt="anthillcreations use svg illustrations">
                <!-- <img src="https://s3.ap-south-1.amazonaws.com/crafttor.com/assets/img/snew.jpeg" alt=""> -->
              </div>
              <div class="content">
                <h3>Best Illustrations I Ever have.</h3>
                <p>We have been using Crafttor since the last few months and it has fulfilled all our core graphic design requirements. Not only do they have variety, the graphics are easily editable and reconfigurable.
                  The designers in my team are delivering high quality outcomes in half the time.</p>
                <div class="name">Souradeep Paul</div>
                <div class="designation"><span>Founder:</span> anthillcreations.org</div>
              </div>
            </div>
          </div>
        </div>

      </ng-template>
      <ng-template ngbSlide>

        <div class="picsum-img-wrapper">
          <div class="slide-desc">
            <div class="inner-desc">
              <div class="thumb">
                <img src="assets/img/prnew.jpeg" alt="ketoindia use vector graphics">
                <!-- <img src="https://s3.ap-south-1.amazonaws.com/crafttor.com/assets/img/prnew.jpeg" alt=""> -->
              </div>
              <div class="content">
                <h3>High quality illustrations for every business.</h3>
                <p>Crafttor and the team helped us in the early days at Keto India, in the website design and illustrations. The team was able to help us with high quality illustrations
                  that helped us, not just build a beautiful, engaging website - but a highly converting one!</p>
                <div class="name">Sahil Pruthi</div>
                <div class="designation"><span>Founder:</span> Livofy</div>
              </div>
            </div>
          </div>
        </div>

      </ng-template>
      <ng-template ngbSlide>

        <div class="picsum-img-wrapper">
          <div class="slide-desc">
            <div class="inner-desc">
              <div class="thumb">
                <img src="assets/img/sanew.jpeg" alt="innerfit likes our impressive illustrations">
                <!-- <img src="https://s3.ap-south-1.amazonaws.com/crafttor.com/assets/img/sanew.jpeg" alt=""> -->
              </div>
              <div class="content">
                <h3>Extremely Impressive Illustrations.</h3>
                <p>Crafttor is the best place for the illustrations for applications & websites. Crafttor's illustrations helped us in designing our platform more interesting & engaging
                for our business levels. I highly recommend its illustrations to everyone.</p>
                <div class="name">Sanchit Garg</div>
                <div class="designation"><span>Founder:</span> innerfit.me</div>
              </div>
            </div>
          </div>
        </div>

      </ng-template>
    </ngb-carousel>
          </div>
      </div>
    </div>
  </div>
</section>

<!-- Lets Make Section -->
<section class="letsmake-section-mkt">
<div class="container">
  <div class="row">
    <div class="col-sm-12">
      <div class="letsmake-box">
        <h2>Let’s make world a better place </h2>
        <p>You're free to use these illustrations for both personal and commercial projects.</p>
        <div class="price-card">

          <div class="inner-card" *ngFor="let membership of memberships | slice:1:3">
            <div class="card-box">
              <img src="assets/img/{{membership.image}}" alt="free and membership plans">
              <h3>{{membership.text}}</h3>
              <div class="price-area">
                <span class="price-home" *ngIf="isIndia">{{membership.amount_text.INR.now}}</span>
                <span class="price-home" *ngIf="!isIndia">{{membership.amount_text.USD.now}}</span>
                <span class="main-price" *ngIf="membership.text == 'Free'">Free Access</span>
              <span class="main-price" *ngIf="membership.text != 'Free' && isIndia">Earlier <span><s>{{membership.amount_text.INR.earlier}}</s></span></span>
              <span class="main-price" *ngIf="membership.text != 'Free' && !isIndia">Earlier <span><s>{{membership.amount_text.USD.earlier}}</s></span></span>
              </div>
              <ul>
                <li *ngFor="let li of membership.li">{{li}}</li>
              </ul>
              <a href="javascript:;" class="button gray-btn" *ngIf="membership.text == 'Free'" routerLink="/search">Download</a>
              <a href="javascript:;" class="button" (click)="sendPrice(membership.amount.INR, membership.text,membership.image, membership.order)" *ngIf="isIndia">Buy Now</a>
							<a href="javascript:;" class="button" (click)="sendPrice(membership.amount.USD, membership.text, membership.image, membership.order)"  *ngIf="!isIndia">Buy Now</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</section>

<ng-template #downloadModal let-downloadModal>

	<div class="download-assests">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<button type="button" class="close" (click)="downloadModal.close()">
					<img src="assets/img/cross.svg" alt="download free illustrations">
				</button>
				<div class="modal-body">
					<div class="thumb">
						<img *ngIf="twoDimension" [src]="currentIllus.image_resize" alt="download svg illustrations">
						<img *ngIf="!twoDimension" [src]="currentIllus.image_resize" alt="download svg illustrations">
					</div>
					<div class="content-right">
						<h3>{{currentIllus.title || ''}}</h3>
						<div *ngIf="twoDimension" class="style-txt"><strong>Style: </strong>{{currentIllus.category || ''}}</div>
						<div *ngIf="!twoDimension" class="style-txt"></div>
						<div class="options">
							<div class="custom-checkbox">
								<!-- <a *ngIf="twoDimension" href="javascript:;" class="button" (click)="downloadPngImage()">Download PNG</a>
								<a *ngIf="!twoDimension" href="javascript:;" class="button" (click)="downloadPngImageSmall()">Small PNG</a> -->
							</div>
							<div class="custom-checkbox">
								<!-- <a *ngIf="twoDimension" href="javascript:;" class="button" (click)="downloadSvgImage()">Download SVG</a>
								<a *ngIf="!twoDimension" href="javascript:;" class="button" (click)="downloadPngImageBig()">Large PNG</a> -->
							</div>
						</div>
						<div class="bottom-txt">
							<!--<a href="javascript:;" class="button" (click)="downloadPngImage()">Download PNG</a>
							<a href="javascript:;" class="button" (click)="downloadSvgImage()">Download SVG</a>-->
							<!-- <span>Attribution is required.</span> -->
							<strong>Crafttor License</strong>
							<p>Free for personal and commercial purpose with attribution. <a href="javascript:;" (click)="downloadModal.close(); router.navigate(['/license'])" class="link">More info</a></p>
							<strong>For Exclusive Content & Unlimited Downloads</strong>
							<div class="btn-wrapper"><a class="button yellow-btn" data-dismiss="modal" (click)="downloadModal.close(); router.navigate(['/pricing'])" href="javascript:;">Premium Membership</a></div>
							<span>No attribution required.</span>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</ng-template>

<!-- Modal -->
<ng-template #limitModal let-limitModal>
	<!-- <div class="modal fade premium-pop" > -->
	<div class="modal-dialog modal-dialog-centered  premium-pop" role="document">
		<div class="modal-content">
			<button type="button" class="close" (click)="limitModal.close()">
				<img src="assets/img/cross.svg" alt="download funny illustrations">
			</button>
			<div class="modal-body">
				<h3>Sorry!</h3>
				<h4>You exceed your daily limit</h4>
				<!-- <div class="qty">3 SVG in 24 hrs</div> -->
				<!-- <small>You can download <strong>3 SVG</strong> in <strong>24hrs</strong> & <strong>10 SVG</strong> in a <strong>month</strong></small> -->
				<a href="javascript:;" (click)="limitModal.close(); closeDownloadModal();" [routerLink]="['/license', {active: 'readrules'}]" class="simple-link">Read the Rules</a>
				<p>For Exclusive Content & Unlimited Downloads</p>
				<div class="btn-wrapper"><a class="button yellow-btn" (click)="limitModal.close(); closeDownloadModal(); router.navigate(['/member'])" href="javascript:;">Premium Membership</a></div>
				<span>No attribution required.</span>
			</div>

		</div>
	</div>
	<!-- </div> -->
</ng-template>

<ng-template #premiumModal let-premiumModal>
	<!-- <div class="modal fade premium-pop" > -->
	<div class="modal-dialog modal-dialog-centered  premium-pop-dialog" role="document">
		<div class="modal-content">
			<button type="button" class="close" (click)="premiumModal.close()">
				<img src="assets/img/cross.svg" alt="download funny illustrations">
			</button>
			<div class="modal-body">
				<h3>Unlock Large 3D</h3>
				<h4>& get access of all the products.</h4>
				<!-- <div class="qty">3 SVG in 24 hrs</div> -->
				<!-- <small>You can download <strong>3 SVG</strong> in <strong>24hrs</strong> & <strong>10 SVG</strong> in a <strong>month</strong></small> -->
				<!-- <a href="javascript:;" (click)="premiumModal.close(); closeDownloadModal(); " [routerLink]="['/license', {active: 'readrules'}]" class="simple-link">Read the Rules</a> -->
				<p>For Exclusive Content & Unlimited Downloads</p>
				<div class="btn-wrapper"><a class="button yellow-btn" (click)="premiumModal.close(); closeDownloadModal(); router.navigate(['/member'])" href="javascript:;">Premium Membership</a></div>
				<span>No attribution required.</span>
			</div>

		</div>
	</div>
	<!-- </div> -->
</ng-template>

<ng-template #loginModal let-modal>
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<button type="button" class="close" (click)="openClose()" data-dismiss="modal" aria-label="Close">
				<img src="assets/img/cross.svg" alt="download free illustrations">
			</button>
			<div class="modal-body">
				<h3 style="margin-bottom:0px;">Login to continue</h3>
				<p style="margin-bottom:30px; margin-top:8px;">Please login to download more illustrations</p>
				<div class="button-wrapper">
					<a href="javascript:;" class="button google-btn" (click)="signInWithGoogle()"><i class="fa fa-google-plus"></i> Continue with Google</a>
					<a href="javascript:;" class="button fb-btn" (click)="signInWithFB()"><i class="fa fa-facebook-f"></i>Continue with Facebook</a>
					<!-- <a href="javascript:;" class="button twitter-btn"><i class="fa fa-twitter"></i> Continue with Twitter</a> -->
					<p>By Login, I agree the <a href="javascript:;" (click)="openClose()" [routerLink]="['privacy-terms', {active: 'terms'}]">Terms of Use</a> & <a href="javascript:;" (click)="openClose()"
							[routerLink]="['privacy-terms', {active: 'privacy'}]">Privacy Policy</a>.</p>
				</div>
			</div>

		</div>
	</div>
</ng-template>
