<section class="cart_sec" *ngIf="mainSection">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-wrapper">
					<div class="user-info">
						<div class="member-name"></div>
						<h1>Checkout</h1>
					</div>
				</div>
			</div>
		</div>

		<div class="row table_row">
			<div class="col-sm-7">
				<div class="payments_details_sec">
					<div class="payments_details"></div>
					<table cellspacing="0">
						<thead>
							<tr>
								<th class="product">Name</th>
								<th class="price"></th>
								<th class="subtotal">Price</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<img src="assets/img/{{productImage}}" alt="" class="item-img">
								<td class="individual product-name" data-title="Product">{{productName}}</td>
								<td class="price" data-title="price" *ngIf="isIndia">₹{{productPrice}}</td>
								<td class="price" data-title="price" *ngIf="!isIndia">${{productPrice}}</td>
								<a href="#" routerLink="/mkt"><img src="assets/img/cross.svg" alt=""></a>
							</tr>
						</tbody>
					</table>
					<hr>
					<table cellspacing="0">
					<tfoot>
						<tr class="order-total">
							<th>Total</th>
							<th><strong></strong></th>
							<td class="subtotal_price" *ngIf="isIndia">₹{{productPrice}}/-</td>
							<td class="subtotal_price" *ngIf="!isIndia">${{productPrice}}/-</td>
						</tr>
					</tfoot>
					</table>
				</div>
			</div>
			<div class="col-sm-5 payment-section">
				<table class="shop_table" cellspacing="0">
					<tbody>
						<p>Total savings: <b>30%</b></p>
						<tr class="order-total">
							<th>Total</th>
							<td data-title="Subtotal">
								<span class="woocommerce-Price-amount amount" *ngIf="isIndia">₹{{productPrice}}/-</span>
								<span class="woocommerce-Price-amount amount" *ngIf="!isIndia">${{productPrice}}/-</span>
							</td>
						</tr>
					</tbody>
				</table>
				<a href="javascript:;" class="button payment-btn" *ngIf="isIndia" (click)="checkoutStripe(productPrice, 'inr', productName)">Continue Payment</a>
				<a href="javascript:;" class="button payment-btn" *ngIf="!isIndia" (click)="checkoutStripe(productPrice, 'usd', productName)">Continue Payment</a>
			</div>
			<div class="col-sm-12 notes-section">
				<div class="col-sm-5">
						<small><b>Included in your purchase:</b></small><br><br>
						<small><i>All illustrations & icons.</i></small><br>
						<small><i>All updates & features.</i></small><br><br>
						<small><i><b>*Note:</b> All taxes are included in the total amount.</i></small>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="cart_sec" [hidden]="!payNow">
	<div class="container" style="max-width: 500px;">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-wrapper" style="max-width: 500px; padding-bottom: 60px;">
					<a href="javascript:" class="back-link" (click)="mainSection=true;payNow=false;"><img src="assets/img/back-arrow.svg"> Back</a>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- {%popup content} -->
<ng-template #paymentSuccess>
	<!-- <div class="modal fade product_view" id="product_view_second"> -->
	<div class="modal-dialog modal-dialog-centered" role="document" style="margin-top:auto; margin-bottom: auto;">
		<!-- <div class="modal-dialog"> -->
		<div class="modal-content">
			<div class="modal-header" style="display: block; border-bottom: none;">
				<a (click)="closeSuccessModal()" data-dismiss="modal" class="class pull-right">
					<img  style="display: block; float: right" src="assets/img/cross.svg">
				</a>
			</div>
			<div class="modal-body">
				<div class="row">
					<div class="container">
						<div class="col-md-12 product_img">
							<div class="popup_wrapper">
								<img src="assets/img/gift.png" style="width: 200px; height: 180px; display: block; margin-left: auto; margin-right: auto;width: 50%;">
								<div class="title">
									<h3 style="text-align:center; margin-top:30px;">Your Payment is successfull</h3>
								</div>
								<p style="text-align:center;">Happy Downloading!!</p>
								<a href="javascript:;" class="button" style="width: 100%" (click)="closeSuccessModal(); router.navigate(['/search'])">Search Illustrations</a>
								<p style="text-align:center;"><small>For any further assistance email us: <a href="mailto:info@crafttor.com"><b>info@crafttor.com</b></a></small></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- </div> -->
</ng-template>
