<section class="community-section community-background-design">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
				<h1>Crafttor is now available on <span class="freelaner-txt">Sketch</span></h1>
				<p>Boost design efficiency and team collaboration with Crafttor for Sketch.</p>
				<div class="btn-wrapper">
					<a href="{{sketchData.download}}" download class="button gray-btn" (click)="saveCountDownload()">
						<img style="width:30px; height:25px; margin-right: 10px; " class="btnimg" src="assets/img/sketch.svg" alt="" />
						Download Sketch ( {{sketchData.version}} )</a>
				</div>
      </div>
    </div>
  </div>
</section>

<section class="figma-hero-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<img class="sketch-main-img" src="assets/img/sketch.svg" alt="">

        <h2>Sketch Plugin</h2>
        <p>Enhance your Sketch experience with our powerful plugin, designed to streamline your workflow and boost productivity.</p>
			</div>
		</div>
	</div>
</section>

<section class="figma-hero-section" style="background: #ffffff;">
	<div class="container">
    <div class="row before-after-section">
      <div class="col-sm-1">

      </div>
      <div class="col-sm-5 white-back-temp">
        <h5>Before Sketch</h5>
        <ul>
          <li class="red-before">
            <img src="assets/img/cross-red.svg" alt="">
            Manual Asset Management.
          </li>

          <li class="red-before">
            <img src="assets/img/cross-red.svg" alt="">
            Limited Collaboration.
          </li>

          <li class="red-before">
            <img src="assets/img/cross-red.svg" alt="">
            Inefficient Workflow.
          </li>
        </ul>
      </div>

      <div class="col-sm-5 white-back-temp">
        <h5>After Sketch</h5>
        <ul>
          <li class="green-after">
            <img src="assets/img/tick-green.svg" alt="">
            Centralised Asset Management.
          </li>

          <li class="green-after">
            <img src="assets/img/tick-green.svg" alt="">
            Enhanced Collaboration.
          </li>

          <li class="green-after">
            <img src="assets/img/tick-green.svg" alt="">
            Streamlined Workflow.
          </li>
        </ul>
      </div>
      <div class="col-sm-1">

      </div>
    </div>
	</div>
</section>

<section class="community-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
				<h3>Elevate Your Sketch Workflow with Crafttor's Smart Tools</h3>

        <div class="figma-list-points">
          <ul>
            <li><img src="assets/img/tick-green.svg" alt=""> Upload from Workspace & access from Sketch plugin.</li>
            <li><img src="assets/img/tick-green.svg" alt=""> Switch between Workspace and Marketplace.</li>
            <li><img src="assets/img/tick-green.svg" alt=""> Select Marketplace illustrations categories.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="community-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
				<h3>Crafttor' Figma Marketplace/Workspace</h3>
				<p>Offer seamless access to creative assets and streamlined cloud-based asset management for efficient design workflows.</p>
      </div>

      <ul>
        <li>
          <div class="plugins-data">
            <h1 class="">{{sketchData.total_download}}</h1>
            <h4>Downloads</h4>
          </div>
        </li>

        <li>
          <div class="plugins-data">
  					<h1 class="sketch-count-txt">DAM</h1>
  					<h4>Support</h4>
          </div>
        </li>

        <li>
          <div class="plugins-data">
  					<h1 class="illustrator-count-txt">3D</h1>
  					<h4>Marketplace</h4>
          </div>
        </li>
      </ul>
    </div>
  </div>
</section>

<section class="designed-area" style="padding: 0px 0px 100px 0px; background: #ffffff;">
  <div class="container">
    <h2 class="team-head-title">How it works?</h2>
    <div class="row">
      <div class="col-md-4 how-it-works">
        <img class="figma-img-main" src="assets/img/sketch.svg">
        <h3>Download Plugin</h3>
        <p>Click on the download button and download latest version of Sketch plugin available. Install plugin and open it from the Plugins menu.</p>
      </div>
      <div class="col-md-4 how-it-works-img">
        <img class="mix-img" src="assets/img/match.svg">
        <h3>Latest Version</h3>
        <p>Check for the latest version available with you or download the latest version available for new features, updates, bug fixes, etc.</p>
      </div>
      <div class="col-md-4 how-it-works-update">
        <img class="mix-img" src="assets/img/group-88.png">
        <h3>Update Your Work</h3>
        <p>Crafttor' marketplace has number of latest, trendy and high quality illustrations so you can always update your project work by getting latest designs/illustrations available.</p>
      </div>
    </div>
  </div>
</section>

<!-- Big CTA Section -->
<section class="cta-section">
  <div class="container">
    <div class="inner-cta">
      <div class="row">
        <div class="col-md-7">
          <h2>Customize trendy illustrations with the Sketch plugin and share
             the story of your success with the world just by improving your brand’s design system.</h2>
        </div>
        <div class="col-md-5">
          <div class="btn-wrap">
            <a href="{{sketchData.download}}" download class="button gray-btn" (click)="saveCountDownload()">
              <img style="width:30px; height:25px; margin-right: 10px; " class="btnimg" src="assets/img/sketch.svg" alt="" />
              Download Sketch ( {{sketchData.version}} )</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="kaleidoscope-description-section">
	<div class="container">
		<div class="row">
      <div class="col-sm-3">
			</div>

			<div class="col-sm-6 graphic-section">
        <img src="assets/img/sheild.svg" alt="">
				<h2>Secure and Reliable</h2>
        <p>All our Figma plugins are built with security and reliability in mind — your data remains protected and stays securely within your Figma file.</p>
			</div>

      <div class="col-sm-3">
			</div>
		</div>
	</div>
</section>

<section class="plugin-design-area">
  <div class="container">
    <div class="row">
      <ul>
        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/trans.png" alt="">
              <h2>Translate AI</h2>
            </div>
            <p>Translate AI empowers you to effortlessly translate content within your designs using the power of AI.<br></p>
            <a routerLink="/translate-ai"><button type="button" name="button">Try Translate AI <br><span>Get started today</span></button></a>
          </div>
        </li>

        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/img-fig.png" alt="">
              <h2>Image To Figma</h2>
            </div>
            <p>Image To Figma Design convert any image or screenshot into fully editable Figma designs.<br></p>
            <a routerLink="/image-to-figma"><button type="button" name="button">Try Image To Figma <br><span>Get started today</span></button></a>
          </div>
        </li>

        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/kalei.png" alt="">
              <h2>Kaleidoscope</h2>
            </div>
            <p>Kaleidoscope plugin adds style from your photos and materials, enhancing your design appearance.</p>
            <a routerLink="/kaleidoscope"><button type="button" name="button">Try Kaleidoscope <br><span>Get started today</span></button></a>
          </div>
        </li>

      </ul>
    </div>
  </div>
</section>

<!-- FAQs Section -->
<section class="faq-section-other-pages">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="inner-content">
          <h6>FAQs</h6>
          <hr>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="faq-section design-faq">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>What is Sketch Plugin?</h2>
          <p>The sketch is a Mac-only, vector-based digital design tool that is primarily useful for UI/UX design of web, desktop, mobile, and wearables. Plugins improve functionality and workflow by providing creative elements that teams can directly integrate into their designs. You can add features created by developers across the globe.</p>
          <hr>
        </div>
      </div>
      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>How to use Sketch plugins?</h2>
          <p>Find your desired plugin on the Sketch website and click on the installation button. You will be redirected to a GitHub repository. Click on the ‘clone or download button’ and click on ‘download zip’ from the drop-down menu. Double-click on the Sketch plugin, and it’s installed. </p>
          <hr>
        </div>
      </div>
      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>Who should use Sketch Plugins?</h2>
          <p>Individuals or teams dedicated to designing icons, UI/UX, prototypes, user flow, UI libraries, vector illustrations, social media images, and presentations. However, you must have a MAC to use Sketch.</p>
          <hr>
        </div>
      </div>
      <div class="col-sm-12 right-question faq-bottom">
        <div class="inner-content">
          <h2>How can I access Crafftor on Sketch?</h2>
          <p>We have made the installation easy for you. Click on the download sketch above, and a download will start. Once you’ve installed the Crafttor plugin, you can access its features from the Plugins menu.</p>
          <hr>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- FAQs Section END -->
