import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import AOS from 'aos';

@Component({
  selector: 'app-figma',
  templateUrl: './figma.component.html',
  styleUrls: ['./figma.component.css']
})
export class FigmaComponent implements OnInit {

  title = 'Figma Plugin | Crafttor';

  showFigmaImage: boolean = false;
  figma: boolean = true;

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'description', content: 'figma plugin is designed to create better looking landing pages for your products. Its a way to fill empty states that matches your app palette'},
      { name: 'keywords', content: '' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Crafttor' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);
    AOS.init();
  }

  figmaHover(){
   this.showFigmaImage = true;
   this.figma = true;
  }
}
