<!-- Search Section -->
<section class="account-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="user-account">
          <div class="member-thumb">
            <img *ngIf="!userPic" src="assets/img/user-profile.svg">
						<img *ngIf="userPic" src="{{userPic}}">
          </div>
          <div class="member-info">
            <h5><a href="javascript:;" routerLink="/profile">{{currentUser.firstName+" "+currentUser.lastName}}</a> <span>/</span> Account Setting </h5>
            <p>Update your name and manage your account</p>
          </div>
        </div>
        <div class="tabs-wrapper">
          <div class="vertical-tabs">
            <!-- Tabs -->
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#pag1" role="tab" aria-controls="edit">Edit Profile</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#pag2" role="tab" aria-controls="setting">Account Setting</a>
              </li>
              <!-- <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#pag3" role="tab" aria-controls="password">Password</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#pag4" role="tab" aria-controls="billing">Billing</a>
              </li>-->
            </ul>

            <!-- Tabs Content -->
            <div class="tab-content">
              <div class="tab-pane active" id="pag1" ro le="tabpanel">
                <div class="sv-tab-panel">
                  <form class="edit-form" [formGroup]="profileForm" (ngSubmit)="updateProfile()">
                    <!-- <div class="file-field">
                      <div class="thumb">
                        <img src="assets/img/user.png" class="rounded-circle" alt="">
                      </div>
                      <div class="button-wrapper">
                        <div class="upload-btn">
                          <input type="file">
                          <span>Upload Pic</span>
                        </div>
                        <button type="submit" class="delete-btn">Delete</button>
                      </div>
                    </div> -->
                    <div class="form-group">
                      <label for="InputName">First Name</label>

                      <input type="text" class="form-control" id="InputName" formControlName="firstName">
                      <span class="text-danger" *ngIf="(profileForm.controls.firstName.touched || submitted) && profileForm.controls.firstName.errors?.required">
                        First Name is required
                      </span>

                    </div>
                    <div class="form-group">
                      <label for="InputLocation">Last Name</label>
                      <input type="text" class="form-control" id="InputLocation" formControlName="lastName">
                      <span class="text-danger" *ngIf="(profileForm.controls.lastName.touched || submitted) && profileForm.controls.lastName.errors?.required">
                        Last Name is required
                      </span>
                    </div>
                    <div class="right-btn">
                      <button type="submit" class="button">Save Changes</button>
                    </div>
                  </form>
                </div>
              </div>
              <!-- Tabs 1-->

              <div class="tab-pane" id="pag2" role="tabpanel">
                <div class="sv-tab-panel">
                  <form class="edit-form">
                    <!-- <div class="form-group">
                      <label for="InputEmail">Email</label>
                      <input type="text" class="form-control" id="InputEmail">
                    </div> -->
                    <div class="form-group">
                      <div class="text-box">
                        <h5>Deactivate Account</h5>
                        <p>Deactivating your account & account data</p>
                      </div>
                      <div class="close-account"><a href="javascript:;" (click)="open(deletemodal)" class="button">DEACTIVATE</a></div>
                    </div>
                    <!-- <div class="right-btn">
                      <button type="submit" class="button">Save Changes</button>
                    </div> -->
                  </form>
                </div>
              </div>
              <!-- Tabs 2 -->

              <div class="tab-pane" id="pag3" role="tabpanel">
                <div class="sv-tab-panel">
                  <form class="edit-form">
                    <div class="form-group">
                      <label for="InputOldpassword">Old Password</label>
                      <input type="password" class="form-control" id="InputOldpassword" value="******">
                    </div>
                    <div class="form-group">
                      <label for="Inputnewpassword">New Password <span> ( Minimum 8 character )</span></label>
                      <input type="password" class="form-control" id="Inputnewpassword" value="******">
                    </div>
                    <div class="right-btn">
                      <button type="submit" class="button">Save Changes</button>
                    </div>
                  </form>
                </div>
              </div>
              <!-- Tabs 3 -->

              <div class="tab-pane" id="pag4" role="tabpanel">
                <div class="sv-tab-panel">
                  <div class="order-history">
                    <h5>Order History</h5>
                    <div class="custom-table">
                      <ul class="head">
                        <li>Date</li>
                        <li>Type</li>
                        <li>Receipt</li>
                      </ul>
                      <ul class="load-table">
                        <li>June 5, 2020</li>
                        <li>Individual Monthly Plan</li>
                        <li>Paid: $49</li>
                        <li>December 12, 2019</li>
                        <li>Pro Portfolio</li>
                        <li><a href="javascript:;">HTML</a> <span>|</span> <a href="javascript:;">PDF</a></li>
                        <li>December 10, 2018</li>
                        <li>Pro Portfolio</li>
                        <li><a href="javascript:;">HTML</a> <span>|</span> <a href="javascript:;">PDF</a></li>
                        <li>December 7, 2017</li>
                        <li>Pro Portfolio</li>
                        <li><a href="javascript:;">HTML</a> <span>|</span> <a href="javascript:;">PDF</a></li>
                        <li>December 20, 2016</li>
                        <li>Pro Portfolio</li>
                        <li><a href="javascript:;">HTML</a> <span>|</span> <a href="javascript:;">PDF</a></li>
                        <li>December 10, 2018</li>
                        <li>Pro Portfolio</li>
                        <li><a href="javascript:;">HTML</a> <span>|</span> <a href="javascript:;">PDF</a></li>
                        <li>December 7, 2017</li>
                        <li>Pro Portfolio</li>
                        <li><a href="javascript:;">HTML</a> <span>|</span> <a href="javascript:;">PDF</a></li>
                        <li>December 20, 2016</li>
                        <li>Pro Portfolio</li>
                        <li><a href="javascript:;">HTML</a> <span>|</span> <a href="javascript:;">PDF</a></li>
                      </ul>
                      <a id="list-tablebtn" class="simple-link" href="javascript:;">More History</a>
                    </div>


                    <div class="card-detail">
                      <h5>Credit Card On File</h5>
                      <a href="javascript:;" class="button gray-btn">Change Card</a>
                      <a href="javascript:;" class="button remove-btn">Remove Card</a>
                      <span>Visa ending in <strong>1584</strong></span>
                      <div class="expire-date">
                        <p>expire 6/2024</p>
                        <p>Postal/Zip code: 400076</p>
                      </div>
                      <div class="right-btn">
                        <button type="submit" class="button">Save Changes</button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Tabs 4 -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #deletemodal let-deletemodal>
  <div class="modal-dialog modal-dialog-centered " role="document">
    <div class="modal-content">
      <button type="button" class="close" (click)="deletemodal.close()">
        <img src="assets/img/cross.svg">
      </button>
      <div class="modal-body">
        <h3>Deactivate Account</h3>
        <div class="thumb">
          <img src="assets/img/lock.svg">
        </div>
        <p>After deactivating your account you will not able to access your data. You need to login again to access your account. <br>Are you sure you want to deactivate your account?</p>
        <div class="btn-wrapper">
          <a href="javascript:;" class="simple-link" data-dismiss="modal" aria-label="Close" (click)="deletemodal.close()">Not Now</a>
          <a href="javascript:;" (click)="logOut();deletemodal.close()" class="button" data-toggle="modal" data-target="#closemodal">Deactivate Account</a></div>

      </div>

    </div>
  </div>

</ng-template>
