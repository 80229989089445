import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../authentication.service';
import { map } from 'rxjs/operators';
import { AlertService } from '../components/alert/alert.service';
import { AuthService } from 'angularx-social-login';


@Injectable({
  providedIn: 'root'
})
export class EditProfileService {

  constructor(
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService,
    private alert: AlertService,
    private authService: AuthService,

  ) { }
  api_root: string;
  profileEdit: string = 'users/'
  public updateProfile(id: number, data: any) {
    this.api_root = environment.REST_API_SERVER;
    let url = this.api_root + this.profileEdit + id + "/";

    return this.httpClient.patch(url, data).pipe(map(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      const currentUser = this.authenticationService.currentUserValue;
      currentUser.firstName = user['first_name'];
      currentUser.lastName = user['last_name'];
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
      this.authenticationService.updateUser(currentUser);
      this.alert.success("Profile successfully updated!")
      return user;
    }));
  }

  public deleteProfile(id: number) {
    this.api_root = environment.REST_API_SERVER;
    let url = this.api_root + this.profileEdit + id + "/";

    return this.httpClient.delete(url).pipe(map((data) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      this.authenticationService.logout();
      this.authService.signOut();
    }));;
  }
}
