import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.css']
})
export class LicenseComponent implements OnInit {

    title = 'License | Crafttor';

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private metaTagService: Meta,) { }
  activeTab: String = this.route.snapshot.paramMap.get('active');
  readrules:Boolean=false;
  licence:Boolean=true;

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'description', content: 'Crafttor is always looking for new concepts of designing high end illustrations which ultimately results in a glory for our users.'},
      { name: 'keywords', content: '' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Crafttor' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);

    if(this.activeTab){
      if(this.activeTab == 'readrules'){ this.readrules=true; this.licence=false}
      if(this.activeTab == 'licence'){this.licence=true; this.readrules=false;}
    }
  }
  currentOrientation = 'horizontal';
}
