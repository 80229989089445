<section class="three-d-section">
   <div class="container">
      <div class="row">
         <div class="col-sm-5">
            <div class="inner-content">
               <h1>{{illusTitle}}</h1>
               <p>{{illusDesc}}</p>
               <div class="btn-wrapper">
                 <a href="javascript:;" routerLink="/member" class="button yellow-btn">Premium Membership</a>
                 <a *ngIf="illusTitle != 'Livofy'" href="javascript:;" class="button gray-btn" routerLink="/search" (click)="homeSearchIllus('2d')">Search Illustration</a>
                 <a *ngIf="illusTitle == 'Livofy'" href="https://www.livofy.com/" target="_blank" class="button gray-btn">Website</a>
               </div>
            </div>
         </div>
         <div class="col-sm-1">

         </div>
         <div class="col-sm-6">
            <div class="img-box">
               <img *ngIf="showMainImg" src="{{illusImage}}" alt="download high pixel illustration">
            </div>
         </div>
      </div>
   </div>
</section>

<section class="three-d-preview text-start">
   <div class="container">
      <h3 class="teamHead-title">Illustrations Preview</h3>
   </div>
</section>

<section class="vectors-section">
   <div class="container">
      <ul class="load-vector" infiniteScroll
      [infiniteScrollDistance]="1"
      [infiniteScrollUpDistance]="2"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScrollDown()">
      <li *ngFor="let illus of illustrations">
         <div class="inner-card" (click)="currentIllus= illus;openDownloadModal(downloadModal)">
            <img [src]="illus.image_resize" alt="high quality free illustrations">
            <div class="hover-style">
               <div class="title">{{ illus.title }}</div>
            </div>
         </div>
      </li>
      </ul>

      <img src="assets/img/loader.gif" *ngIf="paginationLoading" id="loadMorevector" href="javascript:;" style="display: block;margin-left: auto;margin-right: auto; margin-top:40px; width: 70px; height:60px;">
   </div>
</section>


<!-- Category Section -->
<section class="vectorimg-section">
<div class="container">
  <div class="row">
    <div class="col-sm-12">
      <h2>More Styles</h2>
      <!-- <p>High-quality digital resources for a faster and smoother design workflow.</p> -->
    </div>
  </div>
  <div class="row">
    <ul class="load-list">
      <li *ngFor="let category of categories">

        <div *ngIf="category.type == '2d'; else elseBlock" class="vector-box" routerLink="/illustration" [queryParams]="{category: category.id}" (click)="selectValue(category)">
          <div class="thumb">
            <img [src]="category.image" alt="high quality professional funny illustrations">
          </div>
          <div class="category-title">
            <!-- <div class="category-price-section">
              <h5 *ngIf="isIndia" id="priceIndia">₹{{ category.price_inr }}</h5>
              <h5 *ngIf="!isIndia" id="priceNotIndia">${{ category.price_usd }}</h5>
            </div> -->
            <h3>{{ category.name }}</h3>
          </div>

          <p>{{ category.description }}</p>

          <!-- <div class="btn-wrapper-category">
            <a href="javascript:;" *ngIf="isIndia" routerLink="payment/" (click)="sendPrice(category.price_inr, category.name, category.image)" class="button">Buy now</a>
            <a href="javascript:;" *ngIf="!isIndia" routerLink="payment/" (click)="sendPrice(category.price_usd, category.name, category.image)" class="button">Buy now</a>
          </div> -->
        </div>

        <ng-template #elseBlock>

          <div #elseBlock class="vector-box" routerLink="/3d-illustrations">
            <div class="thumb">
              <img [src]="category.image" alt="3D funny illustrations">
            </div>
            <div class="category-title">

              <!-- <div class="category-price-section">
                <h5 *ngIf="isIndia" id="priceIndia">₹{{ category.price_inr }}</h5>
                <h5 *ngIf="!isIndia" id="priceNotIndia">${{ category.price_usd }}</h5>
              </div> -->

              <h3>{{ category.name }}</h3>
            </div>

            <p>{{ category.description }}</p>

            <!-- <div class="btn-wrapper-category">
              <a href="javascript:;" *ngIf="isIndia" routerLink="payment/" (click)="sendPrice(category.price_inr, category.name, category.image)" class="button">Buy now</a>
              <a href="javascript:;" *ngIf="!isIndia" routerLink="payment/" (click)="sendPrice(category.price_usd, category.name, category.image)" class="button">Buy now</a>
            </div> -->
          </div>
        </ng-template>
      </li>
    </ul>
  </div>
</div>
</section>




<!-- <section class="three-d-endless">
   <div class="container">
      <div class="row">
         <div class="col-sm-12">
            <div class="letsmake-box">
               <h2>Endless Usecases </h2>
               <p>You're free to use these illustrations for both personal and commercial projects.</p>
            </div>
         </div>
         <div class="col-md-3">
            <div class="three-d-box">
               <img src="assets/img/rocket.png" alt="">
            </div>
         </div>
         <div class="col-md-6">
            <div class="three-d-box-mobile">
               <img class="mobile-img" src="assets/img/ar-mobile.png" alt="">
            </div>
         </div>
         <div class="col-md-3">
            <div class="three-d-box-wallet">

               <img src="assets/img/wallet.png" alt="">

            </div>
         </div>
         <div class="website-view-main">
            <div class="website-view-section">
               <div class="container">
                  <div class="row">
                     <h3>3D</h3>
                     <div class="col-sm-6">
                     </div>
                     <div class="col-sm-2">
                        <h5>Contact Us</h5>
                     </div>
                     <div class="col-sm-1">
                        <h4>Login</h4>
                     </div>
                  </div>
               </div>
               <div class="container">
                  <div class="row">
                     <div class="col-sm-6">
                        <div class="inner-content">
                           <h2>Manage Your Work <br>The Way You Want</h2>
                           <p>Make your work more appealing with the mix & match combinations of unlimited illustration styles.</p>
                        </div>
                     </div>
                     <div class="col-sm-6">
                        <div class="img-box">
                           <img src="assets/img/table-people.png" alt="download 3d illustration">
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section> -->



<!-- Modal -->
<ng-template #downloadModal let-downloadModal>

	<div class="download-assests">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<button type="button" class="close" (click)="downloadModal.close()">
					<img src="assets/img/cross.svg" alt="download free 3D">
				</button>
				<div class="modal-body">
					<div class="thumb">
						<img [src]="currentIllus.image_resize" alt="download free illustrations">
					</div>
					<div class="content-right">
						<h3>{{currentIllus.title || ''}}</h3>
						<div class="options">
              <div class="custom-checkbox">
								<a href="javascript:;" class="button" (click)="downloadPngImage()">Download PNG</a>
							</div>
							<div class="custom-checkbox">
								<a href="javascript:;" class="button" (click)="downloadSvgImage()">Download SVG</a>
							</div>
						</div>
						<div class="bottom-txt">
							<span>Attribution is required.</span>
							<strong>Crafttor License</strong>
							<p>Free for personal and commercial purpose with attribution. <a href="javascript:;" (click)="downloadModal.close(); router.navigate(['/license'])" class="link">More info</a></p>
							<strong>For Exclusive Content & Unlimited Downloads</strong>
							<div class="btn-wrapper"><a class="button yellow-btn" data-dismiss="modal" (click)="downloadModal.close(); router.navigate(['/member'])" href="javascript:;">Premium Membership</a></div>
							<span>No attribution required.</span>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</ng-template>

<!-- Modal -->
<ng-template #limitModal let-limitModal>
	<!-- <div class="modal fade premium-pop" > -->
	<div class="modal-dialog modal-dialog-centered  premium-pop" role="document">
		<div class="modal-content">
			<button type="button" class="close" (click)="limitModal.close()">
				<img src="assets/img/cross.svg" alt="download funny illustrations">
			</button>
			<div class="modal-body">
				<h3>Sorry!</h3>
				<h4>You exceed your daily limit</h4>
				<!-- <div class="qty">3 SVG in 24 hrs</div> -->
				<!-- <small>You can download <strong>3 SVG</strong> in <strong>24hrs</strong> & <strong>10 SVG</strong> in a <strong>month</strong></small> -->
				<a href="javascript:;" (click)="limitModal.close(); closeDownloadModal();" [routerLink]="['/license', {active: 'readrules'}]" class="simple-link">Read the Rules</a>
				<p>For Exclusive Content & Unlimited Downloads</p>
				<div class="btn-wrapper"><a class="button yellow-btn" (click)="limitModal.close(); closeDownloadModal(); router.navigate(['/member'])" href="javascript:;">Premium Membership</a></div>
				<span>No attribution required.</span>
			</div>

		</div>
	</div>
	<!-- </div> -->
</ng-template>

<ng-template #loginModal let-modal>
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<button type="button" class="close" (click)="openClose()" data-dismiss="modal" aria-label="Close">
				<img src="assets/img/cross.svg" alt="download free illustrations">
			</button>
			<div class="modal-body">
				<h3>{{loginSignUpText}}</h3>
				<!-- <p style="margin-bottom:30px; margin-top:8px;">Please login to download more illustrations</p> -->
				<div class="button-wrapper">
					<a href="javascript:;" class="button google-btn" (click)="signInWithGoogle()" *ngIf="socialLoginSection"><i class="fa fa-google-plus"></i> Continue with Google</a>
					<!-- <a href="javascript:;" class="button fb-btn" (click)="signInWithFB()"><i class="fa fa-facebook-f"></i>Continue with Facebook</a> -->
					<!-- <a href="javascript:;" class="button twitter-btn"><i class="fa fa-twitter"></i> Continue with Twitter</a> -->
					<a href="javascript:;" class="button manual-btn" (click)="manualLogin()" *ngIf="socialLoginManualSection"><i class="fa fa-envelope" style="padding-right: 8px;"></i>Continue with Email</a>

					<div class="or-section">
            <hr class="hr-area">
            <span class="or-text">Or</span>
            <hr class="hr-area">
          </div>

					<div class="container" *ngIf="login">
						<form action="" [formGroup]="loginForm" (ngSubmit)="loginFunction()">
							<input id="textinput" formControlName="email" type="email" placeholder="Enter email id*" required>
							<span class="text-danger error-text"
							*ngIf="(loginForm.controls.email.touched || submitted) && loginForm.controls.email.errors?.required">
							Email is required
						</span>
						<span class="text-danger error-text"
							*ngIf="(loginForm.controls.email.touched || submitted) && loginForm.controls.email.errors?.pattern">
							Email is not valid
						</span>
						<input id="password" formControlName="password" type="password" placeholder="Enter password*" required>
							<span class="text-danger error-text"
							*ngIf="(loginForm.controls.password.touched || submitted) && loginForm.controls.password.errors?.required">
							Password is required
						</span>

							<label style="margin-bottom: 10px;color: #2c85ed; display: block; text-align: left;
							clear: both;" class="control-label" for="textinput"><a href="javascript:;" (click)="forgotScreen()">Forgot Password?</a></label>

							<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

							<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="!showLeaveLoader">Submit</button>

							<p class="below-txt">New at Crafttor? <a href="javascript:;" (click)="socialSignUp()" style="color: #2c85ed"><b>Sign Up</b></a></p>
							<!-- <a href="javascript:;" (click)="socialLogin()" style="color: #2c85ed">Back</a> -->

							<!-- <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt=""> -->
							</form>
						</div>

						<div class="container" *ngIf="emailVerify">
							<form action="" [formGroup]="emailVerifyForm" (ngSubmit)="getOTP()">
							<input id="textinput" formControlName="email" type="email" placeholder="Enter email id*" required>
							<span class="text-danger error-text"
							*ngIf="(emailVerifyForm.controls.email.touched || emailVerifySubmit) && emailVerifyForm.controls.email.errors?.required">
							Email is required
						</span>
						<span class="text-danger error-text"
						*ngIf="(emailVerifyForm.controls.email.touched || emailVerifySubmit) && emailVerifyForm.controls.email.errors?.pattern">
						Email is not valid
					</span>

					<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

					<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="!showLeaveLoader">Get OTP</button>

					<p class="below-txt">Already have an account? <a href="javascript:;" (click)="socialLogin()" style="color: #2c85ed"><b>Login</b></a></p>
					<!-- <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt=""> -->
					</form>
					</div>

					<div class="container" *ngIf="signup">
						<form action="" [formGroup]="signUpForm" (ngSubmit)="signUpFormFunction()">
							<input id="textinput" formControlName="fname" type="text" placeholder="Enter first name*" required style="float: left; width: 48%">
							<input id="textinput" formControlName="lname" type="text" placeholder="Enter last name*" required style="float: right; width: 48%">
							<span class="text-danger error-text"
							*ngIf="(signUpForm.controls.fname.touched || submitt) && signUpForm.controls.fname.errors?.required">
							First Name is required
						</span>
							<span class="text-danger error-text" style="float: right;"
							*ngIf="(signUpForm.controls.lname.touched || submitt) && signUpForm.controls.lname.errors?.required">
							Last Name is required
						</span>
						<input id="password" formControlName="password" type="password" placeholder="Enter password*" required>
						<span class="text-danger error-text"
							*ngIf="(signUpForm.controls.password.touched || submitt) && signUpForm.controls.password.errors?.required">
							Password is required
						</span>

						<input id="textinput" formControlName="otp" type="text" placeholder="Enter OTP*" required>
						<span class="text-danger error-text"
							*ngIf="(signUpForm.controls.otp.touched || submitt) && signUpForm.controls.otp.errors?.required">
							OTP is required
						</span>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="!showLeaveLoader">Submit</button>

						<p class="below-txt">Already have an account? <a href="javascript:;" (click)="socialLogin()" style="color: #2c85ed"><b>Login</b></a></p>
							<!-- <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt=""> -->
					</form>
				</div>

				<div class="container" *ngIf="forgotpassword">
					<form action="" [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPasswordFunction()">
						<input id="textinput" formControlName="email" type="email" placeholder="Enter email id*" required>
						<span class="text-danger error-text"
						*ngIf="(forgotPasswordForm.controls.email.touched || submittedForm) && forgotPasswordForm.controls.email.errors?.required">
							Email is required
						</span>
						<span class="text-danger error-text"
						*ngIf="(forgotPasswordForm.controls.email.touched || submittedForm) && forgotPasswordForm.controls.email.errors?.pattern">
							Email is not valid
						</span>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="!showLeaveLoader">Get OTP</button>
						<p class="below-txt"><a href="javascript:;" (click)="socialLogin()" style="color: #2c85ed">Return to Login</a></p>
						<!-- <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt=""> -->
						</form>
					</div>

					<div class="container" *ngIf="enterOtp">
						<form action="" [formGroup]="enterOtpForm" (ngSubmit)="resetPasswordFunction()">
							<input id="otp" formControlName="otp" type="text" placeholder="Enter OTP*" required>
							<span class="text-danger error-text"
							*ngIf="(enterOtpForm.controls.otp.touched || passwordSubmit) && enterOtpForm.controls.otp.errors?.required">
							Enter OTP
						</span>

						<input id="password" formControlName="password" type="password" placeholder="Enter password*" required>
						<span class="text-danger error-text"
						*ngIf="(enterOtpForm.controls.password.touched || passwordSubmit) && enterOtpForm.controls.password.errors?.required">
						Enter new password
					</span>
					<input id="repassword" formControlName="repassword" type="password" placeholder="Re-enter password*" required>
					<span class="text-danger error-text"
						*ngIf="(enterOtpForm.controls.repassword.touched || passwordSubmit) && enterOtpForm.controls.repassword.errors?.required">
						Re-enter password
					</span>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

						<button id="singlebutton" name="singlebutton button" class="btn btn-primary btn-main-login" *ngIf="!showLeaveLoader">Submit</button>
							<p class="below-txt"><a href="javascript:;" (click)="showLoginScreen()" style="color: #2c85ed">Return to Login</a></p>
							<!-- <img class="main-loader-img" *ngIf="showSendingSmall" style="width: 60px;" src="assets/img/loader.svg" alt=""> -->
							</form>
						</div>

					<p>By Login, I agree the <a href="javascript:;" (click)="openClose()" [routerLink]="['privacy-terms', {active: 'terms'}]">Terms of Use</a> & <a href="javascript:;" (click)="openClose()"
							[routerLink]="['privacy-terms', {active: 'privacy'}]">Privacy Policy</a>.</p>
				</div>
			</div>

		</div>
	</div>
</ng-template>
