<section class="three-d-section">
   <div class="container">
      <div class="row">
         <div class="col-sm-5">
            <div class="inner-content">
               <h1>Sunny 3D</h1>
               <p>Collection of trendy, free and high-quality 3D illustrations that are full of personalities.</p>
               <div class="btn-wrapper">
     						<a href="javascript:;" routerLink="/member" class="button yellow-btn">Premium Membership</a>
     					</div>
            </div>
         </div>
         <div class="col-sm-1">

         </div>
         <div class="col-sm-6">
            <div class="img-box">
               <img src="assets/img/man-ar.png" alt="download 3d illustration">
            </div>
         </div>
      </div>
   </div>
</section>

<section class="three-d-preview text-start">
   <div class="container">
      <h3 class="teamHead-title">Illustrations Preview</h3>
   </div>
</section>

<section class="vectors-section">
   <div class="container">
      <ul class="load-vector" infiniteScroll
      [infiniteScrollDistance]="1"
      [infiniteScrollUpDistance]="2"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScrollDown()">
      <li *ngFor="let illus of illustrations">
         <div class="inner-card" (click)="currentIllus= illus;openDownloadModal(downloadModal)">
            <img [src]="illus.image_resize" alt="high quality free 3D illustrations">
            <div class="hover-style">
               <div class="title">{{ illus.title }}</div>
            </div>
         </div>
      </li>
      </ul>

      <img src="assets/img/loader.gif" *ngIf="paginationLoading" id="loadMorevector"
      href="javascript:;" style="display: block;margin-left: auto;margin-right: auto;
      margin-top:40px; width: 70px; height:60px;">
   </div>
</section>

<section class="three-d-endless">
   <div class="container">
      <div class="row">
         <div class="col-sm-12">
            <div class="letsmake-box">
               <h2>Endless Usecases </h2>
               <p>You're free to use these illustrations for both personal and commercial projects.</p>
            </div>
         </div>
         <div class="col-md-3">
            <div class="three-d-box">
               <img src="assets/img/rocket.png" alt="">
            </div>
         </div>
         <div class="col-md-6">
            <div class="three-d-box-mobile">
               <img class="mobile-img" src="assets/img/ar-mobile.png" alt="">
            </div>
         </div>
         <div class="col-md-3">
            <div class="three-d-box-wallet">
              <!-- <img style="float:top;" src="assets/img/wallet-pattern.png" alt=""> -->
               <img src="assets/img/wallet.png" alt="">

            </div>
         </div>
         <div class="website-view-main">
            <div class="website-view-section">
               <div class="container">
                  <div class="row">
                     <h3>3D</h3>
                     <div class="col-sm-6">
                     </div>
                     <div class="col-sm-2">
                        <h5>Contact Us</h5>
                     </div>
                     <div class="col-sm-1">
                        <h4>Login</h4>
                     </div>
                  </div>
               </div>
               <div class="container">
                  <div class="row">
                     <div class="col-sm-6">
                        <div class="inner-content">
                           <h2>Manage Your Work <br>The Way You Want</h2>
                           <p>Mix and match our illustrations to improve the <br>aesthetics of your work.</p>
                        </div>
                     </div>
                     <div class="col-sm-6">
                        <div class="img-box">
                          <img src="assets/img/three-d-people.png" alt="">
                          <!-- <img src="assets/img/ar-back.png" alt=""> -->
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>



<!-- Modal -->
<ng-template #downloadModal let-downloadModal>

	<div class="download-assests">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<button type="button" class="close" (click)="downloadModal.close()">
					<img src="assets/img/cross.svg" alt="download free 3D">
				</button>
				<div class="modal-body">
					<div class="thumb">
						<img [src]="currentIllus.image_resize" alt="download 3D illustrations">
					</div>
					<div class="content-right">
						<h3>{{currentIllus.title || ''}}</h3>
						<div class="options">
							<!-- <div class="custom-checkbox">
								<a href="javascript:;" class="button" (click)="downloadPngImage()">Download</a>
							</div> -->
              <!-- <i class="fa fa-download" style="padding-left:10px;"></i> -->
              <div class="custom-checkbox">
                <a href="javascript:;" class="button" (click)="downloadPngImage()">Small PNG</a>

              </div>
              <div class="custom-checkbox">
                <a href="javascript:;" class="button" (click)="downloadPngImageBig()">Large PNG</a>
              </div>
						</div>
						<div class="bottom-txt">
							<span>Attribution is required.</span>
							<strong>Crafttor License</strong>
							<p>Free for personal and commercial purpose with attribution. <a href="javascript:;" (click)="downloadModal.close(); router.navigate(['/license'])" class="link">More info</a></p>
							<strong>For Exclusive Content & Unlimited Downloads</strong>
							<div class="btn-wrapper"><a class="button yellow-btn" data-dismiss="modal" (click)="downloadModal.close(); router.navigate(['/member'])" href="javascript:;">Premium Membership</a></div>
							<span>No attribution required.</span>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</ng-template>

<!-- Modal -->
<ng-template #limitModal let-limitModal>
	<!-- <div class="modal fade premium-pop" > -->
	<div class="modal-dialog modal-dialog-centered  premium-pop" role="document">
		<div class="modal-content">
			<button type="button" class="close" (click)="limitModal.close()">
				<img src="assets/img/cross.svg" alt="download funny illustrations">
			</button>
			<div class="modal-body">
				<h3>Sorry!</h3>
				<h4>You exceed your daily limit</h4>
				<!-- <div class="qty">3 SVG in 24 hrs</div> -->
				<!-- <small>You can download <strong>3 SVG</strong> in <strong>24hrs</strong> & <strong>10 SVG</strong> in a <strong>month</strong></small> -->
				<a href="javascript:;" (click)="limitModal.close(); closeDownloadModal();" [routerLink]="['/license', {active: 'readrules'}]" class="simple-link">Read the Rules</a>
				<p>For Exclusive Content & Unlimited Downloads</p>
				<div class="btn-wrapper"><a class="button yellow-btn" (click)="limitModal.close(); closeDownloadModal(); router.navigate(['/member'])" href="javascript:;">Premium Membership</a></div>
				<span>No attribution required.</span>
			</div>

		</div>
	</div>
	<!-- </div> -->
</ng-template>

<!-- Modal -->
<ng-template #premiumModal let-premiumModal>
	<!-- <div class="modal fade premium-pop" > -->
	<div class="modal-dialog modal-dialog-centered  premium-pop-dialog" role="document">
		<div class="modal-content">
			<button type="button" class="close" (click)="premiumModal.close()">
				<img src="assets/img/cross.svg" alt="download funny illustrations">
			</button>
			<div class="modal-body">
				<h3>Unlock Large 3D</h3>
				<h4>& get access of all the products.</h4>
				<!-- <div class="qty">3 SVG in 24 hrs</div> -->
				<!-- <small>You can download <strong>3 SVG</strong> in <strong>24hrs</strong> & <strong>10 SVG</strong> in a <strong>month</strong></small> -->
				<!-- <a href="javascript:;" (click)="premiumModal.close(); closeDownloadModal(); " [routerLink]="['/license', {active: 'readrules'}]" class="simple-link">Read the Rules</a> -->
				<p>For Exclusive Content & Unlimited Downloads</p>
				<div class="btn-wrapper"><a class="button yellow-btn" (click)="premiumModal.close(); closeDownloadModal(); router.navigate(['/member'])" href="javascript:;">Premium Membership</a></div>
				<span>No attribution required.</span>
			</div>

		</div>
	</div>
	<!-- </div> -->
</ng-template>

<ng-template #loginModal let-modal>
	<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
			<button type="button" class="close" (click)="openClose()" data-dismiss="modal" aria-label="Close">
				<img src="assets/img/cross.svg" alt="download free illustrations">
			</button>
			<div class="modal-body">
				<h3 style="margin-bottom:0px;">Login to continue</h3>
				<p style="margin-bottom:30px; margin-top:8px;">Please login to download more illustrations</p>
				<div class="button-wrapper">
					<a href="javascript:;" class="button google-btn" (click)="signInWithGoogle()"><i class="fa fa-google-plus"></i> Continue with Google</a>
					<a href="javascript:;" class="button fb-btn" (click)="signInWithFB()"><i class="fa fa-facebook-f"></i>Continue with Facebook</a>
					<!-- <a href="javascript:;" class="button twitter-btn"><i class="fa fa-twitter"></i> Continue with Twitter</a> -->
					<p>By Login, I agree the <a href="javascript:;" (click)="openClose()" [routerLink]="['privacy-terms', {active: 'terms'}]">Terms of Use</a> & <a href="javascript:;" (click)="openClose()"
							[routerLink]="['privacy-terms', {active: 'privacy'}]">Privacy Policy</a>.</p>
				</div>
			</div>

		</div>
	</div>
</ng-template>
