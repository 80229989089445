import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { User } from '../_models/user';
import { Router } from '@angular/router';
import { LocationService } from '../location/location.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(
    private httpClient: HttpClient,
    private http: HttpClient,
    private router: Router,
    private locationService: LocationService,
  ) { }
  api_root: string = environment.REST_API_SERVER;

  getIllus(limit: number){
    return this.httpClient.get(this.api_root+"illus/?limit="+limit)
  }

  private get_user_from(){
    return sessionStorage.getItem("user_from")
  }

  public getDateCall() {
    this.api_root = environment.REST_API_SERVER;
    let similar_url = this.api_root + 'contact-sale/1/get_available_date';
    return this.httpClient.get(similar_url).pipe(map(data => {
      return data['Available_Slots'];
    }));
  }

  public getTimeCall(date: any) {
    this.api_root = environment.REST_API_SERVER;
    let similar_url = this.api_root + 'contact-sale/1/get_available_slot/?date='+date;
    return this.httpClient.get(similar_url).pipe(map(data => {
      return data['Available_Slots'];
    }));
  }

  public getDownloadCountCall() {
    this.api_root = environment.REST_API_SERVER;
    let similar_url = this.api_root + 'users/download_count/';
    return this.httpClient.get<any>(similar_url);
  }

  public contactSalesCallApi(name, email, company, date, time) {
    this.api_root = environment.REST_API_SERVER;
    let similar_url = this.api_root + 'contact-sale/';
    const data = {
      "name": name,
      "email": email,
      "company": company,
      "date": date,
      "time_start": time
    }

    return this.httpClient.post<any>(similar_url, data);
  }

  login(provider: string, uid: string, access_token: string, email:string="", firstName: string="", lastName: string="", photoUrl="", token: string) {
    let country = this.locationService.getCountry();
    let  post_data = { "provider":provider, "uid": uid, "first_name":firstName, "last_name":lastName, "access_token":access_token, "email":email, "workspace_token": token};
    let user_from = this.get_user_from()
    // if(user_from){
    post_data["user_from"] = "dam";
    // }
    if(country){
      post_data['country'] = country;
    }
      return this.http.post<any>(`${environment.REST_API_SERVER}users/login/`, post_data)
          .pipe(map(user => {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              user['firstName'] = user['first_name'];
              user['lastName'] = user['last_name'];
              user['refreshToken'] = user['refresh_token'];
              user['photoUrl'] = photoUrl;
              localStorage.setItem('currentUser', JSON.stringify(user));
              this.currentUserSubject.next(user);
              return user;
          }));
  }

  loginWithEmail(email:any, password: any, workspace_token: string){
    let  post_data = {"email":email, "password": password, "workspace_token": workspace_token}
    return this.http.post<any>(`${environment.REST_API_SERVER}users/email_login/`, post_data)
          .pipe(map(user => {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              user['firstName'] = user['first_name'];
              user['lastName'] = user['last_name'];
              user['refreshToken'] = user['refresh_token'];
              localStorage.setItem('currentUser', JSON.stringify(user));
              this.currentUserSubject.next(user);
              return user;
          }));
  }

  registerWithEmail(firstName: string, lastName: string, email: string, password: any, otp: any){
    let country = this.locationService.getCountry();
    let  post_data = {"first_name":firstName, "last_name":lastName, "email":email, "password": password, "otp": otp};
    let user_from = this.get_user_from()
    // if(user_from){
    post_data["user_from"] = "dam";
    // }
    if(country){
      post_data['country'] = country;
    }
      return this.http.post<any>(`${environment.REST_API_SERVER}users/register/`, post_data)
          .pipe(map(user => {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              user['firstName'] = user['first_name'];
              user['lastName'] = user['last_name'];
              user['refreshToken'] = user['refresh_token'];
              localStorage.setItem('currentUser', JSON.stringify(user));
              this.currentUserSubject.next(user);
              return user;
          }));
  }

  getOTP(email: string){
    this.api_root = environment.REST_API_SERVER;
    let url = this.api_root + 'users/generate_email_otp/';
    const data = {
      "email": email
    }

    return this.httpClient.post<any>(url, data);
  }

  getResetPasswordOTP(email: string){
    this.api_root = environment.REST_API_SERVER;
    let url = this.api_root + 'users/generate_forgot_otp/';
    const data = {
      "email": email
    }

    return this.httpClient.post<any>(url, data);
  }

  resetPassword(email: string, otp: any, password: any, confirmPassword: any){
    this.api_root = environment.REST_API_SERVER;
    let url = this.api_root + 'users/reset_password/';
    const data = {
      "email": email,
      "otp": otp,
      "password": password,
      "confirm_password": confirmPassword,
    }

    return this.httpClient.post<any>(url, data);
  }
}
