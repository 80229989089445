import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Payment } from '../_models/payment';
import { StripePayment } from '../_models/payment';
import { Observable } from 'rxjs';
import {IMemberShipPlan} from './IMemberShip';

declare const Stripe;

@Injectable({
  providedIn: 'root'
})
export class MemberService {

  baseUrl: string = environment.REST_API_SERVER;

  constructor(
    private httpClient: HttpClient,
  ) { }

  webSetting = 'settings/'
  orderUrl = 'create-order/'
  stripeUrl = 'stripe-payments/'
  public getWebSettings(key: string) {
    const api_root = environment.REST_API_SERVER;
    let url = api_root + this.webSetting + key + "/";

    return this.httpClient.get(url).pipe(map(data => {
      return data['value'];
    }));
  }

  // getMemberShip(): Observable<IMemberShipPlan> {
  //   return Of({
  //     id: '',
  //     priceId: '',
  //     name: 'Paid Plan',
  //     price: '$300.00',
  //     features: [
  //       'Upto 10GB Space',
  //       'Basic Support',
  //     ],
  //   });
  // }
  //
  // requestMemberSession(priceId: string): void {
  //   this.httpClient
  //     .post<ISession>(this.baseUrl + this.stripeUrl,{
  //       priceId: priceId,
  //     })
  //     .subscribe((session) => {
  //       this.redirectToCheckout(session.sessionId);
  //     });
  // }
  //

  // redirectToCheckout(sessionId: string) {
  //   const stripe = Stripe("Key");
  //
  //   stripe
  //     .confirmCardPayment('{PAYMENT_INTENT_CLIENT_SECRET}', {
  //       payment_method: {
  //         card: cardElement,
  //         billing_details: {
  //           name: '',
  //         },
  //       },
  //     })
  //     .then(function(result) {
  //       // Handle result.error or result.paymentIntent
  //   });
  // }

  public postOrder(payment: Payment) {
    const api_root = environment.REST_API_SERVER;
    let url = api_root + this.orderUrl;
    const data = {
      "paypal_order_id": payment.paypalOrderId,
      "payment": payment.orderAmount,
      "plan_id": payment.planId,
      "status": payment.status
    }
    return this.httpClient.post<any>(url, data);
  }

  public postStripeOrder(stripeJson: any, amount: string, currency: string, plan_id: string,
    plan_text: string, plan_type: string, userName: string, address: string, city: string,
    state: string, country: string, postcode: string) {
    const api_root = environment.REST_API_SERVER;
    let url = api_root + this.stripeUrl;
    const data = {
      "stripe_obj": stripeJson,
      "amount": amount,
      "currency": currency,
      "plan_id": plan_id,
      "plan_text": plan_text,
      "plan_type": plan_type,
      "name": userName,
      "line1": address,
      "city": city,
      "state": state,
      "country": country,
      "postal_code": postcode,
      "order_from": 'stripe'
    }
    return this.httpClient.post<any>(url, data);
  }

  public postRazorPayOrder(stripeJson: any, amount: string, currency: string,
    plan_id: string, plan_text: string, userName: string, address: string,
    city: string, state: string, country: string, postcode: string, razorpayPaymentId: string,
    razorpayOrderId: string, razorpaySignature: string) {
    const api_root = environment.REST_API_SERVER;
    let url = api_root + 'stripe-payments/1/pay_razor_illus/';
    const data = {
      "stripe_obj": "jhahabmsbjabsambsman",
      "amount": amount,
      "currency": currency,
      "plan_id": plan_id,
      "plan_text": plan_text,
      "name": userName,
      "line1": address,
      "city": city,
      "state": state,
      "country": country,
      "postal_code": postcode,
      "order_from": 'razorpay',
      "razorpay_payment_id": razorpayPaymentId,
      "razorpay_order_id": razorpayOrderId,
      "razorpay_signature": razorpaySignature,
    }
    return this.httpClient.post<any>(url, data);
  }

  public getAmountRazor(userName: string, amount: any, planId: any) {
    const api_root = environment.REST_API_SERVER;
    let url = api_root + 'stripe-payments/1/get_illus_amount_razor/';
    const data = {
      "name": userName,
      "amount": amount,
      "currency": "USD",
      "plan_id": planId,
    }
    return this.httpClient.post<any>(url, data);
  }
}
