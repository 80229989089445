import { Component, OnInit, Inject, HostListener, ViewChild } from '@angular/core';
import { SharedService } from "../../shared.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { AuthenticationService } from '../../authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../../_models/user';
import { DOCUMENT } from '@angular/common';
import { HeaderService } from './header.service';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService } from "../../components/alert/alert.service";


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  closeResult: string;
  user: any;
  loggedIn: any;
  returnUrl: any;
  currentUser: User;
  searchText: string;
  user_from: string;
  mobileView: boolean=false;
  @ViewChild('mymodal') loginModalRef;
  loginModal: any;
  blogClicked: Boolean = false;

  socialLoginSection: boolean = true;
  socialLoginManualSection: boolean = false;
  manualLoginSection: boolean = false;
  submitted: Boolean = false;
  submitt: Boolean = false;
  submittedForm: Boolean = false;
  submittion: Boolean = false;
  emailVerifySubmit: Boolean = false;
  passwordSubmit: Boolean = false;
  showSendingSmall: boolean = false;
  login: boolean = false;
  emailVerify: boolean = false;
  signup: boolean = false;
  forgotpassword: boolean = false;
  forgotpasswordFields: boolean = false;
  enterOtp: boolean = false;
  resetPassword: boolean = false;
  showSending: boolean = false;
  emailIds: string;
  workspace_token: any;
  showLeaveLoader: boolean = false;
  loginSignUpText: string;
  userPic: any;

  constructor(
    private sharedService: SharedService,
    private modalService: NgbModal,
    private authService: AuthService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private headerService: HeaderService,
    private fb: FormBuilder,
    private alert: AlertService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  categories = []

  loginForm = this.fb.group({
    email: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
    password: ['', Validators.required],
  });

  forgotPasswordForm = this.fb.group({
    email: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
  });

  enterOtpForm = this.fb.group({
    otp: ['', Validators.required],
    password: ['', Validators.required],
    repassword: ['', Validators.required],
  });

  emailVerifyForm = this.fb.group({
    email: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
  });

  signUpForm = this.fb.group({
    fname: ['', Validators.required],
    lname: ['', Validators.required],
    password: ['', Validators.required],
    otp: ['', Validators.required],
  });

  resetPasswordForm = this.fb.group({
    otp: ['', Validators.required],
    password: ['', Validators.required],
    repassword: ['', Validators.required],
  });

  public lat;
  public lng;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100) {
      document.getElementById('mainNav').classList.add('navbar-shrink');
    }
    else {
      document.getElementById('mainNav').classList.remove('navbar-shrink');

    }
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    // this.loginToServer();
  }

  fbSignOut(): void {
    this.authService.signOut();
  }

  logOut(): void {
    this.authenticationService.logout();
    this.authService.signOut();
  }

  ngOnInit(): void {
    this.sharedService.getCategories().subscribe((data) => {
      this.categories = data['results'];
      this.sharedService.setCategoriesData(data);
    });
    this.activateRoute.queryParams.subscribe(params => {
      this.returnUrl = params['returnUrl'];
      this.user_from = params['from'];
      if(this.user_from){
        this.headerService.setUserFromSession(this.user_from);
      }
    });

    this.headerService.showLogin.subscribe((open:boolean)=>{
      if(open){
          this.loginModal = this.modalService.open(this.loginModalRef, { centered: true, windowClass: 'dark-modal exceed-limit login-modal' })
      } else{
        try {
          this.loginModal.close();

        } catch (error) {
          console.log(error);
        }
      }
    })

    this.authService.authState.subscribe((user) => {
      if (user && !this.authenticationService.currentUserValue) {
        this.loginToServer(user, user.photoUrl);
      }
    });
    this.authenticationService.currentUser.subscribe(x => { this.currentUser = x });
    if (window.screen.width <= 991) { // 768px portrait
      this.mobileView = true;
    }else{
      this.mobileView = false;
    }

    this.socialLogin();
    this.getLocation();
    this.getUserPic();
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: Position) => {
        if (position) {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
        }
      },
        (error: PositionError) => console.log(error));
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  refreshComponent(){
    // this.router.navigateByUrl('/search', { skipLocationChange: true }).then(() => {
    // this.router.navigate(['SearchComponent']);
    // });
  }

  open(content: any) {
    this.loginModal = this.modalService.open(content, { centered: true, windowClass: 'dark-modal exceed-limit login-modal' })
  }

  openClose() {
    this.loginModal.close();
  }

  loginClicked(open:boolean){
    this.headerService.setLoginModalValue(open);
  }

  loginToServer(user: any = null, photoUrl: string) {
    this.authenticationService.login(user.provider.toLowerCase(), user.id,
    user.authToken, user.email, user.firstName, user.lastName, photoUrl, this.lat, this.lng)
      .subscribe(
        (_data) => {
          if (this.returnUrl) {
            this.router.navigate([this.returnUrl]);
          }
          this.loginModal.close();
        }
      );
  }

  goToSearch() {
    if (this.searchText) {
      localStorage.setItem('2dDetails', JSON.stringify({ nameValue: '2d'}));
      const searchText = this.searchText;
      this.searchText = '';
      this.router.navigate(['search'], { queryParams: { search: searchText } });
    }
  }

  loadDataOnly(value: string){
    localStorage.setItem('2dDetails', JSON.stringify({ nameValue: value}));
  }

  openWorkspace() {
    window.open("https://workspace.crafttor.com/home", "_blank");
  }

  openWorkspaceLogin() {
    window.open("https://workspace.crafttor.com/login", "_blank");
  }

  openWorkspacePricing() {
    window.open("https://workspace.crafttor.com/pricing", "_blank");
  }

  loginFunction(){
    this.submitted = true;
    if (this.loginForm.valid) {
      this.showLeaveLoader = true;
        this.authenticationService.loginWithEmail(this.loginForm.value.email, this.loginForm.value.password, this.workspace_token).subscribe((data) => {
          this.showLeaveLoader = false;
          this.submitted = false;
          this.loginForm.reset()
          this.loginModal.close();
        },
        (error) => {
          const errors = error.error || error.statusText;
          Object.keys(errors).map((key) => {
            errors[key].map((message) => {
              this.alert.error(message);
              this.submitted = false;
              this.showLeaveLoader = false;
            })
          })
       })
    }
  }

  getOTP(){
    this.emailVerifySubmit = true;
    this.emailIds = this.emailVerifyForm.value.email
    if (this.emailVerifyForm.valid) {
      this.showLeaveLoader = true;
      this.authenticationService.getOTP(this.emailVerifyForm.value.email).subscribe((data: any) => {
            this.alert.success("OTP sent Successfully!");
            this.showLeaveLoader = false;
            this.emailVerifyForm.reset()
            this.login = false;
            this.emailVerify = false;
            this.forgotpassword = false;
            this.forgotpasswordFields = false;
            this.enterOtp = false;
            this.resetPassword = false;
            this.signup = true;
            this.emailVerifySubmit = false;
          },
          (error) => {
            const errors = error.error || error.statusText;
            Object.keys(errors).map((key) => {
              errors[key].map((message) => {
                  this.alert.error(message);
                  this.emailVerifySubmit = false;
                  this.showLeaveLoader = false;
              })
            })
         });
    }
  }

  forgotPasswordFunction(){
    this.submittedForm = true;
    if (this.forgotPasswordForm.valid) {
      this.showLeaveLoader = true;
      this.authenticationService.getResetPasswordOTP(this.forgotPasswordForm.value.email).subscribe((data: any) => {
            this.alert.success("OTP sent Successfully!");
            this.showLeaveLoader = false;
            this.login = false;
            this.signup = false;
            this.forgotpassword = false;
            this.enterOtp = true;
            this.resetPassword = true;
            this.submittedForm = false;
          },
          (error) => {
            const errors = error.error || error.statusText;
            Object.keys(errors).map((key) => {
              // console.log("finally revealed" + errors[key]);
              errors[key].map((message) => {
                this.alert.error(message);
                this.submittedForm = false;
                this.showLeaveLoader = false;
              })
            })
         });
    }
  }

  signUpFormFunction(){
    this.submitt = true;
    if (this.signUpForm.valid) {
      this.showLeaveLoader = true;
      this.authenticationService.registerWithEmail(this.signUpForm.value.fname, this.signUpForm.value.lname, this.emailIds, this.signUpForm.value.password, this.signUpForm.value.otp, this.workspace_token)
        .subscribe(
          (_data) => {
            this.submitt = false;
            this.showLeaveLoader = false;
            this.signUpForm.reset()
            this.loginModal.close();
          },
          (error) => {
            const errors = error.error || error.statusText;
            Object.keys(errors).map((key) => {
              errors[key].map((message) => {
                this.alert.error(message);
                this.submitt = false;
                this.showLeaveLoader = false;
              })
            })
         });
    }
  }

  resetPasswordFunction(){
    this.passwordSubmit = true;
      if (this.enterOtpForm.valid) {
        this.showLeaveLoader = true;
        this.authenticationService.resetPassword(this.forgotPasswordForm.value.email, this.enterOtpForm.value.otp,
          this.enterOtpForm.value.password, this.enterOtpForm.value.repassword).subscribe((data: any) => {
            this.alert.success("Password reset Successfully!");
            this.showLeaveLoader = false;
            this.passwordSubmit = false;
            this.enterOtpForm.reset()
            this.forgotPasswordForm.reset()
            this.login = true;
            this.signup = false;
            this.forgotpassword = false;
            this.enterOtp = false;
            this.resetPassword = false;
          },
          (error) => {
            const errors = error.error || error.statusText;
            Object.keys(errors).map((key) => {
              // console.log("finally revealed" + errors[key]);
              errors[key].map((message) => {
                this.alert.error(message);
                this.passwordSubmit = false;
                this.showLeaveLoader = false;
              })
            })
         });
        }
      }

  socialLogin(){
    this.loginSignUpText = "Welcome Back!"
    this.showLeaveLoader = false;
    this.socialLoginSection = true;
    this.manualLoginSection = false;
    this.login = true;
    this.emailVerify = false;
    this.forgotpassword = false;
    this.forgotpasswordFields = false;
    this.enterOtp = false;
    this.resetPassword = false;
    this.signup = false;
    this.emailVerifySubmit = false;
    this.showSending = false;
  }

  socialSignUp(){
    this.loginSignUpText = "Let's get started"
    this.showLeaveLoader = false;
    this.socialLoginSection = true;
    this.manualLoginSection = false;
    this.login = false;
    this.emailVerify = true;
    this.forgotpassword = false;
    this.forgotpasswordFields = false;
    this.enterOtp = false;
    this.resetPassword = false;
    this.signup = false;
    this.emailVerifySubmit = false;
    this.showSending = false;
  }

  manualLogin(){
    this.showSending = false;
    this.showLeaveLoader = false;
    this.socialLoginSection = true;
    this.manualLoginSection = false;
    this.login = false;
    this.emailVerify = true;
    this.forgotpassword = false;
    this.forgotpasswordFields = false;
    this.enterOtp = false;
    this.resetPassword = false;
    this.signup = false;
    this.emailVerifySubmit = false;
  }

  showLoginScreen() {
    this.socialLoginSection = true;
    this.manualLoginSection = false;
    this.forgotpassword = false;
    this.forgotpasswordFields = false;
    this.enterOtp = false;
    this.resetPassword = false;
    this.signup = false;
    this.emailVerify = false;
    this.passwordSubmit = false;
    this.submittedForm = false;
    this.emailVerifySubmit = false;
    this.submitt = false;
    this.submitted = false;
    this.login = true;
  }

  showEmailVerifyScreen() {
    this.login = false;
    this.forgotpassword = false;
    this.forgotpasswordFields = false;
    this.enterOtp = false;
    this.resetPassword = false;
    this.signup = false;
    this.passwordSubmit = false;
    this.submittedForm = false;
    this.emailVerifySubmit = false;
    this.submitt = false;
    this.submitted = false;
    this.emailVerify = true;
  }

  showSignUpScreen() {
    this.login = false;
    this.forgotpassword = false;
    this.forgotpasswordFields = false;
    this.enterOtp = false;
    this.resetPassword = false;
    this.emailVerify = false;
    this.passwordSubmit = false;
    this.submittedForm = false;
    this.emailVerifySubmit = false;
    this.submitt = false;
    this.submitted = false;
    this.signup = true;
  }

  forgotScreen(){
    this.loginForm.reset();
    this.login = false;
    this.enterOtp = false;
    this.resetPassword = false;
    this.signup = false;
    this.emailVerify = false;
    this.forgotpasswordFields = false;
    this.passwordSubmit = false;
    this.submittedForm = false;
    this.emailVerifySubmit = false;
    this.submitt = false;
    this.submitted = false;
    this.forgotpassword = true;
  }

  showResetPasswordScreen() {
    this.login = false;
    this.forgotpassword = false;
    this.forgotpasswordFields = false;
    this.enterOtp = false;
    this.emailVerify = false;
    this.signup = false;
    this.passwordSubmit = false;
    this.submittedForm = false;
    this.emailVerifySubmit = false;
    this.submitt = false;
    this.submitted = false;
    this.resetPassword = true;
  }

  getUserPic() {
    this.headerService.getUserPic().subscribe((data: any) => {
      this.userPic = data.user_pic;
    });
  }
}
