import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { User } from "../_models/user";
import { Title, Meta } from '@angular/platform-browser';
import { HomeService } from "../home/home.service";
import { HeaderService } from '../components/header/header.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  totalPng: any;
  totalSvg: any;
  currentPlan: any;
  planBought = [];
  userPic: any;

  constructor(
    private authenticationService: AuthenticationService,
    private titleService: Title,
    private metaTagService: Meta,
    private homeService: HomeService,
    private headerService: HeaderService,
  ) { }
  currentUser: User;
  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x =>{ this.currentUser = x});
    this.titleService.setTitle(this.currentUser.firstName +" "+this.currentUser.lastName +" | Profile");
    this.metaTagService.addTags([
      { name: 'description', content: 'Free vector graphics to add on to your profile pictures by crafttor. Download character illustrations and many more illustrations for your work.'},
      // { name: 'keywords', content: 'Friend illustrations, mail illustration, laptop illustration, working illustration, money illustration, payment illustration, flight illustration' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Crafttor' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);
    this.getUserPic();
    this.getDownloadCountApi();
  }

  getDownloadCountApi() {
    this.homeService.getDownloadCountCall().subscribe((data: any) => {
      this.totalPng = data.total_png;
      this.totalSvg = data.total_svg;
      this.currentPlan = data.plan;
      this.planBought = data.category_bought['data'];
    });
  }

  selectCategory(category) {
    localStorage.setItem('categoryDetails', JSON.stringify({ name: category.category_name,
      image: category.category_icon, description: category.category_description }));
  }

  getUserPic() {
    this.headerService.getUserPic().subscribe((data: any) => {
      this.userPic = data.user_pic;
    });
  }
}
