import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { SharedService } from "../shared.service";
import { HomeService } from "../home/home.service";
import { MemberService } from '../member/member.service';
import { LocationService } from '../location/location.service';
import { NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';
import { Title, Meta } from '@angular/platform-browser';
import{GoogleAnalyticsService} from '../google-analytics.service';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';
import { HeaderService } from '../components/header/header.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ContactUsService } from "../contact-us/contact-us.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReCaptchaV3Service } from 'ngx-captcha';

@Component({
  selector: 'app-hometwo',
  templateUrl: './hometwo.component.html',
  styleUrls: ['./hometwo.component.css']
})
export class HometwoComponent implements OnInit {

title = '2D/3D Illustrations, Icons, graphics | Crafttor';

slideActivate(ngbSlideEvent: NgbSlideEvent) {}

  constructor(
    private sharedService: SharedService,
    private homeService: HomeService,
    private memberService: MemberService,
    private locationService: LocationService,
    private titleService: Title,
    private metaTagService: Meta,
    private googleAnalyticsService: GoogleAnalyticsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private headerService: HeaderService,
    private fb: FormBuilder,
    private contacUsService: ContactUsService,
    private modalService: NgbModal,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private cdr: ChangeDetectorRef,
  ) {}
  categories = [];
  illus = [];
  memberships = [];
  isIndia:boolean = false;
  currentUser: any = null;

  showLeaveLoader: boolean = false;
  showContactErrorMsg: string;
  submitted: Boolean = false;
  public siteKey?: string = '6Lf59_gUAAAAAEJNHrHp1Vdd1LaG1epuOF62yj80';

  @ViewChild('requesticons') requesticons;

  requestIconsForm = this.fb.group({
    name: ['', Validators.required],
    message: ['', Validators.required],
    email: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
  });

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'description', content: 'Unlimited royalty free trendy, high quality illustrations artworks, graphics, character and images for commercial and non commercial websites.'},
      { name: 'keywords', content: 'free illustrations download, free illustrations online, royality free illustrations, free illustrations, freebie, illustration, illustrations, illustrations for free, Graphics, vector illustration' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Crafttor' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);

    this.authenticationService.currentUser.subscribe(x => { this.currentUser = x });
    this.sharedService.preLoaddedCategoriesData$.subscribe((data) => {
      this.categories = [];
      if (data) {
        this.categories = data['results'];
      }
    });
    this.getIllus();
    this.getMemberships();

    let country = this.locationService.getCountry();
    if(!country){
      this.locationService.getPosition().then(pos=>
      {
        // this.locationService.setLocation(pos);
        this.locationService.getGeoLocationGoogle(pos.lat, pos.lng).subscribe(data=>{
          this.locationService.setLocation(data);
          country = this.locationService.getCountry();
          if(country == 'India'){
            this.isIndia = true;
          }
        })
      }).catch(err => {
        this.isIndia = false;
      })
    }
    else{
      if(country == 'India'){
        this.isIndia = true;
      }
    }

  }

  homeSearchIllus(value: string){
    localStorage.setItem('2dDetails', JSON.stringify({ nameValue: value}));
    this.googleAnalyticsService.eventEmitter("Home-Search-Illustrations", "Premium", "button", "click", 10);
  }

  homeMemberShip(){
    this.googleAnalyticsService.eventEmitter("Home-Membership", "Premium", "button", "click", 10);
  }

  public sendPrice(price: string, name: string, image: string, id: any, desc: any){
    localStorage.setItem('priceDetails', JSON.stringify({ name: name,
      price: price, image: image, id: id, desc: desc }));
    this.router.navigate(['/payment']);
  }

  public selectValue(category: any) {
    localStorage.setItem('categoryDetails', JSON.stringify({ name: category.name,
       image: category.image, description: category.description }));
  }

  private getMemberships() {
    this.memberService.getWebSettings('membership').subscribe(
      (data) => {
        this.memberships = data;
      }
    );
  }
  private getIllus() {
    this.homeService.getIllus(4).subscribe((data: any) => {
      this.illus = data['results'];
    })
  }

  loadDataOnly(value: string){
    localStorage.setItem('2dDetails', JSON.stringify({ nameValue: value}));
  }

  public contactUs() {
    this.router.navigate(['contact']);
  }

  public forum() {
    // this.router.navigate(['contact']);
  }

  openRequestIconsModal(modal) {
    this.requesticons = this.modalService.open(modal, { centered: true, windowClass: 'request-icons-popup ' });
  }

  submitIconsRequest() {
    this.submitted = true;
    this.showContactErrorMsg = "";
    if (this.requestIconsForm.valid) {
      this.showLeaveLoader = true;
      this.reCaptchaV3Service.execute(this.siteKey, 'contact_us', (token) => {
        this.requestIconsForm.value['recaptcha'] = token;
        this.cdr.detectChanges();
        this.contacUsService.contactUsIcons(this.requestIconsForm.value['name'],
        this.requestIconsForm.value['email'], this.requestIconsForm.value['message'],
        this.requestIconsForm.value['recaptcha']).subscribe((data) => {
          this.showLeaveLoader = false;
          this.submitted = false;
          this.requestIconsForm.reset();
          this.requesticons.close();
        },
        (error) => {
          if(error.status == 400){
            this.showLeaveLoader = false;
            this.showContactErrorMsg = error.error.message;
          } else {
            this.showLeaveLoader = false;
            this.showContactErrorMsg = error.error.message;
          }
        });
      });
    }
  }
}
