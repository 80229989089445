import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SketchService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  sketchVersion = 'sketch-version'
  public getSketchVersion() {
    const api_root = environment.REST_API_SERVER;
    let url = api_root + this.sketchVersion + "/";

    return this.httpClient.get(url).pipe(map(data => {
      return data;
    }));
  }

  public downloadCount() {
    const api_root = environment.REST_API_SERVER;
    let url = api_root + 'sketch-version/1/save_count/';

    return this.httpClient.get(url).pipe(map(data => {
      return data;
    }));
  }
}
