import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MemberService } from "../member/member.service";
import { LocationService } from "../location/location.service";
import { HeaderService } from '../components/header/header.service';
import { AuthenticationService } from '../authentication.service';
import { Payment } from '../_models/payment';
import { environment } from '../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { WindowRefService } from '../window-ref.service';
import{GoogleAnalyticsService} from '../google-analytics.service';
import { AuthService } from 'angularx-social-login';
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {

  title = 'Premium Membership | Be a premium member';

  token: any;
  noShowSavings: boolean = false;
  strikeCheckout:any = null;
  memberships = [];
  paymentSelected: boolean = false;
  payNow: boolean = false;
  mainSection: boolean = true;
  public radioYearly: boolean = true;
  public radioMontly: boolean = false;
  currentUser: any = null;
  selectedMembership: any = null;
  selectedMembershipBuyNow: any = null;
  payment: Payment;
  public isIndia: boolean = false;
  private month_membership: any = [];
  private year_membership: any = [];
  @ViewChild('paymentSuccess') paymentSuccess;
  paymentSuccessModal: any;
  address: string = '124 santa street';
  city: string = 'San Francisco';
  state: string = 'CA';
  country: string = 'US';
  postcode: string = '94016';
  mobileView: boolean=false;
  loginModal: any;
  returnUrl: any;
  userPic: any;

  public lat;
  public lng;

  constructor(
    private memberService: MemberService,
    private headerService: HeaderService,
    private authenticationService: AuthenticationService,
    private authService: AuthService,
    private locationService: LocationService,
    private modalService: NgbModal,
    public router: Router,
    private titleService: Title,
    private metaTagService: Meta,
    private winRef: WindowRefService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'description', content: 'Free access to unique vector illustrations to create anything without any blocker to your creative thoughts.'},
      { name: 'keywords', content: 'Free illustrations, clean pixel illustrations, premium illustration, ' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Crafttor' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);

    this.memberService.getWebSettings('membership').subscribe(
      (data) => {
        // console.log('*****', data)

        for (let membership of data) {
          if (!membership.valid) {
            this.year_membership.push(membership);
            this.month_membership.push(membership);
          }
          else if (membership.valid.years == 0 && membership.valid.days == 0) {
            this.month_membership.push(membership);
          }
          else {
            this.year_membership.push(membership);
          }
          // else if(membership.valid)
        }
        this.memberships =  this.year_membership;
      }
    );

    this.authService.authState.subscribe((user) => {
      if (user && !this.authenticationService.currentUserValue) {
        this.loginToServer(user, user.photoUrl);
        // this.paymentSelected = true;
        // this.mainSection = false;
        // this.selectedMembership = this.selectedMembershipBuyNow;
      }
    });

    this.authenticationService.currentUser.subscribe(x => { this.currentUser = x });
    if (window.screen.width <= 991) { // 768px portrait
      this.mobileView = true;
    }else{
      this.mobileView = false;
    }

    if(this.currentUser) {
      this.getUserPic();
    }
    this.selectedMembership = null;
    let country = this.locationService.getCountry();
    if (!country) {
      this.locationService.getPosition().then(pos => {
        // this.locationService.setLocation(pos);
        this.locationService.getGeoLocationGoogle(pos.lat, pos.lng).subscribe(data => {
          this.locationService.setLocation(data);
          country = this.locationService.getCountry();
          if (country == 'India') {
            this.isIndia = true;
          }
        })
      }).catch(err => {
        this.isIndia = false;
      })
    }
    else {
      if (country == 'India') {
        this.isIndia = true;
      }
    }

    this.getLocation();
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: Position) => {
        if (position) {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
        }
      },
        (error: PositionError) => console.log(error));
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  downloadIllus(){
    this.googleAnalyticsService.eventEmitter("Membership-Download", "Premium", "button", "click", 10);
  }

  showPayment(membership: any) {
    window.scrollTo(0, 0);
    this.selectedMembershipBuyNow = membership;
    if (this.currentUser) {
      this.paymentSelected = true;
      this.mainSection = false;
      this.selectedMembership = membership;
      // console.log(membership)
    } else {

      this.headerService.setLoginModalValue(true);
    }
    this.googleAnalyticsService.eventEmitter(membership.text+"-Pricing-page", "Premium", "button", "click", membership.amount_text.INR.now);
  }

  showSuccess() {
    this.payNow = false;
    this.paymentSelected = false;
    this.mainSection = true;
    this.paymentSuccessModal = this.modalService.open(this.paymentSuccess, { windowClass: 'product_view' })
    this.googleAnalyticsService.eventEmitter("Membership-Paid-Member", "Premium", "button", "click", 10);
  }

  closeSuccessModal() {
    this.paymentSuccessModal.close();
  }

  checkout(selectedMembership: any) {
    // this.showSuccess();
    // return;
    this.payNow = true;
    this.paymentSelected = false;
    this.mainSection = false;
  }

  yearly(){
    this.noShowSavings = false;
    this.memberships = this.year_membership;
  }
  monthly(){
    this.noShowSavings = true;
    this.memberships = this.month_membership;
  }

  checkoutStripe(amount, currency, membership) {
    const strikeCheckout = (<any>window).StripeCheckout.configure({
      key: environment.STRIPE_LIVE_KEY,
      locale: 'auto',
      token: (stripeToken: any) => {
        // console.log(stripeToken)
        this.memberService.postStripeOrder(stripeToken, amount, currency, membership.order, membership.text, 'general', this.currentUser.firstName+" "+ this.currentUser.lastName, this.address, this.city, this.state, this.country, this.postcode).subscribe((data) => {
          this.showSuccess();
        });
      }
    });

    strikeCheckout.open({
      name: 'Crafttor',
      description: 'Stripe Payment Gateway',
      image: 'https://crafttor.com/crafttor.jpg',
      currency: currency,
      amount: amount * 100
    });
  }

stripePaymentGateway() {
  if(!window.document.getElementById('stripe-script')) {
    const scr = window.document.createElement("script");
    scr.id = "stripe-script";
    scr.type = "text/javascript";
    scr.src = "https://checkout.stripe.com/checkout.js";

    scr.onload = () => {
      this.strikeCheckout = (<any>window).StripeCheckout.configure({
        key: environment.STRIPE_LIVE_KEY,
        locale: 'auto',
        token: function (token: any) {
        console.log(token)
        alert('Payment via stripe successfull!');
        }
      });
    }
    window.document.body.appendChild(scr);
    }
  }
// }

  // createRzpayOrder(amount, data) {
  //   console.log(data);
  //   // call api to create order_id
  //   this.payWithRazor(amount*100, data, "INR");
  // }
  //
  // payWithRazor(amount, membership, currenyIndia) {
  //   const options: any = {
  //     key: environment.RAZORPAY_TEST_KEY,
  //     amount: amount, // amount should be in paise format to display Rs 1255 without decimal point
  //     currency: 'INR',
  //     name: 'Crafttor', // company name or product name
  //     description: 'Discover the best digital resources',  // product description
  //     image: 'https://crafttor.com/crafttor.jpg', // company logo or product image
  //     // order_id: orderId, // order_id created by you in backend
  //     modal: {
  //       // We should prevent closing of the form when esc key is pressed.
  //       escape: false,
  //     },
  //     notes: {
  //       // include notes if any
  //     },
  //     theme: {
  //       color: '#0c238a'
  //     }
  //   };
  //   options.handler = ((response, error) => {
  //     options.response = response;
  //     console.log("response data: "+response);
  //     console.log("option data: "+options);
  //     // call your backend api to verify payment signature & capture transaction
  //     // this.memberService.postRazorPayOrder(response, amount, currenyIndia, membership.order, membership.text, this.currentUser.firstName+" "+ this.currentUser.lastName, this.address, this.city, this.state, this.country, this.postcode).subscribe((data) => {
  //     //   this.showSuccess();
  //     // });
  //   });
  //   options.modal.ondismiss = (() => {
  //     // handle the case when user closes the form while transaction is in progress
  //     console.log('Transaction cancelled.');
  //   });
  //   const rzp = new this.winRef.nativeWindow.Razorpay(options);
  //   rzp.open();
  // }

  open(content: any) {
    this.loginModal = this.modalService.open(content, { centered: true, windowClass: 'login-modal' })
  }

  openClose() {
    this.loginModal.close();
  }


  loginToServer(user: any = null, photoUrl: string) {
    this.authenticationService.login(user.provider.toLowerCase(), user.id,
    user.authToken, user.email, user.firstName,
    user.lastName, photoUrl, this.lat, this.lng)
      .subscribe(
        (_data) => {
          if (this.returnUrl) {
            this.router.navigate([this.returnUrl]);
          }
          this.loginModal.close();
        }
      );
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    // this.loginToServer();
  }

  fbSignOut(): void {
    this.authService.signOut();
  }

  logOut(): void {
    this.authenticationService.logout();
    this.authService.signOut();
  }

  openLoginModal(){
    this.headerService.setLoginModalValue(true);
  }

  getUserPic() {
    this.headerService.getUserPic().subscribe((data: any) => {
      this.userPic = data.user_pic;
    });
  }
}
