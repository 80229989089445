<!-- Blog Section -->
<section class="blog-section" *ngIf="blogs">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="blog-box">
					<div class="thumb">
						<a href="javascript:;" [routerLink]="['/blog', latestBlog.url_slug]"><img [src]="latestBlog.image_rec"></a>
					</div>
					<div class="desc">
						<div class="meta">
							<!-- <span class="date">{{latestBlog.date}}</span> -->
							<a href="javascript:;">{{latestBlog.author}}</a>
							<!-- <a href="javascript:;">Tools</a> -->
						</div>
						<h3><a href="javascript:;" [routerLink]="['/blog', latestBlog.url_slug]">{{latestBlog.title}}</a></h3>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>


<section class="polpular-blog-section" *ngIf="blogs">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<h2>Popular Blogs</h2>
			</div>
			<div class="col-sm-4" *ngFor="let blog of popularBlogs">
				<div class="blog-box">
					<div class="thumb">
						<a href="javascript:;" [routerLink]="['/blog', blog.url_slug]"><img [src]="blog.image_sqr" class="zoom-image"></a>
					</div>
					<div class="desc">
						<div class="meta">
							<!-- <span class="date">{{blog.date}}</span> -->
							<a href="javascript:;">{{blog.author}}</a>
							<!-- <a href="javascript:;">Tools</a> -->
						</div>
						<h3><a href="javascript:;" [routerLink]="['/blog', blog.url_slug]">{{blog.title}}</a></h3>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="newsletter-form-section" *ngIf="blogs">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="form-news">
					<div class="thumb"><img src="assets/img/mail-box.png"></div>
					<div class="desc">
						<h2>Any suggestions?</h2>
						<h4>Email us at <a href="mailto:info@crafttor.com"><i aria-hidden="true"></i> info@crafttor.com</a></h4>
						<!-- <h2>Email us at info@crafttor.com to get your blog at Crafttor's blog section.</h2> -->
						<!-- <form>
							<input type="text">
							<button type="submit" class="button">Subscribe</button>
						</form> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="polpular-blog-section recent-blog" *ngIf="blogs">
	<div class="container" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollUpDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScrollDown()">
		<div class="row">
			<div class="col-sm-12">
				<h2>Recent Blogs</h2>
			</div>
			<div class="col-sm-4" *ngFor="let blog of recentBlogs">
				<div class="blog-box">
					<div class="thumb">
						<a href="javascript:;" [routerLink]="['/blog', blog.url_slug]"><img [src]="blog.image_sqr" class="zoom-image"></a>
					</div>
					<div class="desc">
						<div class="meta">
							<!-- <span class="date">{{blog.date}}</span> -->
							<a href="javascript:;" >{{blog.author}}</a>
							<!-- <a href="javascript:;">Tools</a> -->
						</div>
						<h3><a href="javascript:;" [routerLink]="['/blog', blog.url_slug]">{{blog.title}}</a></h3>
					</div>
				</div>
			</div>
			<img src="assets/img/loader.gif" *ngIf="paginationLoading" id="loadMorevector" href="javascript:;" style="display: block;margin-left: auto;margin-right: auto; margin-top:40px; width: 70px; height:60px;">

			<div class="col-sm-12">
				<!-- <a class="load-more button" href="javascript:;">Load More</a> -->
			</div>

		</div>
	</div>
</section>
