<section class="community-section community-background-design">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
				<h1>Kaleidoscope is now available on <span class="freelaner-txt">Figma</span></h1>
				<p>A simple plugin that adds kaleidoscopic style from your own photos and materials to your projects, enhancing your design appearance.</p>
				<div class="btn-wrapper">
					<a href="https://www.figma.com/community/plugin/1408354139656199596/kaleidoscope" target="_blank" class="button gray-btn">
						<img style="width:16px; height:20px; margin-right: 15px; " class="btnimg" src="assets/img/figma.svg" alt="" />
						Install Plugin</a>
				</div>
      </div>
    </div>
  </div>
</section>

<section class="figma-hero-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<img class="kaleidoscope-img" src="assets/img/kaleidoscope.png" alt="">

        <h2>Kaleidoscope Plugin</h2>
        <p>Design your own styles with our kaleidoscope plugin and change your design appearance.</p>
			</div>
		</div>
	</div>
</section>

<section class="figma-hero-section" style="background: #ffffff;">
	<div class="container">
    <div class="row before-after-section">
      <div class="col-sm-1">

      </div>
      <div class="col-sm-5 white-back-temp">
        <h5>Before Kaleidoscope</h5>
        <ul>
          <li class="red-before">
            <img src="assets/img/cross-red.svg" alt="">
            Manual Pattern Creation.
          </li>

          <li class="red-before">
            <img src="assets/img/cross-red.svg" alt="">
            Regular old school styles.
          </li>

          <li class="red-before">
            <img src="assets/img/cross-red.svg" alt="">
            Limited Design Options.
          </li>
        </ul>
      </div>

      <div class="col-sm-5 white-back-temp">
        <h5>After Kaleidoscope</h5>
        <ul>
          <li class="green-after">
            <img src="assets/img/tick-green.svg" alt="">
            Instant Pattern Generation.
          </li>

          <li class="green-after">
            <img src="assets/img/tick-green.svg" alt="">
            Extract new styles from the current design.
          </li>

          <li class="green-after">
            <img src="assets/img/tick-green.svg" alt="">
            Versatile Design Options.
          </li>
        </ul>
      </div>
      <div class="col-sm-1">

      </div>
    </div>
	</div>
</section>

<section class="community-section">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
				<h3>Add a Unique Twist to Your Designs with Kaleidoscope Plugin</h3>
				<p>Kaleidoscope Plugin allows you to effortlessly generate mesmerizing patterns from your own photos and materials. Transform ordinary visuals into stunning designs and elevate the aesthetic appeal of your work.</p>
      </div>
    </div>
  </div>
</section>

<section class="kaleidoscope-video-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-7 video-area">
        <video autoplay loop [muted]="'muted'" class="kaleidoscope-img" controls>
          <source src="assets/img/kaleidoscope-video.mp4" type="video/mp4">
        </video>
			</div>

      <div class="col-sm-1">
			</div>

      <div class="col-sm-4 video-text-area">
        <h2>Boost Your Visuals: Here's the Features Walkthrough</h2>
        <p>Check the plugin demo and give it a try to reimagine some new patterns.</p>
			</div>
		</div>
	</div>
</section>

<section class="figma-content-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-content">
					<h1>A new way to learn and design</h1>
					<p>Loved by designers from</p>
				</div>
			</div>
      <ul>
        <li></li>
        <li>
          <img class="air-lic" src="assets/img/airtel.svg" alt="">
        </li>

        <li>
          <img class="air-lic" src="assets/img/licious.svg" alt="">
        </li>

        <li>
          <img class="del-whe" src="assets/img/deloitte.svg" alt="">
        </li>

        <li>
          <img class="del-whe" src="assets/img/wheels.svg" alt="">
        </li>
        <li></li>
      </ul>
		</div>
	</div>
</section>

<!-- Big CTA Section -->
<section class="cta-section">
	<div class="container">
		<div class="inner-cta">
			<div class="row">
				<div class="col-md-7">
					<h2>Extract new design patterns from your current design with Kaleidoscope Figma plugin and style your UI.</h2>
				</div>
				<div class="col-md-5">
					<div class="btn-wrap">
						<a href="https://www.figma.com/community/plugin/1408354139656199596/kaleidoscope" target="_blank" class="button gray-btn">
							<img style="width:16px; height:20px; margin-right: 15px; " class="btnimg" src="assets/img/figma.svg" alt="" />
							Install Plugin</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="kaleidoscope-description-section">
	<div class="container">
		<div class="row">
      <div class="col-sm-3">
			</div>

			<div class="col-sm-6 graphic-section">
        <img src="assets/img/sheild.svg" alt="">
				<h2>Secure and Reliable</h2>
        <p>All our Figma plugins are built with security and reliability in mind — your data remains protected and stays securely within your Figma file.</p>
			</div>

      <div class="col-sm-3">
			</div>
		</div>
	</div>
</section>

<section class="plugin-design-area">
  <div class="container">
    <div class="row">
      <ul>
        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/crafttor.png" alt="" style="border-radius: 4px;">
              <h2>Crafttor</h2>
            </div>
            <p>Workspace & Marketplace offers access to number of illustrations and icons, upload and download options.<br></p>

            <a routerLink="/figma"><button type="button" name="button">Try Crafttor <br><span>Get started today</span></button></a>
          </div>
        </li>

        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/iso.png" alt="">
              <h2>Iso All</h2>
            </div>
            <p>Iso All seems to be a tool or feature that simplifies and enhance the creation of isometric designs.</p>
            <a routerLink="/iso-all"><button type="button" name="button">Try Iso All <br><span>Get started today</span></button></a>
          </div>
        </li>

        <li>
          <div class="plugin-details">
            <div class="img-txt">
              <img src="assets/img/trans.png" alt="">
              <h2>Translate AI</h2>
            </div>
            <p>Translate AI empowers you to effortlessly translate content within your designs using the power of AI.<br></p>
            <a routerLink="/translate-ai"><button type="button" name="button">Try Translate AI <br><span>Get started today</span></button></a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</section>

<!-- FAQs Section start-->
<section class="faq-section-other-pages">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="inner-content">
					<h6>FAQs</h6>
					<hr>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="faq-section design-faq">
	<div class="container">
		<div class="row">
      <div class="col-sm-12 right-question">
				<div class="inner-content">
					<h2>What is Kaleidoscope Plugin?</h2>
					<p>Kaleidoscope is a simple plugin that adds kaleidoscopic style from your own photos and materials to your projects, enhancing your design appearance. You can extract a new design pattern from a current design pattern.</p>
					<hr>
				</div>
			</div>
      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>How many patterns can be extracted from the Kaleidoscope Plugin?</h2>
          <p>The number of patterns that can be extracted from the Kaleidoscope Plugin depends on the input images and the parameters set by the user, such as symmetry, rotation, and color manipulation. Since the plugin uses images to generate unique patterns, the possibilities are virtually limitless, offering an endless range of pattern combinations.</p>
          <hr>
        </div>
      </div>
      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>What file formats does the Kaleidoscope Plugin support?</h2>
          <p>The plugin supports common image formats like JPEG, PNG, and SVG.</p>
          <hr>
        </div>
      </div>
      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>Can I use the Kaleidoscope Plugin with vector images?</h2>
          <p>Yes, vector images are supported, allowing for high-quality pattern outputs.</p>
          <hr>
        </div>
      </div>
      <div class="col-sm-12 right-question">
        <div class="inner-content">
          <h2>Is Crafttor Kaleidoscope Plugin compatible with both Figma and Sketch?</h2>
          <p>No, the plugin is fully compatible with Figma, making it easy to integrate into your design workflow. A Sketch version of the Kaleidoscope Plugin is in the pipeline.</p>
          <hr>
        </div>
      </div>
      <div class="col-sm-12 right-question faq-bottom">
        <div class="inner-content">
          <h2>Can I apply the generated patterns to multiple design projects?</h2>
          <p>Absolutely! The patterns you create can be applied across a wide range of design projects, from illustrations to user interfaces.</p>
          <hr>
        </div>
      </div>
    </div>
	</div>
</section>
<!-- FAQs Section END -->
