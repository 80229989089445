import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MemberService } from "./member.service";
import { LocationService } from "../location/location.service";
import { HeaderService } from '../components/header/header.service';
import { AuthenticationService } from '../authentication.service';
import { Payment } from '../_models/payment';
import { environment } from '../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { WindowRefService } from '../window-ref.service';
import{GoogleAnalyticsService} from '../google-analytics.service';
import { AuthService } from 'angularx-social-login';
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService } from "../components/alert/alert.service";

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.css'],
  providers: [WindowRefService]
})
export class MemberComponent implements OnInit {

  title = 'Premium Membership | Be a premium member';

  token: any;
  noShowSavings: boolean = false;
  strikeCheckout:any = null;
  memberships = [];
  paymentSelected: boolean = false;
  payNow: boolean = false;
  mainSection: boolean = true;
  public radioYearly: boolean = true;
  public radioMontly: boolean = false;
  currentUser: any = null;
  selectedMembership: any = null;
  selectedMembershipBuyNow: any = null;
  payment: Payment;
  public isIndia: boolean = false;
  private month_membership: any = [];
  private year_membership: any = [];
  @ViewChild('paymentSuccess') paymentSuccess;
  paymentSuccessModal: any;
  address: string = '124 santa street';
  city: string = 'San Francisco';
  state: string = 'CA';
  country: string = 'US';
  postcode: string = '94016';
  loginModal: any;
  returnUrl: any;

  showloaderStyle = "none";

  socialLoginSection: boolean = true;
  socialLoginManualSection: boolean = false;
  manualLoginSection: boolean = false;
  submitted: Boolean = false;
  submitt: Boolean = false;
  submittedForm: Boolean = false;
  submittion: Boolean = false;
  emailVerifySubmit: Boolean = false;
  passwordSubmit: Boolean = false;
  showSendingSmall: boolean = false;
  login: boolean = false;
  emailVerify: boolean = false;
  signup: boolean = false;
  forgotpassword: boolean = false;
  forgotpasswordFields: boolean = false;
  enterOtp: boolean = false;
  resetPassword: boolean = false;
  showSending: boolean = false;
  emailIds: string;
  workspace_token: any;
  showLeaveLoader: boolean = false;
  loginSignUpText: string;
  showCouponCodeError: boolean = false;
  couponCodeErrorMsg: any;
  couponBtnText: any = "Apply";
  detailsYearly: boolean = true;
  detailsMonthly: boolean = true;
  couponcode: any;
  orderId: any;
  totalRazorAmount: any;
  userRazorPayName: string;
  razorPayKey: string;
  userName: string;
  showTopRightLoader: boolean = false;
  sendSuccessful: boolean = false;
  sendSuccessfulErrorMsg: boolean = false;
  sendSuccessfulErrorMessage: string;
  successfullMessage: string;

  constructor(
    private memberService: MemberService,
    private headerService: HeaderService,
    private authenticationService: AuthenticationService,
    private authService: AuthService,
    private locationService: LocationService,
    private modalService: NgbModal,
    public router: Router,
    private titleService: Title,
    private metaTagService: Meta,
    private winRef: WindowRefService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private fb: FormBuilder,
    private alert: AlertService,
  ) { }

  loginForm = this.fb.group({
    email: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
    password: ['', Validators.required],
  });

  forgotPasswordForm = this.fb.group({
    email: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
  });

  enterOtpForm = this.fb.group({
    otp: ['', Validators.required],
    password: ['', Validators.required],
    repassword: ['', Validators.required],
  });

  emailVerifyForm = this.fb.group({
    email: ['', [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"), Validators.required]],
  });

  signUpForm = this.fb.group({
    fname: ['', Validators.required],
    lname: ['', Validators.required],
    password: ['', Validators.required],
    otp: ['', Validators.required],
  });

  resetPasswordForm = this.fb.group({
    otp: ['', Validators.required],
    password: ['', Validators.required],
    repassword: ['', Validators.required],
  });

  @ViewChild('loginmodal') loginmodal;

  public lat;
  public lng;

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'description', content: 'Free access to unique vector illustrations to create anything without any blocker to your creative thoughts.'},
      { name: 'keywords', content: 'Free illustrations, clean pixel illustrations, premium illustration, ' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Crafttor' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);

    this.memberService.getWebSettings('membership').subscribe(
      (data) => {
        // console.log('*****', data)

        for (let membership of data) {
          if (!membership.valid) {
            this.year_membership.push(membership);
            this.month_membership.push(membership);
          }
          else if (membership.valid.years == 0 && membership.valid.days == 0) {
            this.month_membership.push(membership);
          }
          else {
            this.year_membership.push(membership);
          }
          // else if(membership.valid)
        }
        this.memberships =  this.year_membership;
      }
    );

    // this.authService.authState.subscribe((user) => {
    //   if (user && !this.authenticationService.currentUserValue) {
    //     this.loginToServer(user, user.photoUrl);
    //     this.paymentSelected = true;
    //     this.mainSection = false;
    //     this.selectedMembership = this.selectedMembershipBuyNow;
    //   }
    // });

    this.authenticationService.currentUser.subscribe(x => { this.currentUser = x });
    this.selectedMembership = null;
    let country = this.locationService.getCountry();
    if (!country) {
      this.locationService.getPosition().then(pos => {
        // this.locationService.setLocation(pos);
        this.locationService.getGeoLocationGoogle(pos.lat, pos.lng).subscribe(data => {
          this.locationService.setLocation(data);
          country = this.locationService.getCountry();
          if (country == 'India') {
            this.isIndia = true;
          }
        })
      }).catch(err => {
        this.isIndia = false;
      })
    }
    else {
      if (country == 'India') {
        this.isIndia = true;
      }
    }
    this.getLocation();
  }

    getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: Position) => {
        if (position) {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
        }
      },
        (error: PositionError) => console.log(error));
    } else {
      // alert("Geolocation is not supported by this browser.");
    }
  }

  downloadIllus(){
    this.googleAnalyticsService.eventEmitter("Membership-Download", "Premium", "button", "click", 10);
  }

  showPayment(membership: any) {
    window.scrollTo(0, 0);
    this.selectedMembershipBuyNow = membership;
    if (this.currentUser) {
      this.paymentSelected = true;
      this.mainSection = false;
      this.selectedMembership = membership;
      // console.log(membership)
    } else {
      this.headerService.setLoginModalValue(true);
      // this.socialLogin();
      // this.loginModal = this.modalService.open(this.loginmodal, { centered: true, windowClass: 'login-modal' })
    }
    this.googleAnalyticsService.eventEmitter(membership.text, "Premium", "button", "click", membership.amount_text.INR.now);
  }

  openClose() {
    this.loginmodal.close();
  }

  showSuccess() {
    this.payNow = false;
    this.paymentSelected = false;
    this.mainSection = true;
    this.paymentSuccessModal = this.modalService.open(this.paymentSuccess, { windowClass: 'product_view' })
    this.googleAnalyticsService.eventEmitter("Membership-Paid-Member", "Premium", "button", "click", 10);
  }

  closeSuccessModal() {
    this.paymentSuccessModal.close();
  }

  checkout(selectedMembership: any) {
    // this.showSuccess();
    // return;
    this.payNow = true;
    this.paymentSelected = false;
    this.mainSection = false;
  }

  yearly(){
    this.noShowSavings = false;
    this.memberships = this.year_membership;
  }
  monthly(){
    this.noShowSavings = true;
    this.memberships = this.month_membership;
  }

  // redirectToCheckout() {
  //   const stripe = Stripe(environment.STRIPE_LIVE_KEY);
  //
  //   stripe
  //     .confirmCardPayment('{PAYMENT_INTENT_CLIENT_SECRET}', {
  //       payment_method: {
  //         // card: cardElement,
  //         billing_details: {
  //           name: '',
  //         },
  //       },
  //     })
  //     .then(function(result) {
  //       // Handle result.error or result.paymentIntent
  //   });
  // }

  checkoutStripe(amount, currency, membership) {
    const strikeCheckout = (<any>window).StripeCheckout.configure({
      key: environment.STRIPE_LIVE_KEY,
      locale: 'auto',
      token: (stripeToken: any) => {
        // console.log(stripeToken)
        this.showloaderStyle = "block";
        this.memberService.postStripeOrder(stripeToken, amount, currency, membership.order, membership.text, 'general', this.currentUser.firstName+" "+ this.currentUser.lastName, this.address, this.city, this.state, this.country, this.postcode).subscribe((data) => {
          this.showloaderStyle = "none";
          this.showSuccess();
        });
      }
    });

    strikeCheckout.open({
      name: 'Crafttor',
      description: 'Stripe Payment Gateway',
      image: 'https://crafttor.com/crafttor.jpg',
      currency: currency,
      amount: amount * 100
    });
  }

stripePaymentGateway() {
  if(!window.document.getElementById('stripe-script')) {
    const scr = window.document.createElement("script");
    scr.id = "stripe-script";
    scr.type = "text/javascript";
    scr.src = "https://checkout.stripe.com/checkout.js";

    scr.onload = () => {
      this.strikeCheckout = (<any>window).StripeCheckout.configure({
        key: environment.STRIPE_LIVE_KEY,
        locale: 'auto',
        token: function (token: any) {
        // console.log(token)
        alert('Payment via stripe successfull!');
        }
      });
    }
    window.document.body.appendChild(scr);
    }
  }
// }

  createRzpayOrder(amount: any, membership: any) {
    this.userName = this.currentUser.firstName + " " + this.currentUser.lastName
    this.memberService.getAmountRazor(this.userName, amount, membership.order).subscribe((data: any) => {
      this.orderId = data.razorpay_order_id;
      this.totalRazorAmount = data.razorpay_amount;
      this.razorPayKey = data.razorpay_merchant_key;
      this.payWithRazor(this.orderId, this.razorPayKey, this.totalRazorAmount, membership);
    },(error: any) => {
      if(error.status == 400){
        this.alert.error(error.error.message)
      } else {
        this.alert.error(error.error.message)
      }
   });
  }

  payWithRazor(val: any, key: any, totalAmount: any, membership: any) {
    let self = this;
    this.showTopRightLoader = true;
    // localStorage.setItem('paymentRazorDetails', JSON.stringify({ totalAmount: amount}));
    const options: any = {
      key: key,
      amount: totalAmount*100,
      currency: 'USD',
      name: 'Crafttor', // company name or product name
      description: '',  // product description
      image: 'https://crafttor.com/crafttor.jpg', // company logo or product image
      order_id: val, // order_id created by you in backend
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: '#0c238a'
      }
    };
    var that = this;
    options.handler = ((response, error) => {
      if (error) {
        this.showTopRightLoader = false;
        console.error('Payment Error:', error);
        return; // Exit the handler on error
      }
      options.response = response;

      this.showTopRightLoader = true;
      setTimeout(() => {
        this.memberService.postRazorPayOrder(response,
          totalAmount, "USD", membership.order, membership.text,
          this.currentUser.firstName+" "+ this.currentUser.lastName,
          this.address, this.city, this.state, this.country, this.postcode,
          response.razorpay_payment_id, response.razorpay_order_id,response.razorpay_signature).subscribe((data) => {
             this.showTopRightLoader = false;
             this.showSuccess();
        },(error: any) => {
          if(error.status == 400){
            this.showTopRightLoader = false;
            this.sendSuccessfulErrorMsg = true;
            this.sendSuccessfulErrorMessage = error.error.message;
            this.hideSuccesfullFunctionErrorDelete();
          } else {
            this.showTopRightLoader = false;
            this.sendSuccessfulErrorMsg = true;
            this.sendSuccessfulErrorMessage = error.error.message;
            this.hideSuccesfullFunctionErrorDelete();
          }
       });
      }, 100); // Adjust delay as necessary
    });
    options.modal.ondismiss = (() => {
      console.log('Transaction cancelled.');
      this.showTopRightLoader = false;
    });
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();

    // Use setTimeout as a fallback to ensure the loader is hidden
    // Ensure the loader is hidden if the Razorpay instance is closed
    const checkDialogClosed = () => {
        setTimeout(() => {
            // Hide the loader if the dialog is closed
            if (!document.querySelector('.razorpay-container')) {
                this.showTopRightLoader = false;
            } else {
                checkDialogClosed(); // Keep checking
            }
        }, 500); // Check every 500 ms
    };

    // Start checking if the dialog is closed
    checkDialogClosed();
  }

  hideSuccesfullFunctionErrorDelete(){
    setTimeout(() => {
      this.sendSuccessfulErrorMsg = false;
    }, 5000);
  }

  loginToServer(user: any = null, photoUrl: string) {
    this.authenticationService.login(user.provider.toLowerCase(), user.id,
    user.authToken, user.email, user.firstName, user.lastName,
    photoUrl, this.lat, this.lng)
      .subscribe(
        (_data) => {
          if (this.returnUrl) {
            this.router.navigate([this.returnUrl]);
          }
          this.loginmodal.close();
        }
      );
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
    // this.loginToServer();
  }

  fbSignOut(): void {
    this.authService.signOut();
  }

  logOut(): void {
    this.authenticationService.logout();
    this.authService.signOut();
  }

  openLoginModal(){
    // this.socialLogin();
    this.headerService.setLoginModalValue(true);
  }

  loginFunction(){
    this.submitted = true;
    if (this.loginForm.valid) {
      this.showLeaveLoader = true;
        this.authenticationService.loginWithEmail(this.loginForm.value.email, this.loginForm.value.password, this.workspace_token).subscribe((data) => {
          this.showLeaveLoader = false;
          this.submitted = false;
          this.loginForm.reset()
          this.loginmodal.close();
        },
        (error) => {
          const errors = error.error || error.statusText;
          Object.keys(errors).map((key) => {
            errors[key].map((message) => {
              this.alert.error(message);
              this.submitted = false;
              this.showLeaveLoader = false;
            })
          })
       })
    }
  }

  getOTP(){
    this.emailVerifySubmit = true;
    this.emailIds = this.emailVerifyForm.value.email
    if (this.emailVerifyForm.valid) {
      this.showLeaveLoader = true;
      this.authenticationService.getOTP(this.emailVerifyForm.value.email).subscribe((data: any) => {
            this.alert.success("OTP sent Successfully!");
            this.showLeaveLoader = false;
            this.emailVerifyForm.reset()
            this.login = false;
            this.emailVerify = false;
            this.forgotpassword = false;
            this.forgotpasswordFields = false;
            this.enterOtp = false;
            this.resetPassword = false;
            this.signup = true;
            this.emailVerifySubmit = false;
          },
          (error) => {
            const errors = error.error || error.statusText;
            Object.keys(errors).map((key) => {
              errors[key].map((message) => {
                  this.alert.error(message);
                  this.emailVerifySubmit = false;
                  this.showLeaveLoader = false;
              })
            })
         });
    }
  }

  forgotPasswordFunction(){
    this.submittedForm = true;
    if (this.forgotPasswordForm.valid) {
      this.showLeaveLoader = true;
      this.authenticationService.getResetPasswordOTP(this.forgotPasswordForm.value.email).subscribe((data: any) => {
            this.alert.success("OTP sent Successfully!");
            this.showLeaveLoader = false;
            this.login = false;
            this.signup = false;
            this.forgotpassword = false;
            this.enterOtp = true;
            this.resetPassword = true;
            this.submittedForm = false;
          },
          (error) => {
            const errors = error.error || error.statusText;
            Object.keys(errors).map((key) => {
              // console.log("finally revealed" + errors[key]);
              errors[key].map((message) => {
                this.alert.error(message);
                this.submittedForm = false;
                this.showLeaveLoader = false;
              })
            })
         });
    }
  }

  signUpFormFunction(){
    this.submitt = true;
    if (this.signUpForm.valid) {
      this.showLeaveLoader = true;
      this.authenticationService.registerWithEmail(this.signUpForm.value.fname, this.signUpForm.value.lname, this.emailIds, this.signUpForm.value.password, this.signUpForm.value.otp, this.workspace_token)
        .subscribe(
          (_data) => {
            this.submitt = false;
            this.showLeaveLoader = false;
            this.signUpForm.reset()
              this.loginmodal.close();
          },
          (error) => {
            const errors = error.error || error.statusText;
            Object.keys(errors).map((key) => {
              errors[key].map((message) => {
                this.alert.error(message);
                this.submitt = false;
                this.showLeaveLoader = false;
              })
            })
         });
    }
  }

  resetPasswordFunction(){
    this.passwordSubmit = true;
      if (this.enterOtpForm.valid) {
        this.showLeaveLoader = true;
        this.authenticationService.resetPassword(this.forgotPasswordForm.value.email, this.enterOtpForm.value.otp,
          this.enterOtpForm.value.password, this.enterOtpForm.value.repassword).subscribe((data: any) => {
            this.alert.success("Password reset Successfully!");
            this.showLeaveLoader = false;
            this.passwordSubmit = false;
            this.enterOtpForm.reset()
            this.forgotPasswordForm.reset()
            this.login = true;
            this.signup = false;
            this.forgotpassword = false;
            this.enterOtp = false;
            this.resetPassword = false;
          },
          (error) => {
            const errors = error.error || error.statusText;
            Object.keys(errors).map((key) => {
              // console.log("finally revealed" + errors[key]);
              errors[key].map((message) => {
                this.alert.error(message);
                this.passwordSubmit = false;
                this.showLeaveLoader = false;
              })
            })
         });
        }
      }

  socialLogin(){
    this.loginSignUpText = "Welcome Back!"
    this.showLeaveLoader = false;
    this.socialLoginSection = true;
    this.manualLoginSection = false;
    this.login = true;
    this.emailVerify = false;
    this.forgotpassword = false;
    this.forgotpasswordFields = false;
    this.enterOtp = false;
    this.resetPassword = false;
    this.signup = false;
    this.emailVerifySubmit = false;
    this.showSending = false;
  }

  socialSignUp(){
    this.loginSignUpText = "Let's get started"
    this.showLeaveLoader = false;
    this.socialLoginSection = true;
    this.manualLoginSection = false;
    this.login = false;
    this.emailVerify = true;
    this.forgotpassword = false;
    this.forgotpasswordFields = false;
    this.enterOtp = false;
    this.resetPassword = false;
    this.signup = false;
    this.emailVerifySubmit = false;
    this.showSending = false;
  }

  manualLogin(){
    this.showSending = false;
    this.showLeaveLoader = false;
    this.socialLoginSection = true;
    this.manualLoginSection = false;
    this.login = false;
    this.emailVerify = true;
    this.forgotpassword = false;
    this.forgotpasswordFields = false;
    this.enterOtp = false;
    this.resetPassword = false;
    this.signup = false;
    this.emailVerifySubmit = false;
  }

  showLoginScreen() {
    this.socialLoginSection = true;
    this.manualLoginSection = false;
    this.forgotpassword = false;
    this.forgotpasswordFields = false;
    this.enterOtp = false;
    this.resetPassword = false;
    this.signup = false;
    this.emailVerify = false;
    this.passwordSubmit = false;
    this.submittedForm = false;
    this.emailVerifySubmit = false;
    this.submitt = false;
    this.submitted = false;
    this.login = true;
  }

  showEmailVerifyScreen() {
    this.login = false;
    this.forgotpassword = false;
    this.forgotpasswordFields = false;
    this.enterOtp = false;
    this.resetPassword = false;
    this.signup = false;
    this.passwordSubmit = false;
    this.submittedForm = false;
    this.emailVerifySubmit = false;
    this.submitt = false;
    this.submitted = false;
    this.emailVerify = true;
  }

  showSignUpScreen() {
    this.login = false;
    this.forgotpassword = false;
    this.forgotpasswordFields = false;
    this.enterOtp = false;
    this.resetPassword = false;
    this.emailVerify = false;
    this.passwordSubmit = false;
    this.submittedForm = false;
    this.emailVerifySubmit = false;
    this.submitt = false;
    this.submitted = false;
    this.signup = true;
  }

  forgotScreen(){
    this.loginForm.reset();
    this.login = false;
    this.enterOtp = false;
    this.resetPassword = false;
    this.signup = false;
    this.emailVerify = false;
    this.forgotpasswordFields = false;
    this.passwordSubmit = false;
    this.submittedForm = false;
    this.emailVerifySubmit = false;
    this.submitt = false;
    this.submitted = false;
    this.forgotpassword = true;
  }

  showResetPasswordScreen() {
    this.login = false;
    this.forgotpassword = false;
    this.forgotpasswordFields = false;
    this.enterOtp = false;
    this.emailVerify = false;
    this.signup = false;
    this.passwordSubmit = false;
    this.submittedForm = false;
    this.emailVerifySubmit = false;
    this.submitt = false;
    this.submitted = false;
    this.resetPassword = true;
  }

  backToDetails() {
    this.paymentSelected = false;
    this.mainSection = true;
  }

  hideErroMsg() {
    this.showCouponCodeError = false;
    this.couponcode = "";
  }

  applyCouponCode() {
    if(this.couponcode == 'SEASONSUMMER50' || this.couponcode == 'seasonsummer50'){
      this.showCouponCodeError = false;
      this.couponCodeErrorMsg = "";
      if(this.couponBtnText == 'Apply') {
        // this.totalHikedPrice = this.totalPriceFixed;
        // this.totalPriceFixed = this.totalPriceFixed/2;
        this.couponBtnText = "Remove";
      } else if (this.couponBtnText == 'Remove') {
        // this.totalPriceFixed = this.totalPriceFixed*2;
        this.couponBtnText = "Apply";
        this.couponcode = "";
      }
    } else {
      this.showCouponCodeError = true;
      this.couponCodeErrorMsg = "Invalid coupon";
    }
  }
}
