import { Component, OnInit } from '@angular/core';
import {SketchService} from './service/sketch.service'
import { Title, Meta } from '@angular/platform-browser';
import AOS from 'aos';

@Component({
  selector: 'app-sketch',
  templateUrl: './sketch.component.html',
  styleUrls: ['./sketch.component.css']
})
export class SketchComponent implements OnInit {

  public sketchData: any;
  public message: string;

  title = 'Sketch plugin | Crafttor';

  constructor(
    private sketchService : SketchService,
    private titleService: Title,
    private metaTagService: Meta,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'description', content: 'Sketch plugin is designed with a new idea & not preconceived for keeping designers engage with their products'},
      { name: 'keywords', content: '' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Crafttor' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);
    this.sketchService.getSketchVersion().subscribe((data: any) => {
      this.sketchData = data
    });
    AOS.init();
  }

  saveCountDownload() {
    this.sketchService.downloadCount().subscribe((data: any) => {
      this.sketchService.getSketchVersion().subscribe((data: any) => {
        this.sketchData = data
      });
    });
  }
}
