import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(
    private httpClient: HttpClient,

  ) { }
  public getPosition(): Promise<any>
   {
     return new Promise((resolve, reject) => {

       navigator.geolocation.getCurrentPosition(resp => {
           resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
         },
         err => {
           reject(err);
         });
     });

   };

 public setLocation(data: any): void{
   localStorage.setItem('currentLocation', data);
   for (var val of data['results']) {
    console.log(val['types']); // prints values: 10, 20, 30, 40
    if(val['types'].includes('country')){
      localStorage.setItem('currentCountry', val['formatted_address']);
    }
  }
 }

 public getLocation(){
   return localStorage.getItem('currentLocation')
 }

 public getCountry(){
   return localStorage.getItem('currentCountry')
 }

 public getGeoLocationGoogle(lat, lng){
   return this.httpClient.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBbTlbkGAKaCC9h1L8XtAHCAiMa6uhJ7rA`).pipe(map(data => {
     console.log('*here in service***')
     // console.log(data)
     return data
   }));

 }
}
