<section class="contact-section">
  <div class="left-bg"></div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="inner-content">
          <h1>Contact Us</h1>
          <p>Send us a message and we'll be in touch as soon as possible.</p>
          <!-- <form [formGroup]="aFormGroup">
            <ngx-recaptcha2 #captchaElem
              [siteKey]="siteKey"
              [useGlobalDomain]="false"
              [type]="type"
              formControlName="recaptcha">
            </ngx-recaptcha2>
          </form> -->
          <form class="form-horizontal" [formGroup]="contactUsForm" (ngSubmit)="submitContactUs()">
            <div class="form-group">
              <div class="col-md-12">
                <!-- <label class="control-label" for="textinput">Name</label> -->
                <input id="textinput" formControlName="name" placeholder="Full name" type="text" class="form-control input-md" required="">
                <span class="text-danger error-text"
                *ngIf="(contactUsForm.controls.name.touched || submitted) && contactUsForm.controls.name.errors?.required">
                Name is required
              </span>
              </div>
            </div>

            <!-- Text input-->
            <div class="form-group">
              <div class="col-md-12">
                <!-- <label class="control-label" for="textinput">Email</label> -->
                <input id="textinput" formControlName="email" type="email" placeholder="abc@email.com" class="form-control input-md" >
                <span class="text-danger error-text"
                *ngIf="(contactUsForm.controls.email.touched || submitted) && contactUsForm.controls.email.errors?.required">
                Email is required
              </span>
              <span class="text-danger error-text"
              *ngIf="(contactUsForm.controls.email.touched || submitted) && contactUsForm.controls.email.errors?.pattern">
              Email is not valid
            </span>
              </div>
            </div>

            <!-- Textarea -->
            <div class="form-group">
              <div class="col-md-12">
                <textarea maxlength="180" id="textinput" formControlName="message" type="text" placeholder="Type your message here..." class="form-control input-md" cols="40" rows="5"></textarea>
                <!-- <label class="control-label" for="textarea">Message</label> -->
                <!-- <input id="textinput" formControlName="message" type="text" placeholder="Type your message here..." class="form-control input-md" required=""> -->
                <span class="text-danger error-text"
                *ngIf="(contactUsForm.controls.message.touched || submitted) && contactUsForm.controls.message.errors?.required">
                Message is required
              </span>
              <span class="text-danger error-text">{{showContactErrorMsg}}</span>
              </div>
            </div>
            <div class="form-group">
              <div class="col-md-12">
                <!-- <ngx-recaptcha2 *ngIf='reload' #captchaElem
                  [siteKey]="siteKeyV2"
                  [useGlobalDomain]="false"
                  [type]="type"
                  (reset)="handleReset()"
                  formControlName="recaptcha">
                </ngx-recaptcha2> -->
                <!-- <ngx-invisible-recaptcha #captchaElem
                  [siteKey]="siteKey"
                  [useGlobalDomain]="false"
                  [type]="type"
                  [useGlobalDomain]="false"
                  [ngModel]="recaptcha"
                  [ngModelOptions]="{ standalone: true }"
                  >

                </ngx-invisible-recaptcha> -->
                <!-- <span class="text-danger"
                          *ngIf="(contactUsForm.controls.recaptcha.touched || submitted) && contactUsForm.controls.recaptcha.errors?.required">
                          Captch is required
                </span> -->
              </div>
            </div>

            <!-- Button -->
            <div class="form-group">
              <div class="col-md-12">
                <button *ngIf="!showContactSubmitLoader" id="singlebutton" name="singlebutton button" class="btn btn-primary">Submit</button>

                <button *ngIf="showContactSubmitLoader" id="singlebutton" name="singlebutton button" class="btn btn-primary"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>

              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
