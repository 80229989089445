import { Component, OnInit, ViewChild } from '@angular/core';
import { SharedService } from '../shared.service';
import { AuthenticationService } from '../authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditProfileService } from "./edit-profile.service";
import { AlertService } from '../components/alert/alert.service';
import { AuthService } from "angularx-social-login";
import { Title, Meta } from '@angular/platform-browser';
import { HeaderService } from '../components/header/header.service';
import { HomeService } from "../home/home.service";

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {

  currentUser = this.authenticationService.currentUserValue;
  submitted: Boolean = false;
  showLeaveLoader: boolean = false;
  userPic: any;
  selectedFiles: FileList;
  userPictureUrl: string;
  selectedPage: string = 'user-info';

  totalPng: any;
  totalSvg: any;
  currentPlan: any;
  planBought = [];

  userInfo: boolean = true;
  userAccount: boolean = false;
  userSubscription: boolean = false;
  userDownloads: boolean = false;
  userProjectsShared: boolean = false;
  userNotifications: boolean = false;

  totalValue = 1000; // Maximum value
  boxes: { color: string, height: string }[] = [];
  boxesPng: { color: string, height: string }[] = [];
  boxCount = 10;

  profileForm = this.fb.group({
    firstName: [this.currentUser.firstName, Validators.required],
    lastName: this.currentUser.lastName,
  });
  constructor(
    private sharedService: SharedService,
    private authService: AuthService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private modalService: NgbModal,
    private activateRoute: ActivatedRoute,
    private fb: FormBuilder,
    private profileSerice: EditProfileService,
    private alertService: AlertService,
    private titleService: Title,
    private metaTagService: Meta,
    private headerService: HeaderService,
    private homeService: HomeService,
  ) { }
  @ViewChild('deletemodal') deletemodal;

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => { this.currentUser = x });
    this.titleService.setTitle("Edit-profile | Crafttor");
    this.metaTagService.addTags([
      { name: 'description', content: 'Edit your profile and access unlimited unique vector illustrations to create anything without any blocker to your creative thoughts.'},
      { name: 'keywords', content: 'Edit profile, profile, Free illustrations, clean pixel illustrations, premium illustration, ' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Crafttor' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);
    this.getUserPic();
    this.getDownloadCountApi();

  }

  getDownloadCountApi() {
    this.homeService.getDownloadCountCall().subscribe((data: any) => {
      this.totalPng = data.total_png;
      this.totalSvg = data.total_svg;
      this.currentPlan = data.plan;
      this.planBought = data.category_bought['data'];

      this.generateBoxes(this.totalSvg);
      this.generateBoxesPng(this.totalPng);
    });
  }

  generateBoxes(svgCount: any) {
    const valuePerBox = this.totalValue / this.boxCount;
    const filledBoxes = Math.ceil(svgCount / valuePerBox);

    this.boxes = Array.from({ length: this.boxCount }, (_, index) => {
      // Calculate the height and bottom position for each box
      const heightPerRange = 20; // Base height increment per range
      const heightMultiplier = Math.floor(index / 2); // Adjust multiplier based on box index
      const height = `${(heightPerRange * (heightMultiplier + 1))}px`;

      return {
        color: index < filledBoxes ? '#2d7cff' : '#d0d0d0', // Dark blue for filled boxes, light gray for the rest
        height: height
      };
    });
  }

  generateBoxesPng(pngCount: any) {
    const valuePerBox = this.totalValue / this.boxCount;
    const filledBoxes = Math.ceil(pngCount / valuePerBox);

    this.boxesPng = Array.from({ length: this.boxCount }, (_, index) => {
      // Calculate the height and bottom position for each box
      const heightPerRange = 20; // Base height increment per range
      const heightMultiplier = Math.floor(index / 2); // Adjust multiplier based on box index
      const height = `${(heightPerRange * (heightMultiplier + 1))}px`;

      return {
        color: index < filledBoxes ? '#ffd364' : '#d0d0d0', // Dark blue for filled boxes, light gray for the rest
        height: height
      };
    });
  }

  selectCategory(category) {
    localStorage.setItem('categoryDetails', JSON.stringify({ name: category.category_name,
      image: category.category_icon, description: category.category_description }));
  }

  open(modal) {
    this.deletemodal = this.modalService.open(modal, { centered: true, windowClass: 'close-account exceed-limit' })
  }

  openClose() {
    this.deletemodal.close();
  }

  updateProfile() {
    // TODO: Use EventEmitter with form value
    this.submitted = true;
    if (this.profileForm.valid) {
      this.showLeaveLoader = true;
      const update_data = {
        first_name: this.profileForm.value['firstName'],
        last_name: this.profileForm.value['lastName']
      }
      this.profileSerice.updateProfile(this.currentUser.id, update_data).subscribe((data) => {
        this.showLeaveLoader = false;
      });
    }
  }

    deleteProfile(){
      this.profileSerice.deleteProfile(this.currentUser.id).subscribe((data) => {
        this.alertService.success("Successfully Deleted!")
      });
    }

    logOut(): void {
      this.authenticationService.logout();
      this.authService.signOut();
    }

    getUserPic() {
      this.headerService.getUserPic().subscribe((data: any) => {
        this.userPic = data.user_pic;
      });
    }

    onFileSelected(event: any): void {
      const file: File = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.userPic = "";
          this.userPictureUrl = reader.result as string;
          this.currentUser.photoUrl = this.userPictureUrl;
        };
      }

      const files = event.target.files;
      let isImage = true;
      for (let i = 0; i < files.length; i++) {
        if (files.item(i).type.match('image.*')) {
          continue;
        } else {
          isImage = false;
          alert('Invalid Format!');
          break;
        }
      }

      if (isImage) {
        this.selectedFiles = event.target.files;
        this.uploadFiles();
      } else {
        this.selectedFiles = undefined;
        event.srcElement.percentage = null;
      }
    }

    uploadFiles(): void {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.submitUserPic(this.selectedFiles[i]);
      }
    }

    submitUserPic(userPic: any) {
      this.headerService.submitUserPicData(userPic).subscribe((data: any) => {
        this.getUserPic();
      },(error: any) => {
        if(error.status == 400){
        } else {
        }
     });
    }

    selectPage(page: string) {
      this.selectedPage = page;
      if(page == "user-info") {
        this.userInfo = true;
        this.userAccount = false;
        this.userSubscription = false;
        this.userDownloads = false;
        this.userProjectsShared = false;
        this.userNotifications = false;
      } else if(page == "account") {
        this.userInfo = false;
        this.userAccount = true;
        this.userSubscription = false;
        this.userDownloads = false;
        this.userProjectsShared = false;
        this.userNotifications = false;
      } else if(page == "subscription") {
        this.userInfo = false;
        this.userAccount = false;
        this.userSubscription = true;
        this.userDownloads = false;
        this.userProjectsShared = false;
        this.userNotifications = false;
      } else if(page == "downloads") {
        this.userInfo = false;
        this.userAccount = false;
        this.userSubscription = false;
        this.userDownloads = true;
        this.userProjectsShared = false;
        this.userNotifications = false;
      } else if(page == "projects-shared") {
        this.userInfo = false;
        this.userAccount = false;
        this.userSubscription = false;
        this.userDownloads = false;
        this.userProjectsShared = true;
        this.userNotifications = false;
      } else if(page == "notifications") {
        this.userInfo = false;
        this.userAccount = false;
        this.userSubscription = false;
        this.userDownloads = false;
        this.userProjectsShared = false;
        this.userNotifications = true;
      }
    }

    buyMembership() {
      this.router.navigate(['/member']);
    }
}
