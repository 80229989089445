import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from './../environments/environment';
import { User } from './_models/user';
import { Router } from '@angular/router';
import { LocationService } from './location/location.service';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {


  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private locationService: LocationService,
  ) {
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
      this.currentUser = this.currentUserSubject.asObservable();
  }

  api_root: string = environment.REST_API_SERVER;

  public get currentUserValue(): User {
      return this.currentUserSubject.value;
  }

  private get_user_from(){
    return sessionStorage.getItem("user_from")
  }

  login(provider: string, uid: string, access_token: string,
    email:string, firstName: string, lastName: string, photoUrl: string,
    latitude: any, longitude: any) {
  let country = this.locationService.getCountry();
  let  post_data = { "provider":provider, "uid": uid, "first_name":firstName,
  "last_name":lastName, "access_token":access_token, "email":email, "latitude": latitude,
  "longitude": longitude };
  let user_from = this.get_user_from()
  // if(user_from){
  post_data["user_from"] = "web";
  // }
  if(country){
    post_data['country'] = country;
  }
      return this.http.post<any>(`${environment.REST_API_SERVER}users/login/`, post_data)
          .pipe(map(user => {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              user['firstName'] = user['first_name'];
              user['lastName'] = user['last_name'];
              user['refreshToken'] = user['refresh_token'];
              user['photoUrl'] = photoUrl;
              user['latitude'] = latitude;
              user['longitude'] = longitude;
              localStorage.setItem('currentUser', JSON.stringify(user));
              this.currentUserSubject.next(user);
              return user;
          }));
  }

  refreshToken() {
    // const token= this.getRefreshToken();
    const user = this.currentUserValue;

  let  post_data = { "refresh":user.refreshToken};
      return this.http.post<any>(`${environment.REST_API_SERVER}token/refresh/`, post_data)
          .pipe(map(data => {
              user['token'] = data['access']
              user['refreshToken'] = data['refresh'];
              localStorage.setItem('currentUser', JSON.stringify(user));
              // this.authenticationService.currentUserValue;
              this.currentUserSubject.next(user);
              return data;
          }),
        );
  }

  updateUser(user){
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
  }

  logout() {
      // remove user from local storage to log user out
      localStorage.removeItem('currentUser');
      this.currentUserSubject.next(null);
        this.router.navigate(['/']);
  }

  registerWithEmail(firstName: string, lastName: string, email: string, password: any, otp: any, workspace_token: string){
    let country = this.locationService.getCountry();
    let  post_data = {"first_name":firstName, "last_name":lastName, "email":email, "password": password, "otp": otp, "workspace_token": workspace_token};
    let user_from = this.get_user_from()
    // if(user_from){
    post_data["user_from"] = "dam";
    // }
    if(country){
      post_data['country'] = country;
    }
      return this.http.post<any>(`${environment.REST_API_SERVER}users/register/`, post_data)
          .pipe(map(user => {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              user['firstName'] = user['first_name'];
              user['lastName'] = user['last_name'];
              user['refreshToken'] = user['refresh_token'];
              localStorage.setItem('currentUser', JSON.stringify(user));
              this.currentUserSubject.next(user);
              return user;
          }));
  }

  loginWithEmail(email:any, password: any, workspace_token: string){
    let  post_data = {"email":email, "password": password, "workspace_token": workspace_token}
    return this.http.post<any>(`${environment.REST_API_SERVER}users/email_login/`, post_data)
          .pipe(map(user => {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              user['firstName'] = user['first_name'];
              user['lastName'] = user['last_name'];
              user['refreshToken'] = user['refresh_token'];
              localStorage.setItem('currentUser', JSON.stringify(user));
              this.currentUserSubject.next(user);
              return user;
          }));
  }

  getOTP(email: string){
    this.api_root = environment.REST_API_SERVER;
    let url = this.api_root + 'users/generate_email_otp/';
    const data = {
      "email": email
    }

    return this.http.post<any>(url, data);
  }

  getResetPasswordOTP(email: string){
    this.api_root = environment.REST_API_SERVER;
    let url = this.api_root + 'users/generate_forgot_otp/';
    const data = {
      "email": email
    }

    return this.http.post<any>(url, data);
  }

  resetPassword(email: string, otp: any, password: any, confirmPassword: any){
    this.api_root = environment.REST_API_SERVER;
    let url = this.api_root + 'users/reset_password/';
    const data = {
      "email": email,
      "otp": otp,
      "password": password,
      "confirm_password": confirmPassword,
    }

    return this.http.post<any>(url, data);
  }
}
