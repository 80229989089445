import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpRequest, HttpEvent } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  api_root: string = environment.REST_API_SERVER;

  private showLoginSubject: BehaviorSubject<boolean>;

  public showLogin: Observable<boolean>;
  constructor(
    private httpClient: HttpClient,
  ) {
    this.showLoginSubject = new BehaviorSubject<boolean>(false);
    this.showLogin = this.showLoginSubject.asObservable();
  }
  setLoginModalValue(state: boolean) {
    this.showLoginSubject.next(state)
  }
  setUserFromSession(from: string){
    sessionStorage.setItem("user_from", from);
  }
  getUserFromSession(){
    return sessionStorage.getItem("user_from")
  }

  submitUserPicData(file: File): Observable<HttpEvent<any>> {
    const api_root = environment.REST_API_SERVER;
    const formData: FormData = new FormData();
    formData.append('user_pic', file);
    const req = new HttpRequest('POST', `${api_root}users/set_profile_pic/`, formData, {
      reportProgress: true,
      responseType: 'json'
    });
    return this.httpClient.request(req);
  }

  getUserPic() {
    this.api_root = environment.REST_API_SERVER;
    let url = this.api_root + 'users/get_profile_pic/';
    return this.httpClient.get(url)
  }
}
