<!-- Hero Section -->
<section class="hero-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-6">
				<div class="inner-content">
					<h1>Discover the Best Digital Resources</h1>
					<p>Make your work more appealing with the mix & match combinations of unlimited illustration styles.</p>
					<div class="btn-wrapper">
						<a href="javascript:;" routerLink="/member" class="button" (click)="homeMemberShip()">Become Member</a>
						<a href="javascript:;" class="button gray-btn" routerLink="/search" (click)="homeSearchIllus('2d')">Search Illustration</a>
					</div>
				</div>
			</div>
			<div class="col-sm-6">
				<div class="img-box">
					<img src="assets/img/hero-img.gif" alt="download gif illustration">
				</div>
			</div>
		</div>
	</div>
</section>

  <!-- Category Section -->
<section class="vectorimg-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<h2>Royalty Free Illustration Styles</h2>
				<p class="p-txt">High-quality digital resources for a faster and smoother design workflow.</p>
			</div>
		</div>
		<div class="row">
			<ul class="load-list">
				<li *ngFor="let category of categories">
					<!-- <div class="vector-box" routerLink="search/" [queryParams]="{category: category.id}">
						<div class="thumb">
							<img [src]="category.image" alt="fit professional funny illustrations">
						</div>
						<div class="category-title">
							<div class="category-price-section">
								<h5 *ngIf="isIndia">₹{{ category.price_inr }}</h5>
								<h5 *ngIf="!isIndia">${{ category.price_usd }}</h5>
							</div>
							<h3>{{ category.name }}</h3>

						</div>

						<p>{{ category.description }}</p>

						<div class="btn-wrapper-category">
							<a href="javascript:;" routerLink="member" class="button">Buy now</a>
						</div>
					</div> -->

					<div *ngIf="category.type == '2d'; else elseBlock" class="vector-box" routerLink="/illustration" [queryParams]="{category: category.id}" (click)="selectValue(category)">
						<div *ngIf="category.is_sponsored == true" class="sponsored-title">
							<div class="sponsored-section">
								<h5>Sponsored</h5>
							</div>
						</div>
						<div class="thumb">
							<img [src]="category.image" alt="fit professional funny illustrations">
						</div>
						<div class="category-title">
							<div class="category-price-section">
								<!-- <h5 id="priceNotIndia" *ngIf="category.is_bought == 'No'">${{ category.price_usd }}</h5>
								<h5 id="priceNotIndia" *ngIf="category.is_bought == 'NA'">${{ category.price_usd }}</h5>
								<h5 id="priceNotIndia" *ngIf="category.is_bought == 'Yes'">Purchased</h5> -->

								<h5 id="priceNotIndia" *ngIf="category.is_bought == 'No' && category.is_sponsored == false">${{ category.price_usd }}</h5>
								<h5 id="priceNotIndia" *ngIf="category.is_bought == 'NA' && category.is_sponsored == false">${{ category.price_usd }}</h5>
								<h5 id="priceNotIndia" *ngIf="category.is_bought == 'Yes' && category.is_sponsored == false">Purchased</h5>
								<h5 id="priceNotIndia" *ngIf="(category.is_bought == 'No' || category.is_bought == 'NA') && category.is_sponsored == true"><a class="sponsored-web-url" href="{{category.web_url}}" target="_blank">Website</a></h5>

								<h5 id="priceNotIndia" *ngIf="category.is_bought == 'No' && category.is_sponsored == false"><del>$35</del></h5>
								<h5 id="priceNotIndia" *ngIf="category.is_bought == 'NA' && category.is_sponsored == false"><del>$35</del></h5>
								<h5 id="priceNotIndia" *ngIf="category.is_bought == 'Yes' && category.is_sponsored == false"></h5>
								<h5 id="priceNotIndia" *ngIf="(category.is_bought == 'No' || category.is_bought == 'NA') && category.is_sponsored == true"><a class="sponsored-web-url" href="{{category.web_url}}" target="_blank"></a></h5>
							</div>

							<h3>{{ category.name }}</h3>
						</div>

						<p>{{ category.description }}</p>

						<div class="btn-wrapper-category">
							<a *ngIf="(category.is_bought == 'No' || category.is_bought == 'NA') && category.is_sponsored == true" href="javascript:;" routerLink="/illustration" [queryParams]="{category: category.id}" (click)="selectValue(category)" class="button">View All</a>

							<a *ngIf="category.is_bought == 'Yes' && category.is_sponsored == false" href="javascript:;" routerLink="/illustration" [queryParams]="{category: category.id}" (click)="selectValue(category)" class="button">View All</a>

							<a *ngIf="category.is_bought == 'NA' && category.is_sponsored == false" href="javascript:;"
							(click)="sendPrice(category.price_usd, category.name, category.image,
							category.id, category.description)" class="button">Buy now</a>

							<a *ngIf="category.is_bought == 'No' && category.is_sponsored == false" href="javascript:;"
							(click)="sendPrice(category.price_usd, category.name,
							category.image, category.id, category.description)" class="button">Buy now</a>
						</div>
					</div>

					<ng-template #elseBlock>

						<div #elseBlock class="vector-box" routerLink="/3d-illustrations" [queryParams]="{category: category.id}" (click)="selectValue(category)">
							<div class="thumb">
								<img [src]="category.image" alt="fit professional funny illustrations">
							</div>
							<div class="category-title">

								<div class="category-price-section">
									<!-- <h5 *ngIf="isIndia" id="priceIndia">₹{{ category.price_inr }}</h5> -->
									<h5 id="priceNotIndia" *ngIf="category.is_bought == 'No'">${{ category.price_usd }}</h5>
									<h5 id="priceNotIndia" *ngIf="category.is_bought == 'NA'">${{ category.price_usd }}</h5>
									<h5 id="priceNotIndia" *ngIf="category.is_bought == 'Yes'">Purchased</h5>

									<h5 id="priceNotIndia" *ngIf="category.is_bought == 'No'"><del>$45</del></h5>
									<h5 id="priceNotIndia" *ngIf="category.is_bought == 'NA'"><del>$45</del></h5>
									<h5 id="priceNotIndia" *ngIf="category.is_bought == 'Yes'"></h5>
								</div>

								<h3>{{ category.name }}</h3>
							</div>

							<p>{{ category.description }}</p>

							<div class="btn-wrapper-category">
								<!-- <a href="javascript:;" *ngIf="isIndia" routerLink="payment/" (click)="sendPrice(category.price_inr, category.name, category.image, category.id)" class="button">Buy now</a> -->
								<a *ngIf="category.is_bought == 'Yes'" href="javascript:;" routerLink="/3d-illustrations" [queryParams]="{category: category.id}" (click)="selectValue(category)" class="button">View</a>

								<a *ngIf="category.is_bought == 'NA'" href="javascript:;"
								(click)="sendPrice(category.price_usd, category.name,
								category.image, category.id, category.description)" class="button">Buy now</a>

								<a *ngIf="category.is_bought == 'No'" href="javascript:;"
								 (click)="sendPrice(category.price_usd, category.name,
								 category.image, category.id, category.description)" class="button">Buy now</a>
							</div>
						</div>
					</ng-template>
				</li>
			</ul>
		</div>
	</div>
</section>

<!-- Create Anything Section -->
<section class="createuwant-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<h2>Why Crafttor?</h2>
				<p>Trendy and artistic illustrations to make your interfaces<br> look stylish.</p>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-4">
				<div class="inner-box">
					<img src="assets/img/quality.svg" alt="high quality illustrations">
					<h6>High-quality illustrations</h6>
					<p>We will provide you the high-quality illustrations <br>in the market.</p>
				</div>
			</div>
			<div class="col-sm-4">
				<div class="inner-box">
					<img src="assets/img/free.svg" alt="free illustrations">
					<h6>Free to use</h6>
					<p>We give free PNG & SVG files for your <br>every project.</p>
				</div>
			</div>
			<div class="col-sm-4">
				<div class="inner-box">
					<img src="assets/img/updates.svg" alt="vector illustrations">
					<h6>Regular Updates</h6>
					<p>Every month we create a new style for the <br>competitive market.</p>
				</div>
			</div>
		</div>
	</div>
  </section>

	<!-- Carousel Section -->
	<section class="carousel-section">
		<div class="container">
			<div class="col-sm-12">
				<h2>Our Customers</h2>
			</div>
			<div class="row" style="margin-top: -20px;">
				<div class="col-sm-12">
					<div class="regular slider">
						<ngb-carousel (slide)="slideActivate($event)">

						<ng-template ngbSlide>

							<div class="picsum-img-wrapper">
						<div class="slide-desc">
							<div class="inner-desc">
								<div class="thumb">
									<img src="assets/img/pnew.jpeg" alt="nickelfox likes our beautiful illustrations">
									<!-- <img src="https://s3.ap-south-1.amazonaws.com/crafttor.com/assets/img/pnew.jpeg" alt=""> -->
								</div>
								<div class="content">
									<h3>Falling In love with these illustrations.</h3>
									<p>We at Nickelfox found ourself spoiled by the amazing illustrations by Crafttor. What an amazing resource. If you're ever looking
										for beautiful SVG illustrations for your app or website Crafttor is your go-to place. Saves a lot of time.</p>
									<div class="name">Prashish</div>
									<div class="designation"><span>Founder:</span> Nickelfox</div>
								</div>
							</div>
						</div>
					</div>

				</ng-template>
				<ng-template ngbSlide>

					<div class="picsum-img-wrapper">
						<div class="slide-desc">
							<div class="inner-desc">
								<div class="thumb">
									<img src="assets/img/snew.jpeg" alt="anthillcreations use svg illustrations">
									<!-- <img src="https://s3.ap-south-1.amazonaws.com/crafttor.com/assets/img/snew.jpeg" alt=""> -->
								</div>
								<div class="content">
									<h3>Best Illustrations I Ever have.</h3>
									<p>We have been using Crafttor since the last few months and it has fulfilled all our core graphic design requirements. Not only do they have variety, the graphics are easily editable and reconfigurable.
										The designers in my team are delivering high quality outcomes in half the time.</p>
									<div class="name">Souradeep Paul</div>
									<div class="designation"><span>Founder:</span> anthillcreations.org</div>
								</div>
							</div>
						</div>
					</div>

				</ng-template>
				<ng-template ngbSlide>

					<div class="picsum-img-wrapper">
						<div class="slide-desc">
							<div class="inner-desc">
								<div class="thumb">
									<img src="assets/img/prnew.jpeg" alt="ketoindia use vector graphics">
									<!-- <img src="https://s3.ap-south-1.amazonaws.com/crafttor.com/assets/img/prnew.jpeg" alt=""> -->
								</div>
								<div class="content">
									<h3>High quality illustrations for every business.</h3>
									<p>Crafttor and the team helped us in the early days at Keto India, in the website design and illustrations. The team was able to help us with high quality illustrations
										that helped us, not just build a beautiful, engaging website - but a highly converting one!</p>
									<div class="name">Sahil Pruthi</div>
									<div class="designation"><span>Founder:</span> Livofy</div>
								</div>
							</div>
						</div>
					</div>

				</ng-template>
				<ng-template ngbSlide>

					<div class="picsum-img-wrapper">
						<div class="slide-desc">
							<div class="inner-desc">
								<div class="thumb">
									<img src="assets/img/sanew.jpeg" alt="innerfit likes our impressive illustrations">
									<!-- <img src="https://s3.ap-south-1.amazonaws.com/crafttor.com/assets/img/sanew.jpeg" alt=""> -->
								</div>
								<div class="content">
									<h3>Extremely Impressive Illustrations.</h3>
									<p>Crafttor is the best place for the illustrations for applications & websites. Crafttor's illustrations helped us in designing our platform more interesting & engaging
									for our business levels. I highly recommend its illustrations to everyone.</p>
									<div class="name">Sanchit Garg</div>
									<div class="designation"><span>Founder:</span> innerfit.me</div>
								</div>
							</div>
						</div>
					</div>

				</ng-template>
			</ngb-carousel>
					  </div>
				</div>
			</div>
		</div>
	</section>

  <!-- Lets Make Section -->
<section class="letsmake-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<div class="letsmake-box">
					<h2>Let’s make world a better place </h2>
					<p>You're free to use these illustrations for both personal and commercial projects.</p>
					<div class="price-card">

						<div class="inner-card" *ngFor="let membership of memberships | slice:0:2">
							<div class="card-box">
								<img src="assets/img/{{membership.image}}" alt="free and membership plans">
								<h3>{{membership.text}}</h3>
								<div class="price-area">
									<span class="price-home" *ngIf="isIndia">{{membership.amount_text.INR.now}}</span>
									<span class="price-home" *ngIf="!isIndia">{{membership.amount_text.USD.now}}</span>
									<span class="main-price" *ngIf="membership.text == 'Free'">Free Access</span>
								<span class="main-price" *ngIf="membership.text != 'Free' && isIndia">Earlier <span><s>{{membership.amount_text.INR.earlier}}</s></span></span>
								<span class="main-price" *ngIf="membership.text != 'Free' && !isIndia">Earlier <span><s>{{membership.amount_text.USD.earlier}}</s></span></span>
								</div>
								<ul>
									<li *ngFor="let li of membership.li">{{li}}</li>
								</ul>
								<a href="javascript:;" class="button gray-btn" *ngIf="membership.text == 'Free'" routerLink="/search">Download</a>
								<a href="javascript:;" class="button" *ngIf="membership.text != 'Free'" routerLink="/member">Become Member</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- Solving Section -->
<section class="solving-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<h2 style="margin-top:70px;">What Crafttor Solving?</h2>
				<p>Our sole purpose is to provide digital craftsmen with a better tool to create awesome products. We aim for every creator in the world to be able to bring their creative thoughts to life without encountering any obstacles.</p>
				<ul class="solving-pointers">
					<li>We at Crafttor believe if creators have good resources, they can change the world with their creativity.</li>
					<li>We are trying to cover every subject like empty states, virus, coronavirus, etc. which can help you in your project.</li>
				</ul>
				<p>Crafttor gives free & high-quality trending illustrations that would go with every project of yours like a website, app, PPT, print, etc.</p>
				<!--<p>Creators who don’t draw face the problem of the boring content for app screens, articles, landing pages and emails. Ouch helps creators who don’t draw overcome the lack of quality graphics. Download the free illustrations from top Dribbble artists to class up your product.</p>
				<p>We have paid the top Dribbble artists. So you don’t have to! The result is the illustrations in 27 styles for 23 typical UX screens:</p>-->
				<ul class="tags">
					<li *ngFor="let illu of illus"><a href="javascript:;" routerLink="/search" (click)="loadDataOnly('2d')" [queryParams]="{search: illu.title}">{{ illu.title }}</a></li>
					<!-- <li><a href="javascript:;">Empty States</a></li>
					<li><a href="javascript:;">Technology</a></li> -->
				</ul>
			</div>
		</div>
	</div>
</section>

<section class="new-blog-section">
	<div class="container">
		<div class="row">
			<div class="col-sm-12">
				<h2>Community</h2>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-4 community-email">
				<img (click)="openRequestIconsModal(requesticons)" src="assets/img/community-email.svg" alt="" style="background: #DAFDFF">
				<h4 class="community-txt">Request Illustrations</h4>
				<span class="community-desc-txt">Missing an illustration? We’ll design it for you.</span>
			</div>

			<!-- <div class="col-sm-3 community-email">
				<img (click)="forum()" src="assets/img/community-msg.svg" alt="">
				<h4 class="community-txt">Forum</h4>
				<span class="community-desc-txt">Coming soon!!.</span>
			</div> -->

			<div class="col-sm-4 community-email" (click)="forum()">
				<img src="assets/img/community-msg.svg" alt="">
				<h4 class="community-txt">Upload your illustrations</h4>
				<span class="community-desc-txt">Coming soon!!.</span>
				<!-- <span class="community-desc-txt">Earn money by sharing your illustrations. We'll upload them and give you % of the subscription revenue from your illustrations.</span> -->
			</div>

			<div class="col-sm-4 community-msg">
				<img (click)="contactUs()" src="assets/img/community-edit.svg" alt="">
				<h4 class="community-txt">Contact Us</h4>
				<span class="community-desc-txt">Connect with us for any queries.</span>
			</div>
		</div>
	</div>
</section>

<ng-template #requesticons let-requesticons>
	<div class="custom-dialog custom-dialog-centered request-icons-popup" role="document">
		<div class="modal-content">
			<div class="modal-body">
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<img src="assets/img/cross.svg" (click)="requesticons.close()">
				</button>
				<div class="modal-body">
					<h2>Request Illustrations</h2>
					<div class="form-wrapper">
					<form class="row g-3" [formGroup]="requestIconsForm" (ngSubmit)="submitIconsRequest()">
						<div class="col-md-12">
							<!-- <label class="form-label">First Name<span>*</span></label> -->
							<input id="textinput" formControlName="name" type="text" placeholder="Brian Adam" class="form-control input-md" required>
							<span class="text-danger error-text"
							*ngIf="(requestIconsForm.controls.name.touched || submitted) && requestIconsForm.controls.name.errors?.required">
							Name is required
							</span>
						</div>
						<div class="col-12">
							<!-- <label for="businessEmail" class="form-label">Business Email<span>*</span></label> -->
							<input id="textinput" formControlName="email" type="email" placeholder="abc@crafttor.com" class="form-control input-md" >
							<span class="text-danger error-text"
							*ngIf="(requestIconsForm.controls.email.touched || submitted) && requestIconsForm.controls.email.errors?.required">
							Email is required
							</span>
							<span class="text-danger error-text"
							*ngIf="(requestIconsForm.controls.email.touched || submitted) && requestIconsForm.controls.email.errors?.pattern">
							Email is not valid
							</span>
						</div>
						<div class="col-12">
							<!-- <label for="company" class="form-label">Icons name<span>*</span></label> -->
							<input id="textinput" formControlName="message" type="text" placeholder="Illustrations name (comma separated)" class="form-control input-md" >
							<span class="text-danger error-text"
							*ngIf="(requestIconsForm.controls.message.touched || submitted) && requestIconsForm.controls.message.errors?.required">
							Illustrations name is required
							</span>
						</div>

						<div class="col-12">
							<button id="singlebutton" name="singlebutton button" class="btn btn-primary request-submit-btn" *ngIf="!showLeaveLoader">Submit</button>
							<button id="singlebutton" name="singlebutton button" class="btn btn-primary request-submit-btn" *ngIf="showLeaveLoader"><img src="assets/img/leave-loader.gif" alt="" style="height: 15px;"></button>
						</div>
					</form>
				 </div>
					<p style="font-size: 13px; color: #ff0000;">{{showContactErrorMsg}}</p>
				</div>
			</div>
		</div>
	</div>
</ng-template>

	<!-- <div class="modal-dialog modal-dialog-centered  sale-pop" role="document">
		<div class="modal-content">
			<div class="modal-body">
				<h4>Limited Period Offer</h4>
				<br>
				<h2>30% Off</h2>
				<h6>Till 30th September</h6>
				<div class="btn-wrapper"><a class="button yellow-btn" routerLink="member/" href="javascript:;">Go Premium</a></div>
			</div>
		</div>
	</div> -->
