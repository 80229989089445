import { Component, Renderer2, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Crafttor - Illustrations for everyone';

  // login = false;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private elRef: ElementRef,

  ) {
    // this.router.events.subscribe((event: any) => {
    //   if (event instanceof NavigationEnd) {
    //     if (event.url === '/mkt') {
    //       this.login= true;
    //     } else {
    //       this.login= false;
    //     }
    //   }
    // });

    this.router.events.subscribe(event => {
         if(event instanceof NavigationEnd){
             gtag('config', 'UA-171026078-2',
                   {
                     'page_path': event.urlAfterRedirects
                   }
                  );
          }
       }
    )
  }

      ngOnInit() {
          this.router.events.subscribe((evt) => {
              if (!(evt instanceof NavigationEnd)) {
                  return;
              }
              window.scrollTo(0, 0);
              // Hide nabar when component changed
              this.renderer.removeClass(this.elRef.nativeElement.querySelector('#navbarResponsive'), 'show')
          });
      }
}
