import { Injectable } from '@angular/core';
import * as alertify from 'alertifyjs'

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() { }

  confirm(message: string, okCallback: () => any) {
    alertify.confirm('Please Confirm', message,
      function(e: any) {
        okCallback();
      },
      function() {
      }
    );
  }

  alert(header:string, message: string, okCallback:()=> any) {
    alertify.confirm(header, message, function () {
        okCallback();
    }, function () {

    }
    );
  }


  success(message: string) {
    alertify.success(message);
  }

  error(message: string) {
    alertify.error(message);
  }

  warning(message: string) {
    alertify.warning(message);
  }

  message(message: string) {
    alertify.message(message);
  }
}
